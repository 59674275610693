import React from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import moment from 'moment'

import { Text } from 'components/typo'
import Layout from 'pages/account/components/Layout'
import { Link } from 'components/link'

import CreditButton from '../../../components/button/CreditButton'
// import PrompayButton from '../../../components/button/PrompayButton'

const PaymentForm = (props) => {
  const { history } = props
  const user = props.member.toJS().user

  const isShowSkip = moment().isSameOrBefore(user.expired_at)

  return (
    <Layout header="ชำระเงิน" subHeader="ขั้นตอนที่ 4 จาก 4">
      <Text gray>เลือกวิธีชำระเงิน</Text>
      <Space size={3} />
      <CreditButton onClick={() => history.push('/account/payment-card')} />
      {/* <Space size={2} />
      <PrompayButton
        onClick={() => history.push('/account/payment-promptpay')}
      /> */}
      <Space size={4} />

      {isShowSkip && (
        <Link to={'/account/profile'}>
          <Skip>
            <Text size="small" bold primary>
              ข้าม
            </Text>
          </Skip>
        </Link>
      )}
    </Layout>
  )
}

const Space = styled.div`
  margin-top: ${(p) => p.size * 8}px;
`

const Skip = styled.div`
  text-align: center;

  span {
    cursor: pointer;
  }
`

export default inject('member')(observer(PaymentForm))
