import React, { useState } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import { Text } from 'components/typo'
import CreditCard from 'components/input/CreditCard'
import SubmitPayment from 'components/button/SubmitPayment'
import AccountLayout from 'pages/account/components/AccountLayout'

const MyPaymentCard = (props) => {
  const [isSubmit, setSubmit] = useState(false)
  const [card, setCard] = useState({
    name: '',
    surname: '',
    card_id: '',
    expired: '',
    cvv: '',
    ex_month: '',
    ex_year: '',
    invalid: true,
  })

  const { onPayment } = props

  const onChange = (val) => {
    setCard(val)
    setSubmit(false)
  }

  const onError = async () => {
    setCard({ ...card, invalid: true })
    setSubmit(true)
  }

  const onNext = (cardInfo) => {
    if (card.invalid) {
      setSubmit(true)
    } else {
      onPayment(cardInfo)
    }
  }

  const disabled = card.invalid
  return (
    <AccountLayout
      title="ชำระเงินทันที"
      backTo={`/account/my-payment`}
      backText="ข้อมูลการชำระเงิน"
    >
      <Content>
        <Text size="large" bold>
          ข้อมูลบัตรเครดิต หรือบัตรเดบิต
        </Text>
        <Description />
        <CreditCard card={card} isSubmit={isSubmit} onChange={onChange} />
        <SubmitPayment
          text="ถัดไป"
          card={card}
          onClick={onNext}
          onError={onError}
          disabled={disabled}
        />
      </Content>
    </AccountLayout>
  )
}

const Description = styled.div`
  margin: 23px 0 33px;
`

const Content = styled.div`
  padding-top: 20px;
  width: 325px;
  margin-bottom: 70px;
`

export default inject('member', 'payment')(observer(MyPaymentCard))
