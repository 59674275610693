import React from 'react'
import moment from 'moment'

import { CalendarIcon } from 'icon'
import { format } from 'utils'

import Calendar from '../Calendar'
import FieldInput from './FieldInput'

const DatePicker = (props) => {
  const {
    label = 'วัน เดือน ปี',
    small,
    value,
    name,
    show,
    onChange,
    errorText,
    isAll,
  } = props

  const onValChange = (val) => {
    onChange(val, name)
  }

  const val = value ? format.toShortMonthDate(value) : undefined

  return (
    <Calendar
      name={name}
      value={val}
      show={show}
      isAll={isAll}
      onChange={onValChange}
    >
      <FieldInput
        small={small}
        maxWidth="250px"
        label={label}
        value={val}
        errorText={errorText}
        readonly
        endIcon={<CalendarIcon />}
      />
    </Calendar>
  )
}

export default DatePicker
