import React, { useState, useEffect, memo } from 'react'
import styled from 'styled-components'
import filter from 'lodash/filter'

import { Close } from 'icon'
import IconButton from 'components/button/IconButton'
import { Button } from 'components/button'
import { Dropdown, FieldInput } from 'components/input'
import { helper } from 'utils'
import useMediaQuery from 'hooks/useMediaQuery'
import DropdownTax from 'components/input/AddressDropdown'

// import rules from '../../pages/account/profile/edit/rules'

const ModalTax = (props) => {
  const { isOpened, setOpen, setSaveText, taxinvoice, setTaxinvoice, user } =
    props
  const isMobile = useMediaQuery('(max-width:713px)')
  const [validate, setValidate] = useState(false)

  const [error, setError] = useState({
    firstNameError: '',
    lastNameError: '',
    companyNameError: '',
    taxNumberError: '',
    phoneNumberError: '',
    emailError: '',
    houseNoError: '',
    roadError: '',
    subDistrictError: '',
    districtError: '',
    provinceError: '',
    postcodeError: '',
  })
  const [currentuser, currentsetuser] = useState(user)

  const taxType = [
    { label: 'บุคคลธรรมดา', value: 'individuals' },
    { label: 'นิติบุคคล', value: 'juristic' },
  ]

  const clearValidate = () => {
    setError('')
  }

  const closeModal = () => setOpen(false)
  const onClickInside = (e) => {
    e.stopPropagation()
  }

  const validateError = () => {
    let firstNameError = ''
    let emailError = ''
    let lastNameError = ''
    let companyNameError = ''
    let taxNumberError = ''
    let phoneNumberError = ''
    let houseNoError = ''
    let subDistrictError = ''
    let districtError = ''
    let provinceError = ''
    let postcodeError = ''
    // eslint-disable-next-line
    const re =
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (taxinvoice.first_name === '') {
      firstNameError = 'กรุณากรอกชื่อ'
    }

    if (taxinvoice.company_name === '') {
      companyNameError = 'กรุณากรอกชื่อบริษัท'
    }
    if (taxinvoice.email === '') {
      emailError = 'กรุณากรอกอีเมล'
    }
    if (
      re.test(String(taxinvoice.email).toLowerCase()) === false &&
      taxinvoice.email !== ''
    ) {
      emailError = 'รูปแบบอีเมลไม่ถูกต้อง'
    }
    if (taxinvoice.last_name === '') {
      lastNameError = 'กรุณากรอกสกุล'
    }

    if (taxinvoice.tax_no.length < 13) {
      if (taxinvoice.tax_type === 'juristic') {
        taxNumberError = 'หมายเลขนิติบุคคลไม่ครบ13หลัก'
      } else {
        taxNumberError = 'หมายเลขบัตรประชาชนไม่ครบ13หลัก'
      }
    }
    if (taxinvoice.phone_number === '') {
      phoneNumberError = 'กรุณากรอกหมายเลขโทรศัพท์'
    }
    if (taxinvoice.house_no === '') {
      houseNoError = 'กรุณากรอกบ้านเลขที่'
    }
    if (taxinvoice.sub_district === '') {
      subDistrictError = 'กรุณาเลือกตำบล/แขวง'
    }

    if (taxinvoice.district === '') {
      districtError = 'กรุณาเลือกอำเภอ'
    }
    if (taxinvoice.postcode === '') {
      postcodeError = 'กรุณาเลือกรหัสไปรษณีย์'
    }
    if (taxinvoice.province === '') {
      provinceError = 'กรุณาเลือกจังหวัด'
    }
    setError({
      ...error,
      firstNameError: firstNameError || '',
      emailError: emailError || '',
      lastNameError: lastNameError || '',
      companyNameError: companyNameError || '',
      taxNumberError: taxNumberError || '',
      phoneNumberError: phoneNumberError || '',
      houseNoError: houseNoError || '',
      subDistrictError: subDistrictError || '',
      districtError: districtError || '',
      provinceError: provinceError || '',
      postcodeError: postcodeError || '',
    })
    if (
      firstNameError ||
      emailError ||
      lastNameError ||
      companyNameError ||
      taxNumberError ||
      phoneNumberError ||
      houseNoError ||
      subDistrictError ||
      districtError ||
      provinceError ||
      postcodeError
    ) {
      setValidate(true)
      return false
    } else return true
  }
  const onChange = (val, name) => {
    setTaxinvoice({ ...taxinvoice, [name]: val })
    clearValidate()
  }
  const onChangeMobileNo = (val, name) => {
    const newValue = String(val || '').replaceAll('-', '')
    if (Number.isNaN(Number(newValue))) return

    if (newValue.length <= 10) {
      onChange(newValue, name)
    }
    clearValidate()
  }

  const onChangeiDCard = (val, name) => {
    const newValue = String(val || '').replaceAll('-', '')
    if (Number.isNaN(Number(newValue))) return
    if (newValue.length <= 13) {
      onChange(newValue, name)
    }
    clearValidate()
  }

  const onSave = () => {
    if (validateError()) {
      setSaveText(true)
      closeModal()
    }
    if (
      (taxinvoice.first_name === '' && taxinvoice.company_name !== '') ||
      (taxinvoice.first_name !== '' && taxinvoice.company_name === '')
    ) {
      setSaveText(true)
      closeModal()
    }
  }

  const errorValidateAddress = (name) => {
    if (name === 'district') {
      return error.districtError
    }
    if (name === 'sub_district') {
      return error.subDistrictError
    }
    if (name === 'province') {
      return error.provinceError
    }
    if (name === 'postcode') {
      return error.postcodeError
    }
  }

  useEffect(() => {
    setTaxinvoice({
      ...taxinvoice,
      phone_number: currentuser.mobile || '',
      email: currentuser.email || '',
      tax_type: 'individuals',
    })
  }, [])

  //  const AutoCompleteAddress = memo(() => {
  //   return addressFied.map((address) => {
  //     return (
  //       <>
  //         <DropdownTax
  //           address={address}
  //           filterable
  //           value={taxinvoice[address.key]}
  //           onChange={(e) => {
  //             setTaxinvoice({ ...taxinvoice, ...e })
  //           }}
  //           // options={helper.generateAddressStructure()}
  //           small
  //           label={address.label}
  //           name={address.key}
  //           show={{ h: 'center' }}
  //           errorText={errorValidateAddress(address.key)}
  //         />
  //       </>
  //     )
  //   })
  // }

  if (isOpened) {
    return (
      <Wrapper onClick={closeModal}>
        <InnerWrapper onClick={onClickInside} isMobile={isMobile}>
          <CloseIcon>
            <IconButton onClick={closeModal}>
              <Close />
            </IconButton>
          </CloseIcon>
          <MainTax isMobile={isMobile}>
            <TaxRow main isMobile={isMobile}>
              <Box>
                <span className="text_header">ข้อมูลการออกใบกำกับภาษี</span>
              </Box>
              <Box>
                <Dropdown
                  value={taxinvoice.tax_type}
                  filterable
                  onChange={onChange}
                  options={taxType}
                  small
                  label="ประเภท"
                  name="tax_type"
                  show={{ h: 'center' }}
                />
              </Box>
            </TaxRow>
            <TaxRow isMobile={isMobile}>
              <BoxInput>
                <FieldInput
                  label={
                    taxinvoice.tax_type === 'juristic' ? 'ชื่อบริษัท' : 'ชื่อ'
                  }
                  name={
                    taxinvoice.tax_type === 'juristic'
                      ? 'company_name'
                      : 'first_name'
                  }
                  small
                  onChange={onChange}
                  value={
                    taxinvoice.tax_type === 'juristic'
                      ? taxinvoice.company_name
                      : taxinvoice.first_name
                  }
                  errorText={
                    taxinvoice.tax_type === 'juristic'
                      ? error.companyNameError
                      : error.firstNameError
                  }
                />
              </BoxInput>

              {taxinvoice.tax_type === 'juristic' ? (
                <BoxInput>
                  <FieldInput
                    label="หมายเลขนิติบุคคล"
                    name="tax_no"
                    small
                    onChange={onChange}
                    value={helper.totaxNumber(taxinvoice.tax_no)}
                    errorText={error.taxNumberError}
                  />
                </BoxInput>
              ) : (
                <BoxInput>
                  <FieldInput
                    label="สกุล"
                    name="last_name"
                    onChange={onChange}
                    value={taxinvoice.last_name}
                    errorText={error.lastNameError}
                    small
                  />
                </BoxInput>
              )}
            </TaxRow>
            {taxinvoice.tax_type !== 'juristic' ? (
              <TaxRow isMobile={isMobile}>
                <FieldInput
                  label="หมายเลขบัตรประชาชน"
                  name="tax_no"
                  onChange={onChangeiDCard}
                  value={helper.toidCard(taxinvoice.tax_no)}
                  errorText={error.taxNumberError}
                  small
                />
              </TaxRow>
            ) : (
              <></>
            )}

            <TaxRow isMobile={isMobile}>
              <BoxInput>
                <FieldInput
                  label="หมายเลขโทรศัพท์"
                  name="phone_number"
                  small
                  onChange={onChangeMobileNo}
                  value={helper.toMobile(taxinvoice.phone_number)}
                  errorText={error.phoneNumberError}
                />
              </BoxInput>
              <BoxInput>
                <FieldInput
                  label="อีเมล"
                  name="email"
                  onChange={onChange}
                  value={taxinvoice.email}
                  small
                  errorText={error.emailError}
                />
              </BoxInput>
            </TaxRow>
            <TaxRow isMobile={isMobile}>
              <span>ที่อยู่ที่ใช้ออกใบกำกับภาษี</span>
            </TaxRow>
            <TaxRow isMobile={isMobile}>
              <BoxInput>
                <FieldInput
                  label="บ้านเลขที่/ อาคาร"
                  name="house_no"
                  onChange={onChange}
                  value={taxinvoice.house_no}
                  errorText={error.houseNoError}
                  small
                />
              </BoxInput>
              <BoxInput>
                <FieldInput
                  label="ถนน"
                  name="road"
                  onChange={onChange}
                  value={taxinvoice.road}
                  small
                />
              </BoxInput>
            </TaxRow>

            <TaxRowAddressFied isMobile={isMobile}>
              <AutoCompleteAddress
                taxinvoice={taxinvoice}
                setTaxinvoice={setTaxinvoice}
                errorValidateAddress={errorValidateAddress}
              />
            </TaxRowAddressFied>

            <TextDiv>
              <span>
                ข้อมูลการออกใบกำกับภาษีจะบันทึกและใช้งานกับใบกำกับภาษีทั้งหมดในอนาคต
                คุณสามารถแก้ไขข้อมูลภายหลังได้ในเมนู ข้อมูลการชำระเงิน
              </span>
            </TextDiv>
            <ButtonDiv isMobile={isMobile}>
              <Button
                large
                color="primary"
                text="บันทึก"
                width="220px"
                onClick={() => onSave()}
              />
            </ButtonDiv>
          </MainTax>
        </InnerWrapper>
      </Wrapper>
    )
  }

  return null
}
const addressFied = [
  { key: 'sub_district', label: 'ตำบล/แขวง' },
  { key: 'district', label: 'อำเภอ/เขต' },
  { key: 'province', label: 'จังหวัด' },
  { key: 'postcode', label: 'รหัสไปรษณีย์' },
]
let AutoCompleteAddress = (props) => {
  const { taxinvoice, setTaxinvoice, errorValidateAddress = () => {} } = props

  return addressFied.map((address) => {
    return (
      <>
        <DropdownTax
          address={address}
          filterable
          value={taxinvoice[address.key]}
          onChange={(e) => {
            setTaxinvoice({ ...taxinvoice, ...e })
          }}
          small
          label={address.label}
          name={address.key}
          show={{ h: 'center' }}
          errorText={errorValidateAddress(address.key)}
        />
      </>
    )
  })
}
AutoCompleteAddress = memo(AutoCompleteAddress)

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;

  padding: 0 24px;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(20, 20, 43, 0.5);
`

const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  position: relative;

  width: 100%;
  height: fit-content;
  max-width: 695px;
  max-height: 90vh;
  border-radius: 16px;
  padding: ${(props) => (props.isMobile ? '0 10px' : '0 24px')};
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background: ${(p) => p.theme.color.primaryBg};
  }
  &::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  //max-width: 560px;
`

const MainTax = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: ${(props) => (props.isMobile ? 'none' : '60px')};
  height: fit-content;
`
const TaxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.isMobile ? 'none' : 'space-between')};
  margin-bottom: ${(props) => (props.main ? '50px' : '20px')};
  align-items: center;
  gap: ${(props) => (props.isMobile ? '10px' : 'none')};
  .text_header {
    font-weight: 600;
    font-size: 18px;
    color: #5f2eea;
  }
`
const TaxRowAddressFied = styled.div`
  width: 100%;
  row-gap: 20px;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.isMobile ? 'none' : 'space-between')};
  margin-bottom: ${(props) => (props.main ? '50px' : '20px')};
  align-items: center;
  gap: ${(props) => (props.isMobile ? '10px' : 'none')};
  .text_header {
    font-weight: 600;
    font-size: 18px;
    color: #5f2eea;
  }
`

const Box = styled.div`
  width: 250px;
`
const BoxInput = styled.div`
  width: 250px;
  height: 57px;
`
const CloseIcon = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`
const ButtonDiv = styled.div`
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`
const TextDiv = styled.div`
  margin-bottom: 20px;
`

export default ModalTax
