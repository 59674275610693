import React from 'react'
import styled from 'styled-components'

import Text from 'components/typo/Text'

import Popup, { flipDirection } from './Popup'

const Tooltip = (props) => {
  const { forwardRef, text, children, show } = props

  return (
    <Popup
      hover
      forwardRef={forwardRef}
      show={show}
      content={(direction) => (
        <Wrapper {...direction}>
          <Text size="xsmall">{text}</Text>
        </Wrapper>
      )}
    >
      {children}
    </Popup>
  )
}

const Wrapper = styled.div`
  white-space: pre-wrap;

  border-radius: 16px;
  padding: 16px 24px;
  z-index: 100;
  width: 100%;
  max-width: 400px;

  @media screen and (max-width: 768px) {
    max-width: 70vw;
  }

  color: ${(p) => p.theme.gray.offWhite};
  background-color: ${(p) => p.theme.gray.body};
  ${({ vertical }) => `${vertical.direction}: ${vertical.value}px`};
  ${({ horizontal }) => `${horizontal.direction}: ${horizontal.value}px`};

  &::after {
    content: "";
    position: absolute;
    ${({ vertical }) => `${flipDirection[vertical.direction]}: -10px`};
    ${({ horizontal }) => `${flipDirection[horizontal.direction]}: 12px`};
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid ${(p) => p.theme.gray.body};

    transform: ${(p) =>
      p.vertical.direction === 'top' ? 'rotate(180deg)' : 'rotate(0)'};
`

export default Tooltip
