import React, { useState, useCallback, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import Menu from 'pages/account/components/Menu/Menu'
import { Text } from 'components/typo'
import { Link } from 'components/link'
import { MetaData, Row, Col } from 'components/display'
import Loading from 'components/loading'
import { CircleNext } from 'icon'
import { message } from 'utils'

const MyPrivacy = (props) => {
  const [loading, setLoading] = useState(false)
  const { connection = {} } = props.account.toJS()

  const onInit = useCallback(async () => {
    try {
      setLoading(true)

      await props.account.getConnectionList()
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    }
    setLoading(false)
  }, [props.account])

  useEffect(() => {
    onInit()
  }, [onInit])

  const email = connection.email || null
  const text_email =
    email === null ? (
      <Link to="/account/add-email">
        <TextValue>เพิ่มอีเมลเพื่อตั้งรหัสผ่าน</TextValue>
      </Link>
    ) : (
      <Link to="/account/set-password">
        <CircleNext />
      </Link>
    )

  return (
    <Menu>
      <MetaData title="ความปลอดภัย" description="ความปลอดภัย" />
      <Content>
        <Title>
          <Text size="large" bold>
            ความปลอดภัย
          </Text>

          <Loading loading={loading}>
            <Detail>
              <Row>
                <Col span={10}>
                  <Label>ผูกบัญชี</Label>
                </Col>
                <Col span={14} justifyContent="end">
                  <Link to="/account/connection">
                    <CircleNext />
                  </Link>
                </Col>
              </Row>

              <Row>
                <Col span={10}>
                  <Label>เปลี่ยนรหัสผ่าน</Label>
                </Col>
                <Col span={14} justifyContent="end">
                  {text_email}
                </Col>
              </Row>
            </Detail>
          </Loading>
        </Title>
      </Content>
    </Menu>
  )
}

const Content = styled.div`
  width: 365px;
  margin: 0 auto;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

const Title = styled.div`
  margin: 36px 0;
  text-align: center;
`

const Label = styled.div`
  font-size: 16px;
  color: #4e4b66;
`

const TextValue = styled.div`
  font-size: 16px;
  color: #5f2eea;
`

const Detail = styled.div`
  margin-top: 52px;
`

export default inject('account')(observer(MyPrivacy))
