import React, { useMemo } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import has from 'lodash/has'

import { format, message, helper } from 'utils'
import { Text } from 'components/typo'
import ToggleButton from 'components/button/ToggleButton'
import { Info } from 'icon'
import useMediaQuery from 'hooks/useMediaQuery'
import { Link } from 'components/link'

import Tooltip from './Tooltip'

const PaymentContent = (props) => {
  const isMobile = useMediaQuery('(max-width:768px)')
  const {
    user = {},
    user_package = {},
    user_card,
    tax_invoice = {},
  } = props.payment.toJS()

  const { onVisible } = props

  const isSubscription = !!user_card
  const getPaymentMethod = () => {
    if (isSubscription) {
      const card = format.toCreditCard(user_card.card)
      return `Credit no. ${card}`
    } else {
      return 'ชำระผ่านคิวอาร์โค้ด/ พร้อมเพย์'
    }
  }

  const getConnectSubscription = () => {
    const date = format.toFullDT(user.expired_at)
    return (
      <span>
        <Text placeholder>
          <Btn onClick={() => onVisible(true)}>ต่ออายุสมาชิก</Btn>
        </Text>
        <br />
        <Text error>
          คุณสามารถเข้าถึงข้อมูล
          <br />
          ได้จนถึงวันที่ {date}
        </Text>
      </span>
    )
  }

  const changePkg = (
    <Link to="/account/my-payment/change-package">
      <Text size="small" primary bold>
        เปลี่ยนแพ็กเกจการใช้งาน
      </Text>
    </Link>
  )

  const applyPro = (
    <Link to="/account/promotion">
      <Text size="small" primary bold>
        กรอกโค้ด
      </Text>
    </Link>
  )

  const Action =
    user.subscription === 'yes' ? (
      <Text size="small" placeholder>
        <Btn onClick={() => onVisible(true)}>ยกเลิกการเป็นสมาชิก</Btn>
      </Text>
    ) : (
      getConnectSubscription()
    )

  const onToggleAutoPayment = async () => {
    try {
      const value = user.subscription === 'no' ? 'yes' : 'no'
      await props.payment.updateSubscription(value)
    } catch (e) {
      message.error({ message: e.message })
    }
  }

  const credit = useMemo(() => {
    return `฿ ${format.toDigi(user.credit || 0)}`
  }, [user.credit])

  const toggleBtn = isSubscription ? (
    <TextInfoRow>
      <Text gray>ต่ออายุอัตโนมัติ</Text>
      <ToggleButton
        value={user.subscription === 'yes'}
        onClick={onToggleAutoPayment}
      />
    </TextInfoRow>
  ) : undefined

  const myTaxInvoice = useMemo(() => {
    if (tax_invoice.tax_id) {
      const isJuristic = tax_invoice.tax_type === 'juristic'
      return (
        <>
          {isJuristic
            ? `ชื่อบริษัท ${tax_invoice.company_name}`
            : `ชื่อ ${tax_invoice.first_name} ${tax_invoice.last_name}`}
          <br />
          ติดต่อ {format.toMobile(tax_invoice.phone_number)} อีเมล{' '}
          {tax_invoice.email}
          <br />
          {isJuristic
            ? `หมายเลขนิติบุคคล ${helper.totaxNumber(tax_invoice.tax_no)}`
            : `หมายเลขบัตรประชาชน ${helper.toidCard(tax_invoice.tax_no)}`}
          <br />
          {format.toAddress(tax_invoice)}
        </>
      )
    }
    return '-'
  }, [tax_invoice])

  const hasMyInvoice = useMemo(() => {
    return has(tax_invoice, 'tax_id')
  }, [tax_invoice])

  const invoiceAction = useMemo(() => {
    if (hasMyInvoice) {
      return 'แก้ไข'
    }
    return 'เพิ่ม'
  }, [hasMyInvoice])

  const needInvoice = useMemo(() => {
    return tax_invoice.need_invoice === 'yes'
  }, [tax_invoice])

  const onToggleInvoice = async () => {
    try {
      const value = tax_invoice.need_invoice === 'yes' ? 'no' : 'yes'
      await props.payment.updateNeedInvoice(value)
    } catch (e) {
      message.error({ message: e.message })
    }
  }

  if (isMobile)
    return (
      <TextInfoWrapper>
        <TextInfo>
          <TextInfoCol>
            <Text placeholder>จัดการรูปแบบการชำระเงิน</Text>
            <Text bold>{getPaymentMethod()}</Text>
            {toggleBtn}
            <Link to="/account/my-payment/payment-option">
              <Text size="small" primary bold>
                เปลี่ยนวิธีการชำระเงิน
              </Text>
            </Link>
          </TextInfoCol>
        </TextInfo>

        <TextInfo>
          <TextInfoCol>
            <Text placeholder>รายละเอียดแพ็กเกจการใช้งาน</Text>
            <Text bold>{user_package.package_name}</Text>
            {changePkg}
          </TextInfoCol>
        </TextInfo>

        <TextInfo>
          <TextInfoCol>
            <Text placeholder>เครดิตการใช้งานคงเหลือ</Text>
            <Text bold>{credit}</Text>
            {applyPro}
          </TextInfoCol>
        </TextInfo>

        <TextInfo>
          <TextInfoCol>
            <TextWithTooltip>
              <Text placeholder>ประวัติการชำระเงินและใบเสร็จ</Text>
              <Tooltip toolTip={<HistoryTooltip />} show={{ h: 'center' }}>
                <Info />
              </Tooltip>
            </TextWithTooltip>
            <ActionWrapper>
              <Link to={'/account/my-payment/paynow'}>
                <Text size="small" primary bold>
                  ชำระเงินทันที
                </Text>
              </Link>
              <Link to={'/account/my-payment/history'}>
                <Text size="small" primary bold>
                  ดูทั้งหมด
                </Text>
              </Link>
            </ActionWrapper>
          </TextInfoCol>
        </TextInfo>

        <TextInfo>
          <TextInfoRow>{Action}</TextInfoRow>
        </TextInfo>
      </TextInfoWrapper>
    )

  return (
    <TextInfoWrapper>
      <TextInfo>
        <TextInfoRow>
          <TextInfoBox>
            <Text placeholder>จัดการรูปแบบการชำระเงิน</Text>
          </TextInfoBox>
          <Link to="/account/my-payment/payment-option">
            <Text size="small" primary bold>
              เปลี่ยนวิธีการชำระเงิน
            </Text>
          </Link>
        </TextInfoRow>
        <TextInfoRow>
          <Text bold>{getPaymentMethod()}</Text>
        </TextInfoRow>
        {toggleBtn}
      </TextInfo>

      <TextInfo>
        <TextInfoRow>
          <Text placeholder>รายละเอียดแพ็กเกจการใช้งาน</Text>
          {changePkg}
        </TextInfoRow>
        <TextInfoRow>
          <Text bold>{user_package.package_name}</Text>
        </TextInfoRow>
      </TextInfo>

      <TextInfo>
        <TextInfoRow>
          <Text placeholder>เครดิตการใช้งานคงเหลือ</Text>
          {applyPro}
        </TextInfoRow>
        <TextInfoRow>
          <Text bold>{credit}</Text>
        </TextInfoRow>
      </TextInfo>

      <TextInfo>
        <TextInfoRow>
          <TextWithTooltip>
            <Text placeholder>ประวัติการชำระเงินและใบเสร็จ</Text>
            <Tooltip toolTip={<HistoryTooltip />}>
              <Info />
            </Tooltip>
          </TextWithTooltip>
          <ActionWrapper>
            <Link to={'/account/my-payment/paynow'}>
              <Text size="small" primary bold>
                ชำระเงินทันที
              </Text>
            </Link>
            <Link to={'/account/my-payment/history'}>
              <Text size="small" primary bold>
                ดูทั้งหมด
              </Text>
            </Link>
          </ActionWrapper>
        </TextInfoRow>
      </TextInfo>

      <TextInfo>
        <TextInfoRow>
          <Text placeholder>ข้อมูลการออกใบกำกับภาษี</Text>
          <Link to={'/account/my-payment/tax-invoice'}>
            <Text size="small" primary bold>
              {invoiceAction}
            </Text>
          </Link>
        </TextInfoRow>
        {hasMyInvoice && (
          <>
            <TextInfoRow>
              <Text bold>{myTaxInvoice}</Text>
            </TextInfoRow>
            <TextInfoRow>
              <Text gray>ต้องการใบกำกับภาษี</Text>
              <ToggleButton value={needInvoice} onClick={onToggleInvoice} />
            </TextInfoRow>
          </>
        )}
      </TextInfo>

      <TextInfo>
        <TextInfoRow>{Action}</TextInfoRow>
      </TextInfo>
    </TextInfoWrapper>
  )
}

const TextWithTooltip = styled.div`
  display: flex;
  column-gap: 8px;
`

const TextInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`

const TextInfo = styled.div``

const TextInfoBox = styled.div`
  display: flex;
  flex-direction: column;
`

const TextInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TextInfoCol = styled.div`
  display: flex;
  flex-direction: column;
`

const ActionWrapper = styled.div`
  & *:not(:last-child):after {
    color: ${(p) => p.theme.gray.line};
    content: '|';
    font-size: 24px;
    height: 24px;
    line-height: 24px;
    font-weight: 200;
    margin: 0 8px;
  }
`

const HistoryTooltip = (props) => (
  <TooltipContent>
    <Text size="small">
      <span>ชำระเงินทันทีได้ต่อเมื่อ</span>
      <ul>
        <li>ชำระผ่านบัตรเครดิตล่วงหน้า 3 วัน ก่อนถึงรอบบิล</li>
        <li>ชำระผ่านคิวอาร์โค้ด/ พร้อมเพย์ล่วงหน้า 7 วันก่อนถึงรอบบิล</li>
      </ul>
      <span>เงื่อนไขการระงับบัญชี</span>
      <ul>
        <li>
          เงื่อนไขการระงับบัญชี เมื่อผู้ใช้ไม่ได้ชำระเงินภายในวัน/ เวลาที่กำหนด
          และจะถูกปรับบัญชี เป็นแพ็กเกจฟรี รวมถึงลดการเข้าถึง ในบางฟีเจอร์
        </li>
      </ul>
    </Text>
  </TooltipContent>
)

const TooltipContent = styled.div`
  & ul {
    padding-left: 24px;
  }
`

const Btn = styled.span`
  cursor: pointer;
`

export default inject('payment')(observer(PaymentContent))
