import React from 'react'
import styled, { useTheme } from 'styled-components'

const Text = (props) => {
  const { children, text, onClick } = props
  const theme = useTheme()

  const style = getStyle(props)
  const font = getFont(props)
  const color = getColor(props, theme)
  const styleProps = { font, style, color }

  return (
    <CustomText onClick={onClick} {...styleProps}>
      {text || children}
    </CustomText>
  )
}

const getFont = (props) => {
  const { align = 'left', bold, size, mobileSize, ipadSize } = props
  const font = {
    ...props,
    weight: 'normal',
    size: getFontSize(size),
    align,
  }

  font.ipadSize = getFontSize(ipadSize, font.size)
  font.mobileSize = getFontSize(mobileSize, font.ipadSize)

  if (bold) {
    font.weight = 'bold'
  }

  return font
}

const getFontSize = (size, defaultSize) => {
  const map = {
    xsmall: { size: '14px', height: '24px' },
    small: { size: '16px', height: '28px' },
    medium: { size: '18px', height: '34px' },
    large: { size: '24px', height: '38px' },
  }
  if (map[size]) return map[size]

  return defaultSize || map.medium
}

const getStyle = (props) => {
  const style = {}

  if (props.oneLine) {
    style.whitespace = 'nowrap'
  }

  return style
}

const getColor = (props, theme) => {
  const {
    primary,
    primaryDarkMode,
    gray,
    title,
    secondary,
    color,
    placeholder,
    label,
    error,
    success,
  } = props

  if (primary) return { font: theme.color.primary }
  if (primaryDarkMode) return { font: theme.color.primaryDarkMode }
  if (secondary) return { font: theme.color.secondary }
  if (error) return { font: theme.color.error }
  if (success) return { font: theme.color.success }
  if (gray) return { font: theme.gray.body }
  if (label) return { font: theme.gray.label }
  if (placeholder) return { font: theme.gray.placeholder }
  if (title) return { font: theme.gray.titleActive }
  if (title) return { font: theme.gray.titleActive }
  if (color) return { font: color }

  return { font: 'inherit' }
}

const CustomText = styled.span`
  transition: all 0.2s;

  color: ${(props) => props.color.font};
  font-weight: ${(props) => props.font.weight};
  font-size: ${(props) => props.font.size.size};
  line-height: ${(props) => props.font.size.height};
  text-align: ${(props) => props.font.align};

  white-space: ${(props) => props.style.whitespace};

  @media screen and (max-width: 1024px) {
    font-size: ${(props) => props.font.ipadSize.size};
    line-height: ${(props) => props.font.ipadSize.height};
    text-align: ${(props) => props.font.ipadAlign};
  }

  @media screen and (max-width: 768px) {
    font-size: ${(props) => props.font.mobileSize.size};
    line-height: ${(props) => props.font.mobileSize.height};
    text-align: ${(props) => props.font.mobileAlign};
  }
`

export default Text
