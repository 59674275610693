import CryptoJS from 'crypto-js'

import { config } from 'config'

import ADDRESS_DATA from '../assets/data/address.min.json'

export class Helper {
  sleep(ms = 1000) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  isNull(val) {
    return val === null || val === undefined
  }

  notNull(val) {
    return val !== null && val !== undefined
  }

  isValue(val) {
    return val !== null && val !== undefined && val !== ''
  }

  notValue(val) {
    return val === null || val === undefined || val === ''
  }

  isEmpty(val) {
    let list = Object.keys(val)
    return (
      val === null ||
      val === undefined ||
      val === '' ||
      list === 0 ||
      list.length === 0
    )
  }

  toPercent(a, b) {
    if (a === 0 || b === 0) return 0

    return a / b
  }

  getFromWidth({ lg = '40%', md = '60%', xs = '90%' }) {
    const width = window.innerWidth
    if (width <= 450) return xs
    else if (width <= 800) return md

    return lg
  }

  random({
    len,
    prefix = '',
    postfix = '',
    character = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
  }) {
    let need = len - (prefix.length + postfix.length)
    let max = character.length
    if (need < 0) return
    if (max === 0) return
    let resp = ''

    for (let i = 0; i < need; i++) {
      resp += character.charAt(Math.floor(Math.random() * max))
    }
    return `${prefix}${resp}${postfix}`
  }

  getUrl(val) {
    if (this.isValue(val)) {
      let prefix = val.substring(0, 4)
      if (prefix === 'http' || prefix === 'data') return val
    }

    return undefined
  }

  isUrl(val) {
    if (this.isValue(val)) {
      let prefix = val.substring(0, 4)
      return prefix === 'http'
    }

    return false
  }

  trim(data, list = []) {
    list.forEach((name) => {
      let temp = data[name]
      if (temp) data[name] = temp.trim()
    })

    return data
  }

  upItem(index, list = []) {
    const current = list[index]
    const next = list[index - 1]

    list[index] = next
    list[index - 1] = current
    return list
  }

  downItem(index, list = []) {
    const current = list[index]
    const next = list[index + 1]

    list[index] = next
    list[index + 1] = current
    return list
  }
  searchString(src, target) {
    return String(src)
      .toLocaleLowerCase()
      .includes(String(target).toLocaleLowerCase())
  }

  compareString(src, target) {
    return (
      String(src).toLocaleLowerCase() === String(target).toLocaleLowerCase()
    )
  }

  formatPattern(value, pattern) {
    if (!value) return ''

    let valueIndex = 0
    const list = String(pattern).split('')
    const newList = []

    for (let i = 0; i < pattern.length && valueIndex < value.length; i++) {
      if (list[i] === 'x') {
        newList.push(value[valueIndex++])
      } else newList.push(list[i])
    }

    return newList.join('')
  }

  toMobile(text) {
    const mobile = (text || '').replaceAll('-', '')
    return this.formatPattern(mobile, 'xxx-xxx-xxxx')
  }

  totaxNumber(text) {
    const taxNumber = (text || '').replaceAll('-', '')
    return this.formatPattern(taxNumber, 'x-xx-x-xxx-xxxxx-x')
  }

  toidCard(text) {
    const idCard = (text || '').replaceAll('-', '')
    return this.formatPattern(idCard, 'x-xxxx-xxxxx-xx-x')
  }

  generateAddressStructure() {
    var obj = []
    ADDRESS_DATA.map((province) =>
      province.district.map((district) =>
        district.sub_district.map((subDistrict) =>
          subDistrict.postal.map((postal) => {
            var newObj = {
              value: {
                province: province.province_name_th,
                district: district.district_name_th,
                sub_district: subDistrict.sub_district_name_th,
                postcode: `${postal.postal_value}`,
              },

              // eslint-disable-next-line max-len
              label: `${province.province_name_th} >> ${district.district_name_th} >> ${subDistrict.sub_district_name_th} >> ${postal.postal_value}`,
            }
            obj.push(newObj)
            return null
          })
        )
      )
    )
    return obj
  }

  encrypt(input) {
    try {
      return CryptoJS.AES.encrypt(input + '', config.key).toString()
    } catch (error) {
      console.error('🚀 ~ encrypt ~ error:', error)
      return null
    }
  }

  decrypt(input) {
    try {
      const bytes = CryptoJS.AES.decrypt(input, config.key)
      return bytes.toString(CryptoJS.enc.Utf8)
    } catch (error) {
      console.error('🚀 ~ decrypt ~ error:', error)
      return null
    }
  }
}

export const helper = new Helper()
export default helper
export const ADDRESS_STRUCTURE = helper.generateAddressStructure()
