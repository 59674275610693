import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { size = 1, color = '#FF003E' } = props

  const width = `${Math.ceil(32 * size)}`
  const height = `${Math.ceil(32 * size)}`
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.0997 32H6.90034C3.07904 32 0 28.8935 0 25.0997V6.90034C0 3.07904 3.07904 0 6.90034 0H25.0997C28.8935 0 32 3.07904 32 6.90034V25.0997C32 28.8935 28.8935 32 25.0997 32Z" fill="white"/>
      <path d="M21.1948 9.37563H23.3116V5.22443C23.3116 5.22443 21.3872 4.89453 19.5453 4.89453C15.6965 4.89453 13.1948 7.2313 13.1948 11.4375V15.1488H8.93359V20.0148H13.1948V31.9736H18.4456V20.0148H22.3769L23.1192 15.1488H18.4456V11.9873C18.4456 10.6677 19.1054 9.37563 21.1948 9.37563Z" fill="#5F2EEA"/>
    </svg>
  )
}
// eslint-enable
export default Logo
