import styled from 'styled-components'

function Arcade() {
  return (
    <Container>
      <ArcadeContainer>
        <ArcadePlayer
          src="https://demo.arcade.software/FMhFjLe41qs0T9SLNrsd?embed"
          frameborder="0"
          loading="lazy"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          title="▶ Planning - Friday : Arcade"
        ></ArcadePlayer>
      </ArcadeContainer>
    </Container>
  )
}

export default Arcade

const Container = styled.div`
  padding: 0 20px;
`
const ArcadeContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  height: fit-content;
  margin: 0 auto;
`
const ArcadePlayer = styled.iframe`
  width: 100%;
  aspect-ratio: 1080 / 600;
  color-scheme: light;
  border: none;

  @media screen and (max-width: 465px) {
    aspect-ratio: 1080 / 670;
  }
`
