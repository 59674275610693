import React from 'react'
import styled from 'styled-components'

import { MetaData } from 'components/display'
import { Text } from 'components/typo'

const NotFound = (props) => {
  return (
    <AppView>
      <MetaData title="ฟรายเดย์ - Friday" description="ไม่พบข้อมูล" />
      <Text align="center">ไม่พบข้อมูล</Text>
    </AppView>
  )
}

const AppView = styled.div`
  padding: 50px;
  text-align: center;
`

export default NotFound
