import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { size = 1, color = '#BCA4FF' } = props

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.6996 5.80151C17.0996 5.40151 17.1996 4.80152 16.7996 4.40152C16.3996 4.00152 15.7996 3.90151 15.3996 4.30151L9.09961 9.80151C8.39961 10.4015 8.09961 11.2015 8.09961 12.1015C8.09961 13.0015 8.49961 13.8015 9.09961 14.4015L15.3996 19.9015C15.5996 20.1015 15.7996 20.1015 16.0996 20.1015C16.3996 20.1015 16.6996 20.0015 16.8996 19.8015C17.2996 19.4015 17.1996 18.8015 16.7996 18.4015L10.4996 12.9015C10.1996 12.6015 9.99961 12.3015 9.99961 12.0015C9.99961 11.7015 10.0996 11.4015 10.2996 11.2015L16.6996 5.80151Z" fill={color} />
    </svg>
  )
}
// eslint-enable
export default Logo
