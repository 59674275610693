import Joi from 'joi'

export class Validator {
  checkValue(data = {}, name_list = []) {
    const valid = {}
    const size = name_list.length
    for (let i = 0; i < size; i++) {
      const name = name_list[i]
      const val = data[name]
      const value = typeof val === 'string' ? val.trim() : val

      if (value === null || value === undefined || value === '') {
        valid[name] = true
      }
    }

    return valid
  }

  isEmail(email) {
    // eslint-disable-next-line
    const re =
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  isPassword(val = '') {
    const re = new RegExp('^(?=.*[a-z, A-Z])(?=.*[0-9])')
    return re.test(val)
  }

  getRolePassword() {
    const roles = Joi.string()
      .trim()
      .messages({
        'string.empty': 'รหัสผ่านต้องมีความยาวอยู่ระหว่าง 8-15 ตัวอักษร',
      })
      .ruleset.min(8)
      .max(15)
      .rule({ message: 'รหัสผ่านต้องมีความยาวอยู่ระหว่าง 8-15 ตัวอักษร' })
      .ruleset.pattern(/^(\w+\S+)$/)
      .rule({
        message: 'รหัสผ่านต้องไม่มีช่องว่าง',
      })
      .ruleset.pattern(/[0-9]/)
      .rule({
        message: 'รหัสผ่านต้องมีตัวเลข (0-9) อย่างน้อย 1 ตัว',
      })
      .ruleset.pattern(/[a-z]/)
      .rule({
        message:
          'รหัสผ่านต้องประกอบด้วยตัวอักษรภาษาอังกฤษพิมพ์เล็ก (a-z) อย่างน้อย 1 ตัว',
      })
      .ruleset.pattern(/[A-Z]/)
      .rule({
        message:
          'รหัสผ่านต้องประกอบด้วยตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ (A-Z) อย่างน้อย 1 ตัว',
      })

    return roles
  }

  validatePassword(password = '') {
    password = password.trim()

    const rule = this.getRolePassword()
    const result = rule.validate(password, { abortEarly: false })
    const errors = result.error
      ? result.error.details.map((err) => err.message)
      : []

    return errors
  }

  process(validator, values) {
    const result = validator.validate(values, { abortEarly: false })
    let errors = {}
    if (result.error) {
      errors = result.error.details
        .map((err) => ({ [err.context.key]: err.message }))
        .reduce((acc, cur) => ({ ...acc, ...cur }), {})
    }

    const isValid = Object.keys(errors).length === 0

    return {
      isValid,
      values,
      errors,
    }
  }
}

export const validator = new Validator()
export default validator
