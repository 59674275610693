import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import { MetaData, UserImg } from 'components/display'
import { Button } from 'components/button'
import { Text } from 'components/typo'
import { FieldInput, DatePicker, Dropdown } from 'components/input'
import { helper, message, validator } from 'utils'

import rules from './rules'
import Agent from './Agent'
import useMediaQuery from '../../../../hooks/useMediaQuery'

const EditProfile = (props) => {
  const [loading, setLoading] = useState(false)
  const [isSubmit, setSubmit] = useState(false)
  const [user, setUser] = useState({})
  const [agent, setAgent] = useState({})
  const [validate, setValidate] = useState({ user: {}, agent: {} })
  const isIpad = useMediaQuery('(max-width:890px)')

  const { init, onFinish } = props

  useEffect(() => {
    if (init === false) {
      const userInfo = props.member.toJS().user || {}
      const userAgent = props.account.toJS().agent || {}

      setUser({ ...userInfo })
      setAgent({ ...userAgent })
    }
  }, [props.member, props.account, init])

  const clearValidate = () => {
    setValidate({ user: {}, agent: {} })
  }

  const onSave = async () => {
    try {
      setSubmit(true)
      setLoading(true)

      const resUser = validator.process(rules.user, user)
      const resAgent = validator.process(rules.agent, agent)

      if (resUser.isValid && resAgent.isValid) {
        clearValidate()
        await Promise.all([
          props.member.updateProfile(user),
          props.account.updateAgent(agent),
        ])

        message.success({
          message: 'บันทึกสำเร็จ',
        })
        onFinish()
      } else {
        setValidate({ user: resUser.errors, agent: resAgent.errors })
      }
    } catch (e) {
      message.error({
        message: e.message,
      })
    }
    setLoading(false)
  }

  const onChange = (val, name) => {
    setUser((user) => {
      user[name] = val
      return user
    })
    clearValidate()
  }

  const onChangeMobileNo = (val, name) => {
    const newValue = String(val || '').replaceAll('-', '')
    if (Number.isNaN(Number(newValue))) return

    if (newValue.length <= 10) {
      onChange(newValue, name)
    }
  }

  const onAgentChange = (val) => {
    setAgent({ ...val })
    clearValidate()
  }

  const license_list = agent.license_list || []
  const found = license_list.find((it) => {
    return it.license_type === '' || it.license_no === ''
  })
  const disabled = init || loading
  const meta = (
    <MetaData title="แก้ไขโปรไฟล์ของฉัน" description="แก้ไขโปรไฟล์ของฉัน" />
  )

  const title = (
    <Title>
      <Text size="large" bold>
        โปรไฟล์ของฉัน
      </Text>
    </Title>
  )
  const img_content = (
    <UserImageBox>
      <UserImg
        editMode
        name="img_url"
        size={125}
        src={user.img_url}
        gender={user.gender}
        onChange={onChange}
      />

      <ButtonArea>
        <Button
          disabled={init || found}
          loading={loading}
          color="subtle"
          maxWidth="114px"
          small
          text="บันทึก"
          onClick={onSave}
        />
      </ButtonArea>
    </UserImageBox>
  )

  if (isIpad) {
    return (
      <Wrapper>
        {meta}
        {title}
        <UserImageBox>
          <UserImg
            editMode
            name="img_url"
            size={125}
            src={user.img_url}
            gender={user.gender}
            onChange={onChange}
          />
        </UserImageBox>
        <ProfileForm>
          <InputForm>
            <FieldInput
              small
              label="ชื่อ"
              name="name"
              value={user.name}
              onChange={onChange}
              errorText={validate.user.name}
              disabled={disabled}
            />
            <FieldInput
              small
              label="นามสกุล"
              name="surname"
              value={user.surname}
              onChange={onChange}
              errorText={validate.user.surname}
              disabled={disabled}
            />
            <Dropdown
              small
              show={{ h: 'center' }}
              label="เพศ"
              name="gender"
              value={user.gender}
              options={genders}
              onChange={onChange}
              errorText={validate.user.gender}
              disabled={disabled}
            />
            <DatePicker
              small
              show={{ h: 'center' }}
              label="วัน เดือน ปีเกิด"
              name="birthday_at"
              value={user.birthday_at}
              onChange={onChange}
              errorText={validate.user.birthday_at}
              disabled={disabled}
            />
            <FieldInput
              small
              label="หมายเลขโทรศัพท์"
              name="mobile"
              value={helper.toMobile(user.mobile)}
              onChange={onChangeMobileNo}
              errorText={validate.user.mobile}
              disabled={true}
            />
            <FieldInput
              small
              label="อีเมล"
              name="email"
              value={user.email}
              onChange={onChange}
              errorText={validate.user.email}
              disabled={true}
            />
          </InputForm>

          <Agent
            agent={agent}
            validate={validate.agent}
            isSubmit={isSubmit}
            disabled={disabled}
            loading={loading}
            onChange={onAgentChange}
          />
          <ButtonArea>
            <Button
              disabled={init || found}
              loading={loading}
              color="subtle"
              maxWidth="114px"
              small
              text="บันทึก"
              onClick={onSave}
            />
          </ButtonArea>
        </ProfileForm>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {meta}
      <UserImageBox>
        <UserImg
          editMode
          name="img_url"
          size={125}
          src={user.img_url}
          gender={user.gender}
          onChange={onChange}
        />

        <ButtonArea>
          <Button
            disabled={init || found}
            loading={loading}
            color="subtle"
            maxWidth="114px"
            small
            text="บันทึก"
            onClick={onSave}
          />
        </ButtonArea>
      </UserImageBox>
      <ProfileForm>
        {title}
        <InputForm>
          <FieldInput
            small
            label="ชื่อ"
            name="name"
            value={user.name}
            onChange={onChange}
            errorText={validate.user.name}
            disabled={disabled}
          />
          <FieldInput
            small
            label="นามสกุล"
            name="surname"
            value={user.surname}
            onChange={onChange}
            errorText={validate.user.surname}
            disabled={disabled}
          />
          <Dropdown
            small
            label="เพศ"
            name="gender"
            value={user.gender}
            options={genders}
            onChange={onChange}
            errorText={validate.user.gender}
            disabled={disabled}
          />
          <DatePicker
            small
            label="วัน เดือน ปีเกิด"
            name="birthday_at"
            value={user.birthday_at}
            onChange={onChange}
            errorText={validate.user.birthday_at}
            disabled={disabled}
          />
          <FieldInput
            small
            label="หมายเลขโทรศัพท์"
            name="mobile"
            value={helper.toMobile(user.mobile)}
            onChange={onChangeMobileNo}
            errorText={validate.user.mobile}
            disabled={true}
          />
          <FieldInput
            small
            label="อีเมล"
            name="email"
            value={user.email}
            onChange={onChange}
            errorText={validate.user.email}
            disabled={true}
          />
        </InputForm>

        <Agent
          agent={agent}
          validate={validate.agent}
          isSubmit={isSubmit}
          disabled={disabled}
          loading={loading}
          onChange={onAgentChange}
        />
      </ProfileForm>
    </Wrapper>
  )
}

const genders = [
  { label: 'ชาย', value: 'male' },
  { label: 'หญิง', value: 'female' },
]

const Wrapper = styled.div`
  margin-top: 40px;
  width: 100%;

  @media screen and (max-width: 890px) {
    max-width: 250px;
  }

  @media screen and (max-width: 500px) {
    max-width: 100%;
  }
`

const UserImageBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    margin-top: 24px;
    margin-bottom: 40px;
  }
`

const ProfileForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 524px;
  margin: 0 auto;
`

const InputForm = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 250px 250px;
  gap: 24px;

  @media screen and (max-width: 890px) {
    grid-template-columns: 1fr;
  }
`

const Title = styled.div`
  margin: 36px 0;
  text-align: center;

  @media screen and (max-width: 1024px) {
    margin: 0px;
  }
`

const ButtonArea = styled.div`
  position: absolute;
  right: 0px;
  width: 114px;

  @media screen and (max-width: 890px) {
    position: inherit;
    margin-top: 40px;
  }
`

export default inject('member', 'account')(observer(EditProfile))
