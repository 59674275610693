import Joi from 'joi'

const user = Joi.object({
  name: Joi.string()
    .trim()
    .required()
    .messages({
      'any.required': 'กรุณากรอกชื่อ',
      'string.empty': 'กรุณากรอกชื่อ',
    })
    .label('ชื่อ'),
  surname: Joi.string()
    .trim()
    .required()
    .messages({
      'any.required': 'กรุณากรอกนามสกุล',
      'string.empty': 'กรุณากรอกนามสกุล',
    })
    .label('นานสกุล'),
  gender: Joi.valid('male', 'female')
    .messages({ 'any.only': `กรุณากรอกเพศให้ถูกต้อง` })
    .label('เพศ'),
  birthday_at: Joi.any().required().invalid(null).messages({
    'any.required': 'กรุณากรอกวันเกิด',
    'any.invalid': 'กรุณากรอกวันเกิด',
  }),
  /*
  mobile: Joi.string()
    .trim()
    .ruleset.pattern(/^[0-9]*$/)
    .length(10)
    .message('หมายเลขของคุณไม่ถูกต้อง')
    .required()
    .messages({
      'any.required': 'กรุณากรอกหมายเลขโทรศัพท์',
      'string.empty': 'กรุณากรอกหมายเลขโทรศัพท์',
    })
    .label('หมายเลขโทรศัพท์'),
  email: Joi.string()
    .trim()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'any.required': 'กรุณากรอกอีเมล',
      'string.empty': 'กรุณากรอกอีเมล',
      'string.email': 'รูปแบบอีเมลของคุณไม่ถูกต้อง',
    })
    .label('อีเมล'),
    */
}).options({ allowUnknown: true })

const agent = Joi.object({
  company_id: Joi.number()
    .required()
    .messages({
      'number.base': 'กรุณากรอกบริษัท',
    })
    .label('บริษัท'),
}).options({ allowUnknown: true })

const roles = {
  user,
  agent,
}

export default roles
