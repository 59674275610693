import React from 'react'

import { Headline, Header, RowItem, Space } from './components'

const Loan = (props) => {
  return (
    <>
      <Headline title="หนี้สินทั้งหมด" value="3,000,000.00" bg="red" />

      <Space />
      <Header title="หนี้สินระยะสั้น" value="3,000,000.00" />
      <RowItem title="เงินสด" value="1,000,000.00" />
      <RowItem title="อื่นๆ" value="1,000,000.00" isLast={true} />

      <Space size="8px" />
      <Header title="หนี้สินระยะยาว" value="3,000,000.00" />
      <RowItem title="เงินสด" value="1,000,000.00" />
      <RowItem title="อื่นๆ" value="1,000,000.00" isLast={true} />

      <Space />
      <Headline title="คงเหลือ" value="3,000,000.00" bg="primary" />
    </>
  )
}

export default Loan
