import React from 'react'

import Row from './Row'
import Col from './Col'

const RowItem4 = (props) => {
  const { v1, v2, v3 = '', v4 = '', isLast } = props

  const border = '1px solid #D9DBE9'
  const css = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '0px 20px',
    color: '#4E4B66',
    backgroundColor: 'white',
    borderRadius: isLast ? '0px 0px 16px 16px' : '0px',
    borderTop: '0px',
    borderBottom: border,
    borderLeft: border,
    borderRight: border,
  }
  const borderLeft = '1px solid #D9DBE9'
  const textAlign = 'center'
  return (
    <div style={css}>
      <Row>
        <Col size="25%" textAlign={textAlign}>
          {v1}
        </Col>
        <Col size="25%" borderLeft={borderLeft} textAlign={textAlign}>
          {v2}
        </Col>
        <Col size="25%" borderLeft={borderLeft} textAlign={textAlign}>
          {v3}
        </Col>
        <Col size="25%" borderLeft={borderLeft} textAlign={textAlign}>
          {v4}
        </Col>
      </Row>
    </div>
  )
}

export default RowItem4
