import React from 'react'

import { Header4, RowItem4, Text, TitleText } from './components'

const Finance = (props) => {
  const t2 = `
  หนี้สินโดยรวมไม่มาก แต่ภาระการผ่อนชำระที่มาก
  อาจทำให้เกิดปัญหาในอนาคต ควรพิจารณาประกันคุ้มครองวงเงินหนี้สิน
  `
  return (
    <>
      <TitleText text="อัตราส่วนสภาพคล่อง" marginTop="24px" />
      <Text
        text="ควรระมันระวังการใช้จ่าย และพิจารณาทำประกัน"
        marginBottom="16px"
      />
      <Header4 t1="อัตราส่วน" t2="เกณฑ์แนะนำ" t3="ผลการคำนวณ" t4="คำแนะนำ" />
      <RowItem4 v1="เงินสด" v2="1,000,000.00" v3="1" v4="3" />
      <RowItem4 v1="อื่นๆ" v2="1,000,000.00" v3="1" v4="3" isLast={true} />

      <TitleText text="อัตราส่วนภาระหนี้สิน" marginTop="24px" />
      <Text text={t2} marginBottom="16px" />
      <Header4 t1="อัตราส่วน" t2="เกณฑ์แนะนำ" t3="ผลการคำนวณ" t4="คำแนะนำ" />
      <RowItem4 v1="เงินสด" v2="1,000,000.00" v3="1" v4="3" />
      <RowItem4 v1="อื่นๆ" v2="1,000,000.00" v3="1" v4="3" isLast={true} />

      <TitleText text="อัตราส่วนการออมลงทุน" marginTop="24px" />
      <Text
        text="วินัยการออมลงทุนดี สินทรัพย์การลงทุนอยู่ในเกณฑ์เหมาะสม"
        marginBottom="16px"
      />
      <Header4 t1="อัตราส่วน" t2="เกณฑ์แนะนำ" t3="ผลการคำนวณ" t4="คำแนะนำ" />
      <RowItem4 v1="เงินสด" v2="1,000,000.00" v3="1" v4="3" />
      <RowItem4 v1="อื่นๆ" v2="1,000,000.00" v3="1" v4="3" isLast={true} />
    </>
  )
}

export default Finance
