import React, { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import Menu from 'pages/account/components/Menu/Menu'
import BackBtn from 'pages/account/components/BackBtn'
import { Text } from 'components/typo'
import { MetaData } from 'components/display'
import { Button } from 'components/button'
import { message } from 'utils'

import Account from './deleted'
import Check from './Check'
import Uncheck from './Uncheck'

const DeleteAccount = (props) => {
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState(undefined)

  const onInit = useCallback(async () => {
    try {
      setLoading(true)

      await props.account.getDeleteReason()
    } catch (e) {
      message.error({
        message: e.message,
      })
    }
    setLoading(false)
  }, [props.account])

  useEffect(() => {
    onInit()
  }, [onInit])

  const onSend = async () => {
    try {
      setLoading(true)

      await props.member.deleteAccount({ reason_type: selected, reason: '' })
    } catch (e) {
      message.error({
        message: e.message,
      })
    }
    setLoading(false)
  }

  const { user } = props.member.toJS()
  const { delete_reason_list = [] } = props.account.toJS()
  if (user.status === 'inactive') {
    return <Account />
  }

  const content = delete_reason_list.map((item, i) => {
    const icon =
      selected === item.value ? (
        <Check label={item.text} />
      ) : (
        <Uncheck label={item.text} onClick={() => setSelected(item.value)} />
      )

    return <SelectItem key={i}>{icon}</SelectItem>
  })

  return (
    <Menu>
      <MetaData title="ลบโปรไฟล์ของฉัน" />
      <BackBtn to="/account/profile" text="โปรไฟล์ของฉัน" />
      <Content>
        <Title>
          <Text size="large" bold>
            ลบบัญชีผู้ใช้งาน
          </Text>
        </Title>

        <Text size="small">ระบุเหตุผล</Text>

        <ContentView>
          {content}
          <Space />
          <Button
            color="primary"
            disabled={!selected}
            loading={loading}
            text="ส่งคำร้อง"
            onClick={onSend}
          />
        </ContentView>
      </Content>
    </Menu>
  )
}

const Content = styled.div`
  width: 325px;
  margin: 0 auto;
  text-align: center;
`

const Title = styled.div`
  margin: 16px 0;
`

const ContentView = styled.div`
  margin-top: 30px;
`

const SelectItem = styled.div`
  margin-bottom: 8px;
`

const Space = styled.div`
  margin-top: 32px;
`

export default inject('member', 'account')(observer(DeleteAccount))
