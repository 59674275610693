import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import { Text } from 'components/typo'
import { format, message } from 'utils'
import getPackageInfo from 'definition/getPackageInfo'
import CommonTable from 'components/table/CommonTable'
import { PACKAGE_TYPE, MAP_PACKAGE_DURATION } from 'definition/package'
import { PAYMENT_TYPE } from 'definition/payment'

import AccountLayout from '../../components/AccountLayout'

const MyPaymentHistory = (props) => {
  const getPaymentHistory = useCallback(async () => {
    try {
      await props.payment.getHistory()
    } catch (e) {
      message.error({ message: e.message })
    }
  }, [props.payment])

  useEffect(() => {
    getPaymentHistory()
  }, [getPaymentHistory])

  const history = props.payment.toJS().history_list
  const columns = [
    {
      label: 'แพ็กเกจปัจจุบัน',
      func: (val) => getPackageInfo({ package_type: val.package_type }).name,
    },
    {
      label: 'งวดชำระ',
      func: (val) => MAP_PACKAGE_DURATION[val.package_duration],
    },
    {
      label: 'ช่องทาง',
      func: (val) => paymentMethod(val),
    },
    {
      label: 'มูลค่า',
      func: (val) => '฿ ' + format.toDigi(val.package_price),
    },
    { label: 'วันเริ่มต้น', func: (val) => format.toNameDate(val.start_at) },
    { label: 'วันสิ้นสุด', func: (val) => format.toNameDate(val.expired_at) },
  ]
  return (
    <AccountLayout backTo="/account/my-payment" backText="ข้อมูลการชำระเงิน">
      <Heading>
        <Text size="large" bold>
          ประวัติการชำระเงินและใบเสร็จ
        </Text>
      </Heading>
      <CommonTable columns={columns} data={history} />
    </AccountLayout>
  )
}

const paymentMethod = (item) => {
  const {
    package_type,
    promotion_code,
    payment_type,
    user_payment_id,
    paid,
    credit,
  } = item
  let text = ''
  if (package_type === PACKAGE_TYPE.TRIAL) text = 'ทดลองใช้'
  else if (promotion_code) text = `โปรโมชั่น (${promotion_code})`
  else if (payment_type === PAYMENT_TYPE.QR) text = 'คิวอาร์โค้ด/ พร้อมเพย์'
  else if (payment_type === PAYMENT_TYPE.CARD) text = 'บัตรเครดิต'
  else if (paid === 0 && credit === 0) text = 'เปลี่ยนแพ็กเกจ'
  else if (user_payment_id === null && credit > 0) text = 'หักเครดิตการใช้งาน'

  return <span>{text}</span>
}

const Heading = styled.div`
  margin-bottom: 60px;
`

export default inject('payment')(observer(MyPaymentHistory))
