import React, { useState } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'

import { Text } from 'components/typo'
import CreditCard from 'components/input/CreditCard'
import SubmitPayment from 'components/button/SubmitPayment'
import { message } from 'utils'

import AccountLayout from '../../../components/AccountLayout'

const MyPaymentCard = (props) => {
  const { packageId, period } = useParams()
  const [loading, setLoading] = useState(false)

  const [isSubmit, setSubmit] = useState(false)
  const [card, setCard] = useState({
    name: '',
    surname: '',
    card_id: '',
    expired: '',
    cvv: '',
    ex_month: '',
    ex_year: '',
    invalid: true,
  })

  const onChange = (val) => {
    setCard(val)
    setSubmit(false)
  }

  const onPayment = async (cardInfo) => {
    try {
      setLoading(true)
      cardInfo.payment_type = 'credit_card'
      const { payment } = await props.payment.changePackage(
        packageId,
        period,
        cardInfo
      )
      setLoading(false)
      const { otp_url } = payment || {}
      if (otp_url) {
        window.location.href = otp_url
      }
    } catch (e) {
      setLoading(false)
      message.error({ message: e.message })
    }
  }

  const onError = async () => {
    setCard({ ...card, invalid: true })
    setSubmit(true)
  }

  const disabled = card.invalid
  return (
    <AccountLayout
      backTo={`/account/my-payment/change-package/payment/${packageId}/${period}/info`}
      backText="ยอดชำระ"
    >
      <Content>
        <Text size="large" bold>
          ข้อมูลบัตรเครดิต หรือบัตรเดบิต
        </Text>
        <Description />
        <CreditCard card={card} isSubmit={isSubmit} onChange={onChange} />
        <SubmitPayment
          text="ถัดไป"
          card={card}
          onClick={onPayment}
          onError={onError}
          loading={loading}
          disabled={disabled}
        />
      </Content>
    </AccountLayout>
  )
}

const Description = styled.div`
  margin: 23px 0 33px;
`

const Content = styled.div`
  padding-top: 20px;
  width: 325px;
  margin-bottom: 70px;
`
export default inject('member', 'payment')(observer(MyPaymentCard))
