import React from 'react'
import styled from 'styled-components'

import CreditButton from 'components/button/CreditButton'
import PrompayButton from 'components/button/PrompayButton'
import { Text } from 'components/typo'
import AccountLayout from 'pages/account/components/AccountLayout'

const PaymentMethod = (props) => {
  const { title, description, back, backText, onBack, onCreditCard, onQRCode } =
    props

  return (
    <AccountLayout
      title={title}
      description={description}
      onClick={onBack}
      backTo={back}
      backText={backText}
    >
      <Text size="large" bold>
        วิธีการชำระเงิน
      </Text>

      <ButtonBox>
        <CreditButton onClick={onCreditCard} />
        {/* <PrompayButton onClick={onQRCode} /> */}
      </ButtonBox>
    </AccountLayout>
  )
}

const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  row-gap: 16px;
  margin-top: 40px;
  width: 387px;
`

export default PaymentMethod
