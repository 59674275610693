import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { onClick, size = 1 } = props

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(2 * size)}`
  return (
    <svg onClick={onClick} width={width} height={height} viewBox="0 0 21 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.79199 1L20.0003 1" stroke="#14142B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
// eslint-enable
export default Logo
