import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import useGTM from 'hooks/useGTM'

import { Link } from '../components/link'
import { Text } from '../components/typo'

const menus = [
  { name: 'แพ็กเกจของเรา', link: '/package', eventName: 'package' },
  { name: 'ฟีเจอร์', link: '/features', eventName: 'features' },
  { name: 'LifeBridge', link: '/lifebridge', eventName: 'lifebridge' },
  { name: 'ติดต่อเรา', link: '/contact-us', eventName: 'contact-us' },
  { name: 'สร้างบัญชี', link: '/register', eventName: 'register' },
]

const loginMenus = [
  { name: 'แพ็กเกจของเรา', link: '/package', eventName: 'package' },
  { name: 'ฟีเจอร์', link: '/features', eventName: 'features' },
  { name: 'LifeBridge', link: '/lifebridge', eventName: 'lifebridge' },
  { name: 'ติดต่อเรา', link: '/contact-us', eventName: 'contact-us' },
  { name: 'โปรไฟล์', link: '/account/profile', eventName: 'profile' },
]

const UserMenuMobile = (props) => {
  const history = useHistory()
  const user = props.member.getCurrentUser()
  const { onEvent } = useGTM()
  const isOnAccountPage = String(history.location.pathname).includes('/account')

  if (!props.isOpen) return false
  let currentMenu = user ? loginMenus : menus

  const onSignOut = () => {
    props.member.logout().then(() => {
      history.push('/login')
    })
  }

  const onMenuClick = (menu) => {
    const modifiedEvent = isOnAccountPage
      ? {
          pageName: 'profile',
          eventName: 'menu-click',
          params: {
            menu,
          },
        }
      : {
          eventName: 'menu-click',
          params: {
            menu,
          },
        }
    onEvent({ ...modifiedEvent })
  }

  if (isOnAccountPage) {
    const accountMenu = [
      { name: 'หน้าแรก', link: '/', eventName: 'home' },
      {
        name: 'บัญชีผู้ใช้งาน',
        list: [
          {
            name: 'โปรไฟล์ของฉัน',
            link: '/account/profile',
            eventName: 'account-profile',
            list: ['/account/delete-account'],
          },
          {
            name: 'ความปลอดภัย',
            link: '/account/privacy',
            eventName: 'account-privacy',
          },
          {
            name: 'ข้อมูลการชำระเงิน',
            link: '/account/my-payment',
            eventName: 'account-my-payment',
          },
        ],
      },
      {
        name: 'การสนับสนุน',
        list: [
          {
            name: 'ปัญหาที่พบบ่อย',
            link: '/account/common-problem',
            eventName: 'account-common-problem',
          },
          {
            name: 'ศูนย์ช่วยเหลือ',
            link: '/account/issue-problem',
            eventName: 'account-issue-problem',
          },
          {
            name: 'เงื่อนไขการใช้บริการ',
            link: '/account/consent/terms',
            eventName: 'account-terms',
          },
          {
            name: 'นโยบายความเป็นส่วนตัว',
            link: '/account/consent/privacy',
            eventName: 'account-privacy',
          },
          {
            name: 'เกี่ยวกับ',
            link: '/account/about',
            eventName: 'account-about',
          },
          {
            name: 'ให้คะแนนผู้ใช้งาน',
            link: '/account/rating',
            eventName: 'account-rating',
          },
        ],
      },
      { list: [{ name: 'ออกจากระบบ', onClick: onSignOut }] },
    ]
    currentMenu = accountMenu
  }

  return (
    <MenuBox>
      <Shadow />
      <Menu>
        {currentMenu.map((m) => {
          if (m.list)
            return (
              <MenuItem key={m.name} onClick={props.onClick}>
                <Text bold gray>
                  {m.name}
                </Text>
                <SubMenu>
                  {m.list.map((item) => (
                    <MenuItem key={item.name} onClick={item.onClick}>
                      <Link
                        to={item.link}
                        onClick={() => onMenuClick(item.eventName)}
                      >
                        <Text gray>{item.name}</Text>
                      </Link>
                    </MenuItem>
                  ))}
                </SubMenu>
              </MenuItem>
            )

          return (
            <MenuItem key={m.name} onClick={props.onClick}>
              <Link to={m.link} onClick={() => onMenuClick(m.eventName)}>
                <Text gray align="center">
                  {m.name}
                </Text>
              </Link>
            </MenuItem>
          )
        })}
      </Menu>
    </MenuBox>
  )
}

const MenuBox = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
`

const Shadow = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 70px);
  background-color: #121212;
  opacity: 0.45;
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  row-gap: 32px;
  width: 100%;
  flex: 1;
  border-top: 1px solid ${(props) => props.theme.gray.inputBackground};
  background-color: white;
`

const SubMenu = styled.div`
  padding-left: 20px;
`

const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  cursor: pointer;
`

export default inject('member')(observer(UserMenuMobile))
