import React from 'react'
import styled from 'styled-components'

const Content = (props) => {
  return <Body>{props.children}</Body>
}

const Body = styled.div`
  width: 1440px;
  margin: 0 auto;

  @media screen and (max-width: 1440px) {
    padding: 0 40px;
    width: 100%;
  }

  @media screen and (max-width: 500px) {
    padding: 20px;
  }
`

export default Content
