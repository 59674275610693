import React, { useState } from 'react'

import FieldInput from 'components/input/FieldInput'
import { EyeClose, EyeOpen } from 'icon'

import IconButton from '../button/IconButton'

const PasswordInput = (props) => {
  const [isOpen, setOpen] = useState(false)
  const { label = 'รหัสผ่าน' } = props
  return (
    <FieldInput
      {...props}
      id="password"
      label={label}
      startIcon={
        <IconButton onClick={() => setOpen(!isOpen)}>
          {isOpen ? <EyeClose /> : <EyeOpen />}
        </IconButton>
      }
      type={isOpen ? 'text' : 'password'}
      clearable
    />
  )
}

export default PasswordInput
