import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { size = 1, color = '#BCA4FF' } = props

  const width = `${Math.ceil(16 * size)}`
  const height = `${Math.ceil(16 * size)}`
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill={color} />
      <circle cx="8" cy="8" r="4" fill="#F7F7FC" />
    </svg>
  )
}
// eslint-enable
export default Logo
