import React, { useState } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import { Text } from 'components/typo'
import { Button } from 'components/button'
import { HelperText } from 'components/display'

import AccountLayout from '../../components/AccountLayout'

const MyPaymentPrompay = (props) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState('')
  const onConfirm = async () => {
    try {
      setSuccess('')
      setLoading(true)
      setError(false)
      await props.payment.changePaymentToQrCode()
      setSuccess('เปลี่ยนวิธีการชำระเงินผ่านคิวอาร์โค้ด/ พร้อมเพย์ สำเร็จ')
    } catch (e) {
      setError(e.message)
      console.error(e)
    }
    setLoading(false)
  }
  return (
    <AccountLayout
      backTo="/account/my-payment/payment-option"
      backText="เปลี่ยนวิธีการชำระเงิน"
    >
      <Text size="large" bold>
        ชำระเงินผ่านคิวอาร์โค้ด/ พร้อมเพย์
      </Text>
      <Description>
        <Text size="small" gray>
          กดยืนยันเพื่อเปลี่ยนวิธีการชำระเงินผ่านคิวอาร์โค้ด/ พร้อมเพย์
          <br />
          ระบบจะแจ้งเตือนเมื่อถึงรอบบิลถัดไป
        </Text>
      </Description>
      <Button
        color="primary"
        text="ยืนยัน"
        maxWidth="325px"
        loading={loading}
        onClick={onConfirm}
      />
      <ErrorInfo>
        <HelperText errorText={error} successText={success} />
      </ErrorInfo>
    </AccountLayout>
  )
}

const Description = styled.div`
  text-align: center;
  margin-top: 24px;
  margin-bottom: 45px;
`

const ErrorInfo = styled.div`
  margin: 8px 0;
`

export default inject('payment')(observer(MyPaymentPrompay))
