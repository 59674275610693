import React, { useState, useEffect } from 'react'

import CollapseList from 'components/CollapseList'
import Arrow from 'icon/Arrow'

import FieldInput from './FieldInput'
import { helper } from '../../utils'

const Dropdown = (props) => {
  const {
    label,
    small,
    value,
    name,
    show,
    disabled,
    onChange = (val) => {},
    options = [],
    errorText,
    filterable,
  } = props
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const onInputChange = (val) => {
    setOpen(true)
    const found = options.find((op) => op.label === val)
    if (found) {
      onChange(found.value, name)
    }

    setInputValue(val)
  }

  const onDropDownChange = (val) => {
    const found = options.find((item) => item.value === val)
    if (found) setInputValue(found.label)
    onChange(val, name)
  }

  const optionFilter = (item) => {
    if (!filterable) return true
    return helper.searchString(item.label, inputValue)
  }

  const isReadOnly = !filterable
  const isClearable = filterable

  useEffect(() => {
    const selected = options.findIndex((op) => op.value === value)
    const label = options[selected]?.label
    if (label) {
      setInputValue(label)
    }
  }, [options, value])

  return (
    <CollapseList
      open={open}
      show={show}
      setOpen={setOpen}
      options={options.filter(optionFilter)}
      value={inputValue}
      gap="0"
      onChange={onDropDownChange}
    >
      <FieldInput
        small={small}
        label={label}
        value={inputValue}
        onChange={onInputChange}
        disabled={disabled}
        readonly={isReadOnly}
        errorText={errorText}
        clearable={isClearable}
        onClick={() => setOpen(!open)}
        endIcon={<Arrow isUp={open} />}
      />
    </CollapseList>
  )
}

export default Dropdown
