import React from 'react'
import styled, { useTheme } from 'styled-components'
import { HashLink as RLink } from 'react-router-hash-link'

import { Back } from 'icon'

import { Text } from '../typo'

const Link = (props) => {
  const { onClick, to, text, children } = props
  const theme = useTheme()

  const btn = onClick ? (
    <BackView onClick={onClick}>
      <Back />
      <Text size="small" primaryDarkMode>
        {text || children}
      </Text>
    </BackView>
  ) : (
    <RLink to={to}>
      <Back />
      <Text size="small" primaryDarkMode>
        {text || children}
      </Text>
    </RLink>
  )

  return <LinkView theme={theme}>{btn}</LinkView>
}

const LinkView = styled.span`
  a {
    display: flex;
    align-items: center;
    height: 20px;
    color: ${(props) => props.theme.color.primaryBg};
  }
`

const BackView = styled.div`
  color: ${(props) => props.theme.color.primaryBg};
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 20px;
`

export default Link
