import React from 'react'
import styled from 'styled-components'

import Footer from '../footer'
import HeaderMenu from './HeaderMenu'
import Messager from './Messager'

const MainLayout = (props) => {
  const { history, children } = props

  return (
    <Wrapper>
      <HeaderMenu history={history} />
      <Messager />
      <Body id="app_body">{children}</Body>
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  position: relative;
`

const Body = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;
  margin: 70px 0;

  @media print {
    min-height: 90vh;
    margin-top: 0;
    padding: 0;
  }
`

export default MainLayout
