import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { getCountdown, formatTime } from './Countdown.utils'

function Countdown() {
  const [isExpired, setExpired] = useState(false)
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })
  useEffect(() => {
    const countDownDate = new Date('May 31, 2023 23:59:59').getTime()
    var now = new Date().getTime()
    var distance = countDownDate - now

    if (distance < 0) setExpired(true)

    const countdownInterval = setInterval(() => {
      setCountdown(getCountdown(countDownDate))
    }, 1000)

    return () => {
      clearInterval(countdownInterval)
    }
  }, [])

  useEffect(() => {
    // console.log('=== isExpired: ', isExpired)
  }, [isExpired])

  return (
    <Container className="Timer">
      {/* <TimeContainer>
        <Time>{formatTime(countdown.days)}</Time>
        <label>วัน</label>
      </TimeContainer>
      <TimeContainer>:</TimeContainer> */}
      <TimeContainer>
        <Time>{isExpired ? '--' : formatTime(countdown.hours)}</Time>
        <label>ชั่วโมง</label>
      </TimeContainer>
      <Time>:</Time>
      <TimeContainer>
        <Time>{isExpired ? '--' : formatTime(countdown.minutes)}</Time>
        <label>นาที</label>
      </TimeContainer>
      <Time>:</Time>
      <TimeContainer>
        <Time>{isExpired ? '--' : formatTime(countdown.seconds)}</Time>
        <label>วินาที</label>
      </TimeContainer>
    </Container>
  )
}

export default Countdown

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 24px;
  margin-bottom: 33px;
  border-radius: 16px;
  background-color: ${(props) => props.theme.color.secondary};
`
const TimeContainer = styled.div`
  padding: 0 2rem;
  text-align: center;

  @media screen and (max-width: 550px) {
    padding: 0 10px;
  }
`
const Time = styled.div`
  text-align: center;
  font-size: 64px;
  font-weight: bold;
  line-height: 66px;
  color: ${(props) => props.theme.gray.titleActive};
`
