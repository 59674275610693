import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  return (
    <svg width="325" height="89" viewBox="0 0 325 89" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
      <path d="M0.0302488 87.7315C0.0302488 59.0783 0.0201682 30.4237 0 1.76758C0 0.289542 0.211766 0 1.29653 0C108.764 0.0432175 216.232 0.0432175 323.699 0C324.784 0 324.996 0.289542 324.996 1.76758C324.952 30.4208 324.942 59.0755 324.965 87.7315C324.79 88.0288 324.536 88.2714 324.23 88.4324C323.925 88.5934 323.581 88.6665 323.237 88.6434C322.498 88.6434 321.759 88.6434 321.015 88.6434C215.323 88.6434 109.631 88.6348 3.94143 88.6175C2.64059 88.6391 1.18416 89.2614 0.0302488 87.7315Z" fill="#0E3E68"/>
      <path d="M144.805 66.9363C134.45 66.9363 124.098 66.9363 113.749 66.9363C108.641 66.9363 103.524 66.6899 98.4326 66.9363C92.3346 67.2345 86.2927 61.9576 86.3878 55.2935C86.4959 47.7477 86.3576 40.1932 86.4916 32.6475C86.5996 26.8434 91.6777 21.9209 97.512 21.8431C105.136 21.7711 112.761 21.7711 120.387 21.8431C126.252 21.8949 131.351 26.9255 131.464 32.8376C131.585 38.9486 131.529 45.0639 131.464 51.1748C131.421 51.738 131.508 52.3034 131.718 52.8278C131.927 53.3522 132.255 53.8216 132.674 54.2C136.849 58.2279 140.959 62.3292 145.09 66.4047L144.805 66.9363Z" fill="#FDFDFD"/>
      <path d="M215.447 26.9437C222.115 26.6412 224.786 33.1973 222.738 38.4439C222.224 39.7663 221.848 40.8208 223.278 41.737C224 42.1995 223.982 42.7051 223.278 43.228C222.725 43.6602 222.202 44.4295 221.549 43.4657C220.586 42.0698 219.479 42.2816 218.044 42.7051C215.106 43.5695 212.249 43.1935 210.162 40.7387C207.668 37.8129 207.698 34.3641 209.029 31.0018C210.179 28.0803 212.647 26.8702 215.447 26.9437Z" fill="#FCFCFD"/>
      <path d="M187.595 52.7691C187.595 55.2901 187.595 57.8082 187.595 60.3235C187.595 61.1014 187.781 61.8966 186.488 61.9053C185.196 61.9139 185.157 61.2051 185.162 60.2543C185.188 56.7969 185.162 53.3395 185.188 49.8821C185.188 49.1907 184.794 48.08 186.117 48.1534C187.128 48.2183 188.412 47.5009 188.978 49.2166C189.898 51.9912 191.022 54.7009 192.158 57.7045C193.256 54.8565 194.436 52.2721 195.231 49.5753C195.789 47.678 197.085 48.1059 198.282 48.1491C199.92 48.2139 199.147 49.5494 199.177 50.31C199.263 53.6161 199.203 56.9309 199.225 60.25C199.225 61.1749 199.272 61.9312 197.928 61.9182C196.584 61.9052 196.714 61.0539 196.714 60.1895C196.777 57.7561 196.651 55.3214 196.338 52.9074C195.516 55.0682 194.669 57.2291 193.883 59.4159C193.598 60.2025 193.356 60.7859 192.292 60.7686C191.229 60.7513 190.806 60.371 190.473 59.3986C189.695 57.1297 188.744 54.9127 187.88 52.674L187.595 52.7691Z" fill="#FBFBFB"/>
      <path d="M165.49 34.9536C165.49 32.7236 165.529 30.4936 165.49 28.2679C165.464 27.3128 165.792 26.9714 166.73 27.0535C167.512 27.114 168.403 26.9065 168.243 28.1858C168.232 28.3296 168.232 28.4741 168.243 28.6179C168.398 34.0072 168.398 33.9769 173.952 33.5923C174.907 33.5275 175.188 33.2379 175.144 32.3303C175.088 31.0338 175.209 29.7373 175.11 28.4408C175.015 27.2264 175.564 27.0362 176.618 27.0275C177.673 27.0189 178.118 27.2825 178.101 28.458C178.031 32.7798 178.044 37.1015 178.101 41.4233C178.101 42.5124 177.828 42.9316 176.661 42.9489C175.365 42.9489 175.028 42.4778 175.119 41.298C175.201 40.2262 175.036 39.1371 175.162 38.0653C175.348 36.4706 174.652 36.1853 173.208 36.1983C168.329 36.2415 168.329 36.1983 168.247 41.0948C168.247 42.0067 168.489 42.9273 166.95 42.9575C165.295 42.9878 165.516 42.0111 165.516 41.0041C165.494 38.9815 165.49 36.9676 165.49 34.9536Z" fill="#FCFCFC"/>
      <path d="M238.522 42.728C236.261 43.4151 234.943 42.7669 234.131 40.7486C233.599 39.4521 232.666 38.2895 232.03 37.0146C231.633 36.2151 231.079 36.0725 230.302 36.1503C229.225 36.2497 229.614 37.0665 229.593 37.6197C229.541 38.9162 229.519 40.2127 229.593 41.5093C229.653 42.6156 229.29 43.0175 228.136 43.0003C226.982 42.983 226.84 42.5421 226.84 41.5784C226.874 37.1961 226.874 32.8153 226.84 28.436C226.84 27.4463 227.056 26.9795 228.136 27.0746C229.986 27.2345 231.862 26.8499 233.703 27.3728C235.544 27.8957 236.84 28.8768 237.225 30.7438C237.61 32.6108 237.018 34.3308 235.263 35.3464C234.399 35.8391 234.446 36.2108 234.93 36.9325C236.162 38.8125 237.311 40.7616 238.522 42.728Z" fill="#FDFDFD"/>
      <path d="M193.13 42.8764C192.019 43.153 191.432 42.5566 191.254 41.4761C190.999 39.9116 190.122 39.3153 188.575 39.5659C187.858 39.66 187.131 39.66 186.414 39.5659C184.936 39.3844 183.951 39.782 183.7 41.4156C183.627 41.8867 183.41 42.5479 183.065 42.7121C182.68 42.9099 182.255 43.0162 181.822 43.023C181.39 43.0297 180.962 42.9367 180.571 42.751C179.823 42.3189 180.623 41.5971 180.822 41.0871C182.464 36.8389 184.184 32.6209 185.874 28.3855C186.168 27.6508 186.38 26.9983 187.46 27.0242C188.54 27.0501 188.756 27.5947 189.059 28.3855C190.736 32.7073 192.43 36.9988 194.189 41.2773C194.682 42.4442 194.708 43.0795 193.13 42.8764Z" fill="#FCFCFC"/>
      <path d="M217.297 53.1993C217.297 55.5676 217.271 57.9532 217.297 60.2999C217.297 61.2983 217.193 61.9206 215.935 61.9119C214.678 61.9033 214.816 61.1297 214.816 60.3388C214.816 56.9679 214.768 53.5926 214.859 50.2173C214.859 49.5388 214.15 48.3892 215.581 48.1601C216.735 47.9743 217.586 48.2768 218.213 49.4307C219.406 51.6262 220.767 53.7525 222.059 55.8701C222.262 56.2116 222.491 56.5443 223.023 57.413C223.023 54.4137 223.066 52.0151 223.001 49.6338C222.971 48.5664 223.205 48.1126 224.402 48.1169C225.599 48.1212 225.625 48.6874 225.616 49.5777C225.59 53.0956 225.616 56.6135 225.581 60.1271C225.581 60.7364 226.066 61.7175 224.938 61.8558C223.969 61.9768 223.019 62.0762 222.366 60.892C220.901 58.2341 219.272 55.667 217.707 53.061L217.297 53.1993Z" fill="#FCFCFC"/>
      <path d="M202.716 54.9854C202.716 53.1184 202.755 51.2471 202.716 49.3671C202.691 48.4466 202.967 48.0965 203.927 48.1224C206.226 48.1786 208.529 48.17 210.841 48.1224C211.706 48.1224 211.917 48.4811 211.922 49.2807C211.926 50.0802 211.585 50.4043 210.772 50.3741C209.623 50.3265 208.469 50.4259 207.315 50.3481C206.018 50.2574 205.249 50.3481 205.258 52.0768C205.266 53.8055 205.923 53.9698 207.28 53.8487C208.279 53.758 209.294 53.8487 210.305 53.8185C211.079 53.7882 211.472 53.9697 211.485 54.8687C211.498 55.7676 211.161 56.1652 210.236 56.0874C209.311 56.0096 208.348 56.1954 207.436 56.0528C205.452 55.733 205.176 56.7357 205.31 58.3736C205.379 59.2379 205.629 59.4886 206.45 59.4627C207.885 59.4151 209.324 59.4627 210.772 59.4368C211.55 59.4152 211.974 59.588 211.93 60.4956C211.892 61.2822 211.879 61.9002 210.811 61.8786C208.512 61.8397 206.208 61.831 203.896 61.8786C202.889 61.9002 202.712 61.4205 202.729 60.582C202.747 58.7237 202.716 56.8567 202.716 54.9854Z" fill="#FCFCFC"/>
      <path d="M171.937 61.6973C170.143 62.2073 168.912 61.9264 168.622 60.0378C168.617 59.8771 168.579 59.7192 168.51 59.5742C168.44 59.4292 168.341 59.3003 168.219 59.1958C168.097 59.0912 167.955 59.0133 167.801 58.967C167.647 58.9208 167.485 58.9072 167.326 58.9271C166.172 58.9271 165.022 58.953 163.868 58.9271C163.707 58.906 163.543 58.9185 163.387 58.9637C163.231 59.0088 163.086 59.0858 162.961 59.1896C162.836 59.2935 162.734 59.4221 162.661 59.5673C162.588 59.7125 162.546 59.8712 162.537 60.0335C162.209 61.9048 161.072 62.2635 159.227 61.6714C160.918 57.4188 162.598 53.1792 164.266 48.9525C164.525 48.2956 164.996 48.1486 165.623 48.1573C165.903 48.1156 166.188 48.1767 166.426 48.3293C166.665 48.4818 166.84 48.7155 166.919 48.9871C168.544 53.2267 170.247 57.449 171.937 61.6973Z" fill="#FBFBFC"/>
      <path d="M150.443 54.9305C150.443 53.2018 150.543 51.4731 150.413 49.7746C150.309 48.3744 150.845 48.0459 152.142 48.1453C153.494 48.2577 154.873 48.1151 156.217 48.2664C157.198 48.3327 158.116 48.7734 158.781 49.4973C159.446 50.2212 159.808 51.173 159.791 52.1559C159.834 54.2044 158.495 55.6954 156.26 56.0455C153.067 56.5684 153.084 56.5684 153.032 59.9351C153.032 60.8297 153.494 61.9793 151.735 61.962C149.976 61.9447 150.512 60.7605 150.482 59.8875C150.404 58.215 150.443 56.5641 150.443 54.9305Z" fill="#FCFCFC"/>
      <path d="M156.855 27.0697C158.505 27.0697 160.161 27.1043 161.816 27.0697C162.745 27.0394 163.285 27.1864 163.259 28.3317C163.259 29.3386 162.957 29.6584 161.915 29.7016C158.216 29.8615 158.22 29.9091 158.22 33.5653V41.0981C158.22 42.0057 158.48 42.9478 156.924 42.9435C155.48 42.9435 155.506 42.1829 155.515 41.1716C155.541 37.9389 155.467 34.6889 155.549 31.4692C155.584 30.1338 155.307 29.4294 153.821 29.6455C153.116 29.7449 152.382 29.6109 151.66 29.6714C150.718 29.7578 150.415 29.4034 150.428 28.457C150.441 27.5105 150.536 26.9833 151.686 27.0438C153.397 27.1388 155.13 27.0697 156.855 27.0697Z" fill="#FDFDFD"/>
      <path d="M170.844 48.2651C175.438 47.2798 174.677 52.2066 177.115 53.7537C177.855 52.6056 178.525 51.4135 179.12 50.184C179.902 48.3559 181.121 47.7119 183.243 48.3386C182.379 49.8123 181.592 51.3119 180.65 52.6603C178.947 55.0675 177.776 57.5828 178.26 60.634C178.402 61.5329 177.975 61.9305 177.028 61.8959C176.259 61.8527 175.442 61.8441 175.637 60.7895C176.445 56.4678 173.778 53.4901 171.799 50.2142C171.41 49.6394 170.839 49.1338 170.844 48.2651Z" fill="#FAFAFB"/>
      <path d="M231.736 55.9244V53.7635C231.736 50.6216 231.736 50.5784 228.629 50.3796C227.704 50.3234 227.388 50.0468 227.393 49.1219C227.393 48.1279 227.993 48.1409 228.689 48.1452C231.632 48.1452 234.575 48.1452 237.518 48.1452C238.348 48.1452 238.642 48.4175 238.633 49.2559C238.625 50.0943 238.348 50.3234 237.518 50.3925C234.281 50.6648 234.286 50.7037 234.286 54.066C234.286 56.2269 234.247 58.3878 234.286 60.5227C234.312 61.4908 234.096 61.9489 232.989 61.9316C231.883 61.9143 231.693 61.3828 231.727 60.4493C231.771 58.941 231.736 57.4327 231.736 55.9244Z" fill="#FAFAFB"/>
      <path d="M197.002 35.0503C197.002 32.8894 197.032 30.7286 197.002 28.5936C197.002 27.6255 197.037 26.9946 198.329 27.0162C199.487 27.0162 199.759 27.4095 199.746 28.4942C199.692 32.7987 199.692 37.1031 199.746 41.4076C199.746 42.4751 199.513 42.9202 198.329 42.9289C197.032 42.9289 196.998 42.2331 197.032 41.2909C197.024 39.2251 197.002 37.142 197.002 35.0503Z" fill="#FDFDFD"/>
      <path d="M101.56 61.9665C100.484 61.9665 99.3995 62.0141 98.3277 61.9665C94.0362 61.7202 91.4864 59.0926 91.3784 54.7924C91.3265 52.7093 91.4259 50.6219 91.3438 48.5432C91.3006 47.3676 91.5988 46.7842 92.8823 46.9398C93.6022 46.9767 94.3234 46.9767 95.0432 46.9398C96.5429 46.9657 98.3839 46.5076 99.4557 47.1862C100.528 47.8647 99.6891 49.8527 99.8404 51.2529C100.026 52.9816 101.098 53.47 102.662 53.5132C106.371 53.6039 106.371 53.6342 106.371 57.4028C106.366 61.9665 106.366 61.9665 101.56 61.9665Z" fill="#0E3E68"/>
      <path d="M91.3652 36.8551C91.4344 35.3252 91.2269 33.6052 91.6505 31.9024C92.2987 29.3094 94.3343 27.2695 97.0224 27.0534C99.7105 26.8373 102.472 26.9324 105.195 26.8373C106.185 26.8071 106.435 27.2695 106.375 28.1339C106.323 28.9204 106.375 29.7156 106.375 30.5022C106.375 31.9413 106.833 33.67 106.193 34.7332C105.458 35.9476 103.6 35.0746 102.213 35.2345C100.281 35.4635 99.8099 36.665 99.8056 38.3894C99.8056 41.7603 99.7494 41.7603 96.4433 41.7603H96.0111C91.3652 41.756 91.3652 41.756 91.3652 36.8551Z" fill="#0E3E68"/>
      <path d="M132.081 61.92C125.84 61.92 119.25 61.92 112.659 61.92C111.833 61.92 111.505 61.6045 111.518 60.7748C111.553 58.6917 111.544 56.6129 111.518 54.5298C111.518 53.8038 111.838 53.579 112.547 53.4926C114.254 53.2895 116.293 54.2878 117.577 52.4986C118.653 50.9946 117.746 49.1233 118.009 47.9219L132.081 61.92Z" fill="#1BA996"/>
      <path d="M116.359 26.8764C117.509 26.8764 118.667 26.8029 119.817 26.8764C123.914 27.1962 126.394 29.7417 126.511 33.8431C126.576 36.0644 126.403 38.2944 126.412 40.5245C126.412 41.4407 126.135 41.821 125.189 41.7562C124.324 41.6914 123.46 41.7562 122.596 41.7562C121.165 41.7259 119.406 42.1624 118.408 41.4839C117.41 40.8054 118.179 38.9514 118.053 37.5943C117.876 35.7706 116.757 35.239 115.102 35.2044C111.545 35.1309 111.545 35.1007 111.545 31.5914C111.545 26.8375 111.545 26.8375 116.359 26.8764Z" fill="#0E3E68"/>
      <path d="M217.729 39.9894C215.568 40.7716 213.74 40.5167 212.279 38.9176C210.724 37.2192 210.581 33.6797 211.847 31.6225C213.049 29.6907 214.916 29.3536 216.934 29.7036C219.843 30.2093 221.64 34.8379 220.08 37.4309C219.605 38.2132 219.289 37.8631 218.974 37.4741C218.166 36.5147 217.44 36.0696 216.476 37.3445C215.456 38.6972 217.128 38.7361 217.405 39.4405C217.504 39.6289 217.612 39.8121 217.729 39.9894Z" fill="#0F3E68"/>
      <path d="M229.619 31.6137C229.248 30.0795 229.797 29.5176 231.374 29.6214C232.952 29.7251 234.473 29.9239 234.468 31.7822C234.468 33.74 232.74 33.6795 231.296 33.861C229.321 34.1246 229.438 32.8886 229.619 31.6137Z" fill="#17446C"/>
      <path d="M185.451 37.0031C186.125 35.1231 186.722 33.4679 187.379 31.6484C188.386 33.0876 188.615 34.6737 189.241 36.0826C189.501 36.6617 189.289 37.029 188.572 37.0117C187.642 36.9858 186.709 37.0031 185.451 37.0031Z" fill="#14416A"/>
      <path d="M163.742 56.773L165.592 51.9023L167.398 56.773H163.742Z" fill="#1A456D"/>
      <path d="M153.052 51.9828C152.766 50.7425 153.246 50.2282 154.62 50.3708C155.917 50.5048 157.296 50.5912 157.213 52.2119C157.131 53.8325 155.705 53.7461 154.456 53.8844C152.861 54.0659 152.952 53.0978 153.052 51.9828Z" fill="#12406A"/>
      </g>
    </svg>
  )
}
// eslint-enable
export default Logo
