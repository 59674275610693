import React from 'react'
import styled from 'styled-components'

import { Text } from 'components/typo'
import { Button } from 'components/button'
import { DisabledInput } from 'components/input'
import { helper } from 'utils'

const ConfirmOtp = (props) => {
  const { loading, mobile, onConfirm, onBack } = props

  return (
    <Title>
      <Text size="large" bold>
        ผูกหมายเลขโทรศัพท์
      </Text>

      <Space />
      <DisabledInput
        label="หมายเลขโทรศัพท์"
        value={helper.formatPattern(mobile, 'xxx-xxx-xxxx')}
      />

      <Detail>
        <Button
          color="primary"
          text="ผูกบัญชี"
          maxWidth="325px"
          loading={loading}
          onClick={onConfirm}
        />
      </Detail>

      <ActionText onClick={onBack}>เปลี่ยนหมายเลขโทรศัพท์</ActionText>
    </Title>
  )
}

const Title = styled.div`
  margin: 0 0 36px;
  text-align: center;
`

const Space = styled.div`
  margin-top: 24px;
`

const Detail = styled.div`
  margin-top: 40px;
  margin-bottom: 29px;
`

const ActionText = styled.span`
  color: #5f2eea;
  cursor: pointer;
  font-size: 14px;
`

export default ConfirmOtp
