import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { message } from 'utils'

import SendOtp from './SendOtp'
import VerifyOtp from './VerifyOtp'
import ConfirmOtp from './ConfirmOtp'
import Connected from './Connected'
import AccountLayout from '../components/AccountLayout'

const SetMobile = (props) => {
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState('')
  const [mobile, setMobile] = useState('')
  const [page, setPage] = useState('otp')

  const onSendOtp = async (m) => {
    try {
      setLoading(true)
      const token = await props.account.sendOtp(m)
      setMobile(m)
      setToken(token)
      setPage('verifying')
      message.success({ message: 'ส่ง OTP สำเร็จ' })
    } catch (e) {
      message.error({ message: e.message })
    }

    setLoading(false)
  }

  const onCheckCode = async (code) => {
    try {
      setLoading(true)
      await props.account.verifyOtp(token, code)
      setPage('confirm')
    } catch (e) {
      message.error({ message: e.message })
    }

    setLoading(false)
  }

  const onConfirm = async () => {
    try {
      setLoading(true)
      await props.account.connectMobile(mobile)
      message.success({ message: 'ผูกหมายเลขโทรศัพท์สำเร็จ' })
      setPage('connected')
    } catch (e) {
      message.error({ message: e.message })
    }

    setLoading(false)
  }

  const onDisconnect = async () => {
    try {
      setLoading(true)
      await props.account.disconnect('mobile')
      message.success({ message: 'ยกเลิกหมายเลขโทรศัพท์สำเร็จ' })
      setPage('otp')
    } catch (e) {
      message.error({ message: e.message })
    }

    setLoading(false)
  }

  const getPage = () => {
    switch (page) {
      case 'otp':
        return <SendOtp loading={loading} onSendOtp={onSendOtp} />
      case 'verifying':
        return (
          <VerifyOtp
            loading={loading}
            mobile={mobile}
            onCheckCode={onCheckCode}
            onResend={onSendOtp}
            onBack={() => setPage('otp')}
          />
        )
      case 'confirm':
        return (
          <ConfirmOtp
            loading={loading}
            mobile={mobile}
            onConfirm={onConfirm}
            onBack={() => setPage('otp')}
          />
        )
      case 'connected':
        return (
          <Connected
            loading={loading}
            mobile={mobile}
            onDisconnect={onDisconnect}
          />
        )
      default:
    }
  }

  const content = getPage()
  return (
    <AccountLayout
      title="ผูกหมายเลขโทรศัพท์"
      description="ผูกหมายเลขโทรศัพท์"
      backTo="/account/connection"
      backText="ผูกบัญชี"
    >
      <Content>{content}</Content>
    </AccountLayout>
  )
}

const Content = styled.div`
  width: 325px;
  margin: 0 auto;
`

export default inject('account')(observer(SetMobile))
