import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { BackArrow } from 'icon'

const BackBtn = (props) => {
  const history = useHistory()
  const onClick = () => history.push(props.to)
  return (
    <Wrapper onClick={onClick}>
      <BackArrow />
      <Text>{props.text}</Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  height: 20px;
`

const Text = styled.span`
  color: #bca4ff;
  font-size: 16px;
  width: max-content;
`

export default BackBtn
