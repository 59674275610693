import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { size = 1 } = props

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.73301 26.2663C5.99967 26.533 6.39967 26.6663 6.66634 26.6663C6.933 26.6663 7.333 26.533 7.59967 26.2663L15.9997 17.8663L24.3997 26.2663C24.6663 26.533 25.0663 26.6663 25.333 26.6663C25.5997 26.6663 25.9997 26.533 26.2663 26.2663C26.7997 25.733 26.7997 24.933 26.2663 24.3997L17.8663 15.9997L26.2663 7.59968C26.7997 7.06634 26.7997 6.26634 26.2663 5.73301C25.733 5.19967 24.933 5.19967 24.3997 5.73301L15.9997 14.133L7.59967 5.73301C7.06633 5.19967 6.26634 5.19967 5.73301 5.73301C5.19967 6.26634 5.19967 7.06634 5.73301 7.59968L14.133 15.9997L5.73301 24.3997C5.19967 24.933 5.19967 25.733 5.73301 26.2663Z" fill="#4E4B66"/>
    </svg>
  )
}
// eslint-enable
export default Logo
