import React, { useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import Layout from 'pages/account/components/Layout'
import { Text } from 'components/typo'
import { Checkbox } from 'components/input'
import CreditCard from 'components/input/CreditCard'
import Loading from 'components/loading'
import PackagePaymentButton from 'components/button/PackagePaymentButton'
import SubmitPayment from 'components/button/SubmitPayment'
import getPackageInfo from 'definition/getPackageInfo'
import Modal from 'components/modal/Modal'
import ModalTax from 'components/modal/ModalTax'
import PromotionModal from 'pages/account/promotion/promotionModal'
import { helper, message } from 'utils'
import useGTM from 'hooks/useGTM'

import couponIcon from '../assetss/Coupon.png'
import couponIconSuccess from '../assetss/CouponSuccess.png'

const PaymentCard = (props) => {
  const history = useHistory()
  const { onEvent } = useGTM()
  const [init, setInit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState(undefined)
  const [accept, setAccept] = useState(true)
  const [acceptTax, setAcceptTax] = useState(false)
  const [isSubmit, setSubmit] = useState(false)
  const [isPromotion, setModalPromotion] = useState(false)
  const [promotion, setPromotion] = useState({})
  const [isTax, setTax] = useState(false)
  const [saveText, setSaveText] = useState(false)
  const [closeTax, setCloseTax] = useState(true)
  const [card, setCard] = useState({
    name: '',
    surname: '',
    card_id: '',
    expired: '',
    cvv: '',
    ex_month: '',
    ex_year: '',
    invalid: true,
  })
  const { user } = props.payment.toJS()
  const [taxinvoice, setTaxinvoice] = useState({
    tax_type: 'บุคคลธรรมดา',
    first_name: '',
    last_name: '',
    company_name: '',
    tax_no: '',
    phone_number: '',
    email: '',
    house_no: '',
    road: '',
    sub_district: '',
    district: '',
    province: '',
    postcode: '',
  })

  const onInit = useCallback(async () => {
    try {
      setInit(true)
      const res = (await props.package_app.getSelectPackage()) || {}
      const packageApp = res.package
      setSelected(packageApp)
    } catch (e) {
      // message.error({ message: e.message })
    }
    setInit(false)
  }, [props.package_app])

  const onChange = (val) => {
    setCard(val)
    setSubmit(false)
  }

  const onError = async () => {
    setCard({ ...card, invalid: true })
    setSubmit(true)
  }

  const onConfirm = async (cardInfo) => {
    onEvent({
      pageName: 'create-account',
      eventName: 'create-account-payment-confirm-button-click',
    })
    const card = { ...cardInfo, payment_type: 'credit_card' }
    try {
      setLoading(true)
      if (promotion.code) await props.promotion.apply(promotion.code)
      if (acceptTax) await props.payment.createTaxInvoice(taxinvoice)
      const { payment } = await props.payment.changePackage(
        selected.package_app_id,
        selected.package_duration,
        card
      )
      const { otp_url } = payment || {}
      if (otp_url) {
        window.location.href = otp_url
      } else {
        history.push('/account/my-payment')
      }
    } catch (e) {
      message.error({ message: e.message })
    }
    setLoading(false)
  }

  const info = getPackageInfo(selected)
  const disabled = !accept || card.invalid || info.price === 0

  const getMyPackage = useCallback(async () => {
    try {
      await props.payment.getMyPlan()
    } catch (e) {
      message.error({ message: e.message })
    }
  }, [props.payment])

  const onPromotionClick = () => {
    onEvent({
      pageName: 'create-account',
      eventName: 'create-account-payment-coupon-code-button-click',
    })
    if (promotion.code) return
    setModalPromotion(true)
  }

  const clearPromotion = useCallback(() => {
    setPromotion({})
  }, [setPromotion])

  const onChangePackage = () => {
    onEvent({
      pageName: 'create-account',
      eventName: 'create-account-payment-change-package-button-click',
    })
    history.push('/account/package-detail', { from: 'payment' })
  }

  useEffect(() => {
    if (acceptTax) {
      setTax(true)
    }
    if (isPromotion) {
      setAcceptTax(false)
    }
  }, [acceptTax, isPromotion])

  useEffect(() => {
    getMyPackage()
  }, [getMyPackage])

  useEffect(() => {
    if (closeTax === false) {
      setSaveText(false)
      setCloseTax(true)
    }
  }, [closeTax])

  useEffect(() => {
    onInit()
  }, [onInit])

  return (
    <Layout header="ชำระเงิน" subHeader="ขั้นตอนที่ 4 จาก 4">
      <Text gray>กรอกรายละเอียดเพื่อชำระเงิน</Text>
      <Modal isOpened={isPromotion} setOpen={setModalPromotion}>
        <PromotionModal
          setOpen={setModalPromotion}
          onApplyPromotion={setPromotion}
        />
      </Modal>
      <ModalTax
        user={user}
        taxinvoice={taxinvoice}
        setTaxinvoice={setTaxinvoice}
        isOpened={isTax}
        setOpen={setTax}
        saveText={saveText}
        setSaveText={setSaveText}
      />
      <Content>
        <CreditCard card={card} isSubmit={isSubmit} onChange={onChange} />
        <Loading loading={init}>
          <PackagePaymentButton
            info={info}
            credit={user.credit || promotion?.value || 0}
            onClick={onChangePackage}
          />
        </Loading>
        <Coupon color={promotion.code} onClick={onPromotionClick}>
          <CouponText>ใช้คูปองโค้ด</CouponText>
          <CouponIconContainer>
            <img src={promotion.code ? couponIconSuccess : couponIcon} alt="" />
            {promotion.code && <div onClick={clearPromotion}>X</div>}
          </CouponIconContainer>
        </Coupon>
        <Space>
          {saveText ? (
            <ContentDiv>
              <RowDiv>
                <Checkbox
                  size
                  label="ต้องการใบกำกับภาษี"
                  checked={closeTax}
                  onCheck={(val) => setCloseTax(val)}
                  disabled={loading}
                />
                <span
                  className="text"
                  onClick={() => {
                    setTax(true)
                  }}
                >
                  แก้ไข
                </span>
              </RowDiv>
              <Padding>
                {taxinvoice.tax_type === 'individuals' ? (
                  <span className="textSpan">
                    ชื่อ {taxinvoice.first_name} {taxinvoice.last_name}
                  </span>
                ) : (
                  <span className="textSpan">
                    ชื่อบริษัท {taxinvoice.company_name}
                  </span>
                )}

                <span className="textSpan">
                  ติดต่อ {helper.toMobile(taxinvoice.phone_number)}
                </span>
                <span className="textSpan">อีเมล {taxinvoice.email}</span>
                <span className="textSpan">
                  หมายเลขบัตรประชาชน/หมายเลขนิติบุคคล <br />
                  {helper.toidCard(taxinvoice.tax_no)}
                </span>

                <span className="textSpan">
                  {taxinvoice.house_no} ถนน {taxinvoice.road} อำเภอ/เขต
                  {taxinvoice.district}
                </span>
                <span className="textSpan">
                  ตำบล/แขวง {taxinvoice.sub_district}
                  <br />
                  จังหวัด {taxinvoice.province}
                </span>
                <span className="textSpan">{taxinvoice.postcode}</span>
              </Padding>
            </ContentDiv>
          ) : (
            <Checkbox
              size
              label="ต้องการใบกำกับภาษี"
              checked={acceptTax}
              onCheck={(val) => setAcceptTax(val)}
              disabled={loading}
            />
          )}
        </Space>
        <Text size="xsmall">
          เมื่อคุณกดยอมรับ ค่าบริการแบบรายเดือนจะเริ่ม เรียกเก็บแบบตั้งแต่วันที่
          15 เม.ษ. 2021 โดยคุณสามารถ ยกเลิกได้ตลอดเวลาที่ต้องการได้ที่
          เมนูโปรไฟล์ของคุณ &gt; เมนูข้อมูลการชำระเงิน
        </Text>

        <Space>
          <Checkbox
            label="ยอมรับ"
            checked={accept}
            onCheck={(val) => setAccept(val)}
            disabled={loading}
          />
        </Space>
        <SubmitPayment
          onClick={onConfirm}
          text="ชำระเงิน"
          card={card}
          onSubmit={() => setSubmit(true)}
          onLoadStart={() => setLoading(true)}
          onLoadEnd={() => setLoading(false)}
          onError={onError}
          loading={loading}
          disabled={disabled}
        />
      </Content>
    </Layout>
  )
}

const Content = styled.div`
  padding-top: 20px;
  width: 325px;
  margin-bottom: 70px;

  .textSpan {
    font-weight: 500;
    font-size: 14px;
    color: #a0a3bd;
  }
`

const Space = styled.div`
  padding: 24px 0 32px;
  text-align: left;
`

const Coupon = styled.div`
  background-color: ${(props) => (props.color ? '#F2FFFB' : 'none')};
  display: flex;
  flex-direction: row;
  padding: 10px;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 16px;
  border: ${(props) =>
    props.color ? '1px solid #00BA88' : '1px solid #d9dbe9'};
  color: ${(props) => (props.color ? '#00BA88' : '#5f2eea')};
  cursor: ${(props) => (props.color ? 'not-allowed' : 'pointer')};
`
const CouponText = styled.div`
  font-size: 16px;
  color: inherit;
  display: inline-block;
  font-weight: 600;
`
const CouponIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
const Padding = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
`
const ContentDiv = styled.div`
  width: 325px;
  height: 264px;
  border: 1px solid #d9dbe9;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 20px;
`
const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .text {
    font-weight: 600;
    font-size: 14px;
    color: #5f2eea;
    font-size: 14px;
    cursor: pointer;
  }
`

export default inject(
  'member',
  'account',
  'package_app',
  'payment',
  'promotion'
)(observer(PaymentCard))
