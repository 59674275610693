import React, { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'

import Menu from 'pages/account/components/Menu/Menu'
import { message } from 'utils'
import useGTM from 'hooks/useGTM'

import EditProfile from './edit'
import Profile from './Profile'

const ProfileRoute = (props) => {
  const [init, setInit] = useState(false)
  const [edit, setEdit] = useState(false)
  const { onPageLoad } = useGTM()

  const onInit = useCallback(async () => {
    try {
      setInit(true)

      await props.member.getUserProfile()

      await Promise.all([
        props.account.getAgent(),
        props.account.getCompanyList(),
      ])
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    }
    setInit(false)
  }, [props.member, props.account])

  useEffect(() => {
    onInit()
  }, [onInit, edit])

  useEffect(() => {
    onPageLoad({ pageName: 'profile' })
  }, [onPageLoad])

  const content = edit ? (
    <EditProfile init={init} onFinish={() => setEdit(false)} />
  ) : (
    <Profile init={init} onEdit={() => setEdit(true)} />
  )

  return <Menu>{content}</Menu>
}

export default inject('member', 'account')(observer(ProfileRoute))
