import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { size = 1, color = "#5F2EEA" } = props;

  const width = `${Math.ceil(38 * size)}`;
  const height = `${Math.ceil(38 * size)}`;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 19C1 9.05888 9.05888 1 19 1C28.9411 1 37 9.05888 37 19C37 28.9411 28.9411 37 19 37C9.05888 37 1 28.9411 1 19Z"
        stroke="#5F2EEA"
        strokeWidth="2"
      />
      <path
        d="M18.9999 11.9609C18.5777 11.9609 18.2962 12.2424 18.2962 12.6646V18.2943H12.6666C12.2444 18.2943 11.9629 18.5758 11.9629 18.998C11.9629 19.4202 12.2444 19.7017 12.6666 19.7017H18.2962V25.3313C18.2962 25.7535 18.5777 26.035 18.9999 26.035C19.4222 26.035 19.7036 25.7535 19.7036 25.3313V19.7017H25.3333C25.7555 19.7017 26.037 19.4202 26.037 18.998C26.037 18.5758 25.7555 18.2943 25.3333 18.2943H19.7036V12.6646C19.7036 12.2424 19.4222 11.9609 18.9999 11.9609Z"
        fill="#5F2EEA"
      />
    </svg>
  );
};
// eslint-enable
export default Logo;
