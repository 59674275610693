import React, { useState, useEffect, useCallback } from 'react'
import { observer, inject } from 'mobx-react'
import styled from 'styled-components'

import Modal from 'components/modal/Modal'
import { Text } from 'components/typo'
import { Button } from 'components/button'
import { HtmlContent } from 'components/display'

function ConsentModal(props) {
  const [isOpened, setOpen] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const [runnerNumber, setNumber] = useState(0)
  const isLogin = props.member.isLogin()

  const onConfirm = useCallback(
    (index) => {
      if (index !== props.member.consentList.length - 1) {
        setNumber(runnerNumber + 1)
      } else {
        props.member.acceptConsent(() => {
          setOpen(false)
        })
      }
    },
    [props.member, runnerNumber, setNumber]
  )

  const onCheck = useCallback(async () => {
    if (isLogin) {
      await props.member.checkConsent(() => {
        setInitialized(true)
      })
      return
    }
  }, [props.member, isLogin])

  useEffect(() => {
    onCheck()
  }, [onCheck])

  useEffect(() => {
    if (initialized && !props.member.isAccepted) {
      setOpen(true)
    }
  }, [initialized, props.member])

  if (initialized) {
    return (
      <Modal isOpened={isOpened} setOpen={() => {}}>
        {props.member.consentList.map((consent, index) => (
          <ConsentContainer
            key={consent.consent_id}
            show={index === runnerNumber}
          >
            <Text size="large" title bold>
              {consent.consent_type === 'privacy' && 'นโยบายความเป็นส่วนตัว'}
              {consent.consent_type === 'terms' && 'เงื่อนไขการใช้บริการ'}
            </Text>
            <br />
            <Content>
              <Text size="small" gray>
                <HtmlContent content={consent.content} />
              </Text>
            </Content>
            <Button
              large
              text="ยอมรับ"
              width="300px"
              color="primary"
              onClick={() => {
                onConfirm(index)
              }}
            />
          </ConsentContainer>
        ))}
      </Modal>
    )
  }

  return <></>
}

export default inject('member', 'account')(observer(ConsentModal))

const ConsentContainer = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 2rem 1rem;
`
const Content = styled.div`
  margin-bottom: 2rem;
  overflow: auto;
`
