import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.66634 17.3337H25.333C26.133 17.3337 26.6663 16.8003 26.6663 16.0003C26.6663 15.2003 26.133 14.667 25.333 14.667H6.66634C5.86634 14.667 5.33301 15.2003 5.33301 16.0003C5.33301 16.8003 5.86634 17.3337 6.66634 17.3337Z" fill="#4E4B66"/>
      <path d="M6.66634 23.9997H25.333C26.133 23.9997 26.6663 23.4663 26.6663 22.6663C26.6663 21.8663 26.133 21.333 25.333 21.333H6.66634C5.86634 21.333 5.33301 21.8663 5.33301 22.6663C5.33301 23.4663 5.86634 23.9997 6.66634 23.9997Z" fill="#4E4B66"/>
      <path d="M6.66634 10.6667H25.333C26.133 10.6667 26.6663 10.1333 26.6663 9.33333C26.6663 8.53333 26.133 8 25.333 8H6.66634C5.86634 8 5.33301 8.53333 5.33301 9.33333C5.33301 10.1333 5.86634 10.6667 6.66634 10.6667Z" fill="#4E4B66"/>
    </svg>
  );
};
// eslint-enable
export default Logo;
