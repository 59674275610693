import React from 'react'
import styled, { useTheme } from 'styled-components'

import Text from '../typo/Text'
import BaseBtn from './BaseBtn'
import LoadingIcon from './LoadingIcon'

const Button = (props) => {
  const {
    startIcon,
    disabled,
    type,
    fontSize = 'small',
    loading,
    align,
    text,
    onClick,
    children,
  } = props

  const theme = useTheme()
  const color = getColor(props, theme)
  const size = getSize(props)

  const style = { size, color }

  if (loading) {
    return (
      <CustomButton {...style}>
        <LoadingIcon loading={true} />
      </CustomButton>
    )
  }

  return (
    <CustomButton {...style} type={type} disabled={disabled} onClick={onClick}>
      {startIcon && <StartIcon>{startIcon}</StartIcon>}
      <ButtonText>
        <Text size={fontSize} align={align}>
          {text || children}
        </Text>
      </ButtonText>
    </CustomButton>
  )
}

const CustomButton = styled(BaseBtn)`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`

const StartIcon = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;

  left: 16px;

  width: 24px;
  height: 24px;
`

const ButtonText = styled.div`
  flex: 1;
`

const getSize = (props) => {
  const { width = '100%', maxWidth, large, small } = props
  const size = { height: '56px', width, maxWidth }

  if (large) size.height = '64px'
  else if (small) size.height = '40px'

  return size
}

const getColor = (props, theme) => {
  const { color: colorType } = props
  const { color, gray } = theme

  switch (colorType) {
    case 'primary':
      return {
        font: theme.gray.offWhite,
        bg: color.primary,
        bgHover: color.primaryDark,
        focusOutline: color.primaryLight,
      }
    case 'secondary':
      return {
        font: color.primary,
        fontHover: color.primaryDark,
        border: color.primary,
        borderHover: color.primaryDark,
        focusOutline: color.primaryLight,
      }
    case 'secondarydisble':
      return {
        font: color.secondaryDisabled,
        fontHover: color.secondaryDisabled,
        border: color.secondaryDisabled,
        borderHover: color.secondaryDisabled,
        focusOutline: color.secondaryDisabled,
      }
    case 'subtle':
      return {
        font: color.primary,
        fontHover: color.primaryDark,
        border: gray.line,
        focusOutline: gray.inputBackground,
      }
    case 'text':
      return {
        font: color.primary,
        fontHover: color.primaryDark,
        border: 'none',
        bg: 'transparent',
        focusOutline: gray.inputBackground,
        bgFocus: gray.inputBackground,
      }
    default:
      return {
        font: gray.body,
        fontHover: gray.titleActive,
        border: gray.line,
        focusOutline: gray.inputBackground,
      }
  }
}

export default Button
