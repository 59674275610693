import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import Display from 'components/typo/Display'
import Button from 'components/button/Button'
import EmailInput from 'components/input/EmailInput'
import Text from 'components/typo/Text'
import { MetaData } from 'components/display'
import useGTM from 'hooks/useGTM'

const ForgotPassword = (props) => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState({})
  const [loading, setLoading] = useState({})
  const [isSend, setSend] = useState(false)
  const [isSubmit, setSubmit] = useState(false)
  const { onPageLoad, onEvent } = useGTM()

  useEffect(() => {
    onPageLoad({ pageName: 'forget-password' })
  }, [onPageLoad])

  const onEmail = async (e) => {
    e.preventDefault()
    try {
      setError({})
      setLoading({ email: true })
      setSubmit(true)
      await props.member.resetPassword({ email })
      setSend(true)
    } catch (e) {
      let errorMsg = e.message

      if (errorMsg === 'email not found') errorMsg = 'อีเมลนี้ไม่มีอยู่ในระบบ'

      setError({ email: errorMsg })
    }
    setLoading({})
  }

  const onEmailChange = (val) => {
    const result = props.member.validateEmail(val)
    setEmail(result.value)

    if (result.error) {
      setError({ email: 'รูปแบบอีเมลไม่ถูกต้อง' })
    } else {
      setError({ email: '' })
    }
  }

  const onClear = () => {
    setSubmit(false)
  }

  const onSubmit = () => {
    onEvent({
      pageName: 'forget-password',
      eventName: 'forget-password-email-next-button-click',
    })
  }

  const onGoBack = () => {
    onEvent({
      pageName: 'forget-password',
      eventName: 'forget-password-back-home-button-click',
    })
  }

  const isDisabled = error.email || email.length === 0
  const isError = isSubmit && error.email

  const goHome = () => props.history.push('/')

  if (isSend)
    return (
      <PageView>
        <TextBox>
          <Display bold>ลืมรหัสผ่าน</Display>
          <Text size="small">เช็คอีเมลของคุณเพื่อสร้างรหัสผ่านใหม่</Text>
        </TextBox>
        <ForgotForm onSubmit={goHome}>
          <Button
            type="submit"
            color="primary"
            text="กลับหน้าแรก"
            onClick={onGoBack}
          />
        </ForgotForm>
      </PageView>
    )

  return (
    <PageView>
      <MetaData title="ลืมรหัสผ่าน" description="ลืมรหัสผ่าน" />
      <TextBox>
        <Display bold>ลืมรหัสผ่าน</Display>
        <Text size="small">โปรดระบุอีเมล</Text>
      </TextBox>
      <ForgotForm onSubmit={onEmail}>
        <EmailInput
          value={email}
          errorText={isError}
          onClear={onClear}
          onChange={onEmailChange}
        />
        <Button
          large
          type="submit"
          onClick={onSubmit}
          disabled={isDisabled}
          loading={loading.email}
          color="primary"
          text="ถัดไป"
        />
      </ForgotForm>
    </PageView>
  )
}

const PageView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 45px 0;
  column-gap: 120px;
  width: 100%;
`

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  row-gap: 16px;
  margin-bottom: 52px;
`

const ForgotForm = styled.form`
  display: flex;
  flex-direction: column;

  row-gap: 32px;
  width: 100%;
  max-width: 325px;
`

export default inject('member')(observer(ForgotPassword))
