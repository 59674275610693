import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { observer, inject } from 'mobx-react'
import { useLocation } from 'react-router-dom'

import Modal from 'components/modal/Modal'
import { message } from 'utils'
import VerifyOtp from 'pages/account/setMobile/VerifyOtp'
import ConfirmOtp from 'pages/account/setMobile/ConfirmOtp'

import SendOtp from './SendOtp'

const BindingMobileModal = (props) => {
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState('')
  const [mobile, setMobile] = useState('')
  const [page, setPage] = useState('otp')
  const [isOpened, setOpen] = useState(false)
  const isLogin = props.member.isLogin()

  const closeModal = () => setOpen(false)

  useEffect(() => {
    if (isLogin) {
      const userInfo = props.member.toJS()
      if (location.pathname === '/' && !userInfo.user.mobile) setOpen(true)
    }
  }, [isLogin, location, props.member])

  const onSendOtp = async (m) => {
    try {
      setLoading(true)
      const token = await props.account.sendOtp(m)
      setMobile(m)
      setToken(token)
      setPage('verifying')
      message.success({ message: 'ส่ง OTP สำเร็จ' })
    } catch (e) {
      message.error({ message: e.message })
    }

    setLoading(false)
  }

  const onCheckCode = async (code) => {
    try {
      setLoading(true)
      await props.account.verifyOtp(token, code)
      setPage('confirm')
    } catch (e) {
      message.error({ message: e.message })
    }

    setLoading(false)
  }

  const onConfirm = async () => {
    try {
      setLoading(true)
      await props.account.connectMobile(mobile)
      message.success({ message: 'ผูกหมายเลขโทรศัพท์สำเร็จ' })
      closeModal()
    } catch (e) {
      message.error({ message: e.message })
    }

    setLoading(false)
  }

  const getPage = () => {
    switch (page) {
      case 'otp':
        return <SendOtp loading={loading} onSendOtp={onSendOtp} />
      case 'verifying':
        return (
          <VerifyOtp
            loading={loading}
            mobile={mobile}
            onCheckCode={onCheckCode}
            onResend={onSendOtp}
            onBack={() => setPage('otp')}
          />
        )
      case 'confirm':
        return (
          <ConfirmOtp
            loading={loading}
            mobile={mobile}
            onConfirm={onConfirm}
            onBack={() => setPage('otp')}
          />
        )
      default:
    }
  }

  const content = getPage()

  return (
    <Modal isOpened={isOpened} setOpen={() => {}}>
      <ModalTextWrapper>{content}</ModalTextWrapper>
    </Modal>
  )
}

const ModalTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 128px 0;
  row-gap: 24px;
  width: 100%;
`

export default inject('member', 'account')(observer(BindingMobileModal))
