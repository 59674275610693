import { theme } from './index'

export const IssueProblemStatus = {
  pending: {
    text: 'รอการตอบรับ',
    color: theme.color.success,
  },
  closed: {
    text: 'ปิด',
    color: theme.gray.placeholder,
  },
  response: {
    text: 'รอการตอบรับ',
    color: theme.color.success,
  },
}
