import React from 'react'
import styled from 'styled-components'

import Dialog from 'dialog'
import { Button } from 'components/button'

const ConnectDialog = (props) => {
  const { name = '', visible, onClose, onConfirm } = props

  const width = name === 'facebook' ? '651px' : '592px'
  const title = name === 'facebook' ? 'Facebook' : 'Gmail'
  return (
    <Dialog width={width} visible={visible} onClose={onClose}>
      <Content>
        <Title>เข้าสู่ {title} เพื่อผูกบัญชี</Title>
        <AreaBtn>
          <Button
            color="primary"
            text={`ล็อกอินเข้า ${title}`}
            onClick={onConfirm}
          />
        </AreaBtn>
        <Confirm onClick={onClose}>กลับ</Confirm>
      </Content>
    </Dialog>
  )
}

const Content = styled.div`
  text-align: center;
  background-color: white;
`

const Title = styled.div`
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: bold;

  @media screen and (max-width: 500px) {
    font-size: 26px;
    margin-bottom: 16px;
  }
`

const AreaBtn = styled.div`
  margin: 0 auto;
  padding: 24px 0;
  width: 220px;
`

const Confirm = styled.span`
  margin: 0 auto;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`

export default ConnectDialog
