import React, { useState } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'

import Layout from 'pages/account/components/Layout'
import { message } from 'utils'

import SendOtpRegister from './SendOtpRegister'
import VerifyOtpRegister from './VerifyOtpRegister'

const SetMobileBind = (props) => {
  const [mobile, setMobile] = useState('')
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState('')
  const [page, setPage] = useState('otp')
  const history = useHistory()
  const onSendOtp = async (m) => {
    try {
      setLoading(true)
      const token = await props.account.sendOtp(m)
      setMobile(m)
      setToken(token)
      setPage('verifying')

      message.success({ message: 'ส่ง OTP สำเร็จ' })
    } catch (e) {
      message.error({ message: e.message })
    }

    setLoading(false)
  }

  const onCheckCode = async (code) => {
    try {
      setLoading(true)
      await props.account.verifyOtp(token, code)
      onConfirm()
    } catch (e) {
      message.error({ message: e.message })
    }

    setLoading(false)
  }

  const onConfirm = async () => {
    try {
      setLoading(true)
      await props.account.connectMobile(mobile)
      message.success({ message: 'ผูกหมายเลขโทรศัพท์สำเร็จ' })
      history.push('/account/package-detail')
    } catch (e) {
      message.error({ message: e.message })
    }

    setLoading(false)
  }
  const getPage = () => {
    switch (page) {
      case 'otp':
        return <SendOtpRegister loading={loading} onSendOtp={onSendOtp} />
      case 'verifying':
        return (
          <VerifyOtpRegister
            loading={loading}
            mobile={mobile}
            onCheckCode={onCheckCode}
            onResend={onSendOtp}
            onBack={() => setPage('otp')}
          />
        )

      default:
    }
  }

  const content = getPage()
  return (
    <Layout
      header="ผูกหมายเลขโทรศัพท์"
      subHeader={<Textcustom>ขั้นตอนที่ 2 จาก 4</Textcustom>}
    >
      {content}
    </Layout>
  )
}

const Textcustom = styled.div`
  color: #4e4b66;
`

export default inject('account')(observer(SetMobileBind))
