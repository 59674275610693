import React, { useState } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import Text from 'components/typo/Text'
import { Button } from 'components/button'
import { MetaData } from 'components/display'
import useGTM from 'hooks/useGTM'

let intervalId
const COUNT = 59
const ResendEmail = (props) => {
  const [count, setCount] = useState(COUNT)
  const [loading, setLoading] = useState({})
  const [error, setError] = useState({})
  const { onEvent } = useGTM()
  const email = props.location.state?.email
  const ref_code = props.location.state?.ref_code

  const onEmail = async (e) => {
    e.preventDefault()
    onEvent({
      pageName: 'register',
      eventName: 'register-resend-mail-button-click',
    })
    try {
      setLoading({ email: true })
      await props.member.resendEmail({ ref_code })
      countDown()
    } catch (e) {
      setError({ email: e.message })
    }
    setLoading({})
  }

  const countDown = () => {
    intervalId = setInterval(() => {
      setCount((count) => count - 1)
    }, 1000)
  }

  if (count <= 0) {
    clearInterval(intervalId)
    setCount(COUNT)
  }
  const isCounting = count !== COUNT
  const btnText = isCounting
    ? `รออีก ${count} วินาที เพื่อส่งอีเมลอีกครั้ง...`
    : 'ส่งอีเมลอีกครั้ง'

  return (
    <Wrapper>
      <MetaData
        title={'ส่งอีเมลไปที่'}
        description={`เราได้ส่งอีเมลไปที่ inbox`}
      />
      <Text size="small">เราได้ส่งอีเมลไปที่</Text>
      <Text size="large" bold>
        {email}
      </Text>
      <Text size="small">โปรดยืนยันอีเมลเพื่อดำเนินการต่อ</Text>
      <Button
        color="text"
        small
        maxWidth="200px"
        loading={loading.email}
        errorText={error.email}
        disabled={isCounting}
        onClick={onEmail}
        text={btnText}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  row-gap: 16px;
  height: 80vh;
`

export default inject('member')(observer(ResendEmail))
