import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Popover } from 'antd'

import LicenseSetting from './LicenseSetting'

const PopupSetting = (props) => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [doc, setDoc] = useState({})

  useEffect(() => {
    if (visible) {
      const userAgent = props.account.toJS().agent || {}
      setDoc(userAgent)
    }
  }, [visible])

  const onVisibleChange = (visible) => {
    setVisible(visible)
  }

  const onSave = async () => {
    try {
      setLoading(true)
      await props.account.updateAgentNotification(doc)
      setVisible(false)
    } catch (e) {
      console.log('msg:', e.message)
    }
    setLoading(false)
  }

  const content = (
    <LicenseSetting
      loading={loading}
      doc={doc}
      onChange={(val) => setDoc({ ...val })}
      onClose={() => setVisible(false)}
      onSave={onSave}
    />
  )
  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      content={content}
      visible={visible}
      onVisibleChange={onVisibleChange}
    >
      {props.children}
    </Popover>
  )
}

export default inject('account')(observer(PopupSetting))
