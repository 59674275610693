import React from 'react'
import { Helmet } from 'react-helmet'

const MetaData = (props) => {
  const {
    title = 'Friday',
    description = 'Friday',
    keywords = '',
    // url,
  } = props

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={window.location.href} />
    </Helmet>
  )
}

export default MetaData

// <meta property="og:image" content={url || config.img_url} />
