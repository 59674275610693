import React, { useState, useCallback, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import Menu from 'pages/account/components/Menu/Menu'
import BackBtn from 'pages/account/components/BackBtn'
import { Text } from 'components/typo'
import { MetaData } from 'components/display'
import Loading from 'components/loading'
import { GoogleBtn, FacebookBtn, EmailBtn, PhoneBtn } from 'components/button'
import { Google, Facebook, Email, Phone } from 'icon'
import { message, format } from 'utils'

import CancelBtn from './CancelBtn'
import CancelDialog from './CancelDialog'
import ConnectDialog from './ConnectDialog'

const MyPrivacy = (props) => {
  const [loading, setLoading] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [connect, setConnect] = useState(null)
  const [disconnect, setDisconnect] = useState(null)
  const { connection = {} } = props.account.toJS()
  const history = useHistory()

  const onInit = useCallback(async () => {
    try {
      setLoading(true)

      await props.account.getConnectionList()
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    }
    setLoading(false)
  }, [props.account])

  useEffect(() => {
    onInit()
  }, [onInit])

  const onDisconnect = async () => {
    try {
      const action = disconnect
      setProcessing(true)
      setDisconnect(null)
      await props.account.disconnect(action)
    } catch (e) {
      message.error({ message: e.message })
    }

    setProcessing(false)
  }

  const onConnect = async () => {
    try {
      const action = connect
      setProcessing(true)
      setConnect(null)
      if (action === 'gmail') {
        await props.account.connectGmail()
      } else {
        await props.account.connectFacebook()
      }
    } catch (e) {
      message.error({ message: e.message })
    }

    setProcessing(false)
  }

  const name = connection.name || ''
  const gmail = connection.gmail || ''
  const facebook = connection.facebook || ''
  const mobile = connection.mobile || ''
  const email = connection.email || ''

  let connected = 0
  if (gmail) connected++
  if (facebook) connected++
  if (email) connected++

  const gmail_btn =
    gmail !== '' ? (
      <CancelBtn
        startIcon={<Google />}
        text={gmail}
        disabled={connected === 1}
        onClick={() => setDisconnect('gmail')}
      />
    ) : (
      <GoogleBtn
        text={'ผูกบัญชีกับ Gmail'}
        onClick={() => setConnect('gmail')}
      />
    )

  const facebook_btn =
    facebook !== '' ? (
      <CancelBtn
        startIcon={<Facebook />}
        text={name}
        disabled={connected === 1}
        onClick={() => setDisconnect('facebook')}
      />
    ) : (
      <FacebookBtn
        loading={processing && connect === 'facebook'}
        text={'ผูกบัญชีกับ Facebook'}
        onClick={() => setConnect('facebook')}
      />
    )

  const phone_btn =
    mobile !== '' ? (
      <CancelBtn
        startIcon={<Phone />}
        text={format.toMobile(mobile)}
        onClick={() => setDisconnect('mobile')}
      />
    ) : (
      <PhoneBtn
        text={'ผูกหมายเลขโทรศัพท์'}
        onClick={() => history.push('/account/set-mobile')}
      />
    )

  const email_btn =
    email !== '' ? (
      <CancelBtn
        startIcon={<Email />}
        text={email}
        disabled={connected === 1}
        onClick={() => setDisconnect('email')}
      />
    ) : (
      <EmailBtn
        text={'ผูกอีเมล'}
        onClick={() => history.push('/account/set-email')}
      />
    )

  return (
    <Menu>
      <MetaData title="ผูกบัญชีล็อกอิน" description="ผูกบัญชีล็อกอิน" />
      <BackBtn to="/account/privacy" text="ความปลอดภัย" />
      <Content>
        <Title>
          <Text size="large" bold>
            ผูกบัญชีล็อกอิน
          </Text>

          <Loading loading={loading}>
            <Detail>
              {gmail_btn}
              <Space />
              {facebook_btn}
            </Detail>
          </Loading>

          <Section />
          <Text size="large" bold>
            ผูกบัญชี
          </Text>

          <Loading loading={loading}>
            <Detail>
              {phone_btn}
              <Space />
              {email_btn}
            </Detail>
          </Loading>
        </Title>
      </Content>
      <CancelDialog
        loading={processing}
        visible={disconnect !== null}
        onConfirm={onDisconnect}
        onClose={() => setDisconnect(null)}
      />

      <ConnectDialog
        name={connect}
        loading={processing}
        visible={connect !== null}
        onConfirm={onConnect}
        onClose={() => setConnect(null)}
      />
    </Menu>
  )
}

const Content = styled.div`
  width: 325px;
  margin: 0 auto;

  @media screen and (max-width: 500px) {
    max-width: 100%;
  }
`

const Title = styled.div`
  margin: 36px 0;
  text-align: center;
`

const Detail = styled.div`
  margin-top: 52px;
`

const Section = styled.div`
  margin-top: 50px;
`

const Space = styled.div`
  margin-top: 24px;
`

export default inject('account')(observer(MyPrivacy))
