import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const INITIAL_VALUES = {
  otp1: '',
  otp2: '',
  otp3: '',
  otp4: '',
  otp5: '',
  otp6: '',
}

const OtpInput = (props) => {
  const [loading, setLoading] = useState(false)

  const { onComplete } = props
  const [isOTP, setIsOTP] = useState(INITIAL_VALUES)

  useEffect(() => {
    const value = Object.values(isOTP).join('')

    if (value.length === 6) {
      setLoading(true)
      onComplete(value)
      setIsOTP(INITIAL_VALUES)
      setLoading(false)
    }
  }, [isOTP, onComplete])

  const handleInput = (e, callback) => {
    const elmnt = e.target
    const value = elmnt.value

    if (value === '') {
      callback('')
      const next = elmnt.tabIndex - 2

      if (next > -1) {
        elmnt.form.elements[next].focus()
      }
    } else {
      if (isNaN(value)) {
        callback('')
      } else {
        const next = elmnt.tabIndex
        if (next < 6) {
          elmnt.form.elements[next].focus()
          callback(value)
        } else {
          callback(value)
          elmnt.blur()
        }
      }
    }
  }

  const list = [
    {
      name: 'otp1',
      tabIndex: 1,
      isOTP: isOTP.otp1,
    },
    {
      name: 'otp2',
      tabIndex: 2,
      isOTP: isOTP.otp2,
    },
    {
      name: 'otp3',
      tabIndex: 3,
      isOTP: isOTP.otp3,
    },
    {
      name: 'otp4',
      tabIndex: 4,
      isOTP: isOTP.otp4,
    },
    {
      name: 'otp5',
      tabIndex: 5,
      isOTP: isOTP.otp5,
    },
    {
      name: 'otp6',
      tabIndex: 6,
      isOTP: isOTP.otp6,
    },
  ]

  return (
    <Content>
      <Form>
        {list.map((event, i) => {
          const onInput = (e) => {
            handleInput(e, (callback) =>
              setIsOTP({ ...isOTP, [event.name]: callback })
            )
          }
          return (
            <Input
              key={i}
              name={event.name}
              type="number"
              value={event.isOTP}
              tabIndex={event.tabIndex}
              disabled={loading}
              maxLength={1}
              onInput={onInput}
            />
          )
        })}
      </Form>
    </Content>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
const Form = styled.form`
  display: flex;
`
const Input = styled.input`
  caret-color: #bca4ff;

  width: 60px;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: 1px solid #d9dbe9;
  text-align: center;
  margin-right: 14px;
  height: 80px;
  font-size: 48px;
  padding-left: 10px;
  color: #14142b;

  :focus {
    outline: none;
    border-color: #5f2eea;
  }

  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;
`

export default OtpInput
