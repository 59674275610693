import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'

import { Text } from 'components/typo'
import CreditCard from 'components/input/CreditCard'
import SubmitPayment from 'components/button/SubmitPayment'
import { message } from 'utils'

import AccountLayout from '../../components/AccountLayout'

const MyPaymentCard = (props) => {
  const [loading, setLoading] = useState(false)
  const [isSubmit, setSubmit] = useState(false)
  const [card, setCard] = useState({
    name: '',
    surname: '',
    card_id: '',
    expired: '',
    cvv: '',
    ex_month: '',
    ex_year: '',
    invalid: true,
  })
  const params = useParams()

  const onCheckCardResult = useCallback(
    async (invoice_no) => {
      setLoading(true)
      try {
        await props.account.getPaymentResult(invoice_no)
        message.success({
          message: 'เปลี่ยนข้อมูลบัตรเครดิต หรือบัตรเดบิตสำเร็จ',
        })
      } catch (e) {
        console.error(e.message)
        message.error({ message: 'ไม่พบข้อมูลการชำระเงิน' })
      }
      setLoading(false)
    },
    [props.account]
  )

  useEffect(() => {
    if (params.code) {
      onCheckCardResult(params.code)
    }
  }, [params.code])

  const onChange = (val) => {
    setCard(val)
    setSubmit(false)
  }

  const onError = async (cardInfo) => {
    setCard({ ...card, invalid: true })
    setSubmit(true)
  }

  const onConfirm = async (cardInfo) => {
    try {
      setLoading(true)
      const { otp_url } =
        (await props.payment.changePaymentToCard(cardInfo)) || {}
      if (otp_url) {
        window.location.href = otp_url
      }
    } catch (e) {
      message.error({ message: e.message })
    }
    setLoading(false)
  }

  const disabled = card.invalid
  return (
    <AccountLayout
      backTo="/account/my-payment/payment-option"
      backText="เปลี่ยนวิธีการชำระเงิน"
    >
      <Content>
        <Text size="large" bold>
          ข้อมูลบัตรเครดิต หรือบัตรเดบิต
        </Text>
        <Description>
          <Text size="small" gray>
            ฿1 จะถูกหักเป็นค่าใช้จ่ายในการยืนยัน เราจะทำการ
            <br />
            คืนเงินให้คุณภายใน 5 วัน
          </Text>
        </Description>
        <CreditCard card={card} isSubmit={isSubmit} onChange={onChange} />
        <SubmitPayment
          text="บันทึก"
          card={card}
          onError={onError}
          loading={loading}
          disabled={disabled}
          onClick={onConfirm}
        />
      </Content>
    </AccountLayout>
  )
}

const Description = styled.div`
  margin: 23px 0 33px;
`

const Content = styled.div`
  padding-top: 20px;
  width: 325px;
  margin-bottom: 70px;
`

export default inject('member', 'payment', 'account')(observer(MyPaymentCard))
