import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  issue_problem: {
    status: 'pending',
    chat_list: [],
  },
}
let state

export class Problem extends BaseStore {
  constructor() {
    super()
    this.observable({
      common_list: [],
      issue_list: [],
      issue_category_list: [],
      issue_problem: cloneDeep(original.issue_problem),
    })

    state = this
  }

  async getCommonProblemList(keywords) {
    const url = `${config.api}/v1/user/support/common-problem?keywords=${keywords}`
    const res = await http.get(url)

    runInAction(() => {
      state.common_list = res.body || []
    })
  }

  async getIssueProblemList(keywords) {
    const url = `${config.api}/v1/user/support/issue-problem?keywords=${keywords}`
    const res = await http.get(url)

    runInAction(() => {
      state.issue_list = res.body || []
    })
  }

  async getIssueCategoryList() {
    const url = `${config.api}/v1/user/support/issue-problem/category`
    const res = await http.get(url)
    const list = res.body || []
    const categorys = list.map((it) => {
      return {
        label: it.name,
        value: it.issue_problem_category_id,
      }
    })
    runInAction(() => {
      state.issue_category_list = categorys
    })
  }

  async getIssueProblem(id) {
    const url = `${config.api}/v1/user/support/issue-problem/${id}/detail`
    const res = await http.get(url)
    runInAction(() => {
      state.issue_problem = res.body || {}
    })
  }

  async createIssueProblem(params = {}) {
    const { category_id, note } = params

    const json = {
      issue_problem_category_id: category_id,
      title: '',
      detail: note,
    }
    const url = `${config.api}/v1/user/support/issue-problem`
    const res = await http.post(url, { json })

    const { issue_list } = this.toJS()
    issue_list.unshift(res.body || {})
    runInAction(() => {
      state.issue_list = issue_list
    })
  }

  async replyIssueProblem(params = {}) {
    const { issue_problem_id, note } = params

    const json = {
      text: note,
      file_list: [],
    }
    const url = `${config.api}/v1/user/support/issue-problem/${issue_problem_id}/reply`
    const res = await http.put(url, { json })
    const { status, item } = res.body
    const { issue_problem } = this.toJS()

    issue_problem.status = status
    issue_problem.chat_list.push(item)
    runInAction(() => {
      state.issue_problem = issue_problem
    })
  }
}

export default new Problem()
