import React from 'react'
import styled from 'styled-components'

import { Text } from 'components/typo'

import ProblemItem from './ProblemItem'

const CategoryItem = (props) => {
  const { item } = props

  const content = item.list.map((it, i) => {
    return <ProblemItem item={it} key={i} />
  })
  return (
    <Content>
      <Text size="small" bold>
        {item.category_name}
      </Text>
      <Body>{content}</Body>
    </Content>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: 16px;
  margin-top: 30px;
  width: 100%;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: 24px;
`

export default CategoryItem
