import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { size = 1, isSelect = false } = props

  const width = `${Math.ceil(40 * size)}`
  const height = `${Math.ceil(40 * size)}`

  if (isSelect) {
    return (
      <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32.4997 11.168H27.1664L25.4998 5.83463C24.6664 3.16797 22.4997 1.66797 19.9997 1.66797C17.4997 1.66797 15.3331 3.16797 14.4997 5.66797L12.8331 11.0013H7.49975C4.99975 11.0013 2.83308 12.5013 1.99974 15.0013C1.16641 17.5013 1.99975 20.168 3.99975 21.668L8.49974 25.0013L6.83308 30.5013C5.99974 33.0013 6.83308 35.668 8.99975 37.168C10.9997 38.668 13.6664 38.5013 15.6664 37.0013L19.9997 33.668L24.3331 37.0013C25.3331 37.8346 26.4998 38.168 27.6664 38.168C28.8331 38.168 29.9997 37.8346 30.8331 37.168C32.9997 35.668 33.8331 33.0013 32.9998 30.5013L31.3331 25.0013L35.8331 21.668C37.8331 20.168 38.6664 17.5013 37.8331 15.0013C37.1664 12.668 34.9997 11.168 32.4997 11.168Z" fill="#FDC851"/>
      </svg>
    )
  }

  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.4997 11.168H27.1664L25.4998 5.83463C24.6664 3.16797 22.4997 1.66797 19.9997 1.66797C17.4997 1.66797 15.3331 3.16797 14.4997 5.66797L12.8331 11.0013H7.49975C4.99975 11.0013 2.83308 12.5013 1.99974 15.0013C1.16641 17.5013 1.99975 20.168 3.99975 21.668L8.49974 25.0013L6.83308 30.5013C5.99974 33.0013 6.83308 35.668 8.99975 37.168C10.9997 38.668 13.6664 38.5013 15.6664 37.0013L19.9997 33.668L24.3331 37.0013C25.3331 37.8346 26.4998 38.168 27.6664 38.168C28.8331 38.168 29.9997 37.8346 30.8331 37.168C32.9997 35.668 33.8331 33.0013 32.9998 30.5013L31.3331 25.0013L35.8331 21.668C37.8331 20.168 38.6664 17.5013 37.8331 15.0013C37.1664 12.668 34.9997 11.168 32.4997 11.168ZM33.9997 19.168L28.6664 23.168C28.1664 23.668 27.8331 24.3346 27.9998 25.0013L29.9997 31.5013C30.4997 32.8346 29.8331 34.0013 28.9997 34.5013C28.6664 34.668 27.4998 35.3346 26.3331 34.5013L20.9997 30.5013C20.6664 30.3346 20.3331 30.168 19.9997 30.168C19.6664 30.168 19.3331 30.3346 18.9998 30.5013L13.6664 34.5013C12.4997 35.5013 11.3331 34.8346 10.9997 34.5013C10.1664 34.0013 9.66641 32.8346 9.99975 31.5013L11.9997 25.0013C12.1664 24.3346 11.9998 23.668 11.3331 23.168L5.99974 19.168C4.83308 18.3346 4.83308 17.0013 5.16641 16.168C5.33308 15.8346 5.83308 14.5013 7.49975 14.5013H13.1664C14.4997 14.5013 15.6664 13.668 15.9997 12.3346L17.8331 6.66797C18.1664 5.5013 19.3331 5.0013 20.1664 5.0013C20.9997 5.0013 21.9997 5.5013 22.4997 6.66797L24.3331 12.3346C24.6664 13.668 25.8331 14.5013 27.1664 14.5013H32.8331C34.3331 14.5013 34.9998 15.8346 35.1664 16.168C35.1664 17.0013 34.9997 18.3346 33.9997 19.168Z" fill="#FDC851"/>
    </svg>
  )
}
// eslint-enable
export default Logo
