import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state

export class Promotion extends BaseStore {
  constructor() {
    super()
    this.observable({
      payment: {},
    })

    state = this
  }

  async verify(code) {
    const url = `${config.api}/v1/user/promotion/${code}`
    const res = await http.get(url)
    return res.body
  }

  async apply(code) {
    const url = `${config.api}/v1/user/promotion/${code}`
    await http.put(url)
  }
}

export default new Promotion()
