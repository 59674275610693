import React, { useState } from 'react'
import styled from 'styled-components'

import { Display, Text } from 'components/typo'
import { Button } from 'components/button'
import HelperText from 'components/display/HelperText'
import Modal from 'components/modal/Modal'
import useGTM from 'hooks/useGTM'

const PackageModal = (props) => {
  const { setOpen, isOpened, onOk, title, body, custom } = props
  const [error, setError] = useState({})
  const [loading, setLoading] = useState(false)
  const { onEvent } = useGTM()

  const onConfirm = async () => {
    onEvent({
      pageName: 'create-account',
      eventName: 'free-package-modal-confirm-button-click',
    })
    try {
      setLoading(true)
      await onOk()
      closeModal()
    } catch (e) {
      setError({ setPackage: e.message })
    }
    setLoading(false)
  }

  const closeModal = () => {
    onEvent({
      pageName: 'create-account',
      eventName: 'free-package-modal-confirm-button-click',
    })
    setOpen(false)
  }

  return (
    <Modal isOpened={isOpened} setOpen={setOpen}>
      <ModalTextWrapper>
        <Display size="small" bold>
          {title}
        </Display>
        <Text align="center" gray>
          {body}
        </Text>
        <ButtonWrapper>
          <Button
            color="primary"
            text={custom ? 'เริ่มใช้งานเลย' : 'ตกลง'}
            loading={loading}
            onClick={onConfirm}
          />
          <Button
            color="text"
            text={custom ? 'เปลี่ยนแพ็กเกจ' : 'ย้อนกลับ'}
            onClick={closeModal}
          />
        </ButtonWrapper>
        <HelperText errorText={error.setPackage} />
      </ModalTextWrapper>
    </Modal>
  )
}

const ModalTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 128px 0;
  row-gap: 24px;
  width: 100%;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: 16px;
  width: 100%;
  max-width: 220px;
`

export default PackageModal
