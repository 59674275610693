import React, { useState, useCallback, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import Menu from 'pages/account/components/Menu/Menu'
import BackBtn from 'pages/account/components/BackBtn'
import { MetaData } from 'components/display'
import { Text } from 'components/typo'
import { Button } from 'components/button'
import { DisabledInput } from 'components/input'
import { message } from 'utils'

import CancelDialog from '../connection/CancelDialog'

const ConfirmEmail = (props) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [email, setEmail] = useState('')
  const history = useHistory()

  const { ref_code, code } = props.match.params
  const { connection } = props.account.toJS()

  const onInit = useCallback(async () => {
    try {
      setLoading(true)

      const [{ data }] = await Promise.all([
        props.account.getVerifyCode(code),
        props.account.getConnectionList(),
      ])

      setEmail(data.email)
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    }
    setLoading(false)
  }, [props.account])

  useEffect(() => {
    onInit(ref_code, code)
  }, [onInit])

  const onConfirmEmail = async () => {
    try {
      setLoading(true)

      await props.account.confirmChangeEmail({ ref_code, code })
      message.success({ message: 'ผูกอีเมลสำเร็จ' })
    } catch (e) {
      message.error({ message: e.message })
    }

    setLoading(false)
  }

  const onDisconnect = async () => {
    try {
      setLoading(true)
      setVisible(false)
      await props.account.disconnect('email')
    } catch (e) {
      message.error({ message: e.message })
    }

    setLoading(false)
  }

  let btn
  let action_btn
  if (connection.email === email) {
    btn = (
      <Button
        color="subtle"
        text="ยกเลิกการผูกอีเมล"
        maxWidth="325px"
        loading={loading}
        onClick={() => setVisible(true)}
      />
    )
  } else {
    btn = (
      <Button
        color="primary"
        text="ผูกบัญชี"
        maxWidth="325px"
        loading={loading}
        onClick={onConfirmEmail}
      />
    )

    action_btn = (
      <ActionText onClick={() => history.push('/account/set-email')}>
        เปลี่ยนอีเมล
      </ActionText>
    )
  }

  return (
    <Menu>
      <MetaData title="ผูกอีเมล" description="ผูกอีเมล" />
      <BackBtn to="/account/connection" text="ผูกบัญชี" />
      <Content>
        <Title>
          <Text size="large" bold>
            ผูกอีเมล
          </Text>
        </Title>
        <Space />
        <DisabledInput label="อีเมล" value={email} />

        <Detail>{btn}</Detail>

        {action_btn}
      </Content>
      <CancelDialog
        visible={visible}
        onConfirm={onDisconnect}
        onClose={() => setVisible(false)}
      />
    </Menu>
  )
}

const Content = styled.div`
  width: 325px;
  margin: 0 auto;
  text-align: center;
`

const Title = styled.div`
  margin: 36px 0px;
`

const Space = styled.div`
  margin-top: 24px;
`

const Detail = styled.div`
  margin-top: 40px;
  margin-bottom: 29px;
`

const ActionText = styled.span`
  color: #5f2eea;
  cursor: pointer;
  font-size: 14px;
`

export default inject('account')(observer(ConfirmEmail))
