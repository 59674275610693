import { firebase } from 'utils'

export const checkEmail = async ({ email = '', mode }) => {
  const auth = firebase.auth()
  email = email.trim()
  const resp = await auth.fetchSignInMethodsForEmail(email)

  return resp.length > 0
}

export const checkFacebook = async () => {
  const auth = firebase.auth()
  try {
    // Start a sign in process for an unauthenticated user.
    const provider = new firebase.firebase_.auth.FacebookAuthProvider()
    //provider.addScope('profile')
    //provider.addScope('email')
    const result = await auth.signInWithPopup(provider)
    const { user = {}, additionalUserInfo = {} } = result
    const { profile = {} } = additionalUserInfo

    const data = {
      uid: user.uid,
      facebook_id: profile.id,
      name: profile.first_name || user.displayName,
      surname: profile.last_name,
      email: profile.email || user.email,
      img_url: user.photoURL,
    }

    const token = await user.getIdToken()
    return { token, user, data }
  } catch (error) {
    if (error.code === 'auth/account-exists-with-different-credential') {
      const email = error.email
      const method = await getMethodByEmail(email)

      // TODO: login by existing method

      // TODO: link account
      // Email maybe difficult.
      // User have to put email and password in order to link with other providers.

      // TODO: if link success return  { token, user, data }

      return { error: error.code, data: method }
    }
    return { error }
  }
}

export const checkGoogle = async () => {
  const auth = firebase.auth()

  // Start a sign in process for an unauthenticated user.
  const provider = new firebase.firebase_.auth.GoogleAuthProvider()
  provider.addScope('profile')
  provider.addScope('email')
  const { user } = await auth.signInWithPopup(provider)
  const googleProvider = user.providerData.find(
    (item) => item.providerId === 'google.com'
  )

  const data = {
    uid: user.uid,
    name: user.displayName,
    email: googleProvider?.email,
    img_url: user.photoURL,
  }

  const token = user ? await user.getIdToken() : null
  return { token, user, data }
}

const getMethodByEmail = async (email) => {
  const auth = firebase.auth()
  const method = await auth.fetchSignInMethodsForEmail(email)

  const { GoogleAuthProvider, FacebookAuthProvider, EmailAuthProvider } =
    firebase.firebase_.auth
  const googleMethod = GoogleAuthProvider.PROVIDER_ID
  const facebookMethod = FacebookAuthProvider.PROVIDER_ID
  const emailMethod = EmailAuthProvider.PROVIDER_ID

  const map = {
    [googleMethod]: 'Gmail',
    [emailMethod]: 'Email',
    [facebookMethod]: 'Facebook',
  }

  return method.map((m) => map[m] || m)
}
