import React from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import Menu from 'pages/account/components/Menu/Menu'
import { Text } from 'components/typo'
import { MetaData } from 'components/display'

import IssueTable from './IssueTable'

const IssueProblem = () => {
  return (
    <Menu>
      <MetaData title="ศูนย์ช่วยเหลือ" description="ศูนย์ช่วยเหลือ" />
      <Wrapper>
        <Title>
          <Text size="large" title bold>
            ศูนย์ช่วยเหลือ
          </Text>
        </Title>

        <IssueTable />
      </Wrapper>
    </Menu>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`

const Title = styled.div`
  margin: 44px 0;
  text-align: center;
`

export default inject('problem')(observer(IssueProblem))
