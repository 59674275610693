const config = {
  mode: 'localhost',
  firebase: {
    apiKey: 'AIzaSyAR_ilE2MCADYarjtJbzGZ6mnRtYSHEQyU',
    authDomain: 'friday-uat.firebaseapp.com',
    projectId: 'friday-uat',
    storageBucket: 'friday-uat.appspot.com',
    messagingSenderId: '946143031513',
    appId: '1:946143031513:web:1693e1dfc3a44081424ec8',
    measurementId: 'G-7QDFSL6BPB',
  },
  api: 'https://uat-api.friday.in.th/api',
  version: '0.0.1',
  key: 'key-friday',
  tag_manager: 'GTM-P3TJTGC',
  pre_register: false,
  payment_url:
    'https://demo2.2c2p.com/2C2PFrontEnd/SecurePayment/api/my2c2p-sandbox.1.7.3.min.js',
  app_url: 'https://fridaydev.page.link/home',
}

module.exports = config
