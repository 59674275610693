import React, { useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Row, Col } from 'antd'

import { Text } from 'components/typo'
import Loading from 'components/loading'
import { message, format } from 'utils'
import AccountLayout from 'pages/account/components/AccountLayout'
import Promptpay from 'pages/account/components/Promptpay'

const MyPaymentPromptpay = (props) => {
  const [init, setInit] = useState(false)
  const [payment, setPayment] = useState({})
  const [info, setInfo] = useState({})
  const history = useHistory()

  const onChecking = useCallback(
    async (invoice_no, time) => {
      let status
      try {
        const { payment } = await props.account.getPaymentResult(invoice_no)
        status = payment.status
        if (status !== 'pending') {
          history.push(`/account/my-payment/paynow/result/${invoice_no}`)
          return
        }
      } catch (e) {
        console.log('qr:', e.message)
      }
      setTimeout(() => onChecking(invoice_no), time || 5000)
    },
    [props.account, history]
  )

  const onInit = useCallback(async () => {
    try {
      setInit(true)

      const params = { payment_type: 'qr_code' }
      const res = await props.payment.makePaymentPackage(params)
      const { result } = res
      const { status } = result || {}
      setInfo(result || {})
      if (status === 'pay_more' && res.payment) {
        setPayment(res.payment)
        onChecking(res.payment.invoice_no, 25000)
      } else {
        message.success({ message: 'ต่ออายุแพ็กเกจสำเร็จ' })
        history.push('/account/my-payment')
      }
    } catch (e) {
      message.error({ message: e.message })
    }
    setInit(false)
  }, [props.payment, history])

  useEffect(() => {
    onInit()
  }, [onInit])

  const infos = [
    {
      label: 'ยอดชำระตามรอบบิล',
      value: format.toDigi(info.package_price || 0),
    },
    {
      label: 'ส่วนลดจากโปรโมชั่น',
      value: format.toDigi(info.used_credit || 0),
      color: { success: true },
    },
    {
      label: 'ยอดที่ต้องชำระ',
      value: format.toDigi(info.total_payment || 0),
      color: { error: true },
    },
  ]
  return (
    <AccountLayout
      title="ชำระเงินทันที"
      backTo={`/account/my-payment/paynow`}
      backText="ชำระเงินทันที"
    >
      <Loading loading={init}>
        <Text size="large" bold>
          ยอดชำระ
        </Text>
        <ContentView>
          <Row>
            <Col lg={12} xs={24}>
              <InfoList>
                {infos.map((info) => (
                  <InfoListItem key={info.label}>
                    <Text color="gray">{info.label}</Text>
                    <Text {...info.color}>฿ {info.value}</Text>
                  </InfoListItem>
                ))}
              </InfoList>
            </Col>
            <Col lg={12} xs={24}>
              <Promptpay code={payment.qr_code} />
              <Remark>สแกนคิวอาร์โค้ดเพื่อชำระเงิน</Remark>
            </Col>
          </Row>
        </ContentView>
      </Loading>
    </AccountLayout>
  )
}

const ContentView = styled.div`
  width: 100%;
`

const InfoList = styled.div`
  width: 100%;
  max-width: 325px;
  margin: 39px 0px 26px 0px;
  padding-right: 10px;
`

const InfoListItem = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
`

const Remark = styled.div`
  color: #a0a3bd;
  padding-top: 16px;
  font-size: 18px;
  text-align: center;
`

export default inject('payment', 'account')(observer(MyPaymentPromptpay))
