import React, { memo, useState, useEffect, useCallback, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import isEqual from 'lodash/isEqual'

import { MetaData } from 'components/display'
import { Text } from 'components/typo'
import { Button } from 'components/button'
import AddressDropdown from 'components/input/AddressDropdown'
import { Dropdown, FieldInput } from 'components/input'
import { message, helper, validator } from 'utils'

import schema from './validate'
import AccountLayout from '../../components/AccountLayout'

const TAX_OPTIONS = [
  { label: 'บุคคลธรรมดา', value: 'individuals' },
  { label: 'นิติบุคคล', value: 'juristic' },
]

const ADDRESS_FIELDS = [
  { key: 'sub_district', label: 'ตำบล/แขวง' },
  { key: 'district', label: 'อำเภอ/เขต' },
  { key: 'province', label: 'จังหวัด' },
  { key: 'postcode', label: 'รหัสไปรษณีย์' },
]

let AddressField = (props) => {
  const { taxInvoice = {}, validate = {}, onChange = () => {} } = props
  return (
    <AddressContainer>
      {ADDRESS_FIELDS.map((addr, index) => (
        <Box key={index}>
          <AddressDropdown
            small
            label={addr.label}
            value={taxInvoice[addr.key]}
            name={addr.key}
            show={{ h: 'center' }}
            errorText={validate.taxInvoice[addr.key]}
            filterable
            address={addr}
            onChange={onChange}
          />
        </Box>
      ))}
    </AddressContainer>
  )
}

AddressField = memo(AddressField)

function MyTaxInvoice(props) {
  const [isInit, setInit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isSubmit, setSubmit] = useState(false)
  const [taxInvoice, setTaxInvoice] = useState({})
  const [validate, setValidate] = useState({ taxInvoice: {} })

  const { tax_invoice = {} } = props.payment.toJS()

  const onInit = useCallback(async () => {
    try {
      setLoading(true)
      await props.payment.getMyTaxInvoice()
    } catch (e) {
      message.error()
    }
    setLoading(false)
    setInit(true)
  }, [props.payment])

  const clearValidate = () => {
    setValidate({ taxInvoice: {} })
  }

  const onChange = (val, key) => {
    setTaxInvoice((taxInvoice) => {
      taxInvoice[key] = val
      return taxInvoice
    })
    clearValidate()
  }

  const onChangeMobileNo = (val, name) => {
    const newValue = String(val || '').replaceAll('-', '')
    if (Number.isNaN(Number(newValue))) return

    if (newValue.length <= 10) {
      onChange(newValue, name)
    }
  }

  const onSave = async () => {
    try {
      setSubmit(true)
      setLoading(true)

      const resInvoice = validator.process(schema, taxInvoice)

      if (resInvoice.isValid) {
        clearValidate()
        await props.payment.updateMyTaxInvoice(taxInvoice)

        message.success({
          message: 'บันทึกสำเร็จ',
        })
      } else {
        setValidate({ taxInvoice: resInvoice.errors })
      }
    } catch (e) {
      message.error({
        message: e.message,
      })
    }
    setLoading(false)
  }

  const onAddressChange = (newAddress) => {
    setTaxInvoice((taxInvoice) => {
      return { ...taxInvoice, ...newAddress }
    })
    clearValidate()
  }

  const isJuristic = useMemo(() => {
    return taxInvoice.tax_type === 'juristic'
  }, [taxInvoice.tax_type])

  useEffect(() => {
    onInit()
  }, [onInit])

  useEffect(() => {
    if (isInit) {
      if (!isEqual(taxInvoice, tax_invoice)) {
        setTaxInvoice((prev) => ({
          ...prev,
          ...tax_invoice,
        }))
      }
    }
    // only 1 time.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInit])

  return (
    <AccountLayout backTo="/account/my-payment/" backText="ข้อมูลการชำระเงิน">
      <MetaData title="ข้อมูลการออกใบกำกับภาษี" />
      <Content>
        <Title>
          <Text size="large" bold>
            ข้อมูลการออกใบกำกับภาษี
          </Text>
        </Title>
        <MainTax>
          <TaxRow>
            <Box>
              <Dropdown
                value={taxInvoice.tax_type}
                onChange={onChange}
                options={TAX_OPTIONS}
                small
                label="ประเภท"
                name="tax_type"
                show={{ h: 'center' }}
                errorText={validate.taxInvoice.tax_type}
              />
            </Box>
          </TaxRow>
          <TaxRow>
            <Box>
              {isJuristic ? (
                <FieldInput
                  label="ชื่อบริษัท"
                  name="company_name"
                  small
                  onChange={onChange}
                  value={taxInvoice.company_name}
                  errorText={validate.taxInvoice.company_name}
                />
              ) : (
                <FieldInput
                  label="ชื่อ"
                  name="first_name"
                  small
                  onChange={onChange}
                  value={taxInvoice.first_name}
                  errorText={validate.taxInvoice.first_name}
                />
              )}
            </Box>
            <Box>
              {isJuristic ? (
                <FieldInput
                  label="หมายเลขนิติบุคคล"
                  name="tax_no"
                  small
                  onChange={onChange}
                  value={helper.totaxNumber(taxInvoice.tax_no)}
                  errorText={validate.taxInvoice.tax_no}
                />
              ) : (
                <FieldInput
                  label="สกุล"
                  name="last_name"
                  onChange={onChange}
                  value={taxInvoice.last_name}
                  errorText={validate.taxInvoice.last_name}
                  small
                />
              )}
            </Box>
          </TaxRow>
          {!isJuristic && (
            <TaxRow>
              <Box>
                <FieldInput
                  label="หมายเลขบัตรประชาชน"
                  name="tax_no"
                  onChange={onChangeMobileNo}
                  value={helper.toidCard(taxInvoice.tax_no)}
                  errorText={validate.taxInvoice.tax_no}
                  small
                />
              </Box>
            </TaxRow>
          )}
          <TaxRow>
            <Box>
              <FieldInput
                label="หมายเลขโทรศัพท์"
                name="phone_number"
                small
                onChange={onChangeMobileNo}
                value={helper.toMobile(taxInvoice.phone_number)}
                errorText={validate.taxInvoice.phone_number}
              />
            </Box>
            <Box>
              <FieldInput
                label="อีเมล"
                name="email"
                onChange={onChange}
                value={taxInvoice.email}
                small
                errorText={validate.taxInvoice.email}
              />
            </Box>
          </TaxRow>
          <TaxRow>
            <span>ที่อยู่ที่ใช้ออกใบกำกับภาษี</span>
          </TaxRow>
          <TaxRow>
            <Box>
              <FieldInput
                label="บ้านเลขที่/ อาคาร"
                name="house_no"
                onChange={onChange}
                value={taxInvoice.house_no}
                errorText={validate.taxInvoice.house_no}
                small
              />
            </Box>
            <Box>
              <FieldInput
                label="ถนน"
                name="road"
                onChange={onChange}
                value={taxInvoice.road}
                small
              />
            </Box>
          </TaxRow>
          <AddressField
            taxInvoice={taxInvoice}
            validate={validate}
            onChange={onAddressChange}
          />
          <TextDiv>
            <span>
              ข้อมูลการออกใบกำกับภาษีจะบันทึกและใช้งานกับใบกำกับภาษีทั้งหมดในอนาคต
              คุณสามารถแก้ไขข้อมูลภายหลังได้ในเมนู ข้อมูลการชำระเงิน
            </span>
          </TextDiv>
          <ButtonDiv>
            <Button
              large
              color="primary"
              text="บันทึก"
              width="220px"
              onClick={onSave}
              loading={loading}
            />
          </ButtonDiv>
        </MainTax>
      </Content>
    </AccountLayout>
  )
}

export default inject('payment')(observer(MyTaxInvoice))

const Content = styled.div`
  width: 100%;
  max-width: 600px;
`
const Title = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 52px;

  @media screen and (max-width: 1024px) {
    margin-bottom: 40px;
  }
`

const MainTax = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 60px;
  height: fit-content;

  > div > div > div {
    max-width: unset;
  }

  @media screen and (max-width: 713px) {
    padding: 0;
  }
`
const TaxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${(props) => (props.main ? '50px' : '20px')};
  align-items: center;
  gap: 10px;
  .text_header {
    font-weight: 600;
    font-size: 18px;
    color: #5f2eea;
  }

  @media screen and (max-width: 713px) {
    justify-content: none;
  }
`
const Box = styled.div`
  width: 100%;
  height: 57px;
`
const ButtonDiv = styled.div`
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`
const TextDiv = styled.div`
  margin-bottom: 20px;
`
const AddressContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 24px;
  margin-bottom: 24px;
`
