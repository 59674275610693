import { useCallback } from 'react'
import TagManager from 'react-gtm-module'

import { firebase, storage, helper } from 'utils'

function useGTM() {
  const getAnonymousUid = useCallback(async () => {
    try {
      const anonoymous_user = storage.load('anonoymous_user')
      if (anonoymous_user) {
        const decryptedUid = helper.decrypt(anonoymous_user)
        return decryptedUid
      } else {
        const anonymousUid = (await firebase.auth().signInAnonymously()).user
          .uid
        const encryptedUid = helper.encrypt(anonymousUid)
        storage.save('anonoymous_user', encryptedUid)
        return anonymousUid
      }
    } catch (error) {
      console.error('🚀 ~ getAnonymousUid:', error)
      return null
    }
  }, [])

  const getUid = useCallback(async () => {
    try {
      const uid = storage.load('user')
      if (uid) {
        return helper.decrypt(uid)
      } else {
        const anonymousUid = await getAnonymousUid()
        return anonymousUid
      }
    } catch (error) {
      console.error('🚀 ~ getUid', error)
      return null
    }
  }, [getAnonymousUid])

  const onPageLoad = useCallback(
    async ({ pageName = '' }) => {
      try {
        const user_id = await getUid()
        TagManager.dataLayer({
          dataLayer: {
            userId: user_id,
            page: pageName,
            event: 'open-screen',
          },
        })
      } catch (error) {
        console.error('🚀 ~ onPageLoad:', error)
      }
    },
    [getUid]
  )

  const onEvent = useCallback(
    async ({ pageName = '', eventName = '', params = {} }) => {
      try {
        const user_id = await getUid()
        TagManager.dataLayer({
          dataLayer: {
            userId: user_id,
            page: pageName,
            event: eventName,
            ...params,
          },
        })
      } catch (error) {
        console.error('🚀 ~ onEvent:', error)
      }
    },
    [getUid]
  )

  return { onPageLoad, onEvent }
}

export default useGTM
