if (typeof window === 'undefined')
  global.window = { location: { host: 'testhost' } }

const maps = {
  'friday-develop.web.app': 'testing',
  'uat.friday.in.th': 'uat',
  'friday-uat.web.app': 'uat',
  'friday.in.th': 'prod',
  'friday-prod.web.app': 'prod',
  'www.friday.in.th': 'prod',
}

const host = window.location.host
const found = maps[host]

const env = found ? found : 'uat'

export const config = window._env_ ? window._env_ : require(`./config.${env}`)
