import React, { useState } from 'react'
import styled from 'styled-components'

import { Button } from 'components/button'
import { helper } from 'utils'
import { FieldInput } from 'components/input'
import useGTM from 'hooks/useGTM'

const SendOtpRegister = (props) => {
  const [mobile, setMobile] = useState('')
  const { onEvent } = useGTM()
  const { loading, onSendOtp } = props

  const onChange = (val) => {
    const newValue = String(val || '').replaceAll('-', '')
    if (Number.isNaN(Number(newValue))) return

    if (newValue.length <= 10) {
      setMobile(newValue)
    }
  }

  const onSend = () => {
    onEvent({
      pageName: 'create-account',
      eventName: 'craete-profile-phone-confirm-button-click',
    })
    onSendOtp(mobile)
  }
  return (
    <>
      <Textdiv>
        <Text>
          เพื่อความปลอดภัย กรุณาทำตามขั้นต่อไปนี้เพื่อตั้งค่าเบอร์โทรศัพท์ของคุณ
        </Text>
        <Text>ก่อนดำเนินงานต่อ</Text>
      </Textdiv>
      <Space />
      <Title>
        <div className="input">
          <FieldInput
            small
            label="หมายเลขโทรศัพท์"
            value={helper.formatPattern(mobile, 'xxx-xxx-xxxx')}
            onChange={onChange}
            disabled={loading}
          />
        </div>
        <Space />
        <Detail>
          <Button
            color="primary"
            text="ส่งรหัส"
            maxWidth="325px"
            loading={loading}
            onClick={onSend}
            disabled={mobile.length < 10}
          />
        </Detail>
      </Title>
    </>
  )
}

const Title = styled.div`
  margin: 0 0 36px;
  text-align: center;

  .input {
    width: 325px;
  }
`

const Text = styled.span`
  font-size: 18px;
  text-align: center;
  color: #4e4b66;
`

const Textdiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 695px;
  height: 68px;
  justify-content: center;
`
const Space = styled.div`
  margin-top: 24px;
`
const Detail = styled.div`
  margin-top: 40px;
  margin-bottom: 29px;
`

export default SendOtpRegister
