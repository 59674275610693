import React from 'react'
import styled from 'styled-components'
import { Row } from 'antd'

import { color } from '../../definition'

const HeaderRow = (props) => {
  return (
    <PageView>
      <Row>{props.children}</Row>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 15px 10px;
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid ${color.border};
  color: ${color.font};
  background-color: ${color.bg};
`

export default HeaderRow
