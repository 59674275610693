import React from 'react'
/* eslint-disable */
const CalendarIcon = () => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 13H17C16.4 13 16 13.4 16 14C16 14.6 16.4 15 17 15H18C18.6 15 19 14.6 19 14C19 13.4 18.6 13 18 13Z" fill="#14142B"/>
        <path d="M20 3V2C20 1.4 19.6 1 19 1C18.4 1 18 1.4 18 2V3H6V2C6 1.4 5.6 1 5 1C4.4 1 4 1.4 4 2V3C2.3 3 1 4.3 1 6V20C1 21.7 2.3 23 4 23H20C21.7 23 23 21.7 23 20V6C23 4.3 21.7 3 20 3ZM4 5H20C20.6 5 21 5.4 21 6V9H3V6C3 5.4 3.4 5 4 5ZM20 21H4C3.4 21 3 20.6 3 20V11H21V20C21 20.6 20.6 21 20 21Z" fill="#14142B"/>
        <path d="M12.5 13H11.5C10.9 13 10.5 13.4 10.5 14C10.5 14.6 10.9 15 11.5 15H12.5C13.1 15 13.5 14.6 13.5 14C13.5 13.4 13.1 13 12.5 13Z" fill="#14142B"/>
        <path d="M7 13H6C5.4 13 5 13.4 5 14C5 14.6 5.4 15 6 15H7C7.6 15 8 14.6 8 14C8 13.4 7.6 13 7 13Z" fill="#14142B"/>
        <path d="M12.5 17H11.5C10.9 17 10.5 17.4 10.5 18C10.5 18.6 10.9 19 11.5 19H12.5C13.1 19 13.5 18.6 13.5 18C13.5 17.4 13.1 17 12.5 17Z" fill="#14142B"/>
        <path d="M7 17H6C5.4 17 5 17.4 5 18C5 18.6 5.4 19 6 19H7C7.6 19 8 18.6 8 18C8 17.4 7.6 17 7 17Z" fill="#14142B"/>
        <path d="M18 17H17C16.4 17 16 17.4 16 18C16 18.6 16.4 19 17 19H18C18.6 19 19 18.6 19 18C19 17.4 18.6 17 18 17Z" fill="#14142B"/>
      </svg>

  )
}

export default CalendarIcon
