import React, { useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import Loading from 'components/loading'
import { Button } from 'components/button'
import { FindInput } from 'components/input'
import { message } from 'utils/message'
import { format } from 'utils/format'
import { Link } from 'components/link'
import CommonTable from 'components/table/CommonTable'
import Circle from 'icon/Circle'
import { IssueProblemStatus } from 'definition/problem'

const headers = [
  { key: 'issue_problem_id', label: 'Issue ID' },
  { key: 'category_name', label: 'หัวข้อ' },
  { key: 'status', label: 'สถานะ', func: (val) => getTextStatus(val) },
  {
    key: 'updated_at',
    label: 'อัปเดตล่าสุด',
    func: (val) => format.toNameDate(val),
  },
]

const getTextStatus = (item) => {
  const { color, text } = IssueProblemStatus[item.status] || {}

  return (
    <Status style={{ color }}>
      <Circle color={color} /> {text}
    </Status>
  )
}

const IssueTable = (props) => {
  const { issue_list = [] } = props.problem.toJS()
  const history = useHistory()
  const [isSearch, setSearch] = useState(false)
  const [keywords, setKeywords] = useState('')
  const [loading, setLoading] = useState(false)

  const onGetList = useCallback(
    async (text) => {
      try {
        setLoading(true)

        await props.problem.getIssueProblemList(text)
      } catch (e) {
        message.error({
          title: `แสดงรายการ`,
          message: e.message,
        })
      }
      setLoading(false)
    },
    [props.problem]
  )

  const onSearch = async () => {
    setSearch(true)
    await onGetList(keywords)
  }

  useEffect(() => {
    onGetList('')
  }, [onGetList])

  const issueList = issue_list

  const onClickRow = (item) => {
    console.log('click:', item)
    const to = `/account/issue-problem/detail/${item.issue_problem_id}`
    history.push(to)
  }

  if (issueList.length < 1 && !isSearch)
    return (
      <Link to={'/account/issue-problem/creator'}>
        <Button large color="primary" maxWidth="325px" text="New Issue" />
      </Link>
    )

  return (
    <Wrapper>
      <InputBox>
        <FindInput
          value={keywords}
          onChange={(val) => setKeywords(val)}
          onEnter={onSearch}
        />
        <Link to={'/account/issue-problem/creator'}>
          <Button text="New Issue" color="secondary" />
        </Link>
      </InputBox>
      <Loading loading={loading}>
        <CommonTable
          columns={headers}
          data={issueList}
          onClickRow={onClickRow}
        />
      </Loading>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  row-gap: 24px;
`

const Status = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  padding: 4px 6px;
`

const InputBox = styled.div`
  display: grid;
  grid-template-columns: auto 200px;
  width: 100%;
  column-gap: 24px;
`

export default inject('problem')(observer(IssueTable))
