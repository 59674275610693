import { Carousel } from 'antd'
import { useRef } from 'react'
import styled from 'styled-components'

import { Back } from 'icon'

import slider1 from '../assets/Slide_LifeBridge_Register-01.png'
import slider2 from '../assets/Slide_LifeBridge_Register-02.png'
import slider3 from '../assets/Slide_LifeBridge_Register-03.png'
import slider4 from '../assets/Slide_LifeBridge_Register-04.png'

const IMAGE_LIST = [slider1, slider2, slider3, slider4]

function RegisterSlider() {
  let sliderRef1 = useRef(null)
  let sliderRef2 = useRef(null)

  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <Container>
      <ImageSlider
        {...settings}
        afterChange={(currentSlide) => {
          sliderRef2.goTo(currentSlide)
        }}
        ref={(slider) => {
          sliderRef1 = slider
        }}
        slidesToShow={1}
        prevArrow={
          <LeftArrow>
            <Back color="#ffffff" />
          </LeftArrow>
        }
        nextArrow={
          <RightArrow>
            <Back color="#ffffff" />
          </RightArrow>
        }
      >
        {IMAGE_LIST.map((l, idx) => (
          <div key={idx}>
            <Image src={l} alt="" />
          </div>
        ))}
      </ImageSlider>
      <ImagePaging
        {...settings}
        ref={(slider) => (sliderRef2 = slider)}
        slidesToShow={7}
        focusOnSelect={true}
        prevArrow={
          <CustomLeftArrow>
            <Back color="#4E4B66" />
          </CustomLeftArrow>
        }
        nextArrow={
          <CustomRightArrow>
            <Back color="#4E4B66" />
          </CustomRightArrow>
        }
        responsive={[
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 3,
            },
          },
        ]}
      >
        {IMAGE_LIST.map((l, idx) => (
          <div key={idx}>
            <Image
              src={l}
              alt=""
              onClick={() => {
                sliderRef1.goTo(idx)
              }}
            />
          </div>
        ))}
      </ImagePaging>
    </Container>
  )
}

export default RegisterSlider

const Container = styled.div`
  width: 100%;
  height: fit-content;
  margin-bottom: 16px;
`

const ImageSlider = styled(Carousel)``

const ArrowContainer = styled.div`
  position: absolute !important;
  z-index: 1 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 56px !important;
  height: 56px !important;
  background-color: #ffffff33 !important;
  border-radius: 50% !important;
`

const LeftArrow = styled(ArrowContainer)`
  left: 32px !important;

  @media screen and (max-width: 465px) {
    left: 16px !important;
  }
`

const RightArrow = styled(ArrowContainer)`
  right: 32px !important;
  svg {
    transform: rotate(180deg);
  }

  @media screen and (max-width: 465px) {
    right: 16px !important;
  }
`

const ImagePaging = styled(Carousel)`
  padding: 16px 64px;
  margin-bottom: 10px;

  .slick-list {
    .slick-track {
      margin: 0 auto;
    }
    .slick-slide > div > div {
      padding: 0 4px;
    }
  }

  @media screen and (max-width: 465px) {
    padding: 16px 40px;
  }
`

const CustomArrowContainer = styled.div`
  position: absolute !important;
  z-index: 1 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 24px !important;
  height: 24px !important;
`

const CustomLeftArrow = styled(CustomArrowContainer)`
  left: 32px !important;

  @media screen and (max-width: 465px) {
    left: 8px !important;
  }
`

const CustomRightArrow = styled(CustomArrowContainer)`
  right: 32px !important;
  svg {
    transform: rotate(180deg);
  }

  @media screen and (max-width: 465px) {
    right: 8px !important;
  }
`

const Image = styled.img`
  width: 100%;
`
