import React from 'react'
/* eslint-disable */
const Google = (props) => {
  const { size = 1 } = props

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.8247 24H5.17525C2.32989 24 0 21.6907 0 18.8247V5.17526C0 2.3299 2.30927 0 5.17525 0H18.8247C21.6701 0 24 2.30928 24 5.17526V18.8247C24 21.6701 21.6907 24 18.8247 24Z'
        fill='white'
      />
      <path
        d='M21.7531 12.239C21.7531 11.4262 21.6814 10.8286 21.538 10.207H12.2148V13.8885H17.6892C17.5697 14.7969 16.972 16.1834 15.6572 17.1157L15.6333 17.2352L18.5737 19.5062L18.7888 19.5301C20.6774 17.809 21.7531 15.2511 21.7531 12.239Z'
        fill='#3283FC'
      />
      <path
        d='M12.2361 21.941C14.9135 21.941 17.1606 21.0565 18.8101 19.5265L15.6785 17.1121C14.8418 17.6858 13.7182 18.1161 12.2361 18.1161C9.6065 18.1161 7.3833 16.3949 6.59442 13.9805H6.47489L3.415 16.3471L3.36719 16.4666C4.99275 19.6939 8.33951 21.941 12.2361 21.941Z'
        fill='#00AC47'
      />
      <path
        d='M6.57204 13.9823C6.35689 13.3607 6.23736 12.6914 6.23736 12.022C6.23736 11.3288 6.35689 10.6833 6.54813 10.0618V9.91835L3.44043 7.50391L3.34481 7.55172C2.67546 8.89042 2.29297 10.3965 2.29297 11.9981C2.29297 13.5998 2.67546 15.1058 3.34481 16.4445L6.57204 13.9823Z'
        fill='#FFBB00'
      />
      <path
        d='M12.2361 5.92643C14.1007 5.92643 15.3677 6.73921 16.0849 7.40856L18.8818 4.68335C17.1606 3.08168 14.9135 2.10156 12.2361 2.10156C8.36342 2.10156 4.99275 4.32476 3.36719 7.5759L6.57052 10.0382C7.3833 7.64762 9.6065 5.92643 12.2361 5.92643Z'
        fill='#FE2C25'
      />
    </svg>
  )
}

export default Google
