import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import Menu from 'pages/account/components/Menu/Menu'
import { Text } from 'components/typo'
import { FindInput } from 'components/input'
import Loading from 'components/loading'
import { MetaData } from 'components/display'
import { message } from 'utils'

import CategoryItem from './CategoryItem'

const CommonProblem = (props) => {
  const [loading, setLoading] = useState(false)
  const [keywords, setKeywords] = useState('')

  const onGetList = useCallback(
    async (text) => {
      try {
        setLoading(true)

        await props.problem.getCommonProblemList(text)
      } catch (e) {
        message.error({
          title: `แสดงรายการ`,
          message: e.message,
        })
      }
      setLoading(false)
    },
    [props.problem]
  )

  const onSearch = () => onGetList(keywords)

  useEffect(() => {
    onGetList('')
  }, [onGetList])

  const { common_list = [] } = props.problem.toJS()
  const content = common_list.map((item, i) => {
    return <CategoryItem key={i} item={item} />
  })

  return (
    <Menu>
      <MetaData title="ปัญหาที่พบบ่อย" description="ปัญหาที่พบบ่อย" />
      <Wrapper>
        <Title>
          <Text size="large" title bold>
            ปัญหาที่พบบ่อย
          </Text>
        </Title>

        <FindInput
          value={keywords}
          onChange={(val) => setKeywords(val)}
          onEnter={onSearch}
        />

        <Loading loading={loading}>{content}</Loading>
      </Wrapper>
    </Menu>
  )
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`

const Title = styled.div`
  margin: 16px 0;
  text-align: center;
`

export default inject('problem')(observer(CommonProblem))
