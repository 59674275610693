import React, { useState, useCallback, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import Menu from 'pages/account/components/Menu/Menu'
import BackBtn from 'pages/account/components/BackBtn'
import { MetaData } from 'components/display'
import { Text } from 'components/typo'
import { Button } from 'components/button'
import { PasswordInput } from 'components/input'
import { validator, message } from 'utils'

const ConfirmAddEmail = (props) => {
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState({
    current: '',
    confirm: '',
  })

  const [validate, setValidate] = useState({})

  const { ref_code, code } = props.match.params
  const { connection } = props.account.toJS()

  const onInit = useCallback(async () => {
    try {
      setLoading(true)

      await props.account.confirmChangeEmail({ ref_code, code })
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    }
    setLoading(false)
  }, [props.account])

  useEffect(() => {
    onInit(ref_code, code)
  }, [onInit, ref_code, code])

  const onChange = (val, name) => {
    password[name] = val
    setPassword({ ...password })

    if (isInvalid(validate)) {
      const valid = getValidate()
      setValidate(valid)
    }
  }

  const getTextError = (pass = '', list = []) => {
    return pass.length > 0 && list.length > 0 ? list[0] : undefined
  }

  const getValidate = () => {
    const valid = {}
    const validCurrent = validator.validatePassword(password.current)
    valid.current = getTextError(password.current, validCurrent)
    if (password.current !== password.confirm) {
      valid.confirm = 'รหัสผ่านไม่ตรงกัน'
    }

    return valid
  }

  const isInvalid = (valid = {}) => {
    return valid.current || valid.confirm
  }

  const onSave = async () => {
    try {
      const valid = getValidate()

      if (isInvalid(valid)) {
        setValidate(valid)
        return
      }

      setLoading(true)
      setValidate({})

      await props.member.setNewPasswordByUser({ password: password.current })
      message.success({
        message: 'เปลี่ยนรหัสผ่านสำเร็จ',
      })
      setPassword({
        current: '',
        confirm: '',
      })
    } catch (e) {
      const msg = e.message
      message.error({
        message:
          msg ===
          'The password is invalid or the user does not have a password.'
            ? 'รหัสผ่านเดิม ไม่ถูกต้อง'
            : msg,
      })
    }
    setLoading(false)
  }

  let disabled = false
  let error_text
  if (loading === false && !connection.email) {
    disabled = true
    error_text = <TextError>ไม่พบอีเมล</TextError>
  }

  const isDisabled =
    password.current.length === 0 || password.confirm.length === 0

  return (
    <Menu>
      <MetaData title="ผูกอีเมล" description="ผูกอีเมล" />
      <BackBtn to="/account/privacy" text="ความปลอดภัย" />
      <Content>
        <Title>
          <Text size="large" bold>
            สร้างรหัสผ่าน
          </Text>
        </Title>
        <Space />

        <PasswordBox>
          <PasswordInput
            id="current"
            label="รหัสผ่านใหม่"
            name="current"
            value={password.current}
            onChange={onChange}
            errorText={validate.current}
            toolTip={<PasswordHint list={passwordComplexity} />}
            disabled={disabled}
          />
          <PasswordInput
            id="confirm"
            label="ยืนยันรหัสผ่านใหม่"
            name="confirm"
            value={password.confirm}
            onChange={onChange}
            errorText={validate.confirm}
            disabled={disabled}
          />
        </PasswordBox>

        <Detail>
          <Button
            color="primary"
            text="ยืนยัน"
            maxWidth="325px"
            loading={loading}
            onClick={onSave}
            disabled={disabled || isDisabled}
          />
          {error_text}
        </Detail>
      </Content>
    </Menu>
  )
}

const Content = styled.div`
  width: 325px;
  margin: 0 auto;
  text-align: center;
`

const Title = styled.div`
  margin: 36px 0px;
`

const Space = styled.div`
  margin-top: 24px;
`

const Detail = styled.div`
  margin-top: 40px;
  margin-bottom: 29px;
`

const passwordComplexity = [
  'รหัสผ่านต้องไม่มีช่องว่าง',
  'รหัสผ่านต้องมีตัวเลข (0-9) อย่างน้อย 1 ตัว',
  'รหัสผ่านต้องมีความยาวอยู่ระหว่าง 8-15 ตัวอักษร',
  'รหัสผ่านต้องประกอบด้วยตัวอักษรภาษาอังกฤษพิมพ์เล็ก (a-z) อย่างน้อย 1 ตัว',
  'รหัสผ่านต้องประกอบด้วยตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ (A-Z) อย่างน้อย 1 ตัว',
]

const PasswordHint = (props) => {
  if (!props.list) return null

  return (
    <HintWrapper>
      {props.list.map((i) => (
        <li key={i}>{i}</li>
      ))}
    </HintWrapper>
  )
}

const HintWrapper = styled.ul`
  margin: 0;
  padding-left: 16px;

  & li {
    margin: 0;
  }
`

const PasswordBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-bottom: 40px;
`

const TextError = styled.div`
  color: #ed2e7e;
  text-align: left;
  font-size: 14px;
  padding-top: 10px;
`

export default inject('account', 'member')(observer(ConfirmAddEmail))
