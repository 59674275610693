import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { width = 44, height = 26 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 46 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.6322 14.0867C39.6322 19.5033 35.1882 23.9233 29.6442 23.9233C24.1442 23.9233 19.6562 19.5033 19.6562 14.0867C19.6562 8.67 24.1002 4.25 29.6002 4.25C35.1882 4.25 39.6322 8.67 39.6322 14.0867Z"
        fill="#FFB600"
      />
      <path
        d="M29.6442 4.25C35.1442 4.25 39.6322 8.67 39.6322 14.0867C39.6322 19.5033 35.1882 23.9233 29.6442 23.9233C24.1442 23.9233 19.6562 19.5033 19.6562 14.0867"
        fill="#F7981D"
      />
      <path
        d="M29.6445 4.25C35.1445 4.25 39.6325 8.67 39.6325 14.0867C39.6325 19.5033 35.1885 23.9233 29.6445 23.9233"
        fill="#FF8500"
      />
      <path
        d="M16.2232 4.25C10.7672 4.29333 6.36719 8.67 6.36719 14.0867C6.36719 19.5033 10.8112 23.9233 16.3552 23.9233C18.9512 23.9233 21.2832 22.9267 23.0432 21.3667C23.3952 21.0633 23.7472 20.6733 24.0552 20.3267H21.9872C21.7232 20.0233 21.4592 19.6333 21.2392 19.33H24.7592C24.9792 19.0267 25.1992 18.6367 25.3312 18.29H20.6232C20.4472 17.9867 20.3152 17.5967 20.1832 17.25H25.6832C25.9912 16.2533 26.2112 15.2133 26.2112 14.13C26.2112 13.3933 26.1232 12.7433 25.9912 12.05H19.8752C19.9192 11.7033 20.0512 11.3567 20.1392 11.01H25.6392C25.5512 10.6633 25.3752 10.2733 25.1992 9.97H20.5352C20.7112 9.62333 20.8872 9.27667 21.1072 8.93H24.6272C24.4072 8.58333 24.1432 8.19333 23.8352 7.89H21.8992C22.2072 7.54333 22.5152 7.19667 22.9112 6.89333C21.1512 5.29 18.7752 4.33667 16.2232 4.33667C16.2672 4.25 16.2672 4.25 16.2232 4.25Z"
        fill="#FF5050"
      />
      <path
        d="M6.36719 14.0859C6.36719 19.5026 10.8112 23.9226 16.3552 23.9226C18.9512 23.9226 21.2832 22.9259 23.0432 21.3659C23.3952 21.0626 23.7472 20.6726 24.0552 20.3259H21.9872C21.7232 20.0226 21.4592 19.6326 21.2392 19.3293H24.7592C24.9792 19.0259 25.1992 18.6359 25.3312 18.2893H20.6232C20.4472 17.9859 20.3152 17.5959 20.1832 17.2493H25.6832C25.9912 16.2526 26.2112 15.2126 26.2112 14.1293C26.2112 13.3926 26.1232 12.7426 25.9912 12.0493H19.8752C19.9192 11.7026 20.0512 11.3559 20.1392 11.0093H25.6392C25.5512 10.6626 25.3752 10.2726 25.1992 9.96927H20.5352C20.7112 9.6226 20.8872 9.27594 21.1072 8.92927H24.6272C24.4072 8.5826 24.1432 8.1926 23.8352 7.88927H21.8992C22.2072 7.5426 22.5152 7.19594 22.9112 6.8926C21.1512 5.28927 18.7752 4.33594 16.2232 4.33594H16.1792"
        fill="#E52836"
      />
      <path
        d="M16.3127 23.9226C18.9087 23.9226 21.2407 22.9259 23.0007 21.3659C23.3527 21.0626 23.7047 20.6726 24.0127 20.3259H21.9447C21.6807 20.0226 21.4167 19.6326 21.1967 19.3293H24.7167C24.9367 19.0259 25.1567 18.6359 25.2887 18.2893H20.5807C20.4047 17.9859 20.2727 17.5959 20.1407 17.2493H25.6407C25.9487 16.2526 26.1687 15.2126 26.1687 14.1293C26.1687 13.3926 26.0807 12.7426 25.9487 12.0493H19.8327C19.8767 11.7026 20.0087 11.3559 20.0967 11.0093H25.5967C25.5087 10.6626 25.3327 10.2726 25.1567 9.96927H20.4927C20.6687 9.6226 20.8447 9.27594 21.0647 8.92927H24.5847C24.3647 8.5826 24.1007 8.1926 23.7927 7.88927H21.8567C22.1647 7.5426 22.4727 7.19594 22.8687 6.8926C21.1087 5.28927 18.7327 4.33594 16.1807 4.33594H16.1367"
        fill="#CB2026"
      />
      <path
        d="M19.8759 16.6008L20.0519 15.7341C20.0079 15.7341 19.8759 15.7774 19.7879 15.7774C19.4359 15.7774 19.3479 15.5608 19.4359 15.4741L19.7439 13.6541H20.3159L20.4919 12.6574H19.9639L20.0519 12.0508H18.9519C18.9519 12.0508 18.3359 15.4741 18.3359 15.9074C18.3359 16.5141 18.6879 16.7741 19.1719 16.7741C19.4799 16.7308 19.7439 16.6441 19.8759 16.6008Z"
        fill="white"
      />
      <path
        d="M20.2285 14.9119C20.2285 16.3852 21.2405 16.7319 22.0765 16.7319C22.8685 16.7319 23.1765 16.5586 23.1765 16.5586L23.3965 15.5619C23.3965 15.5619 22.8245 15.8219 22.2965 15.8219C21.1525 15.8219 21.3285 14.9986 21.3285 14.9986H23.4845C23.4845 14.9986 23.6605 14.3052 23.6605 14.0452C23.6605 13.3519 23.3085 12.5286 22.1205 12.5286C21.0205 12.4852 20.2285 13.6119 20.2285 14.9119ZM22.0765 13.3952C22.6485 13.3952 22.5605 14.0886 22.5605 14.1319H21.4165C21.4165 14.0886 21.5045 13.3952 22.0765 13.3952Z"
        fill="white"
      />
      <path
        d="M28.8073 16.6009L29.0273 15.4742C29.0273 15.4742 28.4993 15.7342 28.1473 15.7342C27.3993 15.7342 27.0913 15.1709 27.0913 14.5209C27.0913 13.2642 27.7073 12.6142 28.4553 12.6142C28.9833 12.6142 29.4233 12.9176 29.4233 12.9176L29.5993 11.8342C29.5993 11.8342 28.9833 11.5742 28.3673 11.5742C27.1353 11.5742 25.9473 12.6142 25.9473 14.6076C25.9473 15.9076 26.5633 16.7742 27.8393 16.7742C28.1913 16.7309 28.8073 16.6009 28.8073 16.6009Z"
        fill="white"
      />
      <path
        d="M14.067 12.4844C13.319 12.4844 12.791 12.701 12.791 12.701L12.615 13.5677C12.615 13.5677 13.099 13.351 13.759 13.351C14.111 13.351 14.463 13.3944 14.463 13.6977C14.463 13.9144 14.419 13.9577 14.419 13.9577C14.419 13.9577 14.111 13.9577 13.935 13.9577C13.055 13.9577 12.043 14.3044 12.043 15.5177C12.043 16.471 12.659 16.6444 13.055 16.6444C13.803 16.6444 14.111 16.1677 14.155 16.1677L14.111 16.601H15.079L15.519 13.741C15.475 12.5277 14.419 12.4844 14.067 12.4844ZM14.287 14.8244C14.287 14.9977 14.199 15.821 13.539 15.821C13.231 15.821 13.099 15.561 13.099 15.3877C13.099 15.1277 13.275 14.781 14.067 14.781C14.199 14.8244 14.287 14.8244 14.287 14.8244Z"
        fill="white"
      />
      <path
        d="M16.4879 16.6881C16.7519 16.6881 18.0719 16.7314 18.0719 15.3447C18.0719 14.0447 16.7959 14.3047 16.7959 13.7847C16.7959 13.5247 17.0159 13.4381 17.3679 13.4381C17.5439 13.4381 18.1159 13.4814 18.1159 13.4814L18.2919 12.5281C18.2919 12.5281 17.9399 12.4414 17.2799 12.4414C16.4879 12.4414 15.6959 12.7447 15.6959 13.7847C15.6959 14.9981 17.0159 14.8681 17.0159 15.3447C17.0159 15.6481 16.6639 15.6914 16.3999 15.6914C15.9159 15.6914 15.4319 15.5181 15.4319 15.5181L15.2559 16.4714C15.3439 16.6014 15.6079 16.6881 16.4879 16.6881Z"
        fill="white"
      />
      <path
        d="M37.5633 11.6602L37.3433 13.0468C37.3433 13.0468 36.9033 12.5268 36.3313 12.5268C35.3633 12.5268 34.5273 13.6535 34.5273 15.0402C34.5273 15.8635 34.9673 16.7735 35.8473 16.7735C36.4633 16.7735 36.8593 16.3402 36.8593 16.3402L36.8153 16.6868H37.8713L38.6633 11.7035L37.5633 11.6602ZM37.0793 14.3902C37.0793 14.9535 36.8153 15.6902 36.2433 15.6902C35.8913 15.6902 35.6713 15.3868 35.6713 14.8668C35.6713 14.0435 36.0233 13.5235 36.5073 13.5235C36.8593 13.5235 37.0793 13.7835 37.0793 14.3902Z"
        fill="white"
      />
      <path
        d="M8.30405 16.6435L8.92005 12.9168L9.00805 16.6435H9.75605L11.12 12.9168L10.548 16.6435H11.648L12.484 11.6602H10.7241L9.66805 14.7368L9.62405 11.6602H8.08405L7.24805 16.6435H8.30405Z"
        fill="white"
      />
      <path
        d="M24.6715 16.6436C24.9795 14.9103 25.0235 13.5236 25.7715 13.7836C25.8595 13.0903 26.0355 12.8303 26.1235 12.5703C26.1235 12.5703 26.0795 12.5703 25.9035 12.5703C25.4195 12.5703 25.0675 13.177 25.0675 13.177L25.1555 12.6136H24.1435L23.4395 16.687H24.5835V16.6436H24.6715Z"
        fill="white"
      />
      <path
        d="M31.2271 12.4844C30.4791 12.4844 29.9511 12.701 29.9511 12.701L29.7751 13.5677C29.7751 13.5677 30.2591 13.351 30.9191 13.351C31.2711 13.351 31.6231 13.3944 31.6231 13.6977C31.6231 13.9144 31.5791 13.9577 31.5791 13.9577C31.5791 13.9577 31.2711 13.9577 31.0951 13.9577C30.2151 13.9577 29.2031 14.3044 29.2031 15.5177C29.2031 16.471 29.8191 16.6444 30.2151 16.6444C30.9631 16.6444 31.2711 16.1677 31.3151 16.1677L31.2711 16.601H32.2391L32.6791 13.741C32.6791 12.5277 31.5791 12.4844 31.2271 12.4844ZM31.4911 14.8244C31.4911 14.9977 31.4031 15.821 30.7431 15.821C30.4351 15.821 30.3031 15.561 30.3031 15.3877C30.3031 15.1277 30.4791 14.781 31.2711 14.781C31.4471 14.8244 31.4471 14.8244 31.4911 14.8244Z"
        fill="white"
      />
      <path
        d="M33.5601 16.6436C33.8681 14.9103 33.9121 13.5236 34.6601 13.7836C34.7481 13.0903 34.9241 12.8303 35.0121 12.5703C35.0121 12.5703 34.9681 12.5703 34.7921 12.5703C34.3081 12.5703 33.9561 13.177 33.9561 13.177L34.0441 12.6136H33.0321L32.3281 16.687H33.4721V16.6436H33.5601Z"
        fill="white"
      />
      <path
        d="M18.248 15.8645C18.248 16.4711 18.6001 16.7311 19.0841 16.7311C19.4361 16.7311 19.7881 16.6445 19.8761 16.5578L20.052 15.6911C20.008 15.6911 19.876 15.7345 19.788 15.7345C19.436 15.7345 19.3481 15.5178 19.4361 15.4311L19.7441 13.6111H20.3161L20.492 12.6145H19.964L20.052 12.0078"
        fill="#DCE5E5"
      />
      <path
        d="M20.7559 14.9119C20.7559 16.3852 21.2399 16.7319 22.0759 16.7319C22.8679 16.7319 23.1759 16.5586 23.1759 16.5586L23.3959 15.5619C23.3959 15.5619 22.8239 15.8219 22.2959 15.8219C21.1519 15.8219 21.3279 14.9986 21.3279 14.9986H23.4839C23.4839 14.9986 23.6599 14.3052 23.6599 14.0452C23.6599 13.3519 23.3079 12.5286 22.1199 12.5286C21.0199 12.4852 20.7559 13.6119 20.7559 14.9119ZM22.0759 13.3952C22.6479 13.3952 22.7799 14.0886 22.7799 14.1319H21.4159C21.4159 14.0886 21.5039 13.3952 22.0759 13.3952Z"
        fill="#DCE5E5"
      />
      <path
        d="M28.8086 16.6009L29.0286 15.4742C29.0286 15.4742 28.5006 15.7342 28.1486 15.7342C27.4006 15.7342 27.0926 15.1709 27.0926 14.5209C27.0926 13.2642 27.7086 12.6142 28.4566 12.6142C28.9846 12.6142 29.4246 12.9176 29.4246 12.9176L29.6006 11.8342C29.6006 11.8342 28.9846 11.5742 28.3686 11.5742C27.1366 11.5742 26.4766 12.6142 26.4766 14.6076C26.4766 15.9076 26.5646 16.7742 27.8406 16.7742C28.1926 16.7309 28.8086 16.6009 28.8086 16.6009Z"
        fill="#DCE5E5"
      />
      <path
        d="M12.615 13.6107C12.615 13.6107 13.099 13.394 13.759 13.394C14.111 13.394 14.463 13.4373 14.463 13.7407C14.463 13.9573 14.419 14.0007 14.419 14.0007C14.419 14.0007 14.111 14.0007 13.935 14.0007C13.055 14.0007 12.043 14.3473 12.043 15.5607C12.043 16.514 12.659 16.6873 13.055 16.6873C13.803 16.6873 14.111 16.2107 14.155 16.2107L14.111 16.644H15.079L15.519 13.784C15.519 12.5707 14.463 12.5273 14.023 12.5273L12.615 13.6107ZM14.815 14.824C14.815 14.9973 14.199 15.8207 13.539 15.8207C13.231 15.8207 13.099 15.5607 13.099 15.3873C13.099 15.1273 13.275 14.7807 14.067 14.7807C14.199 14.824 14.815 14.824 14.815 14.824Z"
        fill="#DCE5E5"
      />
      <path
        d="M15.3438 16.6014C15.3438 16.6014 15.6518 16.6881 16.5758 16.6881C16.8398 16.6881 18.1597 16.7314 18.1597 15.3447C18.1597 14.0447 16.8837 14.3047 16.8837 13.7847C16.8837 13.5247 17.1038 13.4381 17.4558 13.4381C17.6318 13.4381 18.2037 13.4814 18.2037 13.4814L18.3797 12.5281C18.3797 12.5281 18.0277 12.4414 17.3677 12.4414C16.5757 12.4414 16.3117 12.7447 16.3117 13.7847C16.3117 14.9981 17.1037 14.8681 17.1037 15.3447C17.1037 15.6481 16.7517 15.6914 16.4877 15.6914"
        fill="#DCE5E5"
      />
      <path
        d="M37.3432 13.0465C37.3432 13.0465 36.9032 12.5265 36.3312 12.5265C35.3632 12.5265 35.0552 13.6531 35.0552 15.0398C35.0552 15.8631 34.9672 16.7731 35.8472 16.7731C36.4632 16.7731 36.8592 16.3398 36.8592 16.3398L36.8152 16.6865H37.8712L38.6632 11.7031L37.3432 13.0465ZM37.2992 14.3898C37.2992 14.9531 36.8152 15.6898 36.2432 15.6898C35.8912 15.6898 35.6712 15.3865 35.6712 14.8665C35.6712 14.0431 36.0232 13.5231 36.5072 13.5231C36.8592 13.5231 37.2992 13.7831 37.2992 14.3898Z"
        fill="#DCE5E5"
      />
      <path
        d="M8.30405 16.6435L8.92005 12.9168L9.00805 16.6435H9.75605L11.12 12.9168L10.548 16.6435H11.648L12.484 11.6602H11.164L9.66805 14.7368L9.62405 11.6602H9.05205L7.24805 16.6435H8.30405Z"
        fill="#DCE5E5"
      />
      <path
        d="M23.5723 16.6436H24.6723C24.9803 14.9103 25.0243 13.5236 25.7723 13.7836C25.8603 13.0903 26.0363 12.8303 26.1243 12.5703C26.1243 12.5703 26.0803 12.5703 25.9043 12.5703C25.4203 12.5703 25.0683 13.177 25.0683 13.177L25.1563 12.6136"
        fill="#DCE5E5"
      />
      <path
        d="M29.7751 13.6107C29.7751 13.6107 30.2591 13.394 30.9191 13.394C31.2711 13.394 31.6231 13.4373 31.6231 13.7407C31.6231 13.9573 31.5791 14.0007 31.5791 14.0007C31.5791 14.0007 31.2711 14.0007 31.0951 14.0007C30.2151 14.0007 29.2031 14.3473 29.2031 15.5607C29.2031 16.514 29.8191 16.6873 30.2151 16.6873C30.9631 16.6873 31.2711 16.2107 31.3151 16.2107L31.2711 16.644H32.2391L32.6791 13.784C32.6791 12.5707 31.6231 12.5273 31.1831 12.5273L29.7751 13.6107ZM31.9751 14.824C31.9751 14.9973 31.3591 15.8207 30.6991 15.8207C30.3911 15.8207 30.2591 15.5607 30.2591 15.3873C30.2591 15.1273 30.4351 14.7807 31.2271 14.7807C31.4471 14.824 31.9751 14.824 31.9751 14.824Z"
        fill="#DCE5E5"
      />
      <path
        d="M32.416 16.6436H33.516C33.824 14.9103 33.868 13.5236 34.616 13.7836C34.704 13.0903 34.88 12.8303 34.968 12.5703C34.968 12.5703 34.924 12.5703 34.748 12.5703C34.264 12.5703 33.912 13.177 33.912 13.177L34 12.6136"
        fill="#DCE5E5"
      />
      <path
        d="M41.48 27H4.52C2.584 27 1 25.44 1 23.5333V4.46667C1 2.56 2.584 1 4.52 1H41.48C43.416 1 45 2.56 45 4.46667V23.5333C45 25.44 43.416 27 41.48 27Z"
        stroke="#E6E6E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
// eslint-enable
export default Logo;
