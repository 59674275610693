import { useState } from 'react'
import styled from 'styled-components'

import FeatureCard from 'pages/newHome/features/components/FeatureCard'

import ARTICLE_ICON from './assets/article.svg'
import CALENDAR_ICON from './assets/calendar.svg'
import CLIENT_ICON from './assets/client.svg'
import OPPORTUNITY_ICON from './assets/opportunity.svg'
import PLANNING_ICON from './assets/planning.svg'
import PRODUCT_ICON from './assets/product.svg'
import REPORT_ICON from './assets/report.svg'
import TASK_ICON from './assets/task.svg'
import TEAM_ICON from './assets/team.svg'
import TODAY_ICON from './assets/today.svg'
import WORKFLOW_ICON from './assets/workflow.svg'
import FeatureSelector from './components/FeatureSelector'

const HIGHLIGHT_LIST = [
  {
    icon: OPPORTUNITY_ICON,
    topic: 'โอกาสในการขาย',
    arcadeLink: 'https://demo.arcade.software/I7UgoCS4nNEYAeO70Dnz?embed',
  },
  {
    icon: PLANNING_ICON,
    topic: 'วางแผน',
    arcadeLink: 'https://demo.arcade.software/FMhFjLe41qs0T9SLNrsd?embed',
  },
  {
    icon: CLIENT_ICON,
    topic: 'ลูกค้า',
    arcadeLink: 'https://demo.arcade.software/KMn5oqbbNY77M2iFvyRm?embed',
  },
]

const DESCRIPTION = {
  desktop: (
    <>
      อัพเลเวลสู่ตัวแทนมืออาชีพ
      <br />
      ด้วยเครื่องมือวางแผนการเงินที่ครอบคลุม และครบครัน
    </>
  ),
  tablet: (
    <>
      อัพเลเวลสู่ตัวแทนมืออาชีพ
      <br />
      ด้วยเครื่องมือวางแผนการเงินที่ครอบคลุม และครบครัน
    </>
  ),
  mobile: (
    <>
      อัพเลเวลสู่ตัวแทนมืออาชีพ
      <br />
      ด้วยเครื่องมือวางแผนการเงินที่ครอบคลุม
      <br />
      และครบครัน
    </>
  ),
}

const FEATURES_LIST = [
  {
    icon: TODAY_ICON,
    topic: 'วันนี้',
    description: DESCRIPTION,
  },
  {
    icon: CALENDAR_ICON,
    topic: 'นัดหมาย',
    description: DESCRIPTION,
  },
  {
    icon: TASK_ICON,
    topic: 'งาน',
    description: DESCRIPTION,
  },
  {
    icon: TEAM_ICON,
    topic: 'ทีม',
    description: DESCRIPTION,
  },
  {
    icon: WORKFLOW_ICON,
    topic: 'ขั้นตอนการทำงาน',
    description: DESCRIPTION,
  },
  {
    icon: PRODUCT_ICON,
    topic: 'ผลิตภัณฑ์',
    description: DESCRIPTION,
  },
  {
    icon: REPORT_ICON,
    topic: 'รายงาน',
    description: DESCRIPTION,
  },
  {
    icon: ARTICLE_ICON,
    topic: 'บทความ',
    description: DESCRIPTION,
  },
]

function Features() {
  const [selectedFeature, selectFeature] = useState(HIGHLIGHT_LIST[1])

  return (
    <Container>
      <Header>ฟีเจอร์</Header>
      <Topic>ทดลองใช้งาน Highlight ฟีเจอร์</Topic>
      <FeaturesSelectorContainer>
        {HIGHLIGHT_LIST.map((feature, idx) => {
          const isSelected = selectedFeature.topic === feature.topic
          const onSelect = () => selectFeature(feature)
          return (
            <FeatureSelector
              key={idx}
              icon={feature.icon}
              topic={feature.topic}
              isSelected={isSelected}
              onClick={onSelect}
            />
          )
        })}
      </FeaturesSelectorContainer>
      <SelectedTopic>{selectedFeature.topic}</SelectedTopic>
      <Arcade>
        <ArcadeContainer>
          <ArcadePlayer
            src={selectedFeature.arcadeLink}
            frameborder="0"
            loading="lazy"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            title="▶ Planning - Friday : Arcade"
          />
        </ArcadeContainer>
      </Arcade>
      <Topic>
        อัพเลเวลสู่ตัวแทนมืออาชีพ
        <br />
        ด้วยเครื่องมือวางแผนการเงินที่ครอบคลุม และครบครัน
      </Topic>
      <SecondTopic>ฟีเจอร์ ทั้งหมด</SecondTopic>
      <FeaturesContainer>
        {FEATURES_LIST.map((feature, idx) => (
          <FeatureCard
            key={idx}
            icon={feature.icon}
            topic={feature.topic}
            description={feature.description}
          />
        ))}
      </FeaturesContainer>
    </Container>
  )
}

export default Features

const Container = styled.div`
  width: 100%;
  padding: 40px 0 0;

  @media screen and (max-width: 465px) {
    padding: 40px 16px 0;
  }
`

const Header = styled.h1`
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: bold;
  line-height: 34px;
  color: #5f2eea;
  text-align: center;
`

const Topic = styled.p`
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 28px;
  color: #5f2eea;
  text-align: center;
`

const FeaturesSelectorContainer = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
  gap: 24px;
  margin: 0 auto 40px;
`

const SelectedTopic = styled.p`
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: bold;
  line-height: 38px;
  color: #5f2eea;
  text-align: center;
`

const Arcade = styled.div`
  width: 100%;
  padding: 0 20px;
`

const ArcadeContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  height: fit-content;
  margin: 0 auto;
`

const ArcadePlayer = styled.iframe`
  width: 100%;
  aspect-ratio: 1080 / 600;
  color-scheme: light;
  border: none;

  @media screen and (max-width: 465px) {
    aspect-ratio: 1080 / 670;
  }
`

const SecondTopic = styled(SelectedTopic)`
  font-weight: bold;
`

const FeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  gap: 16px;

  @media screen and (max-width: 465px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`
