import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  return (
    <svg width="118" height="40" viewBox="0 0 118 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
      <path d="M117.954 0.584607C117.967 1.27443 117.987 1.96583 117.989 2.65564C117.989 14.8751 117.992 27.0936 118 39.311C118 39.8252 117.904 40.0008 117.341 40.0008C78.965 39.985 40.5884 39.9797 2.21062 39.985C2.03488 39.985 1.85914 39.9691 1.6834 39.9612C1.47409 39.8529 1.23785 39.8089 1.00396 39.8347C0.186447 39.8758 0.159772 39.8426 0.158203 38.9898C0.158203 38.9107 0.158203 38.8316 0.158203 38.7525V1.51175C0.158203 1.31398 0.158203 1.11621 0.158203 0.920023C0.158203 0.45487 0.412403 0.238115 0.836067 0.181157C1.05098 0.16136 1.26699 0.156602 1.48255 0.166918H116.763C117.203 0.168501 117.687 0.0688249 117.954 0.584607Z" fill="#063C65"/>
      <path d="M117.954 0.583815C117.667 0.319595 117.314 0.340164 116.96 0.340164C78.6625 0.340164 40.3637 0.340164 2.0634 0.340164C1.67112 0.340164 1.27884 0.340164 0.886556 0.340164C0.494274 0.340164 0.268321 0.533186 0.274597 0.952457C0.274597 1.07112 0.274597 1.18978 0.274597 1.30844V38.9748C0.274597 39.7864 0.28558 39.8101 1.04347 39.682C1.16592 39.6502 1.29537 39.6603 1.41151 39.7107C1.52765 39.7611 1.62393 39.849 1.68524 39.9604C1.29296 39.9684 0.900679 39.951 0.511535 39.9921C0.0815938 40.0396 -8.61913e-07 39.8624 0.00156827 39.4621C0.0172595 36.2392 0.010985 33.018 0.010985 29.7967C0.010985 20.0707 0.0073226 10.3457 0 0.621788C0 0.136066 0.116115 0 0.610391 0C39.5457 0.0126572 78.4805 0.0126572 117.415 0C117.856 0.00474646 118.108 0.0711973 117.954 0.583815Z" fill="#0D3B5D"/>
      <path d="M1.36882 1.30546C2.09375 1.30546 2.81869 1.3197 3.54363 1.3197H115.118C115.353 1.3197 115.589 1.30071 115.821 1.29122C116.606 1.03175 116.819 1.18363 116.821 2.01426C116.821 6.93793 116.821 11.8611 116.821 16.7837V37.6001C116.821 37.8184 116.821 38.0351 116.811 38.2535C116.814 38.3281 116.801 38.4025 116.774 38.4721C116.748 38.5417 116.707 38.6052 116.655 38.6586C116.604 38.7119 116.542 38.7542 116.473 38.7827C116.405 38.8113 116.331 38.8255 116.257 38.8246C116.022 38.8452 115.786 38.8373 115.551 38.8373H2.73082C2.41699 38.8373 2.10317 38.7756 1.8019 38.7677C1.27153 38.7519 1.27781 38.4718 1.27781 38.0858C1.27781 26.1848 1.28304 14.2833 1.2935 2.38132C1.28879 2.0285 1.07696 1.64087 1.36882 1.30546Z" fill="#FEFEFE"/>
      <path d="M1.36954 1.30729C1.35071 1.52246 1.31776 1.73763 1.31776 1.95281C1.31776 13.9708 1.3141 25.9889 1.30678 38.0069C1.30678 38.5211 1.40249 38.6888 1.95796 38.6888C39.8148 38.673 77.6721 38.6683 115.53 38.6746C116.683 38.6746 116.683 38.6746 116.683 37.4848V2.26133C116.683 1.36583 116.683 1.36583 115.822 1.29305C115.963 0.856376 116.319 1.06997 116.569 1.08421C116.818 1.09845 116.931 1.36583 116.983 1.59682C117.008 1.77281 117.014 1.95113 117 2.12843V37.9452C117 38.8724 116.861 39.0132 115.949 39.0132C78.1642 39.0132 40.3796 39.0184 2.59503 39.029C2.0882 39.029 1.68023 38.7252 1.18282 38.7616C1.06984 38.7695 0.99452 38.6793 1.07768 38.5654C1.33188 38.2253 1.20321 37.8376 1.20478 37.4769C1.21315 25.8323 1.21315 14.1881 1.20478 2.54453C1.20478 2.25975 1.25499 1.97812 1.04787 1.6965C0.840745 1.41488 1.06042 1.27881 1.36954 1.30729Z" fill="#0D3B5D"/>
      <path d="M38.9854 28.937C28.5151 28.937 18.0443 28.9423 7.57304 28.9528C6.95951 28.9528 6.84653 28.7803 6.84967 28.2013C6.87164 22.6258 6.87164 17.0508 6.84967 11.4763C6.84967 10.9732 6.99246 10.8656 7.46947 10.8672C28.4519 10.8778 49.4332 10.8814 70.4135 10.8783C71.2153 10.8783 71.2169 10.8783 71.2169 11.6994C71.2169 17.237 71.2075 22.7745 71.231 28.312C71.231 28.8531 71.0741 28.9544 70.5735 28.9544C60.0457 28.9354 49.5163 28.9296 38.9854 28.937Z" fill="#003D69"/>
      <path d="M72.6587 19.9401C72.6587 17.1144 72.6681 14.2902 72.6477 11.4661C72.6477 11.0009 72.7623 10.8665 73.2315 10.8712C75.6997 10.8949 78.1679 10.8839 80.6378 10.8839C81.6976 10.8608 82.75 11.0669 83.7242 11.4883C85.1365 12.129 86.0418 13.2112 86.3839 14.7396C86.6827 16.0743 86.6709 17.4606 86.3494 18.7899C85.8347 20.9748 84.2781 21.94 82.2383 22.2991C80.6048 22.5855 78.9525 22.3956 77.3096 22.4352C76.4011 22.4573 76.5251 22.3576 76.5251 23.2262C76.5251 24.9445 76.5094 26.6627 76.5251 28.3825C76.5251 28.8065 76.4482 28.9758 75.9853 28.9568C75.0438 28.9188 74.1023 28.9267 73.1609 28.9568C72.7388 28.971 72.6415 28.8271 72.6446 28.4189C72.6619 25.5947 72.654 22.769 72.654 19.9449L72.6587 19.9401Z" fill="#003D69"/>
      <path d="M96.2138 25.1947C95.8121 25.5111 95.4763 25.8069 95.1044 26.0506C93.2873 27.2499 91.3793 27.4508 89.432 26.4224C88.0669 25.7009 87.5946 24.4384 87.6244 22.9512C87.6542 21.4639 88.2159 20.2615 89.5497 19.5211C90.6183 18.9262 91.8045 18.7569 92.9939 18.6082C93.7126 18.518 94.4391 18.4737 95.1389 18.2664C95.8387 18.0591 96.1604 17.6699 96.1416 17.1241C96.1196 16.5197 95.7603 16.0688 95.1154 15.9169C94.5044 15.7648 93.8706 15.7288 93.2466 15.8109C92.4086 15.9264 91.8233 16.3615 91.6209 17.2143C91.5487 17.5196 91.4217 17.632 91.1047 17.6241C90.2432 17.6035 89.3786 17.5956 88.5172 17.6241C88.0794 17.6399 88.0465 17.4658 88.1029 17.0988C88.4262 14.9803 89.5152 13.7288 91.6037 13.2178C93.5389 12.7349 95.5743 12.8613 97.4361 13.5801C98.9817 14.1591 99.6721 15.3204 99.691 16.9026C99.7208 19.3723 99.691 21.8437 99.7098 24.315C99.7098 24.9621 99.6674 25.6408 100.337 26.0553C100.494 26.155 100.457 26.3433 100.425 26.4904C100.388 26.674 100.215 26.6312 100.085 26.6312C99.0256 26.6312 97.9665 26.6202 96.9089 26.6312C96.8514 26.6406 96.7927 26.638 96.7363 26.6235C96.6799 26.609 96.627 26.5831 96.581 26.5472C96.535 26.5113 96.4967 26.4662 96.4686 26.4148C96.4406 26.3634 96.4232 26.3068 96.4178 26.2484C96.3534 25.9335 96.2938 25.6044 96.2138 25.1947Z" fill="#013D68"/>
      <path d="M106.236 17.9037C106.676 16.3073 107.089 14.8882 107.448 13.4547C107.547 13.0624 107.7 12.9516 108.076 12.9627C108.957 12.9896 109.841 12.9927 110.721 12.9627C111.203 12.9453 111.319 13.0529 111.179 13.5497C110.501 15.9609 109.858 18.3816 109.205 20.7991C108.531 23.2926 107.842 25.7829 107.197 28.2842C107.062 28.8048 106.852 28.9709 106.323 28.9393C105.463 28.8855 104.597 28.9076 103.737 28.9393C103.33 28.9503 103.282 28.8127 103.384 28.4535C103.698 27.3729 103.964 26.2828 104.274 25.2022C104.372 24.8642 104.364 24.5037 104.25 24.1706C103.142 20.614 102.053 17.051 100.931 13.4974C100.787 13.0402 100.886 12.9627 101.316 12.9722C102.274 12.9959 103.236 13.007 104.195 12.9722C104.625 12.9548 104.765 13.1304 104.874 13.5054C105.3 14.9056 105.745 16.3153 106.236 17.9037Z" fill="#003D6A"/>
      <path d="M74.5933 7.17833C74.7706 6.6404 74.9511 6.10405 75.1221 5.56454C75.2115 5.27817 75.2633 5.02344 75.6791 5.02344C76.095 5.02344 76.1216 5.32247 76.2111 5.58827C76.3577 6.07799 76.5511 6.55222 76.7885 7.00429C76.7885 6.48218 76.8105 5.95849 76.7885 5.43796C76.7681 5.05192 76.9674 5.02186 77.2592 5.04084C77.5511 5.05983 77.8116 4.96965 77.8022 5.43005C77.7786 6.47586 77.8022 7.52324 77.7927 8.57063C77.7927 8.92978 77.7002 9.2462 77.2545 9.2731C76.8356 9.3 76.47 9.33955 76.3492 8.73992C76.2064 8.03269 75.9271 7.35395 75.6697 6.54389C75.3716 7.39192 75.0844 8.10389 74.8742 8.83959C74.7377 9.31424 74.4238 9.30474 74.0629 9.2731C73.6503 9.23829 73.5687 8.95667 73.5734 8.60543C73.5734 8.11022 73.5734 7.61659 73.5734 7.12296C73.5734 6.8619 73.5561 6.68628 73.2313 6.62774C72.7527 6.54072 72.4326 6.2417 72.5346 5.71326C72.6366 5.18482 73.0791 5.0361 73.5671 5.04401C74.0551 5.05192 74.4693 5.23703 74.5211 5.79553C74.5635 6.24486 74.529 6.70369 74.529 7.15776L74.5933 7.17833Z" fill="#063D65"/>
      <path d="M104.877 7.18185C104.877 6.59013 104.903 5.9984 104.877 5.40984C104.851 4.9874 105.099 5.03012 105.358 5.03962C105.617 5.04911 105.87 4.96209 105.86 5.3956C105.837 6.48254 105.86 7.56948 105.851 8.65642C105.851 9.02031 105.711 9.27504 105.305 9.27346C104.952 9.27346 104.58 9.39528 104.453 8.83362C104.296 8.10583 104.025 7.40019 103.755 6.52842C103.456 7.37488 103.163 8.07735 102.97 8.80672C102.832 9.32092 102.509 9.31459 102.126 9.28137C101.712 9.24023 101.655 8.93013 101.643 8.59472C101.643 8.0821 101.643 7.5679 101.643 7.0537C101.643 6.84011 101.621 6.69297 101.351 6.64075C100.88 6.54899 100.533 6.28952 100.621 5.74684C100.709 5.20416 101.14 5.05227 101.628 5.04911C102.214 5.04911 102.557 5.31016 102.626 5.83227C102.643 6.02905 102.648 6.22665 102.641 6.424C102.629 6.63261 102.658 6.84146 102.724 7.03946C102.881 6.56481 103.03 6.09017 103.194 5.61552C103.293 5.33864 103.298 5.01114 103.767 5.02379C104.202 5.03487 104.211 5.32124 104.299 5.5902C104.476 6.12497 104.651 6.65974 104.826 7.19451L104.877 7.18185Z" fill="#073D65"/>
      <path d="M93.9342 7.92506C93.9342 7.06121 93.9561 6.25273 93.9247 5.44583C93.9075 5.00599 94.1601 5.04554 94.4426 5.0408C94.725 5.03605 94.9258 5.02814 94.9133 5.42526C94.8866 6.49005 94.8976 7.55642 94.8992 8.62121C94.8992 8.92815 94.8191 9.1892 94.4896 9.24458C94.1946 9.29521 93.8839 9.38855 93.6611 9.02149C93.4456 8.63993 93.099 8.35118 92.6867 8.20985C92.5298 9.0832 92.2489 9.33634 91.5208 9.27464C91.0218 9.23192 90.6437 9.00726 90.5574 8.48356C90.4711 7.95987 90.7833 7.67034 91.2054 7.47573C91.4251 7.37447 91.684 7.38397 91.6856 7.00108C91.6856 6.59131 91.3984 6.6625 91.1819 6.5834C90.843 6.45682 90.6123 6.22899 90.6107 5.84453C90.6107 5.42526 90.8712 5.19585 91.2384 5.08351C92.0465 4.82879 92.6192 5.26863 92.6286 6.13723C92.6396 7.33808 92.6396 7.33808 93.9342 7.92506Z" fill="#083C66"/>
      <path d="M85.5782 6.37954H84.6994C84.6759 6.02039 84.8469 5.8068 85.0337 5.60428C85.7272 4.85593 87.4564 4.71195 88.2488 5.34323C88.3936 5.44599 88.5119 5.5822 88.5938 5.74046C88.6757 5.89873 88.7188 6.07446 88.7196 6.25297C88.7196 6.94595 88.7337 7.63735 88.7196 8.32875C88.7007 8.95212 88.434 9.24324 87.8173 9.26855C87.0139 9.30019 86.209 9.26855 85.4056 9.28437C85.1466 9.28437 85.0651 9.18786 85.0713 8.9363C85.0839 8.36356 85.1011 7.78924 85.0713 7.21808C85.0478 6.79565 85.4181 6.65484 85.575 6.36688C85.8598 6.22744 86.1869 6.2042 86.4883 6.30201C86.6799 6.35071 86.8522 6.45699 86.9826 6.60675C87.1129 6.75651 87.1949 6.94268 87.2179 7.14056C87.2681 7.53768 87.061 7.81297 86.7236 8.00441C86.6389 8.05346 86.551 8.09459 86.4679 8.14839C86.4326 8.16452 86.4024 8.18994 86.3803 8.22196C86.3582 8.25398 86.3451 8.29143 86.3423 8.33034C86.3423 8.42685 86.4349 8.44425 86.5087 8.46798C86.6365 8.51684 86.7742 8.53388 86.91 8.51766C87.0459 8.50144 87.1758 8.45244 87.2888 8.37481C87.4018 8.29717 87.4946 8.1932 87.5593 8.07171C87.624 7.95022 87.6587 7.81479 87.6604 7.67691C87.6745 7.40161 87.6714 7.12474 87.6604 6.84944C87.6306 5.91913 86.9245 5.53942 86.1211 6.02514C85.9494 6.15709 85.7679 6.27558 85.5782 6.37954Z" fill="#093C63"/>
      <path d="M108.751 6.70994C108.902 6.79358 109.078 6.81889 109.245 6.78114C109.536 6.75108 109.674 6.83809 109.674 7.1656C109.674 7.49311 109.517 7.51367 109.269 7.50893C108.971 7.50893 108.666 7.50893 108.63 7.93294C108.596 8.33639 108.63 8.56581 109.161 8.55315C110.386 8.51676 110.386 8.55315 110.388 7.33647C110.388 6.70361 110.411 6.07075 110.388 5.43789C110.369 5.05501 110.556 5.0202 110.848 5.03919C111.11 5.05659 111.389 4.96324 111.372 5.42998C111.337 6.37927 111.372 7.32856 111.356 8.27785C111.346 8.9028 111.081 9.22715 110.488 9.25562C109.647 9.29992 108.803 9.27777 107.96 9.2841C107.814 9.2841 107.665 9.26828 107.69 9.07209C107.763 8.50094 107.475 7.88073 107.905 7.3602C107.99 7.25736 107.979 7.1925 107.883 7.12605C107.337 6.75108 107.551 6.18467 107.599 5.71319C107.657 5.15943 108.133 5.05185 108.621 5.04868C109.074 5.04868 109.467 5.17051 109.562 5.65939C109.658 6.14828 109.36 6.42674 108.935 6.58495C108.863 6.6071 108.761 6.5897 108.751 6.70994Z" fill="#083C63"/>
      <path d="M82.1476 6.18815C82.634 6.4144 82.816 6.7957 82.7752 7.33047C82.7438 7.72284 82.7752 8.12154 82.7752 8.51392C82.7642 9.01071 82.4614 9.27177 82.0173 9.34771C81.5199 9.43157 81.0633 9.33664 80.8185 8.83193C80.6083 8.40159 80.8421 7.94751 81.3709 7.78454C81.6549 7.69753 81.8541 7.64531 81.829 7.26085C81.8039 6.87639 81.5811 6.7419 81.2893 6.66438C80.7768 6.53493 80.2469 6.48953 79.7201 6.5299C79.3702 6.55047 79.331 6.42389 79.4298 6.11379C79.7154 5.21829 80.613 4.76263 81.6078 5.02843C81.8463 5.09172 82.0785 5.26101 82.3359 5.09488C82.7391 4.83066 83.0404 5.16766 83.155 5.386C83.2915 5.65022 82.9086 5.74673 82.6936 5.86065C82.6419 5.88754 82.5885 5.90969 82.5367 5.93501C82.1664 6.09322 81.8165 5.94608 81.454 5.88121C81.2196 5.81061 80.9714 5.80081 80.7322 5.85274C81.2296 5.85906 81.7302 5.82742 82.1476 6.18815Z" fill="#093D63"/>
      <path d="M97.2143 6.93503C97.2143 6.46038 97.2425 5.98574 97.2049 5.51109C97.1719 5.09024 97.3618 5.04436 97.7148 5.03645C98.1212 5.01904 98.2044 5.17726 98.1856 5.54432C98.1589 6.13446 98.1856 6.72935 98.173 7.32108C98.1652 7.58213 98.2263 7.69288 98.5057 7.74984C98.9654 7.84318 99.2902 8.11532 99.2306 8.65641C99.1804 9.10258 98.7442 9.3937 98.1714 9.36996C97.6709 9.3494 97.2708 9.15163 97.23 8.59787C97.1892 8.04412 97.2205 7.49036 97.2205 6.94136L97.2143 6.93503Z" fill="#093D67"/>
      <path d="M81.8632 3.62607C82.1516 3.50462 82.3902 3.28748 82.5395 3.01062C82.5991 2.91252 82.6415 2.7638 82.7278 2.72899C82.9647 2.63406 83.2644 2.58818 83.4559 2.73849C83.6473 2.88879 83.426 3.09922 83.3413 3.2606C82.971 3.96782 81.7251 4.64656 80.9625 4.52632C80.8056 4.50101 80.1779 4.58644 80.6486 4.05167C80.771 3.91561 80.6957 3.86498 80.5671 3.80486C80.1936 3.62766 80.0273 3.31914 80.203 2.93784C80.3788 2.55654 80.7554 2.45053 81.1445 2.50591C81.5336 2.56129 81.8365 2.77171 81.8694 3.22104C81.8748 3.35607 81.8727 3.49129 81.8632 3.62607Z" fill="#0D3D62"/>
      <path d="M110.371 4.5486C109.931 4.5486 109.594 4.36665 109.495 3.91574C109.388 3.44109 109.641 3.12466 110.069 2.94905C110.498 2.77343 110.99 2.83355 111.296 2.34624C111.392 2.18803 111.794 2.36839 111.97 2.53294C112.145 2.69748 111.935 2.84937 111.827 2.98069C111.699 3.13391 111.527 3.24426 111.336 3.29712C111.152 3.34933 111.129 3.41578 111.179 3.62937C111.303 4.2116 110.973 4.55968 110.371 4.5486Z" fill="#0E4063"/>
      <path d="M82.1478 6.18719L80.5488 5.89607C80.8627 5.57173 81.1875 5.61761 81.5029 5.72203C81.8273 5.84991 82.1714 5.92004 82.5197 5.92929L82.1478 6.18719Z" fill="#FEFEFE"/>
      <path d="M39.4985 17.8384C41.1665 15.8338 44.0034 16.0379 45.0312 17.8146C45.7059 17.0235 46.5235 16.5252 47.5748 16.4302C49.7496 16.2309 51.0112 17.2767 51.0645 19.4743C51.1147 21.4884 51.0645 23.5056 51.0786 25.5197C51.0786 25.8361 51.0002 25.9627 50.655 25.9738C49.3542 26.0118 49.3542 26.026 49.3542 24.697C49.3542 23.1148 49.3542 21.5327 49.3542 19.9505C49.3542 18.4538 48.9682 17.9111 47.8761 17.8589C46.4215 17.7909 45.4266 18.5487 45.3435 19.8888C45.2729 21.0296 45.309 22.1782 45.3027 23.3237C45.3027 24.0546 45.2901 24.7872 45.3027 25.5181C45.3105 25.8346 45.2211 25.9611 44.8743 25.9706C43.6017 26.0038 43.6017 26.0165 43.6017 24.716C43.6017 23.0753 43.6159 21.4362 43.5923 19.7955C43.5751 18.5472 43.2785 18.063 42.5096 17.8763C41.5336 17.639 40.4054 18.1042 39.9143 18.9538C39.555 19.5724 39.4891 20.2622 39.4828 20.9552C39.4687 22.4361 39.4624 23.9186 39.4828 25.401C39.4906 25.8251 39.4059 25.9849 38.9414 25.9722C37.8336 25.9421 37.8321 25.9722 37.8321 24.8505C37.8321 22.3016 37.8415 19.7528 37.8242 17.2039C37.8242 16.7641 37.8995 16.571 38.3969 16.5948C39.3996 16.6343 39.3996 16.6027 39.4985 17.8384Z" fill="#FBFCFA"/>
      <path d="M10.12 20.7384C10.12 19.1974 10.1294 17.6548 10.1122 16.1137C10.1122 15.7593 10.1954 15.6391 10.5657 15.6391C11.9449 15.6233 11.9449 15.6075 11.9465 17.0077C11.9465 17.5804 11.9465 18.1531 11.9465 18.7275C11.9465 18.9221 11.9591 19.0835 12.2195 19.074C13.6317 19.0249 15.044 19.2053 16.4452 18.979C17.8025 18.7575 18.3501 18.0218 18.2858 16.6232C18.2309 15.4524 17.5012 14.7325 16.191 14.6898C14.3708 14.6329 12.5459 14.6376 10.7226 14.6582C10.2236 14.6582 10.0949 14.5174 10.1106 14.0253C10.1467 13.1456 10.1185 13.1456 10.9846 13.1456C12.7295 13.1456 14.4744 13.1456 16.2192 13.1456C16.8481 13.1319 17.4732 13.2471 18.0567 13.4842C19.585 14.1313 20.3758 15.6249 20.1107 17.3431C19.8267 19.1831 18.6733 20.3697 16.8924 20.4852C15.4802 20.577 14.068 20.5501 12.6557 20.5169C12.1066 20.5058 11.912 20.6213 11.9308 21.2178C11.9779 22.6417 11.9308 24.0656 11.9512 25.4896C11.9512 25.8408 11.8665 25.9642 11.4946 25.9642C10.1153 25.9895 10.1153 26.0054 10.1169 24.6431V20.7384H10.12Z" fill="#FAFCFA"/>
      <path d="M54.9984 24.9931C54.9984 25.8696 54.9812 26.6211 55.0063 27.3663C55.0172 27.735 54.8901 27.8299 54.5355 27.8315C53.354 27.8315 53.354 27.8504 53.354 26.6607C53.354 23.5391 53.3697 20.4159 53.3398 17.2927C53.3398 16.7516 53.4968 16.6282 53.9989 16.6504C54.9404 16.6915 54.9482 16.6615 55.0015 17.6661C55.1585 17.7468 55.2071 17.6028 55.2809 17.5316C57.5592 15.3593 61.1086 16.3703 61.832 19.4508C62.2855 21.3747 62.1803 23.2764 60.8905 24.9124C59.4908 26.6907 56.4797 26.6844 55.2918 25.2415C55.1995 25.1523 55.1015 25.0693 54.9984 24.9931Z" fill="#FAFCFA"/>
      <path d="M26.7188 21.4361C26.758 19.5375 27.2805 17.8905 29.0128 16.9111C31.3995 15.5647 35.6534 16.4665 35.8056 20.6703C35.8526 21.9566 35.6879 23.2017 35.021 24.3203C34.0497 25.942 32.0899 26.6572 29.9621 26.1825C28.2063 25.7901 26.9526 24.2744 26.769 22.3126C26.7344 22.0167 26.7329 21.7193 26.7188 21.4361Z" fill="#FAFCFA"/>
      <path d="M64.2682 21.2796C64.2682 20.3303 64.2494 19.381 64.2776 18.4317C64.2902 18.033 64.2039 17.8748 63.7708 17.8874C62.9611 17.9096 62.8042 17.6644 62.9643 16.8464C63.0333 16.4951 63.2781 16.6217 63.4664 16.6138C64.2698 16.5869 64.2698 16.5932 64.2682 15.7974C64.2682 15.6012 64.2682 15.4035 64.2682 15.2057C64.2682 13.959 64.2682 13.9779 65.5125 13.9859C65.9048 13.9859 65.9629 14.1441 65.9503 14.4747C65.9284 15.0253 65.9692 15.5822 65.9362 16.1313C65.9111 16.5363 66.0602 16.6613 66.4352 16.6059C66.6094 16.5837 66.7914 16.6265 66.9624 16.598C67.4504 16.5205 67.5226 16.7562 67.5477 17.1945C67.5791 17.7735 67.4002 17.9381 66.8337 17.9064C65.9488 17.859 65.9456 17.8954 65.9456 18.8019C65.9456 20.421 65.9456 22.0395 65.9456 23.6576C65.9456 24.5594 66.1119 24.7192 67.0048 24.735C67.2229 24.735 67.5901 24.5768 67.521 25.0388C67.4661 25.4058 67.8097 25.9881 67.0817 26.0419C66.6149 26.0893 66.1447 26.0925 65.6773 26.0513C64.8394 25.9533 64.3435 25.3758 64.3012 24.4059C64.2572 23.3617 64.2918 22.3143 64.2918 21.2685L64.2682 21.2796Z" fill="#FBFDFB"/>
      <path d="M22.7484 17.9161C23.0842 17.617 23.4059 17.3101 23.7495 17.0348C23.963 16.8618 24.1977 16.7171 24.4478 16.6045C24.906 16.4035 25.5023 16.2105 25.8459 16.5269C26.1597 16.8117 25.9275 17.4256 25.9385 17.8939C25.9385 18.1486 25.7533 18.0727 25.6011 18.0711C23.7966 18.0632 22.8426 19.0204 22.8394 20.8383C22.8394 22.3588 22.8269 23.8808 22.8504 25.4012C22.8583 25.8268 22.7673 25.9882 22.3075 25.9787C21.1871 25.955 21.1871 25.9787 21.1871 24.8712C21.1871 22.3034 21.1981 19.734 21.1777 17.1661C21.1777 16.7215 21.2907 16.5934 21.7348 16.6076C22.7296 16.6361 22.7312 16.6076 22.7484 17.636C22.75 17.7436 22.7484 17.8528 22.7484 17.9161Z" fill="#F9FCF8"/>
      <path d="M76.5234 16.6405C76.5234 15.9111 76.5344 15.1818 76.5234 14.4508C76.5234 14.1597 76.5846 14.0173 76.9079 14.0236C78.0816 14.0474 79.2616 13.9952 80.4274 14.079C82.0373 14.1945 82.7811 15.071 82.7481 16.6737C82.7152 18.2765 81.9055 19.2258 80.3474 19.3049C79.1957 19.3618 78.0392 19.3128 76.8859 19.327C76.5972 19.327 76.5203 19.2242 76.525 18.9489C76.5407 18.18 76.525 17.411 76.525 16.6405H76.5234Z" fill="#FDFDFD"/>
      <path d="M96.1553 21.1149C96.1328 21.528 96.0872 21.9394 96.0187 22.3474C95.6767 23.9295 93.5944 24.9706 92.0959 24.3156C91.4871 24.0482 91.2329 23.5577 91.2141 22.9059C91.1921 22.1812 91.4761 21.6401 92.121 21.3158C92.7659 20.9915 93.5066 20.9123 94.2158 20.7731C94.696 20.6798 95.195 20.6355 95.628 20.4013C96.0611 20.1672 96.1615 20.3444 96.1192 20.7573C96.1082 20.8744 96.1192 20.9946 96.1192 21.1117L96.1553 21.1149Z" fill="#FDFDFD"/>
      <path d="M73.736 5.82624C73.7203 6.00027 73.645 6.12052 73.4567 6.12052C73.3909 6.11709 73.3286 6.08966 73.2814 6.04333C73.2341 5.997 73.2052 5.93493 73.2001 5.86871C73.1949 5.80249 73.2138 5.73662 73.2532 5.68339C73.2926 5.63017 73.3499 5.59321 73.4143 5.57942C73.4518 5.57192 73.4904 5.57254 73.5277 5.58122C73.5649 5.5899 73.5999 5.60646 73.6303 5.62979C73.6607 5.65312 73.6858 5.6827 73.7041 5.71657C73.7223 5.75043 73.7332 5.78781 73.736 5.82624Z" fill="#FEFEFE"/>
      <path d="M101.54 6.1222C101.501 6.12104 101.463 6.11165 101.428 6.09465C101.394 6.07764 101.363 6.05341 101.338 6.02353C101.313 5.99365 101.295 5.95881 101.284 5.92128C101.273 5.88375 101.271 5.84439 101.276 5.80577C101.278 5.77118 101.286 5.73727 101.301 5.70619C101.317 5.67511 101.338 5.64754 101.364 5.62523C101.39 5.60292 101.421 5.58635 101.454 5.57657C101.487 5.5668 101.522 5.56403 101.556 5.56845C101.595 5.56581 101.635 5.57267 101.672 5.58846C101.709 5.60425 101.741 5.62854 101.767 5.65935C101.793 5.69016 101.811 5.72663 101.82 5.76581C101.829 5.80499 101.829 5.84578 101.819 5.88488C101.811 5.95268 101.778 6.01483 101.726 6.05885C101.674 6.10287 101.608 6.1255 101.54 6.1222Z" fill="#FEFEFE"/>
      <path d="M91.8405 5.84463C91.8075 6.0155 91.7197 6.12151 91.569 6.1136C91.5306 6.11532 91.4923 6.10875 91.4566 6.09432C91.4209 6.07989 91.3886 6.05792 91.362 6.02991C91.3354 6.00189 91.3151 5.96847 91.3023 5.93187C91.2896 5.89528 91.2848 5.85636 91.2882 5.81773C91.2889 5.78351 91.2966 5.7498 91.3106 5.71864C91.3247 5.68748 91.3449 5.65951 91.3699 5.63644C91.395 5.61337 91.4245 5.59567 91.4566 5.58441C91.4886 5.57314 91.5226 5.56855 91.5565 5.57092C91.7448 5.56617 91.8154 5.69591 91.8405 5.84463Z" fill="#FEFEFE"/>
      <path d="M91.457 8.62522C91.3174 8.56826 91.2217 8.47808 91.264 8.30879C91.3001 8.16956 91.3833 8.0414 91.5371 8.04298C91.7489 8.04298 91.694 8.2281 91.694 8.3515C91.694 8.47491 91.6469 8.63155 91.457 8.62522Z" fill="#FEFEFE"/>
      <path d="M85.5782 6.38051C85.6623 6.24726 85.7573 6.12131 85.8622 6.00395C86.278 5.61158 86.777 5.57203 87.2869 5.74923C87.742 5.90744 87.7796 6.31247 87.7718 6.727C87.7718 7.02286 87.7718 7.32031 87.7718 7.61617C87.7763 7.80684 87.7304 7.99529 87.6388 8.1622C87.5473 8.32911 87.4134 8.46848 87.2509 8.56604C87.0883 8.6636 86.903 8.71584 86.7138 8.71741C86.5247 8.71897 86.3385 8.66981 86.1744 8.57495C85.9469 8.45629 85.983 8.22213 86.1509 8.05126C86.2089 7.99272 86.3361 8.00696 86.4302 7.9769C86.846 7.84875 87.1457 7.60351 87.083 7.12887C87.0233 6.67795 86.6875 6.46594 86.2686 6.40107C86.0366 6.37779 85.8035 6.36723 85.5703 6.36943L85.5782 6.38051Z" fill="#FEFEFE"/>
      <path d="M86.1149 6.97104C86.1487 6.96641 86.1831 6.96881 86.2159 6.97808C86.2487 6.98735 86.2793 7.00329 86.3057 7.02494C86.3322 7.04658 86.354 7.07346 86.3697 7.10392C86.3855 7.13438 86.3949 7.16777 86.3974 7.20204C86.4058 7.23559 86.4064 7.27064 86.3993 7.30449C86.3922 7.33834 86.3774 7.37009 86.3562 7.39729C86.335 7.4245 86.3079 7.44643 86.2769 7.4614C86.246 7.47637 86.212 7.48399 86.1777 7.48366C86.002 7.49632 85.8639 7.42196 85.8639 7.22893C85.8545 7.07072 85.958 6.98528 86.1149 6.97104Z" fill="#FEFEFE"/>
      <path d="M108.89 5.86009C108.887 5.93017 108.857 5.99632 108.806 6.04427C108.755 6.09221 108.688 6.11812 108.618 6.1164C108.461 6.1164 108.315 6.01514 108.336 5.82212C108.337 5.78776 108.345 5.75395 108.359 5.72265C108.373 5.69134 108.393 5.66317 108.418 5.63977C108.443 5.61636 108.472 5.59819 108.504 5.58631C108.536 5.57443 108.57 5.56907 108.604 5.57055C108.793 5.57214 108.876 5.69238 108.89 5.86009Z" fill="#FEFEFE"/>
      <path d="M81.7302 8.778C81.5325 8.75585 81.443 8.66883 81.4462 8.50587C81.4432 8.47143 81.4481 8.43676 81.4606 8.40456C81.4731 8.37236 81.4928 8.34351 81.5181 8.32024C81.5435 8.29696 81.5738 8.27991 81.6068 8.27039C81.6398 8.26088 81.6745 8.25916 81.7082 8.26538C81.7424 8.26325 81.7767 8.26853 81.8087 8.28087C81.8407 8.2932 81.8697 8.3123 81.8938 8.33688C81.9179 8.36146 81.9364 8.39095 81.9483 8.42337C81.9601 8.4558 81.9649 8.4904 81.9624 8.52485C81.9608 8.69256 81.8322 8.74319 81.7302 8.778Z" fill="#FEFEFE"/>
      <path d="M98.2893 8.77339C98.2554 8.77557 98.2214 8.77066 98.1895 8.75896C98.1576 8.74726 98.1284 8.72902 98.1038 8.7054C98.0792 8.68177 98.0597 8.65326 98.0465 8.62167C98.0333 8.59008 98.0268 8.55609 98.0273 8.52182C98.0229 8.48744 98.0262 8.45249 98.0371 8.41959C98.0479 8.38669 98.066 8.35671 98.09 8.33189C98.1141 8.30707 98.1433 8.28806 98.1757 8.27629C98.208 8.26452 98.2426 8.26029 98.2768 8.26393C98.3106 8.2592 98.3449 8.26207 98.3775 8.27234C98.41 8.28261 98.4399 8.30002 98.4649 8.32332C98.49 8.34662 98.5096 8.37523 98.5224 8.40709C98.5352 8.43895 98.5408 8.47327 98.5388 8.50759C98.5415 8.54196 98.5369 8.57651 98.5255 8.60902C98.5141 8.64152 98.4961 8.67125 98.4726 8.69629C98.4491 8.72133 98.4206 8.74113 98.3891 8.75441C98.3575 8.76769 98.3235 8.77415 98.2893 8.77339Z" fill="#FEFEFE"/>
      <path d="M81.2134 3.15718C81.1946 3.31539 81.1208 3.39766 80.9906 3.40241C80.9624 3.4075 80.9334 3.40629 80.9057 3.39886C80.878 3.39143 80.8522 3.37796 80.8302 3.35941C80.8082 3.34085 80.7905 3.31766 80.7784 3.29147C80.7662 3.26528 80.76 3.23672 80.7599 3.20781C80.7557 3.17915 80.7578 3.1499 80.7662 3.12219C80.7746 3.09447 80.789 3.069 80.8084 3.04762C80.8278 3.02624 80.8517 3.0095 80.8783 2.99862C80.905 2.98774 80.9337 2.98299 80.9624 2.98472C81.0163 2.98558 81.0689 3.00237 81.1135 3.03302C81.1581 3.06367 81.1929 3.10683 81.2134 3.15718Z" fill="#FEFEFE"/>
      <path d="M110.353 4.02791C110.328 4.02873 110.303 4.02411 110.28 4.01434C110.257 4.00457 110.236 3.98989 110.219 3.9713C110.202 3.9527 110.189 3.93061 110.181 3.90653C110.173 3.88245 110.17 3.85694 110.173 3.83172C110.174 3.8074 110.179 3.78349 110.189 3.7614C110.2 3.73931 110.214 3.7195 110.232 3.70312C110.25 3.68675 110.271 3.67415 110.293 3.66607C110.316 3.658 110.34 3.6546 110.364 3.6561C110.393 3.65038 110.422 3.6512 110.45 3.65849C110.478 3.66578 110.504 3.67935 110.527 3.6982C110.549 3.71705 110.566 3.74069 110.578 3.76736C110.59 3.79404 110.596 3.82306 110.595 3.85229C110.601 3.95354 110.518 4.02316 110.353 4.02791Z" fill="#FEFEFE"/>
      <path d="M60.3428 21.2242C60.3067 22.0675 60.2518 22.8728 59.8297 23.5975C59.5699 24.0677 59.1704 24.4439 58.6873 24.673C58.2042 24.902 57.662 24.9724 57.1371 24.8743C56.1125 24.716 55.353 24.0025 55.1082 22.9156C54.827 21.7568 54.8903 20.5402 55.2902 19.4174C55.6966 18.3004 56.5063 17.7641 57.6784 17.7736C58.8506 17.7831 59.6351 18.3495 60.0525 19.4491C60.2435 20.0212 60.3415 20.6206 60.3428 21.2242Z" fill="#003D69"/>
      <path d="M34.0604 21.1092C34.0904 21.9251 33.9342 22.737 33.6038 23.4824C33.2177 24.4048 32.5211 24.8446 31.5639 24.9064C30.5487 24.9791 29.6511 24.7608 29.0533 23.8447C28.1306 22.4445 28.2264 19.8893 29.273 18.5746C29.954 17.7234 30.8939 17.6253 31.8903 17.7613C32.8317 17.8943 33.4594 18.4385 33.803 19.3134C34.0216 19.8849 34.1096 20.4987 34.0604 21.1092Z" fill="#003D69"/>
      </g>
    </svg>
  )
}
// eslint-enable
export default Logo
