import React from 'react'
import styled from 'styled-components'

import { config } from 'config'
import Menu from 'pages/account/components/Menu/Menu'
import { Text } from 'components/typo'
import { MetaData } from 'components/display'

const AboutApp = () => {
  return (
    <Menu>
      <MetaData title="เกี่ยวกับฟรายเดย์" description="เกี่ยวกับฟรายเดย์" />
      <Content>
        <Title>
          <Text size="large" title bold>
            เกี่ยวกับ
          </Text>
        </Title>
        <Text size="small" gray>
          เวอร์ชั่น {config.version}
        </Text>
      </Content>
    </Menu>
  )
}

const Content = styled.div`
  width: 524px;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

const Title = styled.div`
  margin: 16px 0;
`

export default AboutApp
