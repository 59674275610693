import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import { ThemeProvider } from 'styled-components'
import TagManager from 'react-gtm-module'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import 'assets'
import ScrollToTop from 'components/ScrollToTop'
import { theme } from 'definition'
import store from 'stores'
import { history } from 'utils/history'
import { config } from 'config'

import App from './App'

TagManager.initialize({ gtmId: config.tag_manager })

ReactDOM.render(
  <Provider {...store}>
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <ScrollToTop>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </ScrollToTop>
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)
