import React from 'react'
import styled from 'styled-components'

import { format } from 'utils'

import { Text } from '../typo'

function PackagePaymentButton(props) {
  return (
    <PackageInfo>
      <label>แพ็กเกจที่คุณเลือก</label>
      <PackageName>
        แพ็กเกจ{props.info.name}
        <Text bold xsmall onClick={props.onClick}>
          เปลี่ยน
        </Text>
      </PackageName>
      {props.credit !== 0 ? (
        <PackagePrice color>
          ฿ {format.toDigi(props.info.price - props.credit)} บาท
          <span>/{props.info.unit}</span>
        </PackagePrice>
      ) : (
        <PackagePrice>
          ฿ {format.toDigi(props.info.price)} บาท<span>/{props.info.unit}</span>
        </PackagePrice>
      )}
    </PackageInfo>
  )
}

const PackageInfo = styled.div`
  padding: 16px 24px;
  text-align: left;
  border: 1px solid #d9dbe9;
  border-radius: 16px;

  label {
    color: #6e7191;
    font-size: 14px;
  }
`

const PackageName = styled.div`
  width: 100%;
  color: #14142b;
  font-size: 16px;
  padding-top: 16px;

  span {
    color: #5f2eea;
    float: right;
    cursor: pointer;
  }
`

const PackagePrice = styled.div`
  width: 100%;
  font-size: 24px;
  color: ${(props) => (props.color ? '#00966D' : '#14142b')};

  font-weight: bold;

  span {
    font-size: 16px;
  }
`

export default PackagePaymentButton
