import React, { useEffect } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import Display from 'components/typo/Display'
import Text from 'components/typo/Text'
import { MetaData, HtmlContent } from 'components/display'

const Term = (props) => {
  useEffect(() => {
    props.app_config.getTermsConsent()
  }, [props.app_config])

  const content = props.app_config?.terms?.content
  return (
    <Wrapper>
      <MetaData
        title={'เงื่อนไขการใช้บริการ'}
        description={'เงื่อนไขการใช้บริการ'}
      />
      <Display size="small" bold>
        เงื่อนไขการใช้บริการ
      </Display>
      <Text size="small">
        <HtmlContent content={content} />
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 768px;
  width: 100%;
  height: 100%;

  padding: 64px 0;
  row-gap: 24px;

  @media screen and (max-width: 1024px) {
    max-width: 674px;
  }

  @media screen and (max-width: 768px) {
    padding: 64px 35px;
  }
`

export default inject('app_config')(observer(Term))
