import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import Text from 'components/typo/Text'
import { Check } from 'icon'
import Button from 'components/button/Button'
import Layout from 'pages/account/components/Layout'
import { MetaData } from 'components/display'

const SelectPackage = () => {
  const history = useHistory()

  const onGotoPackageDetail = () => {
    history.push('/account/package-detail')
  }

  return (
    <Layout header="เลือกแพ็กเกจสำหรับใช้งาน" subHeader="ขั้นตอนที่ 3 จาก 4">
      <MetaData
        title="เลือกแพ็กเกจสำหรับใช้งาน"
        description="เลือกแพ็กเกจสำหรับใช้งาน"
      />
      <Body>
        <Info>
          <InfoRow>
            <Check />
            <Text>ไม่มีข้อผูกมัด</Text>
          </InfoRow>
          <InfoRow>
            <Check />
            <Text>ราคาคุ้มค่า</Text>
          </InfoRow>
          <InfoRow>
            <Check />
            <Text>ไม่มีค่าธรรมเนียมเพิ่มเติม</Text>
          </InfoRow>
        </Info>
        <Button
          large
          color="primary"
          text="ถัดไป"
          onClick={onGotoPackageDetail}
        />
      </Body>
    </Layout>
  )
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 325px;
  row-gap: 52px;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: 16px;
  color: ${(props) => props.theme.gray.body};
`

const InfoRow = styled.div`
  display: flex;
  align-items: center;

  column-gap: 8px;
`

export default SelectPackage
