import { Carousel, Col, Modal, Row } from 'antd'
import { useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import IconButton from 'components/button/IconButton'
import { Back, Close } from 'icon'

import salekit01 from './assets/Slide_SaleKit2024-01.png'
import salekit02 from './assets/Slide_SaleKit2024-02.png'
import salekit03 from './assets/Slide_SaleKit2024-03.png'
import salekit04 from './assets/Slide_SaleKit2024-04.png'
import salekit05 from './assets/Slide_SaleKit2024-05.png'
import salekit06 from './assets/Slide_SaleKit2024-06.png'
import salekit07 from './assets/Slide_SaleKit2024-07.png'
import salekit08 from './assets/Slide_SaleKit2024-08.png'
import salekit09 from './assets/Slide_SaleKit2024-09.png'
import salekit10 from './assets/Slide_SaleKit2024-10.png'
import salekit11 from './assets/Slide_SaleKit2024-11.png'
import salekit12 from './assets/Slide_SaleKit2024-12.png'
import salekit13 from './assets/Slide_SaleKit2024-13.png'
import { ReactComponent as PDF } from './assets/pdf.svg'

const IMAGE_LIST = [
  salekit01,
  salekit02,
  salekit03,
  salekit04,
  salekit05,
  salekit06,
  salekit07,
  salekit08,
  salekit09,
  salekit10,
  salekit11,
  salekit12,
  salekit13,
]

const SaleKit = () => {
  const [isOpen, setOpen] = useState(false)
  const [index, setIndex] = useState(0)

  let sliderRef1 = useRef(null)
  let sliderRef2 = useRef(null)

  const closeModal = () => setOpen(false)

  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    initialSlide: index,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  useLayoutEffect(() => {
    setTimeout(() => {
      setOpen(true)
    }, 1000)
  }, [])

  return (
    <StyledModal width={800} visible={isOpen} footer={null} closable={false}>
      <Header>
        <Row align="middle" justify="center" gutter={[12, 12]}>
          <Col>
            <span>{`${index + 1}/13`}</span>
          </Col>
          <a href="https://bit.ly/3Bm4HQ3" target="__blank">
            <PdfContainer>
              <PDF />
              ดาวน์โหลด
            </PdfContainer>
          </a>
        </Row>
        <IconButton onClick={closeModal}>
          <Close />
        </IconButton>
      </Header>
      <ImageSlider
        {...settings}
        afterChange={(currentSlide) => {
          setIndex(currentSlide)
          sliderRef2.goTo(currentSlide)
        }}
        ref={(slider) => {
          sliderRef1 = slider
        }}
        slidesToShow={1}
        prevArrow={
          <LeftArrow>
            <Back color="#ffffff" />
          </LeftArrow>
        }
        nextArrow={
          <RightArrow>
            <Back color="#ffffff" />
          </RightArrow>
        }
      >
        {IMAGE_LIST.map((l, idx) => (
          <div key={idx}>
            <Image
              src={l}
              alt=""
              onClick={() => {
                sliderRef1.goTo(idx)
              }}
            />
          </div>
        ))}
      </ImageSlider>
      <ImagePaging
        {...settings}
        afterChange={() => {}}
        ref={(slider) => (sliderRef2 = slider)}
        slidesToShow={7}
        focusOnSelect={true}
        prevArrow={
          <CustomLeftArrow>
            <Back color="#4E4B66" />
          </CustomLeftArrow>
        }
        nextArrow={
          <CustomRightArrow>
            <Back color="#4E4B66" />
          </CustomRightArrow>
        }
        responsive={[
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 3,
            },
          },
        ]}
      >
        {IMAGE_LIST.map((l, idx) => (
          <div key={idx}>
            <Image
              src={l}
              alt=""
              onClick={() => {
                sliderRef1.goTo(idx)
              }}
            />
          </div>
        ))}
      </ImagePaging>
    </StyledModal>
  )
}

export default SaleKit

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 0 24px;
  font-size: 14px;
  line-height: 24px;
  color: #a0a3bd;
`

const PdfContainer = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #5f2eea;
`

const ImageSlider = styled(Carousel)``

const ArrowContainer = styled.div`
  position: absolute !important;
  z-index: 1 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 56px !important;
  height: 56px !important;
  background-color: #5f2eea33 !important;
  border-radius: 50% !important;
`

const LeftArrow = styled(ArrowContainer)`
  left: 32px !important;

  @media screen and (max-width: 465px) {
    left: 16px !important;
  }
`

const RightArrow = styled(ArrowContainer)`
  right: 32px !important;
  svg {
    transform: rotate(180deg);
  }

  @media screen and (max-width: 465px) {
    right: 16px !important;
  }
`

const ImagePaging = styled(Carousel)`
  padding: 16px 64px;
  margin-bottom: 10px;

  .slick-list .slick-slide > div > div {
    padding: 0 4px;
  }

  @media screen and (max-width: 465px) {
    padding: 16px 40px;
  }
`

const CustomArrowContainer = styled.div`
  position: absolute !important;
  z-index: 1 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 24px !important;
  height: 24px !important;
`

const CustomLeftArrow = styled(CustomArrowContainer)`
  left: 32px !important;

  @media screen and (max-width: 465px) {
    left: 8px !important;
  }
`

const CustomRightArrow = styled(CustomArrowContainer)`
  right: 32px !important;
  svg {
    transform: rotate(180deg);
  }

  @media screen and (max-width: 465px) {
    right: 8px !important;
  }
`

const Image = styled.img`
  width: 100%;
`
