import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { useHistory, useParams } from 'react-router-dom'

import { Text } from 'components/typo'
import { Button } from 'components/button'
import { format, message } from 'utils'

import AccountLayout from '../../../components/AccountLayout'
import PaymentMethod from '../method'

const PaymentInfo = (props) => {
  const [method, setMethod] = useState('card')
  const [visible, setVisible] = useState(false)

  const history = useHistory()
  const { packageId, period } = useParams()
  const { user_card } = props.payment.toJS()

  const onPayment = () => {
    const path = `/account/my-payment/change-package/payment/${packageId}/${period}`
    const link = method === 'card' ? `${path}/with-card` : `${path}/with-qr`
    history.push(link)
  }

  useEffect(() => {
    // const typ = user_card ? 'card' : 'qrcode'
    setMethod('card')
  }, [user_card])

  const onGetPaymentInfo = async (id, p) => {
    try {
      await Promise.all([
        props.payment.getChangePackage(id, p),
        props.payment.getMyPlan(),
      ])
    } catch (e) {
      message.error({ message: e.message })
    }
  }

  useEffect(() => {
    onGetPaymentInfo(packageId, period)
  }, [packageId, period])

  const getCurrentMethod = () => {
    return method === 'card'
      ? 'ตอนนี้คุณชำระค่าบริการการใช้งานด้วยบัตรเครดิต'
      : 'ตอนนี้คุณชำระค่าบริการการใช้งานด้วยคิวอาร์โค้ด/ พร้อมเพย์'
  }

  const onCreditCard = () => {
    setMethod('card')
    setVisible(false)
  }

  const onQRCode = () => {
    setMethod('qrcode')
    setVisible(false)
  }

  if (visible) {
    return (
      <PaymentMethod
        title="เปลี่ยนแพ็กเกจการใช้งาน"
        description="เปลี่ยนแพ็กเกจการใช้งาน - เลือกวิธีการชำระเงิน"
        back={`/account/my-payment/change-package/payment/${packageId}/${period}/info`}
        backText="ยอดชำระ"
        onBack={() => setVisible(false)}
        onCreditCard={onCreditCard}
        onQRCode={onQRCode}
      />
    )
  }

  const { change_package: cp } = props.payment.toJS()

  const infos = [
    { label: 'แพ็กเกจเดิม', value: format.toDigi(cp.current_package_price) },
    { label: 'แพ็กเกจใหม่', value: format.toDigi(cp.new_package_price) },
    {
      label: 'ส่วนต่างที่ต้องชำระเพิ่ม',
      value: format.toDigi(cp.new_package_price - cp.current_package_price),
    },
    {
      label: 'ส่วนลดจากเครดิตคงเหลือ',
      value: format.toDigi(cp.used_credit),
      color: { success: true },
    },
    {
      label: 'ยอดที่ต้องชำระ',
      value: format.toDigi(cp.total_payment),
      color: { error: true },
    },
  ]

  return (
    <AccountLayout
      title="เปลี่ยนแพ็กเกจการใช้งาน"
      description="เปลี่ยนแพ็กเกจการใช้งาน"
      backTo="/account/my-payment/change-package"
      backText="เปลี่ยนแพ็กเกจการใช้งาน"
    >
      <Text size="large" bold>
        ยอดชำระ
      </Text>
      <InfoList>
        {infos.map((info) => (
          <InfoListItem key={info.label}>
            <Text color="gray">{info.label}</Text>
            <Text {...info.color}>฿ {info.value}</Text>
          </InfoListItem>
        ))}
      </InfoList>
      <Method>{getCurrentMethod()}</Method>
      <ButtonBox>
        <Button
          color="primary"
          maxWidth="325px"
          disabled={cp.total_payment <= 0}
          onClick={onPayment}
        >
          ชำระเงิน
        </Button>
        <Button color="text" maxWidth="325px" onClick={() => setVisible(true)}>
          เปลี่ยนวิธีการชำระเงิน
        </Button>
      </ButtonBox>
    </AccountLayout>
  )
}

const InfoList = styled.div`
  width: 100%;
  max-width: 325px;
  margin: 39px 0px 26px 0px;
`

const InfoListItem = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
`

const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: 16px;
  width: 100%;
  max-width: 325px;
  margin-top: 55px;
`

const Method = styled.div`
  color: #4e4b66;
  font-size: 16px;
`

export default inject('payment')(observer(PaymentInfo))
