import { Switch, Route } from 'react-router-dom'

import Profile from 'pages/account/profile'
import PackageDetail from 'pages/account/package/PackageDetail'
import SelectPackage from 'pages/account/package/SelectPackage'
import PaymentMethod from 'pages/account/payment'
import PaymentCard from 'pages/account/payment/Card'
import PaymentPromptPay from 'pages/account/payment/PromptPay'
import PaymentResult from 'pages/account/payment/Result'
import SetPassword from 'pages/account/setPassword'
import MyConsent from 'pages/account/consent'
import AboutApp from 'pages/account/about'
import CommonProblem from 'pages/account/commonProblem'
import IssueProblem from 'pages/account/issueProblem'
import IssueProblemCreator from 'pages/account/issueProblem/creator'
import IssueProblemDetail from 'pages/account/issueProblem/detail'
import RatingApp from 'pages/account/rating'
import MyPayment from 'pages/account/myPayment'
import MyPrivacy from 'pages/account/privacy'
import MyConnection from 'pages/account/connection'
import SetEmail from 'pages/account/setEmail'
import ConfirmEmail from 'pages/account/confirmEmail'
import AddEmail from 'pages/account/addEmail'
import SetMobile from 'pages/account/setMobile'
import DeleteAccount from 'pages/account/deleteAccount'
import MyPaymentOption from 'pages/account/myPayment/myPaymentOption'
import MyPaymentCard from 'pages/account/myPayment/myPaymentCard'
import MyPaymentPrompay from 'pages/account/myPayment/myPaymentPrompay'
import MyPaymentHistory from 'pages/account/myPayment/myPaymentHistory'
import MyTaxInvoice from 'pages/account/myPayment/myTaxInvoice'
import ChangePackage from 'pages/account/myPayment/changePackage'
import ChangePackageCardPayment from 'pages/account/myPayment/changePackage/payment/CardPayment'
import ChangePackagePaymentInfo from 'pages/account/myPayment/changePackage/paymentInfo'
import ChangePackageResult from 'pages/account/myPayment/changePackage/result'
import Promotion from 'pages/account/promotion'
import Paynow from 'pages/account/paynow'
import PaynowCard from 'pages/account/paynow/paymentCard'
import PaynowPromptPay from 'pages/account/paynow/paymentQRCode'
import PaynowPromptPayInfo from 'pages/account/paynow/paymentQRCode/PromptPay'
import PaynowResult from 'pages/account/paynow/result'

import ChangePackagePromptpay from './pages/account/myPayment/changePackage/payment/PromptPay'

const UserApp = () => {
  return (
    <Switch>
      <Route exact path="/account/package-detail" component={PackageDetail} />
      <Route exact path="/account/select-package" component={SelectPackage} />
      <Route
        exact
        path="/account/select-package/table"
        component={PackageDetail}
      />
      <Route exact path="/account/payment" component={PaymentMethod} />
      <Route exact path="/account/payment-card" component={PaymentCard} />
      <Route
        exact
        path="/account/payment-promptpay"
        component={PaymentPromptPay}
      />
      <Route exact path="/account/payment-result" component={PaymentResult} />
      <Route
        exact
        path="/account/payment-result/:code"
        component={PaymentResult}
      />
      <Route exact path="/account/profile" component={Profile} />
      <Route exact path="/account/set-password" component={SetPassword} />
      <Route
        exact
        path="/account/consent/:consent_type"
        component={MyConsent}
      />
      <Route exact path="/account/common-problem" component={CommonProblem} />
      <Route exact path="/account/issue-problem" component={IssueProblem} />
      <Route
        exact
        path="/account/issue-problem/creator"
        component={IssueProblemCreator}
      />
      <Route
        exact
        path="/account/issue-problem/detail/:issue_problem_id"
        component={IssueProblemDetail}
      />
      <Route exact path="/account/my-payment" component={MyPayment} />

      <Route
        exact
        path="/account/my-payment/change-package"
        component={ChangePackage}
      />
      <Route
        exact
        path="/account/my-payment/change-package/payment/:packageId/:period/info"
        component={ChangePackagePaymentInfo}
      />
      <Route
        exact
        path="/account/my-payment/change-package/payment/:packageId/:period/with-card"
        component={ChangePackageCardPayment}
      />
      <Route
        exact
        path="/account/my-payment/change-package/payment/:packageId/:period/with-qr"
        component={ChangePackagePromptpay}
      />
      <Route
        exact
        path="/account/my-payment/change-package/result/:code"
        component={ChangePackageResult}
      />
      <Route
        exact
        path="/account/my-payment/payment-option"
        component={MyPaymentOption}
      />
      <Route
        exact
        path="/account/my-payment/promptpay"
        component={MyPaymentPrompay}
      />
      <Route
        exact
        path="/account/my-payment/history"
        component={MyPaymentHistory}
      />
      <Route
        exact
        path="/account/my-payment/card/:code"
        component={MyPaymentCard}
      />
      <Route
        exact
        path="/account/my-payment/tax-invoice"
        component={MyTaxInvoice}
      />

      <Route exact path="/account/my-payment/card" component={MyPaymentCard} />
      <Route exact path="/account/delete-account" component={DeleteAccount} />
      <Route exact path="/account/about" component={AboutApp} />
      <Route exact path="/account/rating" component={RatingApp} />
      <Route exact path="/account/privacy" component={MyPrivacy} />
      <Route exact path="/account/connection" component={MyConnection} />
      <Route exact path="/account/set-email" component={SetEmail} />
      <Route exact path="/account/add-email" component={AddEmail} />
      <Route
        exact
        path="/account/confirm-email/:ref_code/:code"
        component={ConfirmEmail}
      />

      <Route exact path="/account/set-mobile" component={SetMobile} />
      <Route exact path="/account/promotion" component={Promotion} />
      <Route exact path="/account/my-payment/paynow" component={Paynow} />
      <Route
        exact
        path="/account/my-payment/paynow/card"
        component={PaynowCard}
      />
      <Route
        exact
        path="/account/my-payment/paynow/qrcode"
        component={PaynowPromptPay}
      />
      <Route
        exact
        path="/account/my-payment/paynow/qrcode/info"
        component={PaynowPromptPayInfo}
      />
      <Route
        exact
        path="/account/my-payment/paynow/result/:code"
        component={PaynowResult}
      />
      <Route exact path="/account/my-payment/promotion" component={Promotion} />
    </Switch>
  )
}

export default UserApp
