const config = {
  mode: 'localhost',
  firebase: {
    apiKey: 'AIzaSyCJyNQte79aUJvJQo-OK38fUyl6z2zEIcQ',
    authDomain: 'friday-develop.firebaseapp.com',
    projectId: 'friday-develop',
    storageBucket: 'friday-develop.appspot.com',
    messagingSenderId: '17124439675',
    appId: '1:17124439675:web:5d7c76c0cffb73dfad54de',
    measurementId: 'G-DF17Z59P86',
  },
  api: 'https://friday.hlo.app/api',
  // api: 'http://localhost:3000/api',
  version: '0.0.1',
  key: 'key-friday',
  tag_manager: 'GTM-P3TJTGC',
  pre_register: false,
  payment_url:
    'https://demo2.2c2p.com/2C2PFrontEnd/SecurePayment/api/my2c2p-sandbox.1.7.3.min.js',
  app_url: 'https://fridaydev.page.link/home',
}

module.exports = config
