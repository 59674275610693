import React from 'react'

import FieldInput from 'components/input/FieldInput'
import { Find } from 'icon'

const FileInput = (props) => {
  return (
    <FieldInput small label="ค้นหา" {...props} startIcon={<Find />} clearable />
  )
}

export default FileInput
