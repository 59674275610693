import React from 'react'
import styled from 'styled-components'

import Dialog from 'dialog'
import { Text } from 'components/typo'
import { Button } from 'components/button'
import { format } from 'utils'

const CancelDialog = (props) => {
  const { user = {}, visible, onClose, onConfirm } = props
  const date = format.toFullDT(user.expired_at)

  return (
    <Dialog width="840px" visible={visible} onClose={onClose}>
      <Content>
        <Title>คุณต้องการยกเลิกการเป็นสมาชิกใช่หรือไม่</Title>
        <div>
          <Text size="small">
            คุณยังสามารถเข้าถึงข้อมูลได้จนถึงวันที่ {date}
          </Text>
        </div>
        <div>
          <Text size="small">
            คุณสามารถกลับมาต่ออายุได้ภายในวันที่ {date}{' '}
            เพื่อรักษาข้อมูลของคุณไว้
          </Text>
        </div>
        <Warning>ข้อมูลทั้งหมดของคุณจะถูกลบแบบถาวรในวันที่ {date}</Warning>
        <AreaBtn>
          <Button color="primary" text="กลับ" onClick={onClose} />
        </AreaBtn>
        <Confirm onClick={onConfirm}>ยกเลิกการเป็นสมาชิก</Confirm>
      </Content>
    </Dialog>
  )
}

const Content = styled.div`
  text-align: center;
  background-color: white;
`

const Title = styled.div`
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: bold;
`

const Warning = styled.div`
  margin-top: 34px;
  color: #ed2e7e;
  font-size: 16px;
`

const AreaBtn = styled.div`
  margin: 0 auto;
  padding: 24px 0;
  width: 220px;
`

const Confirm = styled.span`
  margin: 0 auto;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`

export default CancelDialog
