import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

const Messager = (props) => {
  const { messager } = props.messager.toJS()

  const css = messager ? messager.type : 'none'
  const text = messager ? messager.message : ''
  return <PageView className={css}>{text}</PageView>
}

const PageView = styled.div`
  font-size: 16px;
  text-align: center;
  color: white;
  height: 30px;
  width: 100%;
  position: fixed;
  top: 70px;
  padding-top: 3px;
  z-index: 1000;

  &.none {
    display: none;
  }

  &.success {
    background-color: ${(p) => p.theme.color.success};
  }

  &.error {
    background-color: ${(p) => p.theme.color.error};
  }
`

export default inject('messager')(observer(Messager))
