import { useMemo, useState } from 'react'
import styled from 'styled-components'

// import { Button } from 'components/button'
import Text from 'components/typo/Text'
import Dialog from 'dialog'
import useMediaQuery from 'hooks/useMediaQuery'

import AboutUs from './AboutUs'
import IconText from './IconText'
import callIcon from './assets/callIcon.svg'
import emailIcon from './assets/emailIcon.svg'
import gspIcon from './assets/gpsIcon.svg'
import map from './assets/map.png'

const ContactUs = () => {
  const [visible, setVisible] = useState(false)
  const isTablet = useMediaQuery('(max-width:990px)')

  const addressText = useMemo(() => {
    if (isTablet) {
      return (
        <>
          9 ซอยลาดปลาเค้า 14 แขวงจรเข้บัว
          <br />
          เขตลาดพร้าว กรุงเทพมหานคร 10230
        </>
      )
    }

    return '9 ซอยลาดปลาเค้า 14 แขวงจรเข้บัว เขตลาดพร้าว กรุงเทพมหานคร 10230'
  }, [isTablet])

  // const onSubscript = () => {}

  return (
    <div>
      <AboutUs />
      <PageView>
        <Information>
          <Text mobileSize="small" title bold primary>
            ที่อยู่บริษัท
          </Text>
          <IconText icon={gspIcon}>{addressText}</IconText>
          <IconText icon={callIcon}>063-065-6919</IconText>
          <a href="mailto:customers@tqd.co.th">
            <IconText icon={emailIcon}>customers@tqd.co.th</IconText>
          </a>
          {/* <SubscriptButton>
            <Button
              large
              color="secondary"
              width="220px"
              onClick={() => onSubscript()}
            >
              <b>รับข่าวสารจาก friday</b>
            </Button>
          </SubscriptButton> */}
        </Information>
        <Map onClick={() => setVisible(true)} src={map} />
        <Dialog visible={visible} onClose={() => setVisible(false)}>
          <DialogMap src={map} />
        </Dialog>
      </PageView>
    </div>
  )
}

const Information = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`

const Map = styled.img`
  width: 50%;
  cursor: pointer;

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`

const DialogMap = styled.img`
  width: 100%;
`

const PageView = styled.div`
  display: flex;
  align-items: center;
  max-width: 800px;

  padding: 20px;
  row-gap: 10px;
  column-gap: 22px;

  @media screen and (max-width: 990px) {
    padding: 0 16px;
    row-gap: 30px;
    flex-direction: column;
  }
`

// const SubscriptButton = styled.div`
//   display: flex;
//   justify-content: center;
//   padding-top: 8px;

//   @media screen and (max-width: 550px) {
//     > button {
//       width: 100%;
//     }
//   }
// `

export default ContactUs
