import { runInAction } from 'mobx'

import { http, error } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state
let key
export class Customer extends BaseStore {
  constructor() {
    super()
    this.observable({
      isAccepted: false,
      setting: {
        loading: false,
        error: '',
      },
      customer_list: [],
      report: {},
    })

    state = this
  }

  async updateConsent(params = {}) {
    const { customer_id, code, privacy_id, terms_id } = params
    const val = `${customer_id}/${privacy_id}/${terms_id}/${code}`
    if (key === val) {
      return
    }

    this.setLoading(true)

    key = val
    const url = `${config.api}/v1/public/customer/consent/${val}`
    const res = await http.put(url, { check: false })

    if (res.statusCode === 200) {
      runInAction(() => {
        state.isAccepted = true
      })
    }
    this.setLoading(false)

    error.isRespError(res, { message: 'Code ไม่ถูกต้องกรุณาทำใหม่อีกครั้ง' })
  }

  async getPlanReport(params = {}) {
    const { user_id, customer_id, ver } = params

    const url = `${config.api}/v1/public/crm/plan/${user_id}/${customer_id}/${ver}/summary`
    const res = await http.get(url)

    const data = res.body

    runInAction(() => {
      state.report = data
    })

    return data
  }

  setLoading(loading) {
    runInAction(() => {
      state.setting.loading = loading
    })
  }

  setError(error = '') {
    runInAction(() => {
      state.setting.error = error
    })
  }
}

export default new Customer()
