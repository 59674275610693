import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import Button from 'components/button/Button'
import { config } from 'config'
import useGTM from 'hooks/useGTM'

import Arcade from './arcade'
import Banner from './banner'
import Features from './features'
import Partner from './partner'
import SaleKit from './salekit'

function NewHome(props) {
  const history = useHistory()
  const { onPageLoad, onEvent } = useGTM()

  const isLogin = props.member.isLogin()

  const onStartClick = useCallback(() => {
    onEvent({
      pageName: 'home',
      eventName: 'home-start-button-click',
    })
    if (isLogin) {
      if (isMobile) {
        window.open(config.app_url, '_blank')
      } else {
        const packageEle = document.getElementById('package')
        packageEle.scrollIntoView({ behavior: 'smooth' })
        history.push('/package')
      }
    } else {
      history.push('/register')
    }
  }, [isLogin, history, onEvent])

  const btnText = useMemo(() => {
    if (!isMobile && isLogin) {
      return 'เริ่มใช้งาน'
    }
    return 'เริ่มใช้งานฟรี'
  }, [isLogin])

  useEffect(() => {
    onPageLoad({ pageName: 'home' })
  }, [onPageLoad])

  return (
    <Container>
      <SaleKit />
      <Banner onStartClick={onStartClick} />
      <Arcade />
      <Features />
      <Partner />
      <ButtonContainer>
        <Button
          large
          color="primary"
          maxWidth="220px"
          text={btnText}
          onClick={onStartClick}
        />
      </ButtonContainer>
    </Container>
  )
}

export default inject('register', 'member')(observer(NewHome))

const Container = styled.div`
  width: 100%;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 40px 16px 0;
`
