import React from 'react'
/* eslint-disable */
const Find = (props) => {
  const { size = 1, color = '#14142B' } = props

  const width = `${Math.ceil(22 * size)}`
  const height = `${Math.ceil(23 * size)}`
  return (
    <svg width={width} height={height} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 20C12.1 20 14.1 19.3 15.8 18.2L20.3 22.7C20.5 22.9 20.8 23 21 23C21.2 23 21.5 22.9 21.7 22.7C22.1 22.3 22.1 21.7 21.7 21.3L17.3 16.9C19 15.1 20 12.7 20 10C20 4.5 15.5 0 10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20ZM10 2C14.4 2 18 5.6 18 10C18 14.4 14.4 18 10 18C5.6 18 2 14.4 2 10C2 5.6 5.6 2 10 2Z" fill={color}/>
    </svg>
  )
}

export default Find
