import React from 'react'
import styled from 'styled-components'
import { Row } from 'antd'

const TableHeader = (props) => {
  const { justify = 'center', align = 'middle', children } = props
  return (
    <PageView>
      <Row justify={justify} align={align}>
        {children}
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  background-color: #eff0f7;
  border: 1px solid #d9dbe9;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  width: 100%;
`

export default TableHeader
