import React from 'react'
import styled from 'styled-components'

import femaleProfile from 'assets/female_profile.svg'
import maleProfile from 'assets/male_profile.svg'

import EditProfile from '../../icon/EditProfile'

const ImgMap = {
  none: femaleProfile,
  female: femaleProfile,
  male: maleProfile,
}

const UserImg = (props) => {
  const {
    src,
    editMode = false,
    size = 50,
    gender = 'none',
    name,
    onChange,
  } = props

  const defaultImg = ImgMap[gender]

  const onImageError = (e) => {
    e.target.src = defaultImg
  }

  const onClick = () => {
    const input = document.getElementById(name)
    if (input) input.value = null
  }

  const onImg = (evt) => {
    let files = evt.target.files
    if (files.length === 0) return
    let file = files[0]

    const reader = new FileReader()
    reader.addEventListener('load', () => {
      onChange(reader.result, name, file)
    })
    reader.readAsDataURL(file)
  }

  if (editMode)
    return (
      <Wrapper size={size}>
        <input
          id={name}
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={onImg}
          onClick={onClick}
        />

        <label htmlFor={name}>
          <EditModeImg
            src={src || defaultImg}
            size={size}
            onError={onImageError}
          />
          <Icon>
            <EditProfile />
          </Icon>
        </label>
      </Wrapper>
    )

  return <Img src={src || defaultImg} size={size} onError={onImageError} />
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
`

const Img = styled.img`
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  border-radius: 50%;
  object-fit: fill;
`

const EditModeImg = styled(Img)`
  border: 2px solid ${(p) => p.theme.color.primary};
  padding: 8px;
  cursor: pointer;
`

const Icon = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
`

export default UserImg
