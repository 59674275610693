import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Button } from 'components/button'
import PackageModal from 'components/modal/PackageModal'
import PackageData from 'components/package_data/PackageData'
import PackageMonth from 'components/package_month/PackageMonth'
import PackageYear from 'components/package_year/PackageYear'
import TabButton from 'components/tab/TabButton'
import Display from 'components/typo/Display'
import { PACKAGE_DURATION, PACKAGE_TYPE } from 'definition/package'
import useGTM from 'hooks/useGTM'
import useMediaQuery from 'hooks/useMediaQuery'
import { message } from 'utils/message'

const Package = (props) => {
  const history = useHistory()

  const [selected, setSelect] = useState(PACKAGE_TYPE.FREE)
  const [selectedPackageFree, setSelectedPackagetFree] = useState(true)
  const [selectedPackageStarter, setSelectedPackagetStarter] = useState(false)
  const [isSavePass, setIsSavePass] = useState(PACKAGE_DURATION.PER_YEAR)
  const [isOpened, setOpen] = useState(false)

  const isMobile = useMediaQuery('(max-width: 990px)')
  const { onEvent } = useGTM()

  const openModal = () => setOpen(true)

  const onOk = async () => {
    history.push('/account/profile')
  }

  const onChangePackage = async (packageId) => {
    try {
      const change_package = await props.payment.getChangePackage(
        packageId,
        isSavePass
      )
      const { status } = change_package
      if (status === 'no_change') {
        message.error({ message: 'ไม่สามารถเปลี่ยนแพ็กเกจได้' })
        return
      } else if (status === 'pay_more') {
        const path = '/account/my-payment/change-package/payment'
        return history.push(`${path}/${packageId}/${isSavePass}/info`)
      }

      await props.payment.changePackage(packageId, isSavePass, {})
      message.success({ message: 'เปลี่ยนแพ็กเกจสำเร็จ' })
    } catch (e) {
      message.error({ message: e.message })
    }
  }

  const onClickPackageList = async (packageId) => {
    try {
      if (!props.member.isLogin()) return history.push('/register')

      await props.package_app.setPackage({
        packageId,
        planPeriod: isSavePass,
      })

      if (headerConfig.column[1].id !== packageId)
        // return history.push('/account/payment')
        // return history.push('/account/my-payment/paynow')
        return onChangePackage(packageId)

      openModal()
    } catch (e) {
      message.error({ message: e.message })
    }
  }

  const getPackages = useCallback(async () => {
    try {
      await props.package_app.getPackageList({
        topButton: true,
        planPeriod: selected,
      })
      return { result: true }
    } catch (e) {
      return { err: e }
    }
  }, [props.package_app, selected])

  useEffect(() => {
    getPackages()
  }, [getPackages])

  const getMyPackage = useCallback(async () => {
    try {
      await props.payment.getMyPlan()
    } catch (e) {
      message.error({ message: e.message })
    }
  }, [props.payment])

  const { headerConfig, rows } = props.package_app.toJS()
  const { column } = headerConfig
  const { user = {} } = props.member.toJS()
  const { user_package } = props.payment.toJS()

  useEffect(() => {
    if (props.member.isLogin()) {
      getMyPackage()
      setIsSavePass(user_package.package_duration)
    }
  }, [getMyPackage, props.member, user_package.package_duration])

  const onSelectPackage = (value) => {
    setSelect(value)
  }

  const selectText = () => {
    if (!props.member.isLogin()) {
      return 'เลือก'
    }
    if (
      user?.package_type !== PACKAGE_TYPE.FREE &&
      user_package.package_duration === PACKAGE_DURATION.PER_MONTH &&
      isSavePass === PACKAGE_DURATION.PER_MONTH
    ) {
      return 'คุณใช้งานแพ็กเกจนี้อยู่'
    }
    if (
      user?.package_type !== PACKAGE_TYPE.FREE &&
      user_package.package_duration === PACKAGE_DURATION.PER_YEAR &&
      isSavePass === PACKAGE_DURATION.PER_YEAR
    ) {
      return 'คุณใช้งานแพ็กเกจนี้อยู่'
    } else {
      return 'เปลี่ยนแพ็กเกจ'
    }
  }

  return (
    <Wrapper>
      <Anchor id="package" />
      <PackageModal onOk={onOk} isOpened={isOpened} setOpen={setOpen} />
      <HeaderWrapper>
        <Display size="small" center primary bold>
          แพ็กเกจของเรา
        </Display>
      </HeaderWrapper>
      {isMobile ? (
        <PackageMobileDiv>
          <PackagePageMobile PackageFree={selectedPackageFree}>
            <HeaderPackageMobile>
              <div className="PackageFree">
                <TabButton
                  key={PACKAGE_TYPE.FREE}
                  onClick={() => {
                    setSelectedPackagetStarter(false)
                    setSelectedPackagetFree(true)
                    onSelectPackage(PACKAGE_TYPE.FREE)
                  }}
                  selected={PACKAGE_TYPE.FREE === selected}
                >
                  แพ็คเก็จฟรี
                </TabButton>
              </div>
              <div className="PackageStarter">
                <TabButton
                  key={PACKAGE_TYPE.STANDARD}
                  onClick={() => {
                    setSelectedPackagetFree(false)
                    setSelectedPackagetStarter(true)
                    onSelectPackage(PACKAGE_TYPE.STANDARD)
                  }}
                  selected={PACKAGE_TYPE.STANDARD === selected}
                >
                  แพ็คเก็จเริ่มต้น
                </TabButton>
              </div>
            </HeaderPackageMobile>
            {selectedPackageFree ? (
              <>
                <HeaderMini>แพ็คเก็จฟรี</HeaderMini>
                <TimePackageMobile>100 ข้อมูลรายชื่อลูกค้า</TimePackageMobile>
                <div className="firstColumn">
                  {Object.entries(rows).map(([key, value], index) => {
                    if (key === 'การดูแลลูกค้าและสร้างความสัมพันธ์') {
                      return (
                        <div key={index}>
                          <PackageDataHeader primary>{key}</PackageDataHeader>
                          {Object.entries(value).map(
                            ([keyIn, valueIn], jndex) => {
                              if (valueIn.free === true) {
                                return (
                                  <PackageData key={jndex}>{keyIn}</PackageData>
                                )
                              }
                              return <></>
                            }
                          )}
                        </div>
                      )
                    }
                    return <></>
                  })}
                </div>
                <div className="secondColumn">
                  {Object.entries(rows).map(([key, value]) => {
                    if (key === 'การวางแผนการเงินการให้คำปรึกษา') {
                      return (
                        <div>
                          <PackageDataHeader primary>{key}</PackageDataHeader>
                          {Object.entries(value).map(
                            ([keyIn, valueIn], jndex) => {
                              if (valueIn.free) {
                                return (
                                  <PackageData key={jndex}>{keyIn}</PackageData>
                                )
                              }
                              return <></>
                            }
                          )}
                        </div>
                      )
                    }
                    return <></>
                  })}
                </div>
                <div className="button">
                  <Button
                    large
                    color={
                      user?.package_type === PACKAGE_TYPE.FREE
                        ? 'secondarydisble'
                        : 'secondary'
                    }
                    text={
                      user?.package_type === PACKAGE_TYPE.FREE
                        ? 'คุณกำลังใช้งานแพ็กเกจนี้อยู่'
                        : 'เลือก'
                    }
                    width="360px"
                    onClick={() => {
                      user?.package_type !== PACKAGE_TYPE.FREE &&
                        onClickPackageList(column[1].id)
                    }}
                  />
                </div>
              </>
            ) : selectedPackageStarter ? (
              <>
                <HeaderMini>แพ็คเก็จเริ่มต้น</HeaderMini>
                <TimePackageMobile>
                  <b>ไม่จำกัด</b> ข้อมูลรายชื่อลูกค้า
                </TimePackageMobile>
                <div className="firstColumn">
                  {Object.entries(rows).map(([key, value], index) => {
                    if (key === 'การดูแลลูกค้าและสร้างความสัมพันธ์') {
                      return (
                        <div key={index}>
                          <PackageDataHeader primary>{key}</PackageDataHeader>
                          {Object.entries(value).map(
                            ([keyIn, valueIn], jndex) => {
                              if (valueIn.free)
                                return (
                                  <PackageData key={jndex}>{keyIn}</PackageData>
                                )
                              else if (valueIn.standard) {
                                return (
                                  <PackageData key={jndex} color>
                                    {keyIn}
                                  </PackageData>
                                )
                              }
                              return <></>
                            }
                          )}
                        </div>
                      )
                    }
                    return <></>
                  })}
                </div>
                <div className="secondColumn">
                  {Object.entries(rows).map(([key, value], index) => {
                    if (key === 'การวางแผนการเงินการให้คำปรึกษา') {
                      return (
                        <div key={index}>
                          <PackageDataHeader primary>{key}</PackageDataHeader>
                          {Object.entries(value).map(
                            ([keyIn, valueIn], jndex) => {
                              if (valueIn.free)
                                return (
                                  <PackageData key={jndex}>{keyIn}</PackageData>
                                )
                              else if (valueIn.standard) {
                                return (
                                  <PackageData key={jndex} color>
                                    {keyIn}
                                  </PackageData>
                                )
                              }
                              return <></>
                            }
                          )}
                        </div>
                      )
                    }
                    return <></>
                  })}
                </div>
                <div className="thirdColumn">
                  {Object.entries(rows).map(([key, value], index) => {
                    if (key === 'การทำงานแบบทีม') {
                      return (
                        <div>
                          <PackageDataHeader primary>{key}</PackageDataHeader>
                          {Object.entries(value).map(
                            ([keyIn, valueIn], jndex) => {
                              if (valueIn.free)
                                return (
                                  <PackageData key={jndex}>{keyIn}</PackageData>
                                )
                              else if (valueIn.standard) {
                                return (
                                  <PackageData key={jndex} color>
                                    {keyIn}
                                  </PackageData>
                                )
                              }
                              return <></>
                            }
                          )}
                        </div>
                      )
                    }
                    return <></>
                  })}
                </div>
                <PackageSelectorContainer>
                  <PackageYear
                    isSavePass={isSavePass}
                    setIsSavePass={setIsSavePass}
                    defaultPackage={
                      user?.package_type !== PACKAGE_TYPE.FREE &&
                      props.member.isLogin() &&
                      user_package.package_duration ===
                        PACKAGE_DURATION.PER_YEAR
                        ? true
                        : false
                    }
                  />
                  <PackageMonth
                    isSavePass={isSavePass}
                    setIsSavePass={setIsSavePass}
                    defaultPackage={
                      user?.package_type !== PACKAGE_TYPE.FREE &&
                      props.member.isLogin() &&
                      user_package.package_duration ===
                        PACKAGE_DURATION.PER_MONTH
                        ? true
                        : false
                    }
                  />
                </PackageSelectorContainer>
                <div className="button">
                  <Button
                    large
                    color="secondary"
                    text={selectText()}
                    width="360px"
                    onClick={() => {
                      if (selected === PACKAGE_TYPE.FREE) {
                        onEvent({
                          pageName: 'home',
                          eventName: 'package-free-button-click',
                        })
                      } else {
                        onEvent({
                          pageName: 'home',
                          eventName: 'package-beginer-button-click',
                          params: {
                            package:
                              isSavePass === PACKAGE_DURATION.PER_MONTH
                                ? 'monthly'
                                : 'yearly',
                          },
                        })
                      }
                      onClickPackageList(column[2].id)
                    }}
                    disabled={
                      (user?.package_type !== PACKAGE_TYPE.FREE &&
                        props.member.isLogin() &&
                        user_package.package_duration ===
                          PACKAGE_DURATION.PER_MONTH &&
                        isSavePass === PACKAGE_DURATION.PER_MONTH) ||
                      (user?.package_type !== PACKAGE_TYPE.FREE &&
                        props.member.isLogin() &&
                        user_package.package_duration ===
                          PACKAGE_DURATION.PER_YEAR &&
                        isSavePass === PACKAGE_DURATION.PER_YEAR)
                        ? true
                        : false
                    }
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </PackagePageMobile>
        </PackageMobileDiv>
      ) : (
        <PackageDiv>
          <PackagePage>
            <HeaderPackage>แพ็กเกจฟรี</HeaderPackage>
            <TimePackage>100 ข้อมูลรายชื่อลูกค้า</TimePackage>
            <div className="firstColumn">
              {Object.entries(rows).map(([key, value], index) => {
                if (key === 'การดูแลลูกค้าและสร้างความสัมพันธ์') {
                  return (
                    <div>
                      <PackageDataHeader primary>{key}</PackageDataHeader>
                      {Object.entries(value).map(([keyIn, valueIn], jndex) => {
                        if (valueIn.free === true) {
                          return <PackageData key={jndex}>{keyIn}</PackageData>
                        }
                        return <></>
                      })}
                    </div>
                  )
                }
                return <></>
              })}
            </div>
            <div className="secondColumn">
              {Object.entries(rows).map(([key, value], index) => {
                if (key === 'การวางแผนการเงินการให้คำปรึกษา') {
                  return (
                    <div>
                      <PackageDataHeader primary>{key}</PackageDataHeader>
                      {Object.entries(value).map(([keyIn, valueIn], jndex) => {
                        if (valueIn.free === true) {
                          return <PackageData key={jndex}>{keyIn}</PackageData>
                        }
                        return <></>
                      })}
                    </div>
                  )
                }
                return <></>
              })}
            </div>
            <div className="button">
              <Button
                large
                color={
                  user?.package_type === PACKAGE_TYPE.FREE
                    ? 'secondarydisble'
                    : 'secondary'
                }
                text={
                  user?.package_type === PACKAGE_TYPE.FREE
                    ? 'คุณกำลังใช้งานแพ็กเกจนี้อยู่'
                    : 'เลือก'
                }
                width="360px"
                onClick={() => {
                  onEvent({
                    pageName: 'home',
                    eventName: 'package-free-button-click',
                  })
                  user?.package_type !== PACKAGE_TYPE.FREE &&
                    onClickPackageList(column[1].id)
                }}
              />
            </div>
          </PackagePage>
          <PackagePage>
            <HeaderPackage>แพ็กเกจเริ่มต้น</HeaderPackage>
            <TimePackage>
              <b>ไม่จำกัด</b> ข้อมูลรายชื่อลูกค้า
            </TimePackage>
            <div className="firstColumn">
              {Object.entries(rows).map(([key, value], index) => {
                if (key === 'การดูแลลูกค้าและสร้างความสัมพันธ์') {
                  return (
                    <div>
                      <PackageDataHeader primary>{key}</PackageDataHeader>
                      {Object.entries(value).map(([keyIn, valueIn], jndex) => {
                        if (valueIn.free === true)
                          return <PackageData key={jndex}>{keyIn}</PackageData>
                        else if (valueIn.standard === true) {
                          return (
                            <PackageData key={jndex} color>
                              {keyIn}
                            </PackageData>
                          )
                        }
                        return <></>
                      })}
                    </div>
                  )
                }
                return <></>
              })}
            </div>
            <div className="secondColumn">
              {Object.entries(rows).map(([key, value]) => {
                if (key === 'การวางแผนการเงินการให้คำปรึกษา') {
                  return (
                    <div>
                      <PackageDataHeader primary>{key}</PackageDataHeader>
                      {Object.entries(value).map(([keyIn, valueIn], jndex) => {
                        if (valueIn.free)
                          return <PackageData key={jndex}>{keyIn}</PackageData>
                        else if (valueIn.standard) {
                          return (
                            <PackageData key={jndex} color>
                              {keyIn}
                            </PackageData>
                          )
                        }
                        return <></>
                      })}
                    </div>
                  )
                }
                return <></>
              })}
            </div>
            <div className="thirdColumn">
              {Object.entries(rows).map(([key, value]) => {
                if (key === 'การทำงานแบบทีม') {
                  return (
                    <div>
                      <PackageDataHeader primary>{key}</PackageDataHeader>
                      {Object.entries(value).map(([keyIn, valueIn], jndex) => {
                        if (valueIn.free)
                          return <PackageData key={jndex}>{keyIn}</PackageData>
                        else if (valueIn.standard) {
                          return (
                            <PackageData key={jndex} color>
                              {keyIn}
                            </PackageData>
                          )
                        }
                        return <></>
                      })}
                    </div>
                  )
                }
                return <></>
              })}
            </div>
            <PackageYear
              isSavePass={isSavePass}
              setIsSavePass={setIsSavePass}
              defaultPackage={
                user?.package_type !== PACKAGE_TYPE.FREE &&
                props.member.isLogin() &&
                user_package.package_duration === PACKAGE_DURATION.PER_YEAR
                  ? true
                  : false
              }
            />
            <PackageMonth
              isSavePass={isSavePass}
              setIsSavePass={setIsSavePass}
              defaultPackage={
                user?.package_type !== PACKAGE_TYPE.FREE &&
                props.member.isLogin() &&
                user_package.package_duration === PACKAGE_DURATION.PER_MONTH
                  ? true
                  : false
              }
            />
            <div className="button">
              <Button
                large
                color="secondary"
                text={selectText()}
                width="360px"
                onClick={() => {
                  onEvent({
                    pageName: 'home',
                    eventName: 'package-beginer-button-click',
                    params: {
                      package:
                        isSavePass === PACKAGE_DURATION.PER_MONTH
                          ? 'monthly'
                          : 'yearly',
                    },
                  })
                  onClickPackageList(column[2].id)
                }}
                disabled={
                  (user?.package_type !== PACKAGE_TYPE.FREE &&
                    props.member.isLogin() &&
                    user_package.package_duration ===
                      PACKAGE_DURATION.PER_MONTH &&
                    isSavePass === PACKAGE_DURATION.PER_MONTH) ||
                  (user?.package_type !== PACKAGE_TYPE.FREE &&
                    props.member.isLogin() &&
                    user_package.package_duration ===
                      PACKAGE_DURATION.PER_YEAR &&
                    isSavePass === PACKAGE_DURATION.PER_YEAR)
                    ? true
                    : false
                }
              />
            </div>
          </PackagePage>
        </PackageDiv>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: 100%;
  max-width: 1440px;

  row-gap: 40px;
  padding: 40px 70px;

  @media screen and (max-width: 1024px) {
    padding: 40px;
  }

  @media screen and (max-width: 990px) {
    padding: 40px 0;
  }
`

const HeaderWrapper = styled.div``

const Anchor = styled.span`
  position: absolute;
  top: -70px;
`

const PackageDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  align-items: center;
  .firstColumn {
    max-height: 500px;
    height: 500px;
  }
  .secondColumn {
    margin-top: 40px;
  }
  .button {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
  .thirdColumn {
    margin-top: 20px;
  }
`

const PackageMobileDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  .secondColumn {
    margin-top: 40px;
  }
  .button {
    width: 100%;
    padding: 24px 16px 0;
    > button {
      width: 100%;
    }
  }
  .thirdColumn {
    margin-top: 20px;
  }

  @media screen and (max-width: 990px) {
    padding: 1rem;

    .button {
      padding: 24px 16px;
    }
  }
`

const PackagePage = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 382px;
  height: 1900px;
  border: 1px solid #d9dbe9;
  border-radius: 16px;
  margin-right: 15px;
`

const PackagePageMobile = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: ${(props) => (props.PackageFree ? '900px' : '1900px')};

  @media screen and (max-width: 990px) {
    max-width: 380px;
    margin: 0 auto;
    height: fit-content;
    border: 1px solid #d9dbe9;
    border-radius: 16px;
  }

  @media screen and (max-width: 480px) {
    border: none;
  }
`

const HeaderPackage = styled.p`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #5f2eea;
  padding-top: 30px;
`

const HeaderMini = styled(HeaderPackage)`
  padding-top: 0;
`

const HeaderPackageMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;

  .PackageFree {
    padding: 20px;
    margin-right: 20px;
  }

  .PackageStarter {
    padding: 20px;
  }
`

const TimePackage = styled.p`
  text-align: center;
  font-size: 20px;
  color: #4e4b66;

  padding-top: 30px;
`

const TimePackageMobile = styled.p`
  text-align: center;
  font-size: 20px;
  color: #4e4b66;
`

const PackageDataHeader = styled.p`
  font-size: 18px;
  color: #5f2eea;
  align-items: center;
  font-weight: bold;
  padding: 0 20px;
`

const PackageSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 16px;
`

export default inject('package_app', 'member', 'payment')(observer(Package))
