import React from 'react'

import { FieldInput } from '../input'
import { helper } from '../../utils'

const CardExpireInput = (props) => {
  const onChangeExpire = (val, name) => {
    const originalValue = String(val).replaceAll('/', '')

    if (Number.isNaN(Number(originalValue))) return

    const list = helper.formatPattern(originalValue, 'xx/xx')

    const [month, year] = helper
      .formatPattern(originalValue, 'xx/20xx')
      .split('/')

    props.onChange({
      ex_month: month,
      ex_year: year,
      [name]: list,
    })
  }

  return (
    <FieldInput
      label="วันหมดอายุ"
      name={props.name}
      value={props.value}
      max={5}
      onChange={onChangeExpire}
      disabled={props.disabled}
      errorText={props.error && 'วันหมดอายุไม่ถูกต้อง'}
    />
  )
}

export default CardExpireInput
