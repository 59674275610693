import React from 'react'
import styled from 'styled-components'

import Dialog from 'dialog'
import { Text } from 'components/typo'
import { Button } from 'components/button'

const ConnectDialog = (props) => {
  const { visible, onClose, onConfirm } = props

  return (
    <Dialog width="718px" visible={visible} onClose={onClose}>
      <Content>
        <Title>คุณต้องการต่ออายุสมาชิกใช่หรือไม่</Title>
        <div>
          <Text size="small">
            แพ็กเกจเดิมจะเริ่มใช้หลังคุณยืนยันการต่ออายุสมาชิก
          </Text>
        </div>
        <div>
          <Text size="small">
            ระบบจะคิดค่าบริการผ่านข้อมูลการชำระเงินที่คุณบันทึกไว้
          </Text>
        </div>
        <AreaBtn>
          <Button color="primary" text="ต่ออายุสมาชิก" onClick={onConfirm} />
        </AreaBtn>
        <Confirm onClick={onClose}>กลับ</Confirm>
      </Content>
    </Dialog>
  )
}

const Content = styled.div`
  text-align: center;
  background-color: white;
`

const Title = styled.div`
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: bold;
`

const AreaBtn = styled.div`
  margin: 0 auto;
  padding: 24px 0;
  width: 220px;
`

const Confirm = styled.span`
  margin: 0 auto;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`

export default ConnectDialog
