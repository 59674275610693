import React from 'react'
import styled from 'styled-components'

import { Text } from 'components/typo'
import { NextArrow, PromptPay } from 'icon'

import { Button } from './index'

function PrompayButton(props) {
  return (
    <Button
      color="subtle"
      maxWidth="387px"
      onClick={props.onClick}
      text={
        <InnerButton>
          <Text size="small" bold primary>
            ชำระผ่านคิวอาร์โค้ด/ พร้อมเพย์
          </Text>
          <RightBox>
            <IconBox>
              <PromptPay width={27} height={16} />
            </IconBox>
            <NextArrow />
          </RightBox>
        </InnerButton>
      }
    />
  )
}

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  column-gap: 1px;
`

const RightBox = styled.div`
  display: flex;
  column-gap: 12px;
`

const InnerButton = styled.div`
  display: flex;
  justify-content: space-between;
`

export default PrompayButton
