import React from 'react'
/* eslint-disable */
const Caret = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 15.9996C12.9 15.9996 13.7001 15.5996 14.3001 14.9996L19.8001 8.69956C20.2001 8.29956 20.1 7.69956 19.7 7.29956C19.3 6.89956 18.7001 6.99956 18.3001 7.39956L12.8001 13.6996C12.4001 14.0996 11.7001 14.0996 11.3001 13.6996L5.80005 7.39956C5.40005 6.99956 4.80005 6.89956 4.40005 7.29956C4.00005 7.69956 3.90005 8.29956 4.30005 8.69956L9.80005 14.9996C10.3001 15.5996 11.1 15.9996 12 15.9996Z"
        fill="#14142B"
      />
    </svg>
  )
}

export default Caret
