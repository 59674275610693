import { useRef, useEffect } from 'react'

const useClickAway = ({ onClick, name, forwardRef }, deps = true) => {
  const ref = useRef()
  const selectedRef = forwardRef || ref

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        deps &&
        selectedRef.current &&
        !selectedRef.current.contains(e.target)
      ) {
        onClick()
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [deps, onClick, selectedRef])

  return { ref }
}

export default useClickAway
