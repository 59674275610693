import React, { useState, useEffect, useCallback, Fragment } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { useHistory, useParams } from 'react-router-dom'

import { Display, Text } from 'components/typo'
import { Button } from 'components/button'
import Loading from 'components/loading'
import { format, timer, message } from 'utils'
import Menu from 'pages/account/components/Menu/Menu'
import { PACKAGE_TYPE } from 'definition/package'

const PaymentResult = (props) => {
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(undefined)
  const [user, setUser] = useState(undefined)

  const history = useHistory()
  const { code } = useParams()

  const onInit = useCallback(
    async (invoice_no) => {
      setLoading(true)
      try {
        const { payment, user } = await props.account.getPaymentResult(
          invoice_no
        )
        setResult(payment)
        setUser(user)
      } catch (e) {
        message.error({ message: 'ไม่พบข้อมูลการชำระเงิน' })
      }
      setLoading(false)
    },
    [props.account]
  )

  useEffect(() => {
    onInit(code)
  }, [onInit, code])

  const getStatus = () => {
    const { status } = result || {}
    const { expired_at } = user || {}
    const date = timer.get(expired_at).subtract(3, 'days')

    switch (status) {
      case 'cancelled':
        return 'ยกเลิกการชำระเงิน'
      case 'completed':
        return format.toNameDate(date)
      default:
        return 'รอการยืนยัน'
    }
  }

  const getProductName = () => {
    const { package_type } = result || {}

    switch (package_type) {
      case PACKAGE_TYPE.STARTER:
      case PACKAGE_TYPE.STANDARD:
        return 'เริ่มต้น'
      case PACKAGE_TYPE.PROFESSIONAL:
        return 'โปร'
      case PACKAGE_TYPE.TEAM:
        return 'ทีม'
      default:
        return 'ฟรี'
    }
  }

  const getResult = () => {
    const { user_id, payment_type, invoice_no, amount, status } = result || {}
    let list = []
    if (payment_type === 'card') {
      list = [
        { label: 'เลขประจำตัวคนขาย', value: `${user_id || ''}` },
        { label: 'เลขคำสั่งซื้อ', value: invoice_no },
        { label: 'ชื่อสินค้าที่ซื้อ', value: getProductName() },
        { label: 'จำนวนเงิน', value: `฿ ${format.toDigi(amount || 0)}` },
        { label: 'วิธีการชำระเงิน', value: 'บัครเครดิต/ เดบิต' },
      ]
    } else {
      list = [
        { label: 'เลขประจำตัวคนขาย', value: `${user_id || ''}` },
        { label: 'เลขคำสั่งซื้อ', value: invoice_no },
        { label: 'ชื่อสินค้าที่ซื้อ', value: getProductName() },
        { label: 'จำนวนเงิน', value: `฿ ${format.toDigi(amount || 0)}` },
        { label: 'วิธีการชำระเงิน', value: 'คิวอาร์โค้ด/ พร้อมเพย์' },
      ]
    }

    if (status !== 'completed') {
      list.push({ label: 'สถานะการชําระเงิน', value: getStatus() })
    }
    return list.map((it, i) => {
      return (
        <Fragment key={i}>
          <Text size="medium" placeholder>
            {it.label}
          </Text>
          <Text size="medium" gray>
            {it.value}
          </Text>
        </Fragment>
      )
    })
  }

  const getError = (msg) => {
    return <ErrorMessage>{msg}</ErrorMessage>
  }

  const content = result ? (
    <TextContent> {getResult()}</TextContent>
  ) : (
    getError('ไม่พบข้อมูลการชำระเงิน')
  )
  return (
    <Menu>
      <ContentView>
        <Display size="xsmall" title bold>
          รายละเอียดการชำระเงิน
        </Display>
        <Space />
        <Loading loading={loading}>
          {content}

          <Button
            large
            color="primary"
            maxWidth="325px"
            text="กลับไปหน้าแรก"
            onClick={() => history.push('/')}
          />
        </Loading>
      </ContentView>
    </Menu>
  )
}

const TextContent = styled.div`
  display: grid;
  gap: 8px;
  column-gap: 24px;
  align-items: flex-start;
  grid-template-columns: auto auto;
  margin: 8px 0 24px;
`

const ContentView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 56px 0;
  text-align: center;
  margin: 0 auto;
`

const Space = styled.div`
  padding-top: 36px;
`

const ErrorMessage = styled.div`
  padding: 40px 0;
  color: #c30052;
`

export default inject('member', 'account')(observer(PaymentResult))
