import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { onClick, size = 1 } = props

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg onClick={onClick} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 3V21" stroke="#6E7191" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 12L21 12" stroke="#6E7191" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
// eslint-enable
export default Logo
