import React from 'react'
import styled from 'styled-components'

const FieldInput = (props) => {
  const { label = '', value = '' } = props

  return (
    <Input>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </Input>
  )
}

const Input = styled.div`
  width: 100%;
  padding: 6px 24px;
  border-radius: 16px;
  text-align: left;
  background-color: #eff0f6;
`

const Label = styled.div`
  color: #6e7191;
  font-size: 14px;
  line-height: 24px;
`

const Value = styled.div`
  color: #14142b;
  font-size: 16px;
  line-height: 28px;
`

export default FieldInput
