import React from 'react'
import styled from 'styled-components'

import { message } from 'utils'

import { Button } from './index'

const SubmitPayment = (props) => {
  const { card, text, disabled, loading, onClick, onError } = props
  const onPayment = (encrypted = {}) => {
    try {
      const {
        encryptedCardInfo,
        expMonthCardInfo,
        expYearCardInfo,
        maskedCardInfo,
      } = encrypted

      const req = {
        name: card.name,
        surname: card.surname,
        encrypted_card: encryptedCardInfo,
        exp_month: expMonthCardInfo,
        exp_year: expYearCardInfo,
        card: maskedCardInfo,
      }

      onClick(req)
    } catch (e) {
      message.error({ message: e.message })
      onError()
    }
  }

  const onSubmit = () => {
    try {
      window.My2c2p.getEncrypted(
        '2c2p-payment-form',
        (encrypted, errCode, errDesc) => {
          if (errCode !== 0) {
            throw new Error(errDesc)
          }
          onPayment(encrypted)
        }
      )
    } catch (e) {
      console.log(e, 'error')
      message.error({ message: e.message })
      onError()
    }
  }

  return (
    <>
      <Button
        large
        color="primary"
        text={text}
        loading={loading}
        disabled={disabled}
        onClick={onSubmit}
      />
      <Hider>
        <form id="2c2p-payment-form" action="./payment_3d.php" method="POST">
          <input type="text" data-encrypt="cardnumber" value={card.card_id} />
          <input type="text" data-encrypt="month" value={card.ex_month} />
          <input type="text" data-encrypt="year" value={card.ex_year} />
          <input type="password" data-encrypt="cvv" value={card.cvv} />
        </form>
      </Hider>
    </>
  )
}

const Hider = styled.div`
  display: none;
`

export default SubmitPayment
