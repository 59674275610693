import React from 'react'
import styled from 'styled-components'

import { Check } from 'icon'

const PackageData = (props) => {
  const { children, color } = props
  return (
    <PackageDataDiv>
      <Check primary />

      <PackageDataText color={color}>{children}</PackageDataText>
    </PackageDataDiv>
  )
}

const PackageDataDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 20px;
`
const PackageDataText = styled.p`
  width: 100%;
  font-size: 18px;
  color: ${(props) => (props.color ? '#5f2eea' : '#4e4b66')};

  padding: 0 10px;
`

export default PackageData
