import React, { useState } from 'react'
import styled from 'styled-components'

import usePopup from '../hooks/usePopup'
import useClickAway from '../hooks/useClickAway'

export const flipDirection = {
  left: 'right',
  right: 'left',
  top: 'bottom',
  bottom: 'top',
}

const Popup = (props) => {
  const {
    show = { v: 'bottom', h: 'right' },
    hover = false,
    forwardRef,
  } = props
  const [isOpen, setOpen] = useState(false)
  const { ref, innerRef, horizontal, vertical, onCalculatePosition } = usePopup(
    { show, forwardRef }
  )

  useClickAway({
    name: 'popup',
    onClick: () => setOpen(false),
    forwardRef: ref,
  })

  const content =
    typeof props.content === 'function'
      ? props.content({ horizontal, vertical })
      : props.content
  const children =
    typeof props.children === 'function'
      ? props.children({ isOpen })
      : props.children

  if (hover) {
    return (
      <HoverWrapper ref={ref} onMouseEnter={onCalculatePosition}>
        {children}
        <HoverInnerWrapper
          ref={innerRef}
          horizontal={horizontal}
          vertical={vertical}
        >
          {content}
        </HoverInnerWrapper>
      </HoverWrapper>
    )
  }

  return (
    <Wrapper
      ref={ref}
      onClick={(e) => {
        e.stopPropagation()
        setOpen((open) => !open)
        onCalculatePosition()
      }}
    >
      {children}
      <InnerWrapper
        ref={innerRef}
        isOpen={isOpen}
        horizontal={horizontal}
        vertical={vertical}
        onClick={(e) => {
          e.stopPropagation()
          setOpen((open) => !open)
        }}
      >
        {content}
      </InnerWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 250px;
  width: 100%;
`

const InnerWrapper = styled.div`
  display: flex;
  min-width: max-content;
  max-width: 100vw;

  position: absolute;
  white-space: pre-wrap;
  justify-self: center;

  z-index: 100;
  visibility: ${(p) => (p.isOpen ? 'visible' : 'hidden')};
  transition: all 0.2s;

  ${({ vertical: { direction, value } }) => `${direction}: ${value}px`};
  ${({ horizontal: { direction, value } }) => `${direction}: ${value}px`};
`

const HoverInnerWrapper = styled.div`
  display: flex;
  position: absolute;

  z-index: 100;
  visibility: hidden;
  min-width: max-content;
  max-width: 100vw;

  ${({ vertical: { direction, value } }) => `${direction}: ${value}px`};
  ${({ horizontal: { direction, value } }) =>
    `${flipDirection[direction]}: ${value}px`};
`

const HoverWrapper = styled(Wrapper)`
  &:hover ${HoverInnerWrapper} {
    visibility: visible;
  }
`

export default Popup
