import { inject, observer } from 'mobx-react'
import qs from 'query-string'
import { useCallback, useEffect, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import BindingMobileModal from 'components/modal/BindingMobileModal'
import ConsentModal from 'components/modal/ConsentModal'
import { config } from 'config'
import NotFound from 'pages/NotFound'
import Policy from 'pages/Policy'
import Promotion from 'pages/Promotion'
import ResendEmail from 'pages/ResendEmail'
import Term from 'pages/Term'
import ConfirmAddEmail from 'pages/account/confirmAddEmail'
import ConfirmAppointment from 'pages/confirmAppointment'
import ContactUs from 'pages/contactUs'
import Features from 'pages/features'
import ForgotPassword from 'pages/forgotPassword'
import LifeBridge from 'pages/lifebridge'
import LoginPage from 'pages/login'
import NewCustomer from 'pages/newCustomer'
import Home from 'pages/newHome'
import Package from 'pages/package'
import Privacy from 'pages/privacy'
import RegisterPage from 'pages/register'
import PlanReport from 'pages/report'
import ResetPassword from 'pages/resetPassword'
import SetPassword from 'pages/setPassword'
import UnsubscribeEmail from 'pages/unsubscribeEmail'

import UserApp from './UserApp'
import { Logo } from './icon'
import MainLayout from './menu'
import SetMobileBind from './pages/setMobilenumber'

const App = (props) => {
  const { token } = qs.parse(props.location.search)
  const isLogin = props.member.isLogin()
  const loading = props.member.toJS().verifying
  const history = useHistory()

  const [initialized, setInitiazed] = useState(false)

  const scriptLoaded = () => {
    console.log('loaded', window.My2c2p)
  }

  const onInit = useCallback(async () => {
    const script = document.createElement('script')
    script.src = config.payment_url
    script.async = true
    script.onload = () => scriptLoaded()

    document.body.appendChild(script)
  }, [])

  const onCheck = useCallback(async () => {
    if (isLogin && !token) return
    try {
      await props.member.checkLoginUser(token)
      if (token) {
        history.push(props.location.pathname)
      }
    } catch (e) {
      console.log('check user:', e.message)
    }
    setInitiazed(true)
  }, [props.member, isLogin, token, setInitiazed])

  useEffect(() => {
    onInit()
  }, [onInit])

  useEffect(() => {
    onCheck()
  }, [onCheck])

  const userApp = isLogin ? (
    <Route path="/account" component={UserApp} />
  ) : (
    <Route path="/account" component={LoginPage} />
  )

  if (loading) {
    return (
      <BG>
        <Logo size={2} color="white" />
      </BG>
    )
  }

  if (initialized) {
    return (
      <>
        <BindingMobileModal />
        <ConsentModal />
        <MainLayout history={props.history}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/package" component={Package} />
            <Route exact path="/features" component={Features} />
            <Route exact path="/lifebridge" component={LifeBridge} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route
              exact
              path="/reset-password/:ref_code"
              component={ResetPassword}
            />
            <Route exact path="/register" component={RegisterPage} />
            <Route exact path="/term" component={Term} />
            <Route exact path="/policy" component={Policy} />
            <Route
              exact
              path="/confirm-email/:ref_code"
              component={SetPassword}
            />
            <Route exact path="/unsubscribe" component={UnsubscribeEmail} />
            <Route
              exact
              path="/resend-email/:ref_code"
              component={ResendEmail}
            />

            <Route exact path="/bind-mobile" component={SetMobileBind} />
            <Route
              exact
              path="/new-customer/:customer_id/:code"
              component={NewCustomer}
            />
            <Route
              exact
              path="/confirm-appointment/:appointment_id/:code/:status"
              component={ConfirmAppointment}
            />
            <Route
              exact
              path="/report/:user_id/:customer_id/:ver"
              component={PlanReport}
            />
            <Route
              exact
              path="/account/confirm-add-email/:ref_code/:code"
              component={ConfirmAddEmail}
            />
            {userApp}
            <Route exact path="/promotion" component={Promotion} />
            <Route exact path="/privacy" component={Privacy} />
            <Route path="*" component={NotFound} />
          </Switch>
        </MainLayout>
      </>
    )
  }

  return <div />
}

const BG = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: ${(p) => p.theme.color.primary};
`

export default inject('member')(observer(App))
