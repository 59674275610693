import React, { useState } from 'react'

import Promotion from '../promotion'
import CardPayment from './CardPayment'
import InfoPayment from './InfoPayment'

const MyPaymentCard = (props) => {
  const [page, setPage] = useState('')
  const [card, setCard] = useState(undefined)

  const onPayment = (cardInfo) => {
    setCard(cardInfo)
    setPage('promotion')
  }

  const onNext = () => {
    setPage('infomation')
  }

  switch (page) {
    case 'promotion':
      return <Promotion onNext={onNext} />
    case 'infomation':
      return <InfoPayment card={card} />
    default:
      return <CardPayment onPayment={onPayment} />
  }
}

export default MyPaymentCard
