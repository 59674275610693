import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import Menu from 'pages/account/components/Menu/Menu'
import { Text } from 'components/typo'
import { MetaData, HtmlContent } from 'components/display'
import Loading from 'components/loading'
import { message } from 'utils'

const MyConsent = (props) => {
  const [loading, setLoading] = useState(false)
  const { consent_type } = props.match.params
  const onInit = useCallback(async () => {
    try {
      setLoading(true)

      await props.account.getConsent(consent_type)
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    }
    setLoading(false)
  }, [props.account, consent_type])

  useEffect(() => {
    onInit()
  }, [onInit])

  const title =
    consent_type === 'terms' ? 'เงื่อนไขการใช้บริการ' : 'นโยบายความเป็นส่วนตัว'

  const { consent = {} } = props.account.toJS()
  return (
    <Menu>
      <MetaData title={`ผู้ใช้งาน - ${title}`} description={title} />
      <Content>
        <Title>
          <Text size="large" title bold>
            {title}
          </Text>
        </Title>
        <Loading loading={loading}>
          <Text size="small" gray>
            <HtmlContent content={consent.content} />
          </Text>
        </Loading>
      </Content>
    </Menu>
  )
}

const Content = styled.div`
  width: 100%;
  max-width: 730px;
  margin: 0 auto;
`

const Title = styled.div`
  margin: 36px 0;
  text-align: center;
`

export default inject('account')(observer(MyConsent))
