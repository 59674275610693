import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import Menu from 'pages/account/components/Menu/Menu'
import { Text } from 'components/typo'
import { Button } from 'components/button'
import { BackLink } from 'components/link'
import { TextArea } from 'components/input'
import { MetaData, UserImg } from 'components/display'
import { message } from 'utils'
import { IssueProblemStatus } from 'definition/problem'
import Circle from 'icon/Circle'

import Message from './Message'

const IssueProblemDetail = (props) => {
  const [loading, setLoading] = useState(false)
  const [note, setNote] = useState('')
  const { issue_problem_id } = props.match.params

  const onGetDoc = useCallback(
    async (id) => {
      try {
        setLoading(true)

        await props.problem.getIssueProblem(id)
      } catch (e) {
        message.error({
          title: `แสดงรายการ`,
          message: e.message,
        })
      }
      setLoading(false)
    },
    [props.problem]
  )

  useEffect(() => {
    onGetDoc(issue_problem_id)
  }, [onGetDoc, issue_problem_id])

  const getTextStatus = (item) => {
    const { color, text } = IssueProblemStatus[item.status]

    return (
      <Status style={{ color }}>
        <Circle color={color} /> {text}
      </Status>
    )
  }

  const onReplyIssue = async () => {
    try {
      setLoading(true)
      await props.problem.replyIssueProblem({
        issue_problem_id,
        note,
      })

      setNote('')
    } catch (e) {
      message.error({
        title: `ตอบข้อความ`,
        message: e.message,
      })
    }
    setLoading(false)
  }

  const { user = {} } = props.member.toJS()
  const { issue_problem = {} } = props.problem.toJS()

  const infos = [
    { label: 'Issue ID', value: issue_problem.issue_problem_id },
    { label: 'หัวข้อ', value: issue_problem.category_name },
    { label: 'สถานะ', value: getTextStatus(issue_problem) },
  ]

  const content = infos.map((it, i) => {
    return (
      <ContentWrapper key={i}>
        <Text placeholder>{it.label}</Text>
        <Text>{it.value}</Text>
      </ContentWrapper>
    )
  })

  const footer =
    issue_problem.status === 'closed' ? (
      <Close>Ticket นี้ได้ถูกปิดโดยทีมงาน</Close>
    ) : (
      <PageView>
        <UserImg img_url={user.img_url} />
        <div>
          <TextArea
            label="ข้อความ"
            value={note}
            rows="2"
            onChange={(val) => setNote(val)}
            disabled={loading}
          />
          <BtnArea>
            <Btn>
              <Button
                loading={loading}
                small
                text="ตอบ"
                color="primary"
                onClick={onReplyIssue}
              />
            </Btn>
          </BtnArea>
        </div>
      </PageView>
    )

  const chat = issue_problem.chat_list.map((item, i) => {
    return <Message key={i} item={item} />
  })
  return (
    <Menu active="ศูนย์ช่วยเหลือ">
      <MetaData
        title={`รายละเอียดศูนย์ช่วยเหลือ - ${issue_problem_id}`}
        description="รายละเอียดศูนย์ช่วยเหลือ"
      />
      <BackArea>
        <BackLink to="/account/issue-problem" text="ศูนย์ช่วยเหลือ" />
      </BackArea>
      <Wrapper>
        {content}
        <IssueList>{chat}</IssueList>
        {footer}
      </Wrapper>
    </Menu>
  )
}

const PageView = styled.div`
  display: grid;
  grid-template-columns: min-content 7fr;
  column-gap: 18px;
  padding: 10px 0;
`

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 120px auto;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 100%;
  padding: 56px 0 0 60px;

  @media screen and (max-width: 768px) {
    padding: 10px;
  }

  @media screen and (max-width: 500px) {
    padding: 20px 15px;
  }
`

const BackArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 25px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const IssueList = styled.div`
  padding: 20px 0;
`

const Status = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
`

const Close = styled.div`
  text-align: center;
  color: #a0a3bd;
`

const BtnArea = styled.div`
  margin: 12px 0;
`

const Btn = styled.span`
  float: right;
  width: 120px;
`

export default inject('problem', 'member')(observer(IssueProblemDetail))
