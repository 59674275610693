import React from 'react'
import styled from 'styled-components'

import { Text } from '../typo'

const CommonTable = (props) => {
  const { columns = [], data = [], onClickRow = () => {} } = props
  return (
    <Grid>
      <TableWrapper>
        <Table>
          <TableHead>
            <tr>
              {columns.map((col) => (
                <TableHeadCol key={col.label}>
                  <Text title size="small">
                    {col.label}
                  </Text>
                </TableHeadCol>
              ))}
            </tr>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <tr key={index} onClick={() => onClickRow(item)}>
                {columns.map((col) => (
                  <TableBodyCol key={col.label}>
                    <Text gray size="small">
                      {col.func ? col.func(item) : item[col.key]}
                    </Text>
                  </TableBodyCol>
                ))}
              </tr>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </Grid>
  )
}

const Grid = styled.div`
  display: grid;
  width: 100%;
`
const TableWrapper = styled.div`
  display: grid;
  padding: 1px 1px 16px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background: ${(p) => p.theme.color.primaryBg};
  }
`

const Table = styled.table`
  overflow: hidden;
  min-width: 800px;
  border-radius: 16px;
  box-shadow: 0 0 0 1px ${(p) => p.theme.gray.line};

  & th,
  td {
    border: 1px solid ${(p) => p.theme.gray.line};
  }

  & th:last-child,
  td:last-child {
    border-right: none;
  }

  & th:first-child,
  td:first-child {
    border-left: none;
  }

  & tr:first-child > th {
    border-top: none;
  }

  & tr:last-child > td {
    border-bottom: none;
  }
`

const TableHead = styled.thead`
  height: 52px;
  background: ${(p) => p.theme.gray.inputBackground};
`

const TableBody = styled.tbody``

const TableHeadCol = styled.th`
  text-align: left;
  padding: 12px 20px;
`

const TableBodyCol = styled.td`
  padding: 12px 20px;
`

export default CommonTable
