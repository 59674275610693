import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import Menu from 'pages/account/components/Menu/Menu'
import { Button } from 'components/button'
import { Text } from 'components/typo'
import { PasswordInput } from 'components/input'
import { MetaData } from 'components/display'
import { validator, message } from 'utils'

const SetPassword = (props) => {
  const [password, setPassword] = useState({
    old: '',
    current: '',
    confirm: '',
  })
  const [validate, setValidate] = useState({})
  const [loading, setLoading] = useState(false)

  const onChange = (val, name) => {
    password[name] = val
    setPassword({ ...password })

    if (isInvalid(validate)) {
      const valid = getValidate()
      setValidate(valid)
    }
  }

  const getTextError = (pass = '', list = []) => {
    return pass.length > 0 && list.length > 0 ? list[0] : undefined
  }

  const getValidate = () => {
    const valid = {}
    const validCurrent = validator.validatePassword(password.current)
    valid.current = getTextError(password.current, validCurrent)
    if (password.current !== password.confirm) {
      valid.confirm = 'รหัสผ่านไม่ตรงกัน'
    }

    return valid
  }

  const isInvalid = (valid = {}) => {
    return valid.current || valid.confirm
  }

  const onSave = async () => {
    try {
      const valid = getValidate()

      if (isInvalid(valid)) {
        setValidate(valid)
        return
      }
      setValidate({})
      setLoading(true)

      const user = props.member.toJS().user
      await props.account.verifyOldPassword(user.email, password.old)
      await props.account.updatePassword(password.current)
      message.success({
        message: 'เปลี่ยนรหัสผ่านสำเร็จ',
      })
      setPassword({
        old: '',
        current: '',
        confirm: '',
      })
    } catch (e) {
      const msg = e.message
      message.error({
        message:
          msg ===
          'The password is invalid or the user does not have a password.'
            ? 'รหัสผ่านเดิม ไม่ถูกต้อง'
            : msg,
      })
    }
    setLoading(false)
  }

  const isEnabled =
    password.old.length === 0 ||
    password.current.length === 0 ||
    password.confirm.length === 0

  return (
    <Menu>
      <MetaData
        title="เปลี่ยนรหัสผ่าน"
        description="ผู้ใช้งานเปลี่ยนรหัสผ่าน"
      />
      <Content>
        <Title>
          <Text size="large" bold>
            เปลี่ยนรหัสผ่าน
          </Text>
        </Title>
        <PasswordBox>
          <PasswordInput
            id="old"
            label="รหัสผ่านเดิม"
            name="old"
            value={password.old}
            onChange={onChange}
            errorText={validate.old}
            toolTip={<PasswordHint list={passwordComplexity} />}
          />

          <PasswordInput
            id="current"
            label="รหัสผ่านใหม่"
            name="current"
            value={password.current}
            onChange={onChange}
            errorText={validate.current}
          />

          <PasswordInput
            id="confirm"
            label="ยืนยันรหัสผ่านใหม่"
            name="confirm"
            value={password.confirm}
            onChange={onChange}
            errorText={validate.confirm}
          />
        </PasswordBox>

        <Button
          large
          loading={loading}
          disabled={isEnabled}
          text="ยืนยันรหัสผ่าน"
          color="primary"
          onClick={onSave}
        />
      </Content>
    </Menu>
  )
}

const passwordComplexity = [
  'รหัสผ่านต้องไม่มีช่องว่าง',
  'รหัสผ่านต้องมีตัวเลข (0-9) อย่างน้อย 1 ตัว',
  'รหัสผ่านต้องมีความยาวอยู่ระหว่าง 8-15 ตัวอักษร',
  'รหัสผ่านต้องประกอบด้วยตัวอักษรภาษาอังกฤษพิมพ์เล็ก (a-z) อย่างน้อย 1 ตัว',
  'รหัสผ่านต้องประกอบด้วยตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ (A-Z) อย่างน้อย 1 ตัว',
]

const PasswordHint = (props) => {
  if (!props.list) return null

  return (
    <HintWrapper>
      {props.list.map((i) => (
        <li key={i}>{i}</li>
      ))}
    </HintWrapper>
  )
}

const HintWrapper = styled.ul`
  margin: 0;
  padding-left: 16px;

  & li {
    margin: 0;
  }
`

const PasswordBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-bottom: 40px;
`

const Content = styled.div`
  width: 325px;
  margin: 0 auto;

  @media screen and (max-width: 500px) {
    width: 85%;
  }
`

const Title = styled.div`
  margin: 36px 0;
  text-align: center;
`

export default inject('member', 'account')(observer(SetPassword))
