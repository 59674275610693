import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { Text } from 'components/typo'
import CreditButton from 'components/button/CreditButton'
import PrompayButton from 'components/button/PrompayButton'

import AccountLayout from '../../components/AccountLayout'

const MyPaymentOption = () => {
  const history = useHistory()
  return (
    <AccountLayout backTo="/account/my-payment" backText="ข้อมูลการชำระเงิน">
      <Title>
        <Text size="large" bold>
          วิธีการชำระเงิน
        </Text>
      </Title>
      <Description>
        <Text mobileSize="small" gray>
          ตอนนี้คุณชำระค่าบริการการใช้งานด้วยบัตรเครดิต
          <br />
          ข้อมูลการเปลี่ยนแปลงจะมีผลในรอบบิลถัดไป
        </Text>
      </Description>
      <ButtonBox>
        <CreditButton
          onClick={() => history.push('/account/my-payment/card')}
        />
        <PrompayButton
          onClick={() => history.push('/account/my-payment/promptpay')}
        />
      </ButtonBox>
    </AccountLayout>
  )
}

const Title = styled.div``

const Description = styled.div`
  text-align: center;
  margin: 23px 0 33px;
`

const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 387px;
`

export default MyPaymentOption
