import React from 'react'
import styled from 'styled-components'

import Text from 'components/typo/Text'

const IconText = (props) => {
  const { icon, children } = props
  return (
    <Wrapper>
      <Icon src={icon} />
      <Text size="small" mobileSize="xsmall" gray>
        {children}
      </Text>
    </Wrapper>
  )
}

const Icon = styled.img`
  width: 24px;
`

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;

  column-gap: 22px;
  color: ${(props) => props.theme.gray.body};
`

export default IconText
