import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'
import member from './Member'

let state

export class Payment extends BaseStore {
  constructor() {
    super()
    this.observable({
      history_list: [],
      change_package: {},
      payment_package: {},
      user: {},
      user_package: {},
      user_card: undefined,
      tax_invoice: {},
    })

    state = this
  }

  async getMyPlan() {
    const url = `${config.api}/v1/user/plan/my`
    const res = await http.get(url)
    const body = res.body || {}

    runInAction(() => {
      state.user = body.user
      state.user_package = body.package
      state.user_card = body.card
    })
  }

  async changePaymentToCard(json) {
    /*
      const json = {
        name,
        surname,
        encrypted_card,
        exp_month,
        exp_year,
        card
      }
    */
    const url = `${config.api}/v1/user/payment/change/credit-card`
    const res = await http.put(url, { json })
    return res.body
  }

  async changePaymentToQrCode() {
    const url = `${config.api}/v1/user/payment/change/qr-code`
    return http.put(url)
  }

  async getHistory() {
    const url = `${config.api}/v1/user/package/history`
    const res = await http.get(url)
    const list = res.body || []

    const result = []
    for (const item of list) {
      const paid = item.paid || 0
      const credit = item.credit || 0

      if (paid !== 0 || credit !== 0) {
        result.push(item)
      }
    }

    runInAction(() => {
      state.history_list = result
    })
  }

  async getChangePackage(package_id, package_duration) {
    const url = `${config.api}/v1/user/package/change/${package_id}/${package_duration}`
    const res = await http.get(url)
    const pkg = res.body || {}

    runInAction(() => {
      state.change_package = pkg
    })

    return pkg
  }

  async changePackage(package_id, package_duration, json) {
    /*
    const json = {
      payment_type: 'credit_card',
      name,
      surname,
      encrypted_card,
      exp_month,
      exp_year,
      card
    }

    const json = {
      payment_type: 'qr_code',
    }
    */

    const url = `${config.api}/v1/user/package/change/${package_id}/${package_duration}`
    const res = await http.put(url, { json })

    const { user, payment } = res.body || {}
    member.setUserProfile({ user })
    return { user, payment }
  }

  async getPaymentPackage() {
    const url = `${config.api}/v1/user/package/payment`
    const res = await http.get(url)
    const pkg = res.body || {}

    runInAction(() => {
      state.payment_package = pkg
    })

    return pkg
  }

  async makePaymentPackage(json = {}) {
    const url = `${config.api}/v1/user/package/payment`
    const res = await http.put(url, { json })
    const { user, payment, result } = res.body || {}

    member.setUserProfile({ user })
    return { user, payment, result }
  }

  async updateSubscription(val = 'yes') {
    const url = `${config.api}/v1/user/application/subscription/${val}`
    const res = await http.put(url)

    const data = res.body

    runInAction(() => {
      state.user = data
    })
  }

  async createTaxInvoice(json = {}) {
    const url = `${config.api}/v1/user/tax_invoice`
    await http.post(url, { json })
  }

  async getMyTaxInvoice() {
    const url = `${config.api}/v1/user/tax_invoice/my`
    const res = await http.get(url)

    const data = res.body

    runInAction(() => {
      state.tax_invoice = data
    })

    return data
  }

  async updateMyTaxInvoice(json = {}) {
    let res
    if (json.tax_id) {
      res = await http.put(`${config.api}/v1/user/tax_invoice/${json.tax_id}`, {
        json,
      })
    } else {
      res = await http.post(`${config.api}/v1/user/tax_invoice`, { json })
    }

    const data = res.body

    runInAction(() => {
      state.tax_invoice = data
    })

    return data
  }

  async updateNeedInvoice(val = 'yes') {
    const url = `${config.api}/v1/user/tax_invoice/subscription/${val}`
    const res = await http.put(url)

    const data = res.body
    if (data === 'success') {
      runInAction(() => {
        state.tax_invoice = { ...state.tax_invoice, need_invoice: val }
      })
    }
  }
}

export default new Payment()
