import React from 'react'
import { HashLink } from 'react-router-hash-link'

const Link = (props) => {
  const { to, style, className, onClick = null, children } = props
  const css = {
    color: 'inherit',
    display: 'contents',
    ...style,
  }

  return (
    <HashLink
      to={to}
      style={css}
      className={className}
      {...(onClick ? { onClick: onClick } : {})}
    >
      {children || 'Click'}
    </HashLink>
  )
}

export default Link
