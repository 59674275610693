import React from 'react'
import styled, { useTheme } from 'styled-components'

import Text from 'components/typo/Text'
import IconButton from 'components/button/IconButton'

const Checkbox = (props) => {
  const {
    children,
    label,
    name,
    disabled,
    checked,
    onCheck = () => {},
    size,
  } = props
  const theme = useTheme()

  const color = getColor({ isChecked: checked }, theme)
  const styleProps = { color }

  const toggle = () => {
    onCheck(!checked, name)
  }

  return (
    <Wrapper {...styleProps} disabled={disabled} onClick={toggle}>
      <Box {...styleProps}>{checked && <CheckIcon />}</Box>
      <Text size={size ? 'small' : 'xsmall'} color={theme.gray.body}>
        {label || children}
      </Text>
    </Wrapper>
  )
}
/* eslint-disable */
const CheckIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#5F2EEA" />
      <path
        d="M20.6996 5.8C20.2996 5.4 19.6996 5.4 19.2996 5.8L9.39961 16.1L4.79962 11.3C4.39962 10.9 3.79961 10.9 3.39961 11.3C2.99961 11.7 2.99961 12.3 3.39961 12.7L8.69961 18.2C8.89961 18.4 9.09961 18.5 9.39961 18.5C9.69961 18.5 9.89962 18.4 10.0996 18.2L20.7996 7.2C21.0996 6.8 21.0996 6.2 20.6996 5.8Z"
        fill="white"
      />
    </svg>
  )
}
// eslint-enable
const getColor = (props, theme) => {
  const { color, gray } = theme

  if (props.isChecked)
    return {
      bg: color.primary,
      bgHover: color.primaryDark,
      bgFocus: color.primary,
      outlineFocus: color.primaryLight,
      border: 'none',
    }

  return {
    bg: 'none',
    bgHover: gray.placeholder,
    outlineFocus: gray.inputBackground,
    bgFocus: gray.line,
    border: `1px solid ${gray.line}`,
  }
}

const Box = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 6px;
  margin-right: 8px;

  background-color: ${(props) => props.color.bg};
  border: ${(props) => props.color.border};

  &:hover {
    background-color: ${(props) => props.color.bgHover};
  }
`

const Wrapper = styled(IconButton)`
  display: flex;
  cursor: pointer;
  justify-content: flex-start;

  &:disabled {
    opacity: 0.5;
  }

  &:focus ${Box} {
    box-shadow: 0 0 0 6px ${(props) => props.color.outlineFocus};
    background-color: ${(props) => props.color.bgFocus};
  }
`

export default Checkbox
