export const USER_STATUS = Object.freeze({
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  EXPIRED: 'expired',
  DELETED: 'deleted',
})

export const USER_REGISTER_STATUS = Object.freeze({
  NONE: 'none',
  CONFIRM_EMAIL: 'confirm.email',
  PACKAGE: 'package',
  PAYMENT: 'payment',
  COMPLETED: 'completed',
})

export const USER_MEMTER_TYPE = Object.freeze({
  NONE: 'none',
  OWNER: 'owner',
  MEMBER: 'member',
})

export const USER_GENDER_TYPE = Object.freeze({
  NONE: 'none',
  MALE: 'male',
  FEMALE: 'female',
})

export const USER_GENDER_TH = Object.freeze({
  [USER_GENDER_TYPE.NONE]: '',
  [USER_GENDER_TYPE.MALE]: 'ชาย',
  [USER_GENDER_TYPE.FEMALE]: 'หญิง',
})

export const USER_SUBSCRIPTION = Object.freeze({
  NONE: 'none',
  YES: 'yes',
  NO: 'no',
})

export const USER_AGENT_TYPE = Object.freeze({
  LIA: {
    EN: 'Life Insurance Agent',
    TH: 'ใบอนุญาตตัวแทนประกันชีวิต',
  },
  'LIA/ULP': {
    EN: 'Life Insurance Agent + Unit-linked Product',
    TH: 'ใบอนุญาตตัวแทนประกันชีวิต+ประกันชีวิตควบการลงทุน',
  },
  NIA: {
    EN: 'Non-life Insurance Agent',
    TH: 'ใบอนุญาตตัวแทนประกันวินาศภัย',
  },
  LIB: {
    EN: 'Life Insurance Broker',
    TH: 'ใบอนุญาตนายหน้าประกันชีวิต',
  },
  NIB: {
    EN: 'Non-life Insurance Broker',
    TH: 'ใบอนุญาตนายหน้าประกันวินาศภัย',
  },
  IC: {
    EN: 'Investment Consultant',
    TH: 'ใบอนุญาตผู้แนะนำการลงทุน',
  },
  IB: {
    EN: 'Investment Brokerage',
    TH: 'ใบอนุญาตผู้แนะนำลูกค้าหลักทรัพย์',
  },
  IP: {
    EN: 'Investment Planner',
    TH: 'ใบอนุญาตผู้วางแผนการลงทุน',
  },
  CFP: {
    EN: 'Certified Financial Planner',
    TH: 'สมาคมนักวางแผนการเงินไทย',
  },
  AFPT: {
    EN: 'Associate Financial Planner Thailand',
    TH: 'เลขที่สมาชิกวิสามัญ สมาคมนักวางแผนการเงินไทย',
  },
})
