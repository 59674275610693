import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Text } from 'components/typo'
import { Button, FindBtn } from 'components/button'
import { FieldInput } from 'components/input'
import { message } from 'utils'
import useGTM from 'hooks/useGTM'

const PromotionModal = (props) => {
  const { onEvent } = useGTM()
  const [loading, setLoading] = useState(false)
  const [promotion, setPromotion] = useState({})
  const [verifying, setVerifying] = useState(false)
  const [code, setCode] = useState('')

  const onVerify = async () => {
    onEvent({
      pageName: 'create-account',
      eventName: 'coupon-modal-search-button-click',
    })
    try {
      setVerifying(true)
      setPromotion({})
      const pro = await props.promotion.verify(code)

      setPromotion(pro)
    } catch (e) {
      message.error({ message: e.message })
    }

    setVerifying(false)
  }

  const onApply = async () => {
    onEvent({
      pageName: 'create-account',
      eventName: 'coupon-modal-confirm-button-click',
    })
    try {
      setLoading(true)
      setCode('')
      setPromotion({})
      props.onApplyPromotion({ ...promotion, code })
      props.setOpen(false)
    } catch (e) {
      message.error({ message: e.message })
    }
    setLoading(false)
  }

  const { status } = promotion
  const err =
    status === 'not found' || status === 'used'
      ? 'รหัสไม่ถูกต้อง/ ถูกใช้ครบแล้ว'
      : undefined

  const success =
    status === 'ready' ? 'สามารถใช้รหัสนี้เป็นส่วนลดได้' : undefined

  return (
    <Maindiv>
      <Content>
        <Text size="large" bold>
          ใช้คูปองโค้ด
        </Text>
        <Title>
          <Info>ใส่คูปองโค้ดเพื่อใช้งานส่วนลด</Info>
        </Title>

        <InputBox>
          <FieldInput
            label="รหัสโปรโมชั่น/ ส่วนลด"
            value={code}
            errorText={err}
            successText={success}
            onChange={(val) => setCode(val)}
          />
          <FindArea>
            <FindBtn loading={verifying} onClick={onVerify} disabled={!code} />
          </FindArea>
        </InputBox>

        <Detail>
          <Button
            color="primary"
            text="ยืนยัน"
            maxWidth="325px"
            loading={loading}
            disabled={status !== 'ready'}
            onClick={onApply}
          />
        </Detail>
      </Content>
    </Maindiv>
  )
}

const Content = styled.div`
  width: 325px;
  text-align: center;
`

const Title = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`

const InputBox = styled.div`
  display: flex;
  column-gap: 16px;
  margin-bottom: 40px;
`

const Info = styled.div`
  font-size: 18px;
  color: #4e4b66;
`

const Detail = styled.div`
  margin-top: 40px;
  margin-bottom: 29px;
`

const FindArea = styled.div`
  float: right;
`

const Maindiv = styled.div`
  margin: 80px;
`
export default inject('promotion', 'payment')(observer(PromotionModal))
