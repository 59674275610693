import React from 'react'
/* eslint-disable */

const Logo = (props) => {
  const { size = 1 } = props
  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`

  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#D9DBE9"/>
    </svg>
  )
}

// eslint-enable
export default Logo
