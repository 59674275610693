import React from 'react'
import styled from 'styled-components'
import { Pagination as Pagina } from 'antd'

import { format } from '../../utils/format'

const Pagination = (props) => {
  const { style, text, page = {}, onChange = () => {} } = props

  const { index = 1, total = 0, per_page = 1 } = page

  let local_index

  const onPage = (val) => {
    if (local_index !== val) {
      onChange(val, per_page)
    }
  }

  const onShowSizeChange = (val, size) => {
    local_index = val
    onChange(1, size)
  }

  return (
    <Page style={style}>
      <Pagina
        size="small"
        showTotal={(total) => `${text} ${format.toDigi(total)}`}
        current={index}
        pageSize={per_page}
        total={total}
        onChange={onPage}
        onShowSizeChange={onShowSizeChange}
      />
    </Page>
  )
}

const Page = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 4px 10px;
`

export default Pagination
