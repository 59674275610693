import styled, { css } from 'styled-components'

function FeatureSelector({
  icon = null,
  topic = '',
  isSelected = false,
  onClick = () => {},
}) {
  return (
    <Container onClick={onClick} isSelected={isSelected}>
      <Icon src={icon} alt="feature icon" />
      <Topic>{topic}</Topic>
    </Container>
  )
}

export default FeatureSelector

const Container = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 10px;
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  background-color: #f7f7fc;
  cursor: pointer;
  transition: background-color 0.3s;

  ${(props) =>
    props.isSelected
      ? css`
          background-color: #5f2eea;
          > img {
            filter: brightness(100);
          }
          > p {
            color: white;
          }
        `
      : ''};
`
const Icon = styled.img`
  display: block;
  width: 40px;
  height: 40px;
  transition: filter 0.3s;
`
const Topic = styled.p`
  width: fit-content;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 34px;
  color: #5f2eea;
  white-space: nowrap;
  transition: color 0.3s;

  @media screen and (max-width: 990px) {
    display: none;
  }
`
