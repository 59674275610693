import React, { useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'

import { Title, Row, Col } from './components'
import Asset from './Asset'
import Loan from './Loan'
import Finance from './Finance'
import Risk from './Risk'

const PlanReport = (props) => {
  const onInit = useCallback(async () => {
    try {
      await props.report.get()
    } catch (e) {
      console.log('e', e.message)
    }
  }, [props.report])

  useEffect(() => {
    onInit()
  }, [onInit])

  const doc = props.report.toJS().doc
  console.log('doc:', doc)
  const css = {
    width: '925px',
    padding: '20px',
  }

  const cssTitle = {
    color: '#14142B',
    fontSize: '24px',
    textAlign: 'center',
    marginBottom: '28px',
  }
  return (
    <div style={css}>
      <div style={cssTitle}>{doc.name}</div>
      <Title title="งบดุล" detail="ความมั่งคั่งสุทธิ ฿ 39,000,000.00" />
      <Row>
        <Col paddingRight="12px">
          <Asset doc={doc} />
        </Col>
        <Col paddingLeft="12px">
          <Loan />
        </Col>
      </Row>

      <div style={{ marginTop: '46px' }} />
      <Title
        title="งบกระแสเงินสด"
        detail="คงเหลือ ฿ 39,000,000.00/ปี หรือ ฿ 39,000/เดือน"
      />
      <Row>
        <Col paddingRight="12px">
          <Asset />
        </Col>
        <Col paddingLeft="12px">
          <Loan />
        </Col>
      </Row>

      <div style={{ marginTop: '46px' }} />
      <Title title="อัตราส่วนทางการเงิน" />
      <Finance />

      <div style={{ marginTop: '46px' }} />
      <Title title="ข้อมูลอื่นๆ ที่เกี่ยวข้องกับตัวบุคคล เพื่อใช้ในการวางแผน" />
      <Risk />
    </div>
  )
}

export default inject('report')(observer(PlanReport))
