import React from 'react'

import Row from './Row'
import Col from './Col'

const Header = (props) => {
  const { title, value, isLast } = props

  const border = '1px solid #D9DBE9'
  const css = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '0px 20px',
    color: '#4E4B66',
    backgroundColor: 'white',
    borderRadius: isLast ? '0px 0px 16px 16px' : '0px',
    borderTop: '0px',
    borderBottom: border,
    borderLeft: border,
    borderRight: border,
  }
  const borderLeft = '1px solid #D9DBE9'
  const textAlign = 'right'
  return (
    <div style={css}>
      <Row>
        <Col size="60%">{title}</Col>

        <Col size="40%" borderLeft={borderLeft} textAlign={textAlign}>
          {value}
        </Col>
      </Row>
    </div>
  )
}

export default Header
