import { PACKAGE_TYPE, PACKAGE_DURATION } from 'definition/package'

const getPackageInfo = (packageInfo) => {
  const { package_type, package_price, package_duration } = packageInfo || {}
  const info = {
    name: '',
    price: package_price || 0,
    unit: package_duration === PACKAGE_DURATION.PER_MONTH ? 'เดือน' : 'ปี',
  }
  switch (package_type) {
    case PACKAGE_TYPE.STARTER:
    case PACKAGE_TYPE.STANDARD:
      info.name = 'เริ่มต้น'
      break
    case PACKAGE_TYPE.PROFESSIONAL:
      info.name = 'โปร'
      break
    case PACKAGE_TYPE.TEAM:
      info.name = 'ทีม'
      break
    case PACKAGE_TYPE.TRIAL:
    case PACKAGE_TYPE.FREE:
      info.name = 'ฟรี'
      break
    default:
  }

  return info
}

export const tabs = [
  {
    label: PACKAGE_DURATION.PER_MONTH_TH,
    value: PACKAGE_DURATION.PER_MONTH,
  },
  {
    label: PACKAGE_DURATION.PER_YEAR_TH,
    value: PACKAGE_DURATION.PER_YEAR,
  },
]

export default getPackageInfo
