import styled from 'styled-components'

import PLANT_CONSULTANT from './assets/plant_consultant.png'
import SIAM_WEALTH from './assets/siam_wealth.png'

const PARTNER_LIST = [
  { src: SIAM_WEALTH, alt: 'siam wealth logo' },
  { src: PLANT_CONSULTANT, alt: 'plant consultant logo' },
]

function Partner() {
  return (
    <Container>
      <Topic>พันธมิตร</Topic>
      <PartnerContainer>
        {PARTNER_LIST.map((partner, idx) => (
          <Logo src={partner.src} alt={partner.alt} key={idx} />
        ))}
      </PartnerContainer>
    </Container>
  )
}

export default Partner

const Container = styled.div`
  padding: 24px;
`
const Topic = styled.p`
  margin-bottom: 32px;
  font-size: 32px;
  font-weight: bold;
  line-height: 36px;
  text-align: center;
  color: #5f2eea;
`
const PartnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @media screen and (max-width: 465px) {
    flex-direction: column;
  }
`
const Logo = styled.img``
