import React from 'react'
import styled from 'styled-components'
import { Row } from 'antd'

const RowItem = (props) => {
  const { justify, align } = props
  return (
    <PageView style={props.style}>
      <Row justify={justify} align={align}>
        {props.children}
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  padding-bottom: 8px;
`

export default RowItem
