import React, { useState } from 'react'
import styled from 'styled-components'

import { Text } from 'components/typo'

let intervalId
const COUNT = 59
const VerifyMail = (props) => {
  const [count, setCount] = useState(COUNT)
  const { onSendEmail, onChangeEmail, email } = props

  const onResend = () => {
    onSendEmail(email)
    countDown()
  }

  const countDown = () => {
    intervalId = setInterval(() => {
      setCount((count) => count - 1)
    }, 1000)
  }

  if (count <= 0) {
    clearInterval(intervalId)
    setCount(COUNT)
  }
  const isCounting = count !== COUNT

  const btnText = isCounting ? (
    <DisabledText>รออีก {count} วินาที เพื่อส่งอีเมลอีกครั้ง...</DisabledText>
  ) : (
    <ActionText onClick={onResend}>ส่งอีเมลอีกครั้ง</ActionText>
  )

  return (
    <Title>
      <Text size="large" bold>
        ผูกอีเมล
      </Text>

      <TextSent>เราได้ส่งอีเมลไปที่</TextSent>
      <Text size="large" bold>
        {email}
      </Text>

      <Label>โปรดยืนยันอีเมลเพื่อดำเนินการต่อ</Label>

      {btnText}
      <ActionText onClick={onChangeEmail}>เปลี่ยนอีเมล</ActionText>
    </Title>
  )
}

const Title = styled.div`
  margin: 0 0 36px;
  text-align: center;
`

const TextSent = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
  color: #4e4b66;
`

const Label = styled.div`
  margin-top: 16px;
  margin-bottom: 40px;
  color: #4e4b66;
`

const DisabledText = styled.div`
  margin-bottom: 29px;
  color: #5f2eea;
  opacity: 0.6;
  font-size: 14px;
`

const ActionText = styled.div`
  margin-bottom: 29px;
  color: #5f2eea;
  cursor: pointer;
  font-size: 14px;
`

export default VerifyMail
