import React, { useState } from 'react'
import styled from 'styled-components'

import { Text } from 'components/typo'
import { Button } from 'components/button'
import { FieldInput } from 'components/input'
import { helper } from 'utils'

const SendOtp = (props) => {
  const [mobile, setMobile] = useState('')

  const { loading, onSendOtp } = props
  const onChange = (val) => {
    const newValue = String(val || '').replaceAll('-', '')
    if (Number.isNaN(Number(newValue))) return

    if (newValue.length <= 10) {
      setMobile(newValue)
    }
  }

  const onSend = () => {
    onSendOtp(mobile)
  }

  return (
    <Content>
      <Title>
        <Text size="large" bold>
          ผูกหมายเลขโทรศัพท์
        </Text>
      </Title>
      <Text gray>
        เพื่อความปลอดภัย กรุณาตั้งค่าเบอร์โทรศัพท์
        <br />
        ของคุณก่อนดำเนินงานต่อ
      </Text>

      <Space />
      <FieldInput
        small
        label="หมายเลขโทรศัพท์"
        value={helper.formatPattern(mobile, 'xxx-xxx-xxxx')}
        onChange={onChange}
        disabled={loading}
      />

      <Detail>
        <Button
          color="primary"
          text="ส่งรหัส"
          maxWidth="325px"
          loading={loading}
          onClick={onSend}
          disabled={mobile.length < 10}
        />
      </Detail>
    </Content>
  )
}

const Content = styled.div``

const Title = styled.div`
  margin: 0 0 36px;
  text-align: center;
`

const Space = styled.div`
  margin-top: 24px;
`

const Detail = styled.div`
  margin-top: 40px;
`

export default SendOtp
