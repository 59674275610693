import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { size = 1 } = props;

  const width = `${Math.ceil(30 * size)}`;
  const height = `${Math.ceil(30 * size)}`;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15Z"
        stroke="#4E4B66"
        strokeWidth="2"
      />
      <path
        d="M10.0009 14.4414H14.4453H15.5564H20.0009C20.3342 14.4414 20.5564 14.6636 20.5564 14.997C20.5564 15.3303 20.3342 15.5525 20.0009 15.5525H15.5564H14.4453H10.0009C9.66753 15.5525 9.44531 15.3303 9.44531 14.997C9.44531 14.6636 9.66753 14.4414 10.0009 14.4414Z"
        fill="#4E4B66"
      />
    </svg>
  );
};
// eslint-enable
export default Logo;
