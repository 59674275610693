import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import Button from 'components/button/Button'
import useGTM from 'hooks/useGTM'

import CLIENT_ICON from './assets/client.svg'
import OPPORTUNITY_ICON from './assets/opportunity.svg'
import PLANNING_ICON from './assets/planning.svg'
import FeatureCard from './components/FeatureCard'

const FEATURES_LIST = [
  {
    icon: PLANNING_ICON,
    topic: 'วางแผน',
    description: {
      desktop: (
        <>
          อัพเลเวลสู่ตัวแทนมืออาชีพ
          <br />
          ด้วยเครื่องมือวางแผนการเงินที่ครอบคลุม
          <br />
          และครบครัน
        </>
      ),
      tablet: (
        <>
          อัพเลเวลสู่ตัวแทนมืออาชีพ
          <br />
          ด้วยเครื่องมือวางแผนการเงินที่ครอบคลุม และครบครัน
        </>
      ),
      mobile: (
        <>
          อัพเลเวลสู่ตัวแทนมืออาชีพ
          <br />
          ด้วยเครื่องมือวางแผนการเงินที่ครอบคลุม
          <br />
          และครบครัน
        </>
      ),
    },
  },
  {
    icon: CLIENT_ICON,
    topic: 'ลูกค้า',
    description: {
      desktop: (
        <>
          บริหารข้อมูลลูกค้าง่ายเป็นระบบ
          <br />
          ต่อยอดการบริการลูกค้า
          <br />
          อย่างมืออาชีพด้วย CRM&CDP
        </>
      ),
      tablet: (
        <>
          บริหารข้อมูลลูกค้าง่ายเป็นระบบ ต่อยอดการบริการลูกค้า
          <br />
          อย่างมืออาชีพด้วย CRM&CDP
        </>
      ),
      mobile: (
        <>
          บริหารข้อมูลลูกค้าง่ายเป็นระบบ
          <br />
          ต่อยอดการบริการลูกค้า
          <br />
          อย่างมืออาชีพด้วย CRM&CDP
        </>
      ),
    },
  },
  {
    icon: OPPORTUNITY_ICON,
    topic: 'โอกาสในการขาย',
    description: {
      desktop: (
        <>
          ช่วยให้คุณไม่พลาดทุกโอกาสสำคัญ
          <br />
          บริหารงานขายอย่างมืออาชีพ
        </>
      ),
    },
  },
]

function Features() {
  const history = useHistory()
  const { onEvent } = useGTM()

  const onFeatureClick = () => {
    onEvent({
      pageName: 'home',
      eventName: 'home-feature-button-click',
    })
    history.push('/features')
  }

  const onVideoClick = (e) => {
    e.preventDefault()
    // TODO: Find the way to implement this.
    // onEvent({
    //   pageName: 'home',
    //   eventName: 'home-video-play',
    // })
  }

  return (
    <Container>
      <Topic>ฟีเจอร์</Topic>
      <FeaturesContainer>
        {FEATURES_LIST.map((feature, idx) => (
          <FeatureCard
            key={idx}
            icon={feature.icon}
            topic={feature.topic}
            description={feature.description}
          />
        ))}
      </FeaturesContainer>
      <Button
        large
        color="secondary"
        maxWidth="220px"
        fontSize="bold"
        onClick={onFeatureClick}
      >
        <b>ดูฟีเจอร์ทั้งหมด</b>
      </Button>
      <VideoContainer onClick={onVideoClick}>
        <Video
          src="https://www.youtube-nocookie.com/embed/YNrUJLfvBgU"
          title="YouTube video player"
          frameBorder="0"
          //eslint-disable-next-line max-len
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </VideoContainer>
    </Container>
  )
}

export default Features

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 32px;

  @media screen and (max-width: 465px) {
    padding: 32px 16px;
  }
`
const Topic = styled.p`
  margin-bottom: 32px;
  font-size: 32px;
  font-weight: bold;
  line-height: 36px;
  text-align: center;
  color: #5f2eea;
`
const FeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  max-width: 1080px;
  margin: 0 auto 72px;
  gap: 16px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`
const VideoContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 72px auto 0;
`
const Video = styled.iframe`
  display: block;
  width: 1080px;
  height: 600px;
  margin: 0 auto;
  border-radius: 16px;

  @media screen and (max-width: 1024px) {
    width: 800px;
    height: 480px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 380px;
  }

  @media screen and (max-width: 568px) {
    width: 100%;
    height: 300px;
  }
`
