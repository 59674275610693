import React from 'react'

import { Headline, Header, RowItem, Space } from './components'

const Asset = (props) => {
  const { doc = {} } = props
  const liquid_asset_list = doc.liquid_asset_list || []

  const asset_len = liquid_asset_list.length - 1
  const asset = liquid_asset_list.map((it, i) => {
    return (
      <RowItem
        key={i}
        title={it.name}
        value={it.value}
        isLast={i === asset_len}
      />
    )
  })
  return (
    <>
      <Headline title="สินทรัพย์ทั้งหมด" value="3,000,000.00" bg="green" />

      <Space />
      <Header title="สินทรัพย์ทั้งหมด" value="3,000,000.00" />
      {asset}

      <Space size="8px" />
      <Header title="สินทรัพย์เพื่อการลงทุนและธุรกิจ" value="3,000,000.00" />
      <RowItem title="เงินสด" value="1,000,000.00" />
      <RowItem title="อื่นๆ" value="1,000,000.00" isLast={true} />

      <Space size="8px" />
      <Header title="สินทรัพย์ส่วนตัว" value="3,000,000.00" />
      <RowItem title="เงินสด" value="1,000,000.00" />
      <RowItem title="อื่นๆ" value="1,000,000.00" isLast={true} />
    </>
  )
}

export default Asset
