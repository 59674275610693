import styled from 'styled-components'

import { MetaData } from 'components/display'
import { Text } from 'components/typo'
import useMediaQuery from 'hooks/useMediaQuery'

const Privacy = () => {
  const isMobile = useMediaQuery('(max-width:713px)')

  return (
    <AppView>
      <MetaData title="ฟรายเดย์ - Friday" description="privacy policy" />
      <Header>Privacy Policy</Header>
      {isMobile ? (
        <Text>
          Friday คือระบบสำหรับตัวแทนประกันที่ถูกพัฒนาขึ้นโดยบริษัท TQD
          ซึ่งเป็นบริษัทผู้เชี่ยวชาญด้านเทคโนโลยี และการประกันภัย
          ด้วยความตั้งใจที่อยากให้ทุกคนเข้าถึงบริการทางการเงินที่มีประสิทธิภาพ
          และตอบโจทย์ กับความต้องการได้อย่างเท่าเทียม Friday
          จึงถูกคิดค้นและพัฒนา เพื่อให้อุตสาหกรรม ด้านการประกันภัยก้าวไปสู่
          Digital Transformation ได้อย่างเต็มรูปแบบ{' '}
          <Link href="https://www.tqd.co.th/portfolio/">
            ดูผลิตภัณ์ที่เกี่ยวข้อง
          </Link>
        </Text>
      ) : (
        <Text>
          Friday คือระบบสำหรับตัวแทนประกันที่ถูกพัฒนาขึ้นโดยบริษัท TQD
          ซึ่งเป็นบริษัทผู้เชี่ยวชาญด้านเทคโนโลยี
          <br />
          และการประกันภัย
          ด้วยความตั้งใจที่อยากให้ทุกคนเข้าถึงบริการทางการเงินที่มีประสิทธิภาพ
          และตอบโจทย์
          <br />
          กับความต้องการได้อย่างเท่าเทียม Friday จึงถูกคิดค้นและพัฒนา
          เพื่อให้อุตสาหกรรม
          <br />
          ด้านการประกันภัยก้าวไปสู่ Digital Transformation ได้อย่างเต็มรูปแบบ{' '}
          <Link href="https://www.tqd.co.th/portfolio/">
            ดูผลิตภัณ์ที่เกี่ยวข้อง
          </Link>
        </Text>
      )}
    </AppView>
  )
}

const AppView = styled.div`
  padding: 40px 16px;
  text-align: center;
`

const Header = styled.h1`
  margin-bottom: 40px;
  font-weight: bold;
  color: ${(props) => props.theme.color.primary};
`

const Link = styled.a`
  font-weight: bold;
  color: ${(props) => props.theme.color.primary};
  text-wrap: nowrap;

  :hover {
    color: ${(props) => props.theme.color.primary};
  }
`

export default Privacy
