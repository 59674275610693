import { useRef, useState } from 'react'

const DEFAULT_VERTICAL = 'bottom'
const DEFAULT_HORIZONTAL = 'left'

const usePopup = (props) => {
  const { show, forwardRef } = props
  const ref = useRef(forwardRef)
  const innerRef = useRef()

  const [horizontal, setHorizontal] = useState({
    direction: DEFAULT_HORIZONTAL,
  })
  const [vertical, setVertical] = useState({ direction: DEFAULT_VERTICAL })

  const onCalculatePosition = () => {
    const maxHeight = window.innerHeight
    const maxWidth = window.innerWidth

    const box = ref.current.getBoundingClientRect()
    const innerBox = innerRef.current.getBoundingClientRect()

    onSetHorizontal({ maxWidth, box, innerBox })
    onSetVertical({ maxHeight, box, innerBox })
  }

  const onSetHorizontal = ({ maxWidth, box, innerBox }) => {
    if (show.h === 'center') return setHorizontal({})

    const isOnRight = maxWidth - box.x < innerBox.width
    const isOnLeft = box.x < innerBox.width
    const hMap = {
      right: { direction: 'right', value: 0 },
      left: { direction: 'left', value: -10 },
    }

    let newHorizontal = {}
    if (isOnLeft === isOnRight) newHorizontal = hMap[show.h]
    else if (isOnRight) newHorizontal = hMap.left
    else if (isOnLeft) newHorizontal = hMap.right

    setHorizontal((h) => ({ ...h, ...newHorizontal }))
  }

  const onSetVertical = ({ box, maxHeight, innerBox }) => {
    const isOnTop = box.y > innerBox.height
    const isOnBottom = maxHeight - box.y > innerBox.height
    const vMap = {
      top: { direction: 'top', value: -(10 + innerBox.height) },
      bottom: { direction: 'bottom', value: -(10 + innerBox.height) },
    }
    let newVertical = {}
    if (isOnTop && isOnBottom) newVertical = vMap[show.v || DEFAULT_VERTICAL]
    else if (isOnTop) newVertical = vMap.top
    else if (isOnBottom) newVertical = vMap.bottom

    setVertical((v) => ({ ...v, ...newVertical }))
  }

  return { ref, innerRef, vertical, horizontal, onCalculatePosition }
}

export default usePopup
