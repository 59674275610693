import React, { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import Menu from 'pages/account/components/Menu/Menu'
import { Text } from 'components/typo'
import { MetaData } from 'components/display'
import Loading from 'components/loading'
import { message } from 'utils'

import ConnectDialog from './ConnectDialog'
import CancelDialog from './CancelDialog'
import PaymentContent from './PaymentContent'

const MyPayment = (props) => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const { user } = props.payment.toJS()
  const isSubscription = user.subscription === 'yes'

  const onInit = useCallback(async () => {
    try {
      setLoading(true)

      await props.payment.getMyPlan()
      await props.payment.getMyTaxInvoice()
    } catch (e) {
      message.error()
    }
    setLoading(false)
  }, [props.payment])

  useEffect(() => {
    onInit()
  }, [onInit])

  const onVisible = (val = false) => setVisible(val)
  const onClose = () => setVisible(false)
  const onConfirm = () => {
    if (isSubscription) onCancelSubscription()
    else onConnectSubscription()
  }

  const dialog = isSubscription ? (
    <CancelDialog
      user={user}
      visible={visible}
      onConfirm={onConfirm}
      onClose={onClose}
    />
  ) : (
    <ConnectDialog
      user={user}
      visible={visible}
      onConfirm={onConfirm}
      onClose={onClose}
    />
  )

  const onConnectSubscription = async () => {
    try {
      setLoading(true)
      onClose()
      await props.payment.updateSubscription('yes')
    } catch (e) {
      message.error()
    }

    setLoading(false)
  }

  const onCancelSubscription = async () => {
    try {
      setLoading(true)
      onClose()
      await props.payment.updateSubscription('no')
    } catch (e) {
      message.error()
    }

    setLoading(false)
  }

  return (
    <Menu>
      <MetaData title="ข้อมูลการชำระเงิน" />
      <Content>
        <Title>
          <Text size="large" bold>
            ข้อมูลการชำระเงิน
          </Text>
        </Title>
        <Loading loading={loading}>
          <PaymentContent onVisible={onVisible} setLoading={setLoading} />
        </Loading>
      </Content>
      {dialog}
    </Menu>
  )
}

const Content = styled.div`
  width: 100%;
  max-width: 600px;
`

const Title = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 52px;

  @media screen and (max-width: 1024px) {
    margin-bottom: 40px;
  }
`

export default inject('payment')(observer(MyPayment))
