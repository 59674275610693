import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams, useLocation } from 'react-router-dom'
import qs from 'query-string'
import { inject, observer } from 'mobx-react'

import { PasswordInput } from 'components/input'
import { Text } from 'components/typo'
import { Button } from 'components/button'
import Layout from 'pages/account/components/Layout'
import Loading from 'components/loading'
import { MetaData, HelperText } from 'components/display'
import { validator } from 'utils'
import useGTM from 'hooks/useGTM'

const passwordComplexity = [
  'รหัสผ่านต้องไม่มีช่องว่าง',
  'รหัสผ่านต้องมีตัวเลข (0-9) อย่างน้อย 1 ตัว',
  'รหัสผ่านต้องมีความยาวอยู่ระหว่าง 8-15 ตัวอักษร',
  'รหัสผ่านต้องประกอบด้วยตัวอักษรภาษาอังกฤษพิมพ์เล็ก (a-z) อย่างน้อย 1 ตัว',
  'รหัสผ่านต้องประกอบด้วยตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ (A-Z) อย่างน้อย 1 ตัว',
]

const SetPassword = (props) => {
  const params = useParams()
  const location = useLocation()
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [loading, setLoading] = useState({})
  const [error, setError] = useState({ validate: [] })
  const { onPageLoad, onEvent } = useGTM()

  const setUserPassword = async (e) => {
    e.preventDefault()
    try {
      setLoading({ password: true })
      const query = qs.parse(location.search)
      const { ref_code } = params
      const { code } = query

      await props.member.setPassword({
        code,
        ref_code,
        password,
      })

      props.history.push('/bind-mobile')
    } catch (e) {
      let message = e.message
      setError({ password: message })
    }
    setLoading({ password: false })
  }

  const onPasswordChange = (val) => {
    const { password } = validate({ password: val, repeatPassword })
    setPassword(password)
  }

  const onRepeatPasswordChange = (val) => {
    const { repeatPassword } = validate({
      password,
      repeatPassword: val,
    })
    setRepeatPassword(repeatPassword)
  }

  const validate = ({ password, repeatPassword }) => {
    password = password.trim()
    repeatPassword = repeatPassword.trim()
    const validate = validator.validatePassword(password, repeatPassword)

    setError({ validate })

    return { password, repeatPassword }
  }

  const onClear = () => {
    setError({})
  }

  const verifyEmail = useCallback(async () => {
    try {
      setLoading({ verify: true })
      const query = qs.parse(location.search)
      const { ref_code } = params
      const { code } = query

      await props.member.verifyRegisterCode({
        code,
        ref_code,
      })
    } catch (e) {
      let message = e.message
      if (message === 'ref_code not found') {
        message = 'ไม่พบเลขหมายอ้างอิงอยู่ในระบบ กรุณาสมัครบัญชีใหม่อีกครั้ง'
      }
      setError({ verify: message })
    }
    setLoading({ verify: false })
  }, [location.search, params, props.member])

  const onSubmit = () => {
    onEvent({
      pageName: 'create-account',
      eventName: 'craete-profile-password-confirm-button-click',
    })
  }

  useEffect(() => {
    verifyEmail()
  }, [verifyEmail])

  useEffect(() => {
    onPageLoad({ pageName: 'create-account' })
  }, [onPageLoad])

  if (loading.verify) {
    return (
      <Layout header="" subHeader="กำลังตรวจสอบ...">
        <Loading loading />
      </Layout>
    )
  }

  if (error.verify) {
    return (
      <Layout
        header=""
        subHeader="ตรวจสอบโค้ดผิดพลาด กรุณาสร้างบัญชีผู้ใช้ใหม่"
      >
        <HelperText errorText={error.verify} />
      </Layout>
    )
  }

  const hasError = error.validate?.length > 0 && 'รหัสผ่านไม่ถูกต้อง'
  const isRepeatError =
    password !== repeatPassword && !hasError && 'รหัสผ่านไม่ตรงกัน'
  const isDisabledButton = hasError || isRepeatError || password.length === 0
  return (
    <Layout header="ตั้งค่าบัญชีผู้ใช้งานของคุณ" subHeader="ขั้นตอนที่ 1 จาก 4">
      <MetaData
        title={'ตั้งค่าบัญชีผู้ใช้งานของคุณ'}
        description={'สร้างรหัสผ่าน'}
      />
      <Box>
        <Text align="center">สร้างรหัสผ่าน</Text>
        <Form onSubmit={setUserPassword}>
          <PasswordInput
            value={password}
            onChange={onPasswordChange}
            toolTip={<PasswordHint list={passwordComplexity} />}
            errorText={hasError}
            onClear={onClear}
          />
          <PasswordInput
            label={'ยืนยันรหัสผ่าน'}
            value={repeatPassword}
            onChange={onRepeatPasswordChange}
            loading={loading.password}
            errorText={isRepeatError}
            onClear={onClear}
          />
          <ButtonWrapper>
            <Button
              type="submit"
              loading={loading.password}
              disabled={isDisabledButton}
              color="primary"
              text="ถัดไป"
              onClick={onSubmit}
            />
          </ButtonWrapper>
          <HelperText errorText={error.password} />
        </Form>
      </Box>
    </Layout>
  )
}

const PasswordHint = (props) => {
  if (!props.list) return null

  return (
    <HintWrapper>
      {props.list.map((i) => (
        <li key={i}>{i}</li>
      ))}
    </HintWrapper>
  )
}

const HintWrapper = styled.ul`
  margin: 0;
  padding-left: 16px;

  & li {
    margin: 0;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;

  padding: 32px 0;
  row-gap: 24px;
`

const ButtonWrapper = styled.div`
  margin-top: 12px;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 325px;
  width: 100%;

  @media screen and (max-width: 500px) {
    width: 85%;
  }
`

export default inject('member')(observer(SetPassword))
