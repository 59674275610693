import React from 'react'
import styled from 'styled-components'
import { Col } from 'antd'

import { Text } from 'components/typo'

const TableHeader = (props) => {
  const { children, line, text, bold } = props
  const content = text ? (
    <Text title small bold={bold}>
      {text}
    </Text>
  ) : (
    children
  )

  const css = line ? { borderRight: '1px solid #d9dbe9' } : undefined
  return (
    <Col {...props} style={css}>
      <Cell>{content}</Cell>
    </Col>
  )
}

const Cell = styled.div`
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default TableHeader
