export const PAYMENT_TYPE = Object.freeze({
  CARD: 'card',
  QR: 'qr',
})

export const PAYMENT_SOURCE = Object.freeze({
  DIRECT: 'direct',
  RECURRING: 'recurring',
  CHANGE_PACKAGE: 'change_package',
  CHANGE_CARD: 'change_card',
  CURRENT_PACKAGE: 'current_package',
})

export const PAYMENT_STATUS = Object.freeze({
  INIT: 'init',
  PENDING: 'pending',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  REJECTED: 'rejected',
})

export const PAYMENT_RETURN_STATUS = Object.freeze({
  NONE: 'none',
  PENDING: 'pending',
  CANCELLED: 'cancelled',
})
