import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { width = 44, height = 26 } = props

  return (
      <svg width={width} height={height} viewBox="0 0 46 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.8535 19.4626L19.8335 8.58594H22.9135L21.0215 19.4626H17.8535Z" fill="#3C58BF"/>
          <path d="M17.8535 19.4626L20.3615 8.58594H22.8695L20.9775 19.4626H17.8535Z" fill="#293688"/>
          <path d="M32.1094 8.71495C31.4934 8.45495 30.5254 8.23828 29.2934 8.23828C26.2134 8.23828 24.0134 9.75495 24.0134 11.9216C24.0134 13.5683 25.5534 14.3916 26.7854 14.9549C28.0174 15.5183 28.3694 15.8649 28.3694 16.3416C28.3694 17.0783 27.4014 17.4249 26.5214 17.4249C25.2894 17.4249 24.6294 17.2516 23.5734 16.8183L23.1334 16.6449L22.6934 19.1149C23.4414 19.4183 24.7614 19.7216 26.2134 19.7216C29.4694 19.7216 31.6254 18.2049 31.6254 15.9083C31.6254 14.6516 30.8334 13.6549 28.9854 12.8749C27.8854 12.3116 27.1814 12.0083 27.1814 11.4883C27.1814 11.0116 27.7534 10.5349 28.9854 10.5349C30.0414 10.5349 30.7894 10.7083 31.3174 10.9683L31.6254 11.0983L32.1094 8.71495Z" fill="#3C58BF"/>
          <path d="M32.1084 8.71495C31.4924 8.45495 30.5244 8.23828 29.2924 8.23828C26.2124 8.23828 24.5404 9.75495 24.5404 11.9216C24.5404 13.5683 25.5084 14.3916 26.7404 14.9549C27.9724 15.5183 28.3244 15.8649 28.3244 16.3416C28.3244 17.0783 27.3564 17.4249 26.4764 17.4249C25.2444 17.4249 24.5844 17.2516 23.5284 16.8183L23.0884 16.6449L22.6484 19.1149C23.3964 19.4183 24.7164 19.7216 26.1684 19.7216C29.4244 19.7216 31.5804 18.2049 31.5804 15.9083C31.5804 14.6516 30.7884 13.6549 28.9404 12.8749C27.8404 12.3116 27.1364 12.0083 27.1364 11.4883C27.1364 11.0116 27.7084 10.5349 28.9404 10.5349C29.9964 10.5349 30.7444 10.7083 31.2724 10.9683L31.5804 11.0983L32.1084 8.71495Z" fill="#293688"/>
          <path d="M37.3876 8.58594C36.6396 8.58594 36.1116 8.62927 35.8036 9.36594L31.1836 19.4626H34.4836L35.0996 17.6426H39.0156L39.3676 19.4626H42.3596L39.7636 8.58594H37.3876ZM35.9796 15.8659C36.1556 15.3026 37.2116 12.6593 37.2116 12.6593C37.2116 12.6593 37.4756 12.0093 37.6516 11.5759L37.8276 12.6159C37.8276 12.6159 38.4436 15.3459 38.5756 15.9526H35.9796V15.8659Z" fill="#3C58BF"/>
          <path d="M38.1356 8.58594C37.3876 8.58594 36.8596 8.62927 36.5516 9.36594L31.1836 19.4626H34.4836L35.0996 17.6426H39.0156L39.3676 19.4626H42.3596L39.7636 8.58594H38.1356ZM35.9796 15.8659C36.2436 15.2593 37.2116 12.6593 37.2116 12.6593C37.2116 12.6593 37.4756 12.0093 37.6516 11.5759L37.8276 12.6159C37.8276 12.6159 38.4436 15.3459 38.5756 15.9526H35.9796V15.8659Z" fill="#293688"/>
          <path d="M12.1766 16.2119L11.8686 14.6519C11.2966 12.8319 9.53664 10.8385 7.55664 9.88521L10.3286 19.5485H13.6286L18.6006 8.67188H15.3006L12.1766 16.2119Z" fill="#3C58BF"/>
          <path d="M12.1766 16.2119L11.8686 14.6519C11.2966 12.8319 9.53664 10.8385 7.55664 9.88521L10.3286 19.5485H13.6286L18.6006 8.67188H15.9166L12.1766 16.2119Z" fill="#293688"/>
          <path d="M3.64062 8.58594L4.21263 8.71594C8.12862 9.62594 10.8566 11.9226 11.9126 14.6526L10.8126 9.49594C10.6366 8.75927 10.0646 8.58594 9.40462 8.58594H3.64062Z" fill="#FFBC00"/>
          <path d="M3.64062 8.58594C7.55663 9.49594 10.8126 11.8359 11.8686 14.5659L10.8126 10.2759C10.6366 9.53927 10.0206 9.10594 9.31663 9.10594L3.64062 8.58594Z" fill="#F7981D"/>
          <path d="M3.64062 8.58594C7.55663 9.49594 10.8126 11.8359 11.8686 14.5659L11.1206 12.2259C10.9446 11.4893 10.6806 10.7526 9.84463 10.4493L3.64062 8.58594Z" fill="#ED7C00"/>
          <path d="M15.2565 15.8632L13.1885 13.8265L12.2205 16.1232L11.9565 14.6065C11.3845 12.7865 9.62453 10.7932 7.64453 9.83984L10.4165 19.5032H13.7165L15.2565 15.8632Z" fill="#051244"/>
          <path d="M20.9775 19.4636L18.3375 16.8203L17.8535 19.4636H20.9775Z" fill="#051244"/>
          <path d="M28.0614 15.7344C28.3254 15.9944 28.4134 16.1677 28.3694 16.3844C28.3694 17.121 27.4014 17.4677 26.5214 17.4677C25.2894 17.4677 24.6294 17.2944 23.5734 16.861L23.1334 16.6877L22.6934 19.1577C23.4414 19.461 24.7614 19.7644 26.2134 19.7644C28.1934 19.7644 29.7774 19.201 30.7014 18.2477L28.0614 15.7344Z" fill="#051244"/>
          <path d="M31.625 19.4612H34.529L35.145 17.6413H39.061L39.413 19.4612H42.361L41.305 15.0413L37.609 11.5312L37.785 12.4846C37.785 12.4846 38.401 15.2146 38.533 15.8213H35.937C36.201 15.2146 37.169 12.6146 37.169 12.6146C37.169 12.6146 37.433 11.9646 37.609 11.5312" fill="#051244"/>
          <path d="M41.48 27H4.52C2.584 27 1 25.44 1 23.5333V4.46667C1 2.56 2.584 1 4.52 1H41.48C43.416 1 45 2.56 45 4.46667V23.5333C45 25.44 43.416 27 41.48 27Z" stroke="#E6E6E6" strokeWidth="2" stroke-miterlimit="10"/>
      </svg>
  );
}
// eslint-enable
export default Logo
