import React, { useState, useEffect, useCallback, Fragment } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import { config } from 'config'
import { Display, Text } from 'components/typo'
import { Button } from 'components/button'
import Loading from 'components/loading'
import { format, timer, message } from 'utils'
import useGTM from 'hooks/useGTM'

const PaymentResult = (props) => {
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(undefined)
  const [user, setUser] = useState(undefined)
  const { code } = props.match.params
  const { onPageLoad, onEvent } = useGTM()

  const onInit = useCallback(
    async (invoice_no) => {
      setLoading(true)
      try {
        const { payment, user } = await props.account.getPaymentResult(
          invoice_no
        )
        setResult(payment)
        setUser(user)
      } catch (e) {
        message.error({ message: 'ไม่พบข้อมูลการชำระเงิน' })
      }
      setLoading(false)
    },
    [props.account]
  )

  const onStart = useCallback(() => {
    onEvent({
      pageName: 'create-account',
      eventName: 'create-account-payment-success-start-button-click',
    })
    window.open(config.app_url, '_blank')
  }, [onEvent])

  useEffect(() => {
    onInit(code)
  }, [onInit, code])

  useEffect(() => {
    onPageLoad({ pageName: 'payment-success' })
  }, [onPageLoad])

  const getStatus = () => {
    const { status } = result || {}
    const { expired_at } = user || {}
    const date = timer.get(expired_at).subtract(3, 'days')

    switch (status) {
      case 'cancelled':
        return 'ยกเลิกการชำระเงิน'
      case 'completed':
        return format.toNameDate(date)
      default:
        return 'รอการยืนยัน'
    }
  }

  const getResult = () => {
    const { user_id, payment_type, invoice_no, amount } = result || {}
    let list = []
    if (payment_type === 'card') {
      const { name, surname, card } = result || {}
      list = [
        { label: 'ชื่อ นามสกุล', value: `${name} ${surname}` },
        { label: 'หมายเลขหน้าบัตร 16 หลัก', value: format.toCreditCard(card) },
        { label: 'Customer ID', value: `${user_id || ''}` },
        { label: 'Transaction ID', value: invoice_no },
        { label: 'จำนวนเงิน', value: `฿ ${format.toDigi(amount || 0)}` },
        { label: 'ต่ออายุอัตโนมัติ', value: getStatus() },
      ]
    } else {
      const member = props.member.toJS().user || {}
      const name = member.name || ''
      const surname = member.surname || ''
      const mobile = member.mobile || ''
      list = []
      if (name !== '' && surname !== '') {
        list.push({ label: 'ชื่อ นามสกุล', value: `${name} ${surname}` })
      }

      list.push({ label: 'Customer ID', value: `${user_id}` })
      list.push({ label: 'Transaction ID', value: invoice_no })
      list.push({ label: 'จำนวนเงิน', value: `฿ ${format.toDigi(amount)}` })
      if (mobile !== '') {
        list.push({ label: 'หมายเลขโทรศัพท์', value: format.toMobile(mobile) })
      }
      list.push({ label: 'ต่ออายุอัตโนมัติ', value: getStatus() })
    }

    return list.map((it, i) => {
      return (
        <Fragment key={i}>
          <Text size="small" placeholder>
            {it.label}
          </Text>
          <Text size="small" gray>
            {it.value}
          </Text>
        </Fragment>
      )
    })
  }

  const getError = (msg) => {
    return <ErrorMessage>{msg}</ErrorMessage>
  }

  const content = result ? (
    <TextContent> {getResult()}</TextContent>
  ) : (
    getError('ไม่พบข้อมูลการชำระเงิน')
  )

  return (
    <ContentView>
      <Display title bold>
        ชำระเงินเสร็จสิ้น
      </Display>
      <Space />
      <Text title bold>
        รายละเอียดการชำระเงิน
      </Text>
      <Loading loading={loading}>
        {content}
        <TextBox>
          <Text size="xsmall" gray>
            หากมีข้อสงสัย ติดต่อเราได้ที
          </Text>
          <Text size="xsmall" primary bold>
            customers@tqd.co.th
          </Text>
        </TextBox>

        <Button
          large
          color="primary"
          maxWidth="325px"
          text="เริ่มใช้งาน"
          onClick={onStart}
        />
      </Loading>
    </ContentView>
  )
}

const TextContent = styled.div`
  display: grid;
  gap: 8px;
  column-gap: 24px;
  align-items: flex-start;
  grid-template-columns: auto auto;
  margin: 8px 0 24px;
`

const ContentView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 56px 0;
  text-align: center;
  margin: 0 auto;
`

const Space = styled.div`
  padding-top: 36px;
`

const TextBox = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 8px;
  margin-bottom: 18px;
`

const ErrorMessage = styled.div`
  padding: 40px 0;
  color: #c30052;
`

export default inject('member', 'account')(observer(PaymentResult))
