import React, { useState } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import { FieldInput, Dropdown, DatePicker } from 'components/input'
import { Text } from 'components/typo'
import { AddBtn, Bell } from 'icon'
import RemoveBtn from 'icon/RemoveBtn'
import RemoveLicenseModal from 'components/modal/RemoveLicenseModal'

import PopupSetting from './PopupSetting'
import useMediaQuery from '../../../../hooks/useMediaQuery'
import iconInfo from '../../../../assets/Info.png'
import useHover from '../../../../hooks/useHover'

const licenseTypes = [
  {
    label: 'LIA',
    value: 'LIA',
  },
  {
    label: 'LIA/ULP',
    value: 'LIA/ULP',
  },
  {
    label: 'NIA',
    value: 'NIA',
  },
  {
    label: 'LIB',
    value: 'LIB',
  },
  {
    label: 'NIB',
    value: 'NIB',
  },
  {
    label: 'IC',
    value: 'IC',
  },
  {
    label: 'IB',
    value: 'IB',
  },
  {
    label: 'IBA',
    value: 'IBA',
  },
]

const LICENSE_LIST = [
  { en: 'LIA = Life Insurance Agent', th: 'ใบอนุญาตตัวแทนประกันชีวิต' },
  {
    en: 'LIA/ULP = Life Insurance Agent + Unit-linked Product',
    th: 'ใบอนุญาตตัวแทนประกันชีวิต+ประกันชีวิตควบการลงทุน',
  },
  { en: 'NIA = Non-life Insurance Agent', th: 'ใบอนุญาตตัวแทนประกันวินาศภัย' },
  { en: 'LIB = Life Insurance Broker', th: 'ใบอนุญาตนายหน้าประกันชีวิต' },
  {
    en: 'NIB = Non-life Insurance Broker',
    th: 'ใบอนุญาตนายหน้าประกันวินาศภัย',
  },
  {
    en: 'IC = Investment Consultant',
    th: 'ใบอนุญาตผู้แนะนำการลงทุน',
  },
  {
    en: 'IB = Investment Brokerage',
    th: 'ใบอนุญาตผู้แนะนำลูกค้าหลักทรัพย์',
  },
  {
    en: 'IP = Investment Planner',
    th: 'ใบอนุญาตผู้วางแผนการลงทุน',
  },
  {
    en: 'CFP = Certified Financial Planner',
    th: 'เลขที่สมาชิกสามัญ สมาคมนักวางแผนการเงินไทย',
  },
  {
    en: 'AFPT = Associate Financial Planner Thailand',
    th: 'เลขที่สมาชิกวิสามัญ สมาคมนักวางแผนการเงินไทย',
  },
]

const LicenseInfo = () => {
  const [ref, isHover] = useHover()

  return (
    <span className="iconInfo">
      <div className="containerIcon" ref={ref}>
        <img alt="info icon" src={iconInfo} />
        <HiddenInfo enter={isHover}>
          {LICENSE_LIST.map(({ en, th }, index) => (
            <React.Fragment key={index}>
              {en}
              <br />
              {th}
              <br />
              <br />
            </React.Fragment>
          ))}
        </HiddenInfo>
      </div>
      <ArrowUp enter={isHover}></ArrowUp>
    </span>
  )
}

const Agent = (props) => {
  const [licenseType, setLicenseType] = useState('')
  const [licenseNo, setLicenseNo] = useState('')
  const [isOpened, setOpen] = useState(false)
  const [licenseIndex, setLicenseIndex] = useState(-1)
  const isIpad = useMediaQuery('(max-width:1024px)')
  const { agent, validate = {}, isSubmit, loading, onChange } = props
  const license_list = agent.license_list || []

  const { company } = props.account.toJS()

  const onCompany = (val) => {
    agent.company_id = val
    onChange(agent)
  }

  const { dropdown_list } = company

  const onAdd = () => {
    license_list.push({
      license_type: '',
      license_no: '',
    })
    agent.license_list = license_list
    onChange(agent)
    setLicenseType('')
    setLicenseNo('')
  }

  const openRemoveDialog = (i) => (type, no) => {
    if (!type && !no) return onRemove(i)
    setLicenseIndex(i)
    setLicenseType(type)
    setLicenseNo(no)
    setOpen(true)
  }

  const onRemove = (i) => {
    license_list.splice(i, 1)
    agent.license_list = license_list
    onChange(agent)
    setOpen(false)
  }

  const onChangeItem = (i) => (val, name) => {
    license_list[i][name] = val
    agent.license_list = license_list
    onChange(agent)
  }

  return (
    <Wrapper>
      <RemoveLicenseModal
        licenseType={licenseType}
        licenseNo={licenseNo}
        isOpened={isOpened}
        setOpen={setOpen}
        onOk={() => onRemove(licenseIndex)}
      />
      <TopBox>
        <Text size="small" bold title>
          ข้อมูลตัวแทน
        </Text>
        <LineIcon>
          <PopupSetting doc={agent}>
            <Icon>
              <Bell />
            </Icon>
          </PopupSetting>
          <Icon onClick={onAdd}>
            <AddBtn />
          </Icon>
        </LineIcon>
      </TopBox>

      <Dropdown
        label="บริษัทที่สังกัด"
        show={{ h: 'center' }}
        value={agent.company_id}
        filterable
        options={dropdown_list}
        disabled={loading}
        onChange={onCompany}
        errorText={validate.company_id}
      />
      <License>
        <Text size="small" bold title>
          ข้อมูลใบอนุญาต
          <LicenseInfo />
        </Text>
      </License>
      {license_list.map((it, i) => {
        return (
          <Content
            key={i}
            loading={loading}
            isSubmit={isSubmit}
            data={it}
            isIpad={isIpad}
            onChangeItem={onChangeItem(i)}
            openRemoveDialog={openRemoveDialog(i)}
          />
        )
      })}
    </Wrapper>
  )
}

const Content = (props) => {
  const item = props.data
  const { onChangeItem, openRemoveDialog, loading, isSubmit } = props

  const type = item.license_type
  const no = item.license_no
  const isTypeError = isSubmit && !type && 'กรอกใบอนุญาต'
  const isNoError = isSubmit && !no && 'กรอกหมายเลขใบอนุญาต'
  const isExpireError = isSubmit && !item.expired_at && 'กรอกหมายเลขใบอนุญาต'

  return (
    <ContentBox>
      <InputForm>
        <Dropdown
          label="ใบอนุญาต"
          show={{ h: 'center' }}
          options={licenseTypes}
          disabled={loading}
          value={type}
          onChange={(val) => onChangeItem(val, 'license_type')}
          errorText={isTypeError}
        />
        <FieldInput
          label="หมายเลขใบอนุญาต"
          placeholder="เลขใบอนุญาต"
          value={no}
          disabled={loading}
          onChange={(val) => onChangeItem(val, 'license_no')}
          errorText={isNoError}
        />
        <DateTimePicker>
          <DatePicker
            label="วันหมดอายุ"
            name="expired_at"
            value={item.expired_at}
            onChange={(val) => onChangeItem(val, 'expired_at')}
            errorText={isExpireError}
            isAll={true}
          />
        </DateTimePicker>
      </InputForm>
      <RemoveIcon onClick={() => openRemoveDialog(type, no)}>
        <RemoveBtn />
      </RemoveIcon>
    </ContentBox>
  )
}

const TopBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ContentBox = styled.div`
  display: flex;

  align-items: baseline;
  gap: 9px;
  .iconInfo {
    margin-left: 8px;
    position: relative;
  }
  .containerIcon {
    width: 40px;
    height: 33px;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: row-reverse;
  }
`

const License = styled(ContentBox)`
  flex-direction: column;
  > span {
    display: flex;
  }
`

const ArrowUp = styled.div`
  display: ${(props) => (props.enter ? 'block' : 'none')};
  position: absolute;
  top: 26px;
  left: 6px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #4e4b66;
`
const HiddenInfo = styled.div`
  display: ${(props) => (props.enter ? 'block' : 'none')};
  font-size: 16px;
  font-weight: 400;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  z-index: 9999;
  top: 31px;
  color: white;
  white-space: nowrap;
  left: -10px;
  max-width: 443px;
  max-height: 224px;
  width: fit-content;
  background: #4e4b66;
  border-radius: 16px;
  padding: 16px 24px;
  gap: 10px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
  row-gap: 24px;

  @media screen and (max-width: 500px) {
    max-width: 268px;
  }
`

const LineIcon = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
`

const Icon = styled.div`
  cursor: pointer;
  margin-left: 16px;

  &.disabled {
    cursor: not-allowed;
  }
`

const InputForm = styled.div`
  display: flex;

  gap: 8px;

  @media screen and (max-width: 890px) {
    flex-direction: column;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

const DateTimePicker = styled.div`
  flex: 0 0 170px;
  max-height: 60px;

  @media screen and (max-width: 500px) {
    flex: 0 0 100%;
  }
`

const RemoveIcon = styled.div`
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
`

export default inject('account')(observer(Agent))
