import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { size = 1 } = props;

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.40067 20.6991C1.60067 22.0991 2.70067 23.0991 4.00067 23.0991C4.10067 23.0991 4.20067 23.0991 4.30067 23.0991C6.70067 22.7991 11.5007 21.6991 16.5007 16.4991C21.6007 11.3991 22.8007 6.59911 23.0007 4.19911C23.2007 2.79911 22.1007 1.49911 20.6007 1.29911L17.2007 0.999107C16.0007 0.899107 14.9007 1.49911 14.3007 2.49911L12.9007 4.99911C12.2007 6.19911 12.4007 7.59911 13.4007 8.59911L14.0007 9.19911C14.1007 9.39911 14.3007 9.59911 14.2007 9.79911C13.9007 10.3991 13.4007 11.2991 12.3007 12.3991C11.3007 13.3991 10.3007 13.9991 9.80067 14.2991C9.50067 14.3991 9.30067 14.1991 9.20067 14.0991L8.60067 13.4991C7.70067 12.5991 6.20067 12.3991 5.00067 12.9991L2.60067 14.3991C1.60067 14.9991 1.00067 16.0991 1.10067 17.2991L1.40067 20.6991Z" fill="#14142B"/>
    </svg>
  )
}
// eslint-enable
export default Logo;
