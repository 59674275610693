import React from 'react'

const Col = (props) => {
  const {
    children,
    size = '50%',
    paddingRight,
    paddingLeft,
    paddingTop = '12px',
    paddingBottom = '12px',
    borderLeft,
    textAlign,
  } = props

  const css = {
    display: 'block',
    flex: `0 0 ${size}`,
    maxWidth: size,
    paddingTop,
    paddingBottom,
    paddingRight,
    paddingLeft,
    borderLeft,
    textAlign,
  }
  return <div style={css}>{children}</div>
}

export default Col
