import styled from 'styled-components'

const ToggleButton = styled.button((p) => {
  const isTrue = p.value
  const { primary, primaryDark, primaryLight } = p.theme.color
  const { background, offWhite, line, placeholder, inputBackground } =
    p.theme.gray

  const bgColor = isTrue ? primary : line
  const bgHoverColor = isTrue ? primaryDark : placeholder
  const focusColor = isTrue ? primaryLight : inputBackground
  return `
  position: relative;
  width: 43px;
  height: 24px;
  border-radius: 50px;
  border: none;
  background-color: ${bgColor};
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
  background-color: ${bgHoverColor};
  }

  &:focus {
    outline: 8px solid ${focusColor}
  }


  &:after {
    content: "";
    height: 24px;
    width: 24px;
    top: 0;
    right: ${isTrue ? '0' : '19px'};
    border: 2px solid ${bgColor};
    position: absolute;
    background-color: ${isTrue ? background : offWhite};
    border-radius: 50%;
    transition: all 0.2s;
  }

   &:hover:after{
      border: 2px solid ${bgHoverColor};
  }

  &:disabled {
    opacity: 0.5
  }
`
})

export default ToggleButton
