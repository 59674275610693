import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import Select from 'components/Select'

import Popup from './Popup'

const getDate = (value) => {
  const startOfMonth = moment(value).startOf('months').startOf('week')
  const list = []
  for (let i = 0; i < 7; i++) {
    for (let j = 0; j < 6; j++) {
      const value = startOfMonth.clone().add(i * 6 + j, 'day')

      list.push(value)
    }
  }

  return list
}

const days = ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส']

const months = [
  { label: 'มกราคม', value: 'มกราคม' },
  { label: 'กุมภาพันธ์', value: 'กุมภาพันธ์' },
  { label: 'มีนาคม', value: 'มีนาคม' },
  { label: 'เมษายน', value: 'เมษายน' },
  { label: 'พฤษภาคม', value: 'พฤษภาคม' },
  { label: 'มิถุนายน', value: 'มิถุนายน' },
  { label: 'กรกฎาคม', value: 'กรกฎาคม' },
  { label: 'สิงหาคม', value: 'สิงหาคม' },
  { label: 'กันยายน', value: 'กันยายน' },
  { label: 'ตุลาคม', value: 'ตุลาคม' },
  { label: 'พฤศจิกายน', value: 'พฤศจิกายน' },
  { label: 'ธันวาคม', value: 'ธันวาคม' },
]

const thisYear = moment().format('YYYY')
const years = Array(90)
  .fill(1900)
  .map((item, i) => {
    const year = parseInt(thisYear) - i
    return { label: year, value: year }
  })

const nextYear = moment().add(20, 'years').format('YYYY')
const all_years = Array(90)
  .fill(1900)
  .map((item, i) => {
    const year = parseInt(nextYear) - i
    return { label: year, value: year }
  })

const Calendar = (props) => {
  const { value, onChange, children, show, isAll = false } = props

  const [date, setDate] = useState(value ? moment() : moment(value))
  const [list, setList] = useState([])

  const onSelectDate = (val) => () => {
    onChange(moment(val))
  }

  const isSelectedDay = (val) => {
    return moment(value, 'DD/MM/YYYY').isSame(val, 'day')
  }

  const isInThisMonth = (val) => moment(date).isSame(val, 'month')

  const onChangeMonth = (val) => {
    const index = months.findIndex((i) => i.value === val)
    const newDate = moment(date).month(index)
    setDate(newDate)
  }

  const onChangeYear = (val) => {
    const newDate = moment(date).year(val)
    setDate(newDate)
  }

  useEffect(() => {
    setList(getDate(date))
  }, [date])

  const getMonthYear = () => {
    let month
    let year
    if (date) {
      const currentDate = moment(date)
      month = currentDate.month()
      year = years.find((item) => item.label === currentDate.year())
    }
    return { month: months[month], year }
  }

  const { month, year } = getMonthYear()
  const yoption = isAll ? all_years : years
  return (
    <Popup
      show={show}
      content={
        <Wrapper>
          <Action>
            <Select options={months} value={month} onChange={onChangeMonth} />
            <Select options={yoption} value={year} onChange={onChangeYear} />
          </Action>
          <Day>
            {days.map((day) => (
              <span key={day}>{day}</span>
            ))}
          </Day>
          <Date>
            {list.map((item, i) => (
              <DateItem
                onClick={onSelectDate(item)}
                isSelect={isSelectedDay(item)}
                isGray={!isInThisMonth(item)}
                key={item + i}
              >
                {moment(item).format('D')}
              </DateItem>
            ))}
          </Date>
        </Wrapper>
      }
    >
      <IconButton>{children}</IconButton>
    </Popup>
  )
}

const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
`

const Wrapper = styled.div`
  background-color: white;

  padding: 20px;
  width: 325px;
  height: 288px;
  border-radius: 16px;
  z-index: 100;

  transition: 0.1s;
  transform-origin: top;

  border: 1px solid ${(p) => p.theme.gray.background};

  &:focus {
    border: 1px solid ${(p) => p.theme.color.primary};
  }
`

const Action = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: min-content min-content;
`

const Day = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;

  margin-bottom: 8px;

  border-bottom: 1px solid ${(p) => p.theme.gray.line};
`

const Date = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  text-align: center;
  gap: 4px;

  font-size: 18px;
`

const DateItem = styled.span`
  cursor: pointer;
  width: 28px;
  line-height: 28px;
  border-radius: 50%;

  background-color: ${(p) => p.isSelect && p.theme.color.primary};
  color: ${(p) => p.isSelect && p.theme.gray.offWhite};
  color: ${(p) => p.isGray && p.theme.gray.background};
`

export default Calendar
