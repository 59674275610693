import React from 'react'

import Row from './Row'
import Col from './Col'

const Headline = (props) => {
  const { title, value, bg = '#00BA88' } = props

  const getBGColor = () => {
    switch (bg) {
      case 'green':
        return '#00BA88'
      case 'red':
        return '#ED2E7E'
      default:
        return '#5F2EEA'
    }
  }

  const css = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '0px 20px',
    color: 'white',
    backgroundColor: getBGColor(),
    borderRadius: '16px',
    border: '1px solid white',
  }
  const borderLeft = '1px solid white'
  const textAlign = 'right'
  return (
    <div style={css}>
      <Row>
        <Col size="60%">{title}</Col>

        <Col size="40%" borderLeft={borderLeft} textAlign={textAlign}>
          {value}
        </Col>
      </Row>
    </div>
  )
}

export default Headline
