import { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { Radio } from 'components/input'
import { PACKAGE_DURATION } from 'definition/package'

const PackageMonth = ({ isSavePass, setIsSavePass, defaultPackage }) => {
  const isSelected = useMemo(
    () => isSavePass === PACKAGE_DURATION.PER_MONTH,
    [isSavePass]
  )

  const onCheck = useCallback(() => {
    setIsSavePass(PACKAGE_DURATION.PER_MONTH)
  }, [setIsSavePass])

  return (
    <PackagesMonthDiv
      selected={isSelected}
      onClick={onCheck}
      customerPackage={defaultPackage}
    >
      <PackageMonthBody>
        <div className="check_box">
          <Radio
            removeCheckIcon={defaultPackage}
            checked={isSelected}
            onCheck={onCheck}
            label={
              <PackageMonthlabel className="default_package">
                {PACKAGE_DURATION.PER_MONTH_TH}
              </PackageMonthlabel>
            }
          />
        </div>
        <div className="package_price">
          <p className="text_real">฿ 599.00</p>
        </div>
      </PackageMonthBody>
    </PackagesMonthDiv>
  )
}
const PackagesMonthDiv = styled.div`
  width: 360px;
  height: 78px;
  border-radius: 16px;
  background-color: ${(props) => (props.customerPackage ? '#f3efff' : 'none')};
  border: ${(props) =>
    props.selected
      ? '2px solid #5f2eea'
      : props.customerPackage
      ? '1px solid #BCA4FF'
      : '2px solid #D9DBE9'};
  justify-content: center;
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
  .text_real {
    color: ${(props) => (props.customerPackage ? '#BCA4FF' : '#00000')};
  }
  .default_package {
    color: ${(props) => (props.customerPackage ? '#BCA4FF' : '#00000')};
  }

  @media screen and (max-width: 740px) {
    width: 100%;
    margin-top: 0;
    margin-left: 0;
  }
`

const PackageMonthlabel = styled.label`
  font-weight: 600;
  font-size: 24px;
  cursor: pointer;
`

const PackageMonthBody = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  .check_box {
    padding-top: 30px;
    padding-left: 10px;
  }
  .package_price {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .text_real {
    font-size: 24px;
    margin-left: 20px;
    padding-top: 25px;
    font-weight: bold;
  }
`

export default PackageMonth
