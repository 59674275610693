import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { size = 1, color = '#4E4B66' } = props
  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 13.2C20.9 13.2 20.8 13.1 20.8 13.1L19.9 7.4C19.3 3.7 16 1 12 1C8 1 4.7 3.7 4.1 7.4L3.2 13.1C3.2 13.1 3.1 13.2 3 13.2C1.3 13.2 0 14.5 0 16.1C0 17.7 1.3 19 3 19H7.1C7.6 21.3 9.6 23 12 23C14.4 23 16.4 21.3 16.9 19H21C22.7 19 24 17.7 24 16.1C24 14.5 22.7 13.2 21 13.2ZM12 21C10.7 21 9.6 20.2 9.2 19H14.8C14.4 20.2 13.3 21 12 21ZM21 17H3C2.4 17 2 16.6 2 16.1C2 15.6 2.4 15.2 3 15.2C4.1 15.2 5 14.5 5.1 13.4L6 7.7C6.5 5 9 3 12 3C15 3 17.5 5 17.9 7.7L18.8 13.4C19 14.4 19.9 15.2 20.9 15.2C21.5 15.2 21.9 15.6 21.9 16.1C21.9 16.6 21.6 17 21 17Z" fill={color}/>
    </svg>
  )
}
// eslint-enable
export default Logo
