import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Text } from 'components/typo'
import { Button, FindBtn } from 'components/button'
import { FieldInput } from 'components/input'
import { message } from 'utils'

import AccountLayout from '../components/AccountLayout'

const Promotion = (props) => {
  const [loading, setLoading] = useState(false)
  const [promotion, setPromotion] = useState({})
  const [verifying, setVerifying] = useState(false)
  const [code, setCode] = useState('')

  const onVerify = async () => {
    try {
      setVerifying(true)
      setPromotion({})
      const pro = await props.promotion.verify(code)

      setPromotion(pro)
    } catch (e) {
      message.error({ message: e.message })
    }

    setVerifying(false)
  }

  const onApply = async (val) => {
    try {
      setLoading(true)

      await props.promotion.apply(code)
      setCode('')
      setPromotion({})
      message.success({ message: 'บันทึกรหัสโปรโมชั่น/ ส่วนลด สำเร็จ' })
    } catch (e) {
      message.error({ message: e.message })
    }
    setLoading(false)
  }

  const { status } = promotion
  const err =
    status === 'not found' || status === 'used'
      ? 'รหัสไม่ถูกต้อง/ ถูกใช้ครบแล้ว'
      : undefined

  const success =
    status === 'ready' ? 'สามารถใช้รหัสนี้เป็นส่วนลดได้' : undefined
  return (
    <AccountLayout
      title="Apply Promotion"
      description="Apply Promotion"
      backTo="/account/my-payment"
      backText="ข้อมูลการชำระเงิน"
    >
      <Content>
        <Text size="large" bold>
          กรอกโค้ด
        </Text>
        <Title>
          <Info>เมื่อกด ยืนยัน ระบบจะบันทึกรหัสโปรโมชั่น/ ส่วนลดไว้</Info>
          <Info>เพื่อนำไปเป็นส่วนลดในการชำระเงินครั้งถัดไป</Info>
        </Title>

        <InputBox>
          <FieldInput
            label="รหัสโปรโมชั่น/ ส่วนลด"
            value={code}
            errorText={err}
            successText={success}
            onChange={(val) => setCode(val)}
          />
          <FindArea>
            <FindBtn loading={verifying} onClick={onVerify} />
          </FindArea>
        </InputBox>

        <Detail>
          <Button
            color="primary"
            text="ยืนยัน"
            maxWidth="325px"
            loading={loading}
            disabled={status !== 'ready'}
            onClick={onApply}
          />
        </Detail>
      </Content>
    </AccountLayout>
  )
}

const Content = styled.div`
  width: 325px;
  text-align: center;
`

const Title = styled.div`
  margin-top: 24px;
  margin-bottom: 48px;
`

const InputBox = styled.div`
  display: flex;
  column-gap: 16px;
  margin-bottom: 40px;
`

const Info = styled.div`
  font-size: 16px;
  color: #4e4b66;
`

const Detail = styled.div`
  margin-top: 40px;
  margin-bottom: 29px;
`

const FindArea = styled.div`
  float: right;
`

export default inject('promotion')(observer(Promotion))
