import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { size = 1, color = '#FF003E' } = props

  const width = `${Math.ceil(32 * size)}`
  const height = `${Math.ceil(32 * size)}`
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32 0H0V32H32V0Z" fill="white"/>
      <path d="M3.07812 21.5508H3.63813V23.5468H4.61733V24.0148H3.07812V21.5508Z" fill="#5F2EEA"/>
      <path d="M5.50921 21.5508V24.0148H4.94922V21.5508H5.50921Z" fill="#5F2EEA"/>
      <path d="M5.99219 24.0148V21.5508H6.64179L7.15379 22.454C7.30568 22.7264 7.43931 23.0086 7.55379 23.2988H7.56419C7.53108 22.9572 7.51693 22.614 7.52179 22.2708V21.5508H8.03379V24.0148H7.44739L6.92099 23.0644C6.76304 22.782 6.62018 22.4915 6.49299 22.194H6.48179C6.49619 22.5196 6.50419 22.8668 6.50419 23.2692V24.0108L5.99219 24.0148Z" fill="#5F2EEA"/>
      <path d="M9.97812 22.9732H9.07092V23.558H10.0837V24.0148H8.51172V21.5508H10.0317V22.0076H9.07172V22.5196H9.97892L9.97812 22.9732Z" fill="#5F2EEA"/>
      <path d="M13.4586 22.7542C13.4586 23.5622 12.969 24.0526 12.2482 24.0526C11.5274 24.0526 11.0898 23.4998 11.0898 22.7982C11.0898 22.0598 11.561 21.5078 12.2898 21.5078C13.0458 21.5078 13.4586 22.0782 13.4586 22.7542ZM11.6778 22.787C11.6778 23.267 11.905 23.6102 12.2778 23.6102C12.6506 23.6102 12.8698 23.2518 12.8698 22.7726C12.8698 22.3302 12.6578 21.9502 12.2738 21.9502C11.8898 21.9502 11.6794 22.3086 11.6794 22.787H11.6778Z" fill="#5F2EEA"/>
      <path d="M13.8242 21.5829C14.0733 21.5453 14.3251 21.5282 14.577 21.5317C14.921 21.5317 15.1658 21.5973 15.3306 21.7293C15.4147 21.7983 15.4821 21.8855 15.5277 21.9843C15.5733 22.0831 15.5959 22.1909 15.5938 22.2997C15.5981 22.4076 15.5802 22.5151 15.5411 22.6158C15.502 22.7164 15.4426 22.8078 15.3666 22.8845C15.177 23.0637 14.8954 23.1445 14.5666 23.1445C14.503 23.1451 14.4394 23.1413 14.3762 23.1333V24.0133H13.8242V21.5829ZM14.3762 22.7029C14.4365 22.7151 14.4979 22.721 14.5594 22.7205C14.8554 22.7205 15.0394 22.5701 15.0394 22.3205C15.0394 22.0941 14.8794 21.9589 14.6042 21.9589C14.5268 21.9566 14.4494 21.9638 14.3738 21.9805L14.3762 22.7029Z" fill="#5F2EEA"/>
      <path d="M17.4148 22.9732H16.5084V23.558H17.5204V24.0148H15.9492V21.5508H17.4692V22.0076H16.5092V22.5196H17.4156L17.4148 22.9732Z" fill="#5F2EEA"/>
      <path d="M17.9023 24.0148V21.5508H18.5535L19.0647 22.454C19.2166 22.7264 19.3503 23.0086 19.4647 23.2988H19.4759C19.4406 22.9573 19.4245 22.6141 19.4279 22.2708V21.5508H19.9399V24.0148H19.3615L18.8351 23.0644C18.6753 22.7821 18.5306 22.4916 18.4015 22.194H18.3911C18.4055 22.5196 18.4127 22.8668 18.4127 23.2692V24.0108L17.9023 24.0148Z" fill="#5F2EEA"/>
      <path d="M22.2349 23.9431C22.0337 24.0214 21.8186 24.0576 21.6029 24.0495C20.7509 24.0495 20.3125 23.5191 20.3125 22.8175C20.3125 21.9767 20.9117 21.5087 21.6573 21.5087C21.8652 21.5014 22.0723 21.5388 22.2645 21.6183L22.1509 22.0607C22.0026 21.9991 21.8434 21.9681 21.6829 21.9695C21.2405 21.9695 20.8973 22.2359 20.8973 22.7847C20.8973 23.2783 21.1893 23.5887 21.6869 23.5887C21.845 23.5883 22.0018 23.5612 22.1509 23.5087L22.2349 23.9431Z" fill="#5F2EEA"/>
      <path d="M23.1577 21.5508V22.498H24.0793V21.5508H24.6345V24.0148H24.0793V22.9836H23.1577V24.0148H22.5977V21.5508H23.1577Z" fill="#5F2EEA"/>
      <path d="M25.6903 23.3828L25.5151 24.0148H24.9375L25.6903 21.5508H26.4215L27.1863 24.0148H26.5863L26.3959 23.3828H25.6903ZM26.3159 22.966L26.1591 22.4428C26.1151 22.2964 26.0711 22.114 26.0343 21.9676H26.0271C25.9911 22.114 25.9543 22.3004 25.9143 22.4428L25.7679 22.966H26.3159Z" fill="#5F2EEA"/>
      <path d="M27.6889 22.0188H27.0273V21.5508H28.9217V22.0188H28.2489V24.0148H27.6889V22.0188Z" fill="#5F2EEA"/>
      <path d="M21.5146 11.9189C21.4879 10.8977 21.0792 9.92358 20.369 9.18917C19.6589 8.45475 18.6991 8.01346 17.6794 7.95251H17.6658C17.5898 7.95251 17.5138 7.94531 17.4378 7.94531C17.3618 7.94531 17.285 7.94531 17.209 7.95251H14.373C13.2909 7.95251 12.2531 8.38237 11.488 9.14752C10.7228 9.91267 10.293 10.9504 10.293 12.0325C10.293 12.0493 10.293 12.0645 10.293 12.0805V15.2357C10.3292 16.2698 10.7569 17.2515 11.4896 17.9821C12.2223 18.7127 13.2052 19.1377 14.2394 19.1709V19.1781H17.689C17.763 19.178 17.8341 19.1495 17.8877 19.0984C17.9413 19.0473 17.9733 18.9776 17.977 18.9037C17.9791 18.8932 17.9791 18.8823 17.977 18.8717V17.7045C17.9769 17.6341 17.9511 17.5661 17.9044 17.5133C17.8577 17.4606 17.7933 17.4267 17.7234 17.4181V17.4141H14.2394C13.7046 17.3716 13.2 17.1493 12.8078 16.7833C12.4156 16.4174 12.1588 15.9294 12.0794 15.3989C12.0643 15.2964 12.0558 15.1929 12.0538 15.0893V15.0813V12.0789C12.0545 11.9594 12.0647 11.8401 12.0842 11.7221C12.1641 11.2302 12.3967 10.7758 12.7492 10.4235C13.1017 10.0711 13.5562 9.83861 14.0482 9.75891C14.169 9.73933 14.2913 9.72943 14.4138 9.72931H17.6794C17.7034 9.72931 17.7282 9.73491 17.7522 9.73891C18.2828 9.82214 18.7696 10.0829 19.1329 10.4786C19.4961 10.8743 19.7145 11.3815 19.7522 11.9173V18.9613C19.7659 19.0265 19.8016 19.0849 19.8533 19.1268C19.905 19.1688 19.9696 19.1917 20.0362 19.1917H21.2361C21.3117 19.19 21.3836 19.1589 21.4365 19.1049C21.4894 19.051 21.5192 18.9785 21.5194 18.9029V11.9189H21.5146Z" fill="#5F2EEA"/>
    </svg>
  )
}
// eslint-enable
export default Logo
