import React from 'react'
import styled from 'styled-components'

import Caret from './Caret'

const Arrow = (props) => {
  const { isUp, onClick } = props
  return (
    <ArrowWrapper isUp={isUp} onClick={onClick}>
      <Caret />
    </ArrowWrapper>
  )
}

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  transition: 0.2s;
  transform: rotateX(${(p) => (p.isUp ? '180deg' : '0')});
`

export default Arrow
