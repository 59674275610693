import { useEffect, useState } from 'react'

const useMediaQuery = (query) => {
  const mediaQuery = window.matchMedia(query)
  const [match, setMatch] = useState(!!mediaQuery.matches)

  useEffect(() => {
    const handler = () => setMatch(!!mediaQuery.matches)
    mediaQuery.addEventListener('change', handler)
    return () => mediaQuery.removeEventListener('change', handler)
  }, [mediaQuery.matches, mediaQuery])

  if (typeof window === 'undefined' || typeof window.matchMedia === 'undefined')
    return false

  return match
}

export default useMediaQuery
