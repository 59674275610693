import React from 'react'
import styled from 'styled-components'

import { helper } from '../utils'
import Popup from './Popup'

const CollapseList = (props) => {
  const { options, value, gap, onChange, children, show } = props

  const onSelect = (obj) => {
    onChange(obj.value)
  }

  const onEnter = (e) => {
    e.preventDefault()

    const found = options.find((item) => helper.searchString(item.label, value))
    if (found) {
      onSelect(found)
    }
  }
  return (
    <Popup
      onSubmit={onEnter}
      show={show}
      content={
        <ListBox gap={gap}>
          <List>
            {options?.map((item, i) => {
              return (
                <Item
                  key={String(item.value) + i}
                  isSelect={helper.compareString(item.label, value)}
                  onClick={() => onSelect(item)}
                >
                  {item.label}
                </Item>
              )
            })}
          </List>
        </ListBox>
      }
    >
      {children}
    </Popup>
  )
}

const ListBox = styled.div`
  background-color: white;
  white-space: nowrap;
  overflow: hidden;

  margin-top: ${(p) => p.gap};
  width: 100%;
  min-width: 100%;
  padding: 16px 4px;
  border-radius: 16px;
  z-index: 10;
  border: 1px solid ${(p) => p.theme.color.primary};

  // animation
  transition: 0.2s;
  transform-origin: top;
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  background-color: white;
  cursor: pointer;

  border: none;
  overflow-y: scroll;

  max-height: 300px;
  width: auto;
  padding: 0 4px;
  margin: 0;
  row-gap: 10px;

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background: ${(p) => p.theme.color.primaryBg};
  }
`

const Item = styled.li`
  padding: 8px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 16px;

  color: ${(p) => p.isSelect && p.theme.color.primary};
  background-color: ${(p) => p.isSelect && p.theme.color.primaryBg};

  &:hover {
    color: ${(p) => p.theme.color.primary};
    background-color: ${(p) => p.theme.color.primaryBg};
  }
`

export default CollapseList
