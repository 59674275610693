import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { size = 1 } = props

  const width = `${Math.ceil(32 * size)}`
  const height = `${Math.ceil(32 * size)}`
  return (
    <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M25.0997 32H6.90034C3.07904 32 0 28.8935 0 25.0997V6.90034C0 3.07904 3.07904 0 6.90034 0H25.0997C28.8935 0 32 3.07904 32 6.90034V25.0997C32 28.8935 28.8935 32 25.0997 32Z'
        fill='#1877F2' />
      <path
        d='M21.1962 9.37514H23.3131V5.22394C23.3131 5.22394 21.3887 4.89404 19.5467 4.89404C15.6979 4.89404 13.1962 7.23081 13.1962 11.437V15.1483H8.93506V20.0143H13.1962V31.9731H18.4471V20.0143H22.3784L23.1206 15.1483H18.4471V11.9868C18.4471 10.6672 19.1069 9.37514 21.1962 9.37514Z'
        fill='white' />
    </svg>

  )
}
// eslint-enable 
export default Logo
