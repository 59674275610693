import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

const Display = (props) => {
  const theme = useContext(ThemeContext)

  const color = getColor(theme, props)
  const font = getFont(props)

  const styleProps = { color, font }

  return <CustomDisplay {...styleProps}>{props.children}</CustomDisplay>
}

function getColor(theme, props) {
  if (props.primary) {
    return theme.color.primary
  }

  if (props.secondary) {
    return theme.color.secondary
  }

  if (props.inherit) return 'inherit'

  return theme.gray.titleActive
}

function getFont(props) {
  const { align, bold, size, ipadSize, mobileSize } = props
  const font = {
    weight: 'normal',
    size: getFontSize(size),
    align,
  }

  font.ipadSize = getFontSize(ipadSize, font.size)
  font.mobileSize = getFontSize(mobileSize, font.ipadSize)

  if (bold) {
    font.weight = 'bold'
  }

  return font
}

const getFontSize = (size, defaultSize) => {
  const map = {
    xsmall: { size: '24px', height: '30px' },
    small: { size: '32px', height: '34px' },
    medium: { size: '48px', height: '50px' },
    large: { size: '64px', height: '66px' },
  }
  if (map[size]) return map[size]

  return defaultSize || map.medium
}

const CustomDisplay = styled.h1`
  margin: 0;
  letter-spacing: 1px;

  font-weight: ${(props) => props.font.weight} !important;
  font-size: ${(props) => props.font.size.size};
  line-height: ${(props) => props.font.height};
  text-align: ${(props) => props.font.align};

  color: ${(props) => props.color};

  @media screen and (max-width: 1024px) {
    font-size: ${(props) => props.font.ipadSize.size};
    line-height: ${(props) => props.font.ipadSize.height};
    text-align: ${(props) => props.font.ipadAlign};
  }

  @media screen and (max-width: 768px) {
    font-size: ${(props) => props.font.mobileSize.size};
    line-height: ${(props) => props.font.mobileSize.height};
    text-align: ${(props) => props.font.mobileAlign};
  }
`

export default Display
