import React, { useEffect } from 'react'
import styled from 'styled-components'

import Header from './Header'
import Info from './Info'
import Footer from './footer'

function Promotion() {
  useEffect(() => {
    const header = document.getElementById('app_header')
    const body = document.getElementById('app_body')
    const dbd = document.getElementById('app_dbd')
    const footer = document.getElementById('app_footer')
    if (header) {
      header.style.display = 'none'
    }
    if (body) {
      body.style.margin = '0'
    }
    if (dbd) {
      dbd.style.display = 'none'
    }
    if (footer) {
      footer.style.display = 'none'
    }

    return () => {
      if (header) {
        header.style.display = 'flex'
      }
      if (body) {
        body.style.margin = '70px 0'
      }
      if (dbd) {
        dbd.style.display = 'flex'
      }
      if (footer) {
        footer.style.display = 'flex'
      }
    }
  }, [])

  return (
    <Container>
      <Header />
      <Info />
      <Footer />
    </Container>
  )
}

export default Promotion

const Container = styled.div`
  width: 100%;
`
