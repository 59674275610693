import React from 'react'
import { useTheme } from 'styled-components'
/* eslint-disable */

const Logo = (props) => {
  const theme = useTheme()
  const color = getColor(props, theme)

  const { size = 1 } = props
  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`

  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.7001 5.8C20.3001 5.4 19.7001 5.4 19.3001 5.8L9.4001 16.1L4.80011 11.3C4.40011 10.9 3.8001 10.9 3.4001 11.3C3.0001 11.7 3.0001 12.3 3.4001 12.7L8.7001 18.2C8.9001 18.4 9.1001 18.5 9.4001 18.5C9.7001 18.5 9.90011 18.4 10.1001 18.2L20.8001 7.2C21.1001 6.8 21.1001 6.2 20.7001 5.8Z'
        fill={color} />
    </svg>
  )
}

const getColor = (props, theme) => {
  if (props.color) return props.color

  if (props.primary) return theme.color.primary
  if (props.background) return theme.gray.background


  return theme.gray.titleActive
}
// eslint-enable 
export default Logo
