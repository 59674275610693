import React from 'react'
import styled from 'styled-components'

import Loading from 'components/loading'
import { Close, Bell, CheckSql, UncheckSql } from 'icon'

const LicenseSetting = (props) => {
  const { loading = false, doc = {}, onSave, onClose, onChange } = props

  const onToggle = (isCheck, name) => {
    doc[name] = !isCheck
    onChange(doc)
  }

  const getMenuItem = (name, val) => {
    const isCheck = doc[name] || 0
    const checker = isCheck ? <CheckSql /> : <UncheckSql />
    return (
      <MenuItem>
        <MenuCheck onClick={() => onToggle(isCheck, name)}>{checker}</MenuCheck>
        <MenuText>{val} เดือนก่อนหมดอายุ</MenuText>
      </MenuItem>
    )
  }

  return (
    <Wrapper>
      <TopBar>
        <Icon>
          <Bell color="#5F2EEA" />
        </Icon>
        <Title>การแจ้งเตือน</Title>
        <Icon onClick={onClose}>
          <Close />
        </Icon>
      </TopBar>
      <Text>การแจ้งเตือนที่เลือกจะมีผลกับ</Text>
      <Text>ทุกใบอนุญาตที่บันทึกไว้ในโปรไฟล์ของคุณ</Text>
      <Menu>
        {getMenuItem('twelve_month', 12)}
        {getMenuItem('nine_month', 9)}
        {getMenuItem('six_month', 6)}
        {getMenuItem('three_month', 3)}
        {getMenuItem('one_month', 1)}
      </Menu>
      <Loading size={25} loading={loading}>
        <Button onClick={onSave}>
          <span>บันทึก</span>
        </Button>
      </Loading>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border: 1px solid #5f2eea;
  border-radius: 16px;
  padding: 24px 24px 13px 24px;
  width: 245px;
`

const TopBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

const Title = styled.div`
  color: #5f2eea;
  flex: 0 0 152px;
  padding-left: 8px;
`

const Icon = styled.span`
  cursor: pointer;
  flex: 0 0 20px;
  display: flex;
  align-items: center;
  &.disabled {
    cursor: not-allowed;
  }
`

const Text = styled.div`
  color: #4e4b66;
  font-size: 16px;
  line-height: 28px;
`

const Menu = styled.div`
  color: #4e4b66;
  font-size: 16px;
  line-height: 28px;
`

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  border: 0px solid #d9dbe9;
  border-bottom-width: 1px;
  svg {
    padding-left: 2px;
  }
`

const MenuCheck = styled.div`
  cursor: pointer;
`

const MenuText = styled.div`
  padding-left: 16px;
`

const Button = styled.div`
  color: #5f2eea;
  text-align: center;
  margin-top: 23px;

  span {
    font-size: 16px;
    cursor: pointer;
    padding: 10px;
  }
`

export default LicenseSetting
