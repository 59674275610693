import styled from 'styled-components'

import Display from 'components/typo/Display'
import Text from 'components/typo/Text'
import useMediaQuery from 'hooks/useMediaQuery'

const ContactUs = (props) => {
  const isMobile = useMediaQuery('(max-width:550px)')
  return (
    <PageView>
      <Anchor id="profile" />
      <Display size="small" primary bold>
        เกี่ยวกับเรา
      </Display>
      <TextBox>{isMobile ? <MobileText /> : <IpadText />}</TextBox>
    </PageView>
  )
}

const MobileText = () => {
  return (
    <Text size="small" ipadSize="xsmall" gray align="center">
      Friday คือระบบสำหรับตัวแทนประกัน ที่ถูกพัฒนา
      <br />
      ขึ้นโดยบริษัท TQD ซึ่งเป็นบริษัทผู้เชี่ยวชาญ
      <br />
      ด้านเทคโนโลยี และการประกันภัย ด้วยความตั้งใจ
      <br />
      ที่อยากให้ทุกคนเข้าถึง บริการทางการเงินที่มีประสิทธิภาพ
      <br />
      และตอบโจทย์กับ ความต้องการได้อย่างเท่าเทียม
      <br />
      Friday จึงถูกคิดค้นและพัฒนา เพื่อให้อุตสาหกรรม
      <br />
      ด้านการประกันภัยก้าวไปสู่ Digital Transformation
      <br />
      ได้อย่างเต็มรูปแบบ{' '}
      <a
        href="https://www.tqd.co.th/portfolio/"
        target="_blank"
        rel="noreferrer"
      >
        <Text size="small" ipadSize="xsmall" primary oneLine bold>
          ดูผลิตภัณฑ์ที่เกี่ยวข้อง
        </Text>
      </a>
    </Text>
  )
}

const IpadText = () => {
  return (
    <Text size="small" ipadSize="xsmall" gray align="center">
      Friday คือระบบสำหรับตัวแทนประกัน ที่ถูกพัฒนาขึ้นโดยบริษัท TQD
      ซึ่งเป็นบริษัทผู้เชี่ยวชาญด้านเทคโนโลยี
      <br />
      และการประกันภัย ด้วยความตั้งใจที่อยากให้ทุกคนเข้าถึง
      บริการทางการเงินที่มีประสิทธิภาพ และตอบโจทย์กับ
      <br />
      ความต้องการได้อย่างเท่าเทียม Friday จึงถูกคิดค้นและพัฒนา
      เพื่อให้อุตสาหกรรม
      <br />
      ด้านการประกันภัยก้าวไปสู่ Digital Transformation ได้อย่างเต็มรูปแบบ{' '}
      <a
        href="https://www.tqd.co.th/portfolio/"
        target="_blank"
        rel="noreferrer"
      >
        <Text size="small" ipadSize="xsmall" primary oneLine bold>
          ดูผลิตภัณฑ์ที่เกี่ยวข้อง
        </Text>
      </a>
    </Text>
  )
}

const PageView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  width: 100%;
  max-width: 1440px;
  row-gap: 24px;
  padding: 50px 40px;

  @media screen and (max-width: 1024px) {
    padding: 40px 35px;
  }

  @media screen and (max-width: 500px) {
    padding: 40px 15px;
  }
`

const Anchor = styled.span`
  position: absolute;
  top: -70px;
`

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
`

export default ContactUs
