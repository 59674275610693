import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { Content } from 'components/display'
import PLAYSTORE from 'pages/home/banner/assets/playstore_download.png'
import APPSTORE from 'pages/home/banner/assets/appstore_download.png'
import useMediaQuery from 'hooks/useMediaQuery'

import Facebook from './Facebook'
import Line from './Line'

const Footer = () => {
  const isMobile = useMediaQuery('(max-width:768px)')

  return (
    <>
      {/* <DBDContainer>
        <Content>
          <Row justify="center" align="middle">
            <div id="Certificate-banners"></div>
            <span>ใบอนุญาตจากกรมพัฒนาธุรกิจการค้า เลขที่ 0105559148619</span>
          </Row>
        </Content>
      </DBDContainer> */}
      <PageView>
        <Content>
          <Row align="middle">
            <Col lg={12} md={24} xs={24}>
              <Title>สงวนลิขสิทธิ์ © 2564 บริษัท ทีคิวดี จำกัด</Title>
            </Col>
            <Col lg={12} md={24} xs={24}>
              <Row align="middle" justify={isMobile ? 'start' : 'end'}>
                <Download>ดาวน์โหลดได้แล้ววันนี้</Download>
                <a
                  href="https://bit.ly/3Kefz1F"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="app_download"
                    src={PLAYSTORE}
                    alt="playstore icon"
                  />
                </a>
                <a
                  href="https://apple.co/3uD5Qx8"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="app_download"
                    src={APPSTORE}
                    alt="appstore icon"
                  />
                </a>
                <a
                  href="https://bit.ly/3E5yacx"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Line />
                </a>
                <a
                  href="https://bit.ly/3p03WDN"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Facebook />
                </a>
              </Row>
            </Col>
          </Row>
        </Content>
      </PageView>
    </>
  )
}

const PageView = styled.div`
  background-color: #5f2eea;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 1;

  a {
    max-height: 32px;
  }
  img {
    border-radius: 6px;
    margin-left: 8px;
  }
  svg {
    border-radius: 6px;
    margin-left: 16px;
  }

  @media screen and (max-width: 500px) {
    height: auto;
  }
`
const DBDContainer = styled(PageView)`
  background-color: white;

  #Certificate-banners {
    img {
      width: 100px;
      margin-right: 10px;
    }
  }

  span {
    font-size: 16px;
  }

  @media screen and (max-width: 990px) {
    #Certificate-banners {
      img {
        width: 60px;
      }
    }
    span {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 500px) {
    #Certificate-banners {
      img {
        width: 32px;
      }
    }
    span {
      font-size: 10px;
    }
  }
`
const Title = styled.label`
  color: white;
`
const Download = styled.small`
  color: white;
`

export default Footer
