import moment from 'moment-timezone'

moment.tz.setDefault('Asia/Phnom_Penh')

export class Timer {
  toMomentOffset(val) {
    const init = val ? val.clone() : moment()

    return init.add(543, 'year')
  }

  get(init, format) {
    if (init && format) return moment(init, format)
    else if (init) return moment(init)

    return this.toMomentOffset()
  }

  getDate() {
    const val = moment().startOf('day')
    return this.toMomentOffset(val)
  }

  getNoMinute() {
    return this.toMomentOffset().minute(0).second(0)
  }

  format() {
    return this.toMomentOffset().format()
  }

  toThatDate(val, time = false) {
    const date = this.toMomentOffset(val)
    const text_date = date.format('DD/MM/') + `${date.year()}`

    const text_time = time ? ` ${date.format('HH:mm')}` : ''
    return text_date + text_time
  }

  convertToMoment(val, format) {
    try {
      if (val === '' || val === undefined || val === null) return undefined
      else if (moment.isMoment(val)) return val

      return moment(val, format)
    } catch (e) {
      console.log('to moment:', e.message)
    }

    return undefined
  }

  toSelectDate(val) {
    const date = {}

    if (val) {
      const time = moment(val)

      date.day = time.date()
      date.month = `${time.month()}`
      //date.year = time
      date.year = `${time.year()}`

      date.hour = time.hour()
      date.minute = time.minute()
      date.second = time.second()
    }

    return date
  }

  toSelectMoment(val = {}) {
    const year = val.year //val.year ? val.year.year() : 2000
    const date = `${+val.day || 1}/${+val.month + 1 || 1}/${year}`
    const time = `${val.hour || 0}:${val.minute || 0}:${val.second || 0}`
    const txt = `${date} ${time}`
    const data = moment(txt, 'DD/MM/YYYY hh:mm:ss')

    return data
  }

  getSelectDate(val = {}) {
    const data = this.toSelectMoment(val)

    return data.toISOString()
  }
}

export const timer = new Timer()
export default timer
