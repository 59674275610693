import numeral from 'numeral'
import moment from 'moment'
import 'moment/locale/th'

export class Format {
  isNull(val) {
    if (val === undefined || val === null || val === '') return true
    else return false
  }

  toInt(val) {
    if (this.isNull(val) || isNaN(+val)) return '0'
    else return numeral(val).format('0')
  }

  toDigi(val) {
    if (this.isNull(val) || isNaN(+val)) return '0'
    else return numeral(val).format('0,0')
  }

  precision(val) {
    return Math.floor(val * 100) / 100
  }

  toMoney(val) {
    if (this.isNull(val) || isNaN(+val)) return '0'
    else return numeral(val).format('0,0.00')
  }

  toText(val) {
    if (this.isNull(val)) return '-'
    else return val
  }

  toFullDT(val) {
    if (this.isNull(val)) return '-'
    else return moment(val).locale('th').format('D MMM YYYY เวลา HH:mm')
  }

  toShortDay(val) {
    if (this.isNull(val)) return '-'
    else if (typeof val === 'string') return val.slice(0, 3)
    else return moment(val).format('DD')
  }

  toShortMonth(val) {
    if (this.isNull(val)) return '-'
    else if (typeof val === 'string') return val.slice(0, 3)
    else return moment(val).format('MM')
  }

  toDate(val) {
    if (this.isNull(val)) return '-'
    else return moment(val).format('DD/MM/YYYY')
  }

  toNameDate(val) {
    if (this.isNull(val)) return '-'
    else return moment(val).locale('th').format('D MMM YYYY')
  }

  toWeekDate(val) {
    if (this.isNull(val)) return '-'
    else return moment(val).format('dddd')
  }

  toTime(val) {
    if (this.isNull(val)) return '-'
    else return moment(val).format('HH:mm')
  }

  limitText(text, size = 100) {
    text = text ? text : ''
    if (text.length <= size) return text

    return text.substr(0, size - 3) + '..'
  }

  upperText(text = '') {
    if (text.length === 0) return text
    return text[0].toUpperCase() + text.substring(1)
  }

  toThaiDate(val, options = {}) {
    const { shortMonth } = options
    if (this.isNull(val)) return '-'

    const date = moment(val)
    const m = date.month()
    let month = MONTH[m]

    if (shortMonth) month = SHORT_MONTH[m]

    return `${date.date()} ${month} ${date.year()}`
  }

  toThaiDateTime(val) {
    return moment(val).locale('th').format('DD MMM YYYY เวลา HH:mm')
  }

  toShortDate(val) {
    if (this.isNull(val)) return '-'

    const date = moment(val)
    const m = date.month()
    return `${date.date()} ${MONTH[m]} ${date.year()}`
  }

  toShortMonthDate(val) {
    if (this.isNull(val)) return '-'

    const date = moment(val)
    const m = date.month()
    return `${date.date()} ${SHORT_MONTH[m]} ${date.year()}`
  }

  getError(msg = '') {
    switch (msg) {
      case 'The popup has been closed by the user before finalizing the operation.':
        return undefined
      default:
        return msg
    }
  }

  toMobile(val = '') {
    const ch = '-'
    const ids = [3, 6]
    return this.formatText(val, { ch, ids })
  }

  toCreditCard(val = '') {
    const ch = '-'
    const ids = [4, 8, 12]
    return this.formatText(val, { ch, ids })
  }

  formatText(val = '', { ch = '', ids = [] } = {}) {
    const len = val.length
    let txt = ''

    for (let i = 0; i < len; i++) {
      if (ids.includes(i)) {
        txt += ch
      }

      txt += val.charAt(i)
    }

    return txt
  }

  toAddress(address) {
    if (Object.keys(address).length === 0) return null
    const bufferAddress = []
    ORDERED_ADDRESS_KEY.forEach((key) => {
      const value = address[key]
      if (!this.isNull(value)) bufferAddress.push(value)
    })
    return bufferAddress.join(' ')
  }
}

const MONTH = {
  0: 'มกราคม',
  1: 'กุมภาพันธ์',
  2: 'มีนาคม',
  3: 'เมษายน',
  4: 'พฤษภาคม',
  5: 'มิถุนายน',
  6: 'กรกฎาคม',
  7: 'สิงหาคม',
  8: 'กันยายน',
  9: 'ตุลาคม',
  10: 'พฤศจิกายน',
  11: 'ธันวาคม',
}

const SHORT_MONTH = {
  0: 'ม.ค.',
  1: 'ก.พ.',
  2: 'มี.ค.',
  3: 'เม.ย.',
  4: 'พ.ค.',
  5: 'มิ.ย.',
  6: 'ก.ค.',
  7: 'ส.ค.',
  8: 'ก.ย.',
  9: 'ต.ค',
  10: 'พ.ย.',
  11: 'ธ.ค.',
}

const ORDERED_ADDRESS_KEY = [
  'house_no',
  'road',
  'sub_district',
  'district',
  'province',
  'postcode',
]

export const format = new Format()
export default format
