import React from 'react'
import styled from 'styled-components'
/* eslint-disable */

const Logo = (props) => {
  const { label, onClick } = props
  return (
    <Content onClick={onClick}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#D9DBE9"/>
      </svg>
      {label}
    </Content>
  )
}
// eslint-enable
const Content = styled.span`
  cursor: pointer;
  display: flex;

  svg {
    margin-right: 16px;
  }
`
export default Logo
