import React, { useState, useEffect, memo } from 'react'
import filter from 'lodash/filter'

import CollapseList from 'components/CollapseList'
import Arrow from 'icon/Arrow'
import { ADDRESS_STRUCTURE } from 'utils/helper'

import FieldInput from './FieldInput'
import { helper } from '../../utils'

const DropdownTax = (props) => {
  const {
    label,
    small,
    value,
    name,
    show,
    disabled,
    onChange = (val) => {},

    errorText,
    filterable,
    address,
  } = props
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [filterOptions, setFilterOptions] = useState([])

  const onInputChange = (val) => {
    if (val) {
      setFilterOptions(
        filter(helper.generateAddressStructure(), (o) =>
          o.value[address.key].startsWith(val)
        )
      )
    }

    setInputValue(val)
  }

  const onDropDownChange = (val) => {
    const found = filterOptions.find((item) => item.value === val)

    if (found) setInputValue(found.label)
    onChange(val, name)
  }

  const optionFilter = (item) => {
    if (!filterable) return true

    return helper.searchString(item.label, inputValue)
  }

  const isReadOnly = !filterable

  useEffect(() => {
    if (value) setInputValue(value)
  }, [value])

  return (
    <CollapseList
      open={open}
      show={show}
      setOpen={setOpen}
      options={open ? ADDRESS_STRUCTURE.filter(optionFilter) : filterOptions}
      value={inputValue}
      gap="0"
      onChange={onDropDownChange}
    >
      <FieldInput
        small={small}
        label={label}
        value={inputValue}
        onChange={onInputChange}
        disabled={disabled}
        readonly={isReadOnly}
        errorText={errorText}
        onClick={() => setOpen(!open)}
        endIcon={<Arrow isUp={open} />}
      />
    </CollapseList>
  )
}

export default memo(DropdownTax)
