import React, { useEffect, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { Text } from 'components/typo'
import IconButton from 'components/button/IconButton'
import { Close, Info } from 'icon'
import { HelperText } from 'components/display'
import Tooltip from 'components/Tooltip'

import resizeIcon from '../../assets/resize-icon.svg'

const TextArea = (props) => {
  const {
    id,
    type,
    startIcon,
    endIcon,
    label,
    name,
    rows = 4,
    placeholder = 'placeholder',
    toolTip = '',
    isDisabled = false,
    clearable = false,
    helperText = '',
    errorText = '',
    successText = '',
    autoFocus = false,
    value = '',
    onChange,
  } = props

  const theme = useTheme()
  const ref = useRef()
  const [isEnter, setEnter] = useState(false)
  const [hasValue, setHasValue] = useState(false)

  const onFocus = () => ref.current.focus()
  const resetValue = () => onChange('', name)
  const onValChange = (e) => onChange(e.target.value || '', name)

  const styleProps = {
    size: getSize(props),
    color: getColor(
      theme,
      { isEnter, isDisabled },
      { error: errorText, success: successText }
    ),
  }

  useEffect(() => {
    setHasValue(isEnter || value)
  }, [isEnter, value])

  return (
    <Wrapper onClick={onFocus}>
      <InputWrapper {...styleProps} isEnter={isEnter} isDisabled={isDisabled}>
        {startIcon && <StartIcon>{startIcon}</StartIcon>}
        <InnerInputWrapper>
          <InputLabel hasValue={hasValue} {...styleProps}>
            <Text size={hasValue ? 'xsmall' : 'small'}>
              {label || placeholder}
            </Text>
          </InputLabel>
          <Input
            id={id}
            ref={ref}
            type={type}
            rows={rows}
            autoFocus={autoFocus}
            disabled={isDisabled}
            onFocus={() => setEnter(true)}
            onBlur={() => setEnter(false)}
            value={value}
            onChange={onValChange}
          />
        </InnerInputWrapper>
        {clearable && value && (
          <IconButton onClick={resetValue}>
            <Close />
          </IconButton>
        )}
        {endIcon && <EndIcon>{endIcon}</EndIcon>}
      </InputWrapper>
      {toolTip && (
        <CustomIconButton>
          <Tooltip text={toolTip}>
            <Info />
          </Tooltip>
        </CustomIconButton>
      )}
      <HelperText
        helperText={helperText}
        successText={successText}
        errorText={errorText}
      />
      <Resize
        onDragStart={(e) => {
          console.log('drag', e)
          e.stopPropagation()
          e.preventDefault()
        }}
        onTouchStart={(e) => {
          e.stopPropagation()
        }}
      >
        <img width={20} alt="resizeIcon" src={resizeIcon} />
      </Resize>
    </Wrapper>
  )
}

const Resize = styled.div`
  pointer-events: none;
  position: absolute;
  cursor: pointer;
  bottom: 8px;
  right: 8px;
`

const getSize = (props) => {
  const { width, small } = props
  if (small) return { padding: '14px 8px 8px 24px' }

  return { width, padding: '18px 8px 8px 24px' }
}

const getColor = (theme, states = {}, options = {}) => {
  if (options.error) {
    return {
      border: `2px ${theme.color.error}`,
      bg: theme.color.errorLight,
      label: theme.color.errorDark,
    }
  }

  if (options.success) {
    return {
      border: `2px ${theme.color.success}`,
      bg: theme.color.successLight,
      label: theme.color.successDark,
    }
  }

  return {
    border: states.isEnter
      ? `2px ${theme.color.primary}`
      : `1px ${theme.gray.line}`,
    bg: states.isDisabled && theme.gray.inputBackground,
    label: theme.gray.label,
  }
}

const CustomIconButton = styled(IconButton)`
  position: absolute;
  right: -36px;

  &:hover path {
    fill: ${(props) => props.theme.color.primaryDark};
  }
`

const StartIcon = styled.div`
  display: flex;
  align-items: center;
`

const EndIcon = styled.div`
  display: flex;
  align-items: center;
`

const Input = styled.textarea`
  border: none;
  outline: none;
  background: none;

  width: 100%;
  line-height: 18px;
  margin-top: 14px;
  padding: 5px 0;
  color: ${(props) => props.theme.color.titleActive};

  &:disabled {
    color: ${(props) => props.theme.gray.placeholder};
  }

  &::-webkit-resizer {
    opacity: 0;
  }
`

const InputLabel = styled.div`
  position: absolute !important;
  pointer-events: none;
  transition: all 0.2s;
  color: ${(props) => props.color.label};

  margin-top: ${(props) => (props.hasValue ? '-14px' : '0')};
`

const InnerInputWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`

const InputWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  transition: all 0.1s;
  border-radius: 16px;
  width: ${(props) => props.size.width || '100%'};

  padding: ${(props) => props.size.padding};
  box-shadow: 0 0 0 ${(props) => props.color.border} inset;
  background-color: ${(props) => props.color.bg};
  opacity: ${(props) => props.isDisabled && 0.5};
  height: ${(props) => props.size.height};
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  width: 100%;
  column-gap: 16px;
`

export default TextArea
