import React from 'react'
import styled, { useTheme } from 'styled-components'

import IconButton from 'components/button/IconButton'
import Tooltip from 'components/Tooltip'
import { Info } from 'icon'

const TooltipHelp = (props) => {
  const theme = useTheme()

  const { forwardRef, toolTip, show } = props

  const onClickTooltip = (e) => {
    e.stopPropagation()
  }

  return (
    <CustomIconButton {...theme} onClick={onClickTooltip}>
      <Tooltip forwardRef={forwardRef} text={toolTip} show={show}>
        <Info />
      </Tooltip>
    </CustomIconButton>
  )
}

const CustomIconButton = styled(IconButton)`
  &:hover path {
    fill: ${(props) => props.theme.color.primaryDark};
  }
`

export default TooltipHelp
