import React from 'react'
import styled from 'styled-components'

import Payment from 'pages/account/payment/PromptPay/Payment'
import PromptPay from 'pages/account/payment/PromptPay/PromptPay'

const Promptpay = (props) => {
  return (
    <ContentView>
      <Payment />
      <QRLogo>
        <PromptPay />
      </QRLogo>

      <QRCode src={props.code} />

      <CompanyName>บริษัท ทีคิวดี จำกัด</CompanyName>
    </ContentView>
  )
}

const QRCode = styled.img`
  width: 200px;
  height: 200px;
`

const QRLogo = styled.div`
  padding-top: 25px;
  padding-bottom: 20px;
`

const ContentView = styled.div`
  width: 325px;
  height: 449px;
  border: 1px solid #d9dbe9;
  text-align: center;
  margin: 0 auto;
  margin-top: 39px;
`

const CompanyName = styled.div`
  color: #0e3e68;
  padding-top: 20px;
  font-weight: 600;
  font-size: 18px;
`

export default Promptpay
