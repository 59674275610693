import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Text } from 'components/typo'

const MenuSection = (props) => {
  const history = useHistory()
  const { header, list, active } = props

  const pathname = history.location.pathname
  const goto = (link) => () => history.push(link)
  const isActive = (item) => {
    const { list = [] } = item
    return (
      item.link === pathname ||
      item.text === active ||
      pathname.includes(item.link) ||
      list.includes(pathname)
    )
  }

  return (
    <Wrapper>
      <HeadMenuLink>
        <Text size="small" title bold>
          {header}
        </Text>
      </HeadMenuLink>
      {list.map((item, i) => (
        <MenuLink
          key={item.text + i}
          isActive={isActive(item)}
          onClick={item.onClick || goto(item.link)}
        >
          {item.text}
        </MenuLink>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div``

const HeadMenuLink = styled.div`
  font-size: 16px;
  line-height: 36px;
  padding: 0 16px;
  width: 100%;

  color: ${(p) => p.theme.gray.titleActive};
`

const MenuLink = styled.div`
  cursor: pointer;
  white-space: nowrap;

  font-size: 16px;
  line-height: 36px;
  padding: 0 16px;
  margin: 2px 0;
  width: 100%;
  border-radius: 8px;

  color: ${(p) => p.theme.gray.body};

  ${(p) =>
    p.isActive &&
    `color: ${p.theme.color.primary};
    background-color: ${p.theme.color.primaryBg};`}
  &:hover {
    color: ${(p) => p.theme.color.primary};
    background-color: ${(p) => p.theme.color.primaryBg};
  }
`

export default MenuSection
