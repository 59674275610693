import { useMemo } from 'react'
import styled from 'styled-components'

import useMediaQuery from 'hooks/useMediaQuery'

function FeatureCard({ icon = null, topic = '', description = {} }) {
  const isIpad = useMediaQuery('(max-width:1024px)')
  const isMobile = useMediaQuery('(max-width:768px)')

  const renderDescription = useMemo(() => {
    if (isMobile) {
      return description.mobile || description.desktop
    }
    if (isIpad) {
      return description.tablet || description.desktop
    }

    return description.desktop
  }, [description, isMobile, isIpad])

  return (
    <Container>
      <Icon src={icon} alt="feature icon" />
      <Topic>{topic}</Topic>
      <Description>{renderDescription}</Description>
    </Container>
  )
}

export default FeatureCard

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 10px;
  border-radius: 16px;
  background-color: #f7f7fc;
`
const Icon = styled.img`
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 auto 8px;
`
const Topic = styled.p`
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 38px;
  text-align: center;
  color: #5f2eea;
`
const Description = styled.p`
  margin-bottom: 0;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #5f2eea;
`
