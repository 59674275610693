export const PACKAGE_TYPE = Object.freeze({
  NONE: 'none',
  TRIAL: 'trial',
  STARTER: 'starter',
  PROFESSIONAL: 'professional',
  TEAM: 'team',
  FREE: 'free',
  STANDARD: 'standard',
  ADVANCE: 'advance',
})

export const PACKAGE_DURATION = Object.freeze({
  PER_MONTH: 'per_month',
  PER_MONTH_TH: 'รายเดือน',
  PER_YEAR: 'per_year',
  PER_YEAR_TH: 'รายปี',
})

export const MAP_PACKAGE_DURATION = Object.freeze({
  [PACKAGE_DURATION.PER_MONTH]: 'รายเดือน',
  [PACKAGE_DURATION.PER_YEAR]: 'รายปี',
})

export const PACKAGE_STATUS = Object.freeze({
  NONE: 'none',
  ACTIVE: 'active',
  PENDING: 'pending',
})

export const PACKAGE_RESULT_STATUS = Object.freeze({
  PAY_MORE: 'pay_more',
  READY_TO_CHANGE: 'ready_to_change',
  CHARGE_NEXT_TIME: 'charge_next_time',
})
