import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { Text } from 'components/typo'
import Layout from 'pages/account/components/Layout'
import Loading from 'components/loading'
import { helper, message } from 'utils'
import getPackageInfo from 'definition/getPackageInfo'
import PackagePaymentButton from 'components/button/PackagePaymentButton'
import ModalTax from 'components/modal/ModalTax'
import { Checkbox } from 'components/input'
import { Button } from 'components/button'
import Modal from 'components/modal/Modal'
import PromotionModal from 'pages/account/promotion/promotionModal'

import Payment from './Payment'
import PromptPay from './PromptPay'
import couponIcon from '../assetss/Coupon.png'
import couponIconSuccess from '../assetss/CouponSuccess.png'

const PaymentPromptPay = (props) => {
  const { user } = props.member.toJS()
  const history = useHistory()

  const [init, setInit] = useState(false)
  const [initSelected, setInitSelected] = useState(false)
  const [payment, setPayment] = useState({})
  const [selected, setSelected] = useState({})
  const [isOpened, setOpen] = useState(false)
  const [saveText, setSaveText] = useState(false)
  const [closeTax, setCloseTax] = useState(true)
  const [acceptTax, setAcceptTax] = useState(false)
  const [isSubmit, setSubmit] = useState(false)
  const [isPromotion, setModalPromotion] = useState(false)
  const [promotion, setPromotion] = useState({})
  const [timoutId, setTimeoutId] = useState(null)

  const onChecking = useCallback(
    async (invoice_no, time) => {
      let status
      try {
        const { payment } = await props.account.getPaymentResult(invoice_no)
        status = payment.status
        if (status === 'completed' || status === 'cancelled') {
          props.history.push(`/account/payment-result/${invoice_no}`)
          return
        }
      } catch (e) {
        console.log('qr:', e.message)
      }
      setTimeoutId(setTimeout(() => onChecking(invoice_no), time || 8000))
    },
    [props.account, props.history]
  )

  const onInit = useCallback(async () => {
    try {
      setInit(true)
      const res = (await props.package_app.getSelectPackage()) || {}
      const packageApp = res.package

      const { package_duration, package_price, package_type } = packageApp

      const paymentRes = await props.account.paymentByQRCode({
        package_duration,
        package_price,
        package_type,
      })

      setPayment(paymentRes)

      onChecking(paymentRes.invoice_no, 25000)
    } catch (e) {
      message.error({ message: e.message })
    }
    setInit(false)
  }, [onChecking, props.account, props.package_app])

  const onInitSelectPackage = useCallback(async () => {
    try {
      setInitSelected(true)
      const res = (await props.package_app.getSelectPackage()) || {}
      const packageApp = res.package
      setSelected(packageApp)
    } catch (e) {
      message.error({ message: e.message })
    }
    setInitSelected(false)
  }, [props.package_app])

  useEffect(() => {
    onInitSelectPackage()
    if (isSubmit) {
      onInit()
    }
  }, [onInit, onInitSelectPackage, isSubmit])

  const [taxinvoice, setTaxinvoice] = useState({
    tax_type: 'บุคคลธรรมดา',
    first_name: '',
    last_name: '',
    company_name: '',
    tax_no: '',
    phone_number: '',
    email: '',
    house_no: '',
    road: '',
    sub_district: '',
    district: '',
    province: '',
    postcode: '',
  })

  useEffect(() => {
    if (acceptTax) {
      setOpen(true)
    }
    if (isOpened) {
      setAcceptTax(false)
    }
  }, [acceptTax, isOpened])

  useEffect(() => {
    if (closeTax === false) {
      setSaveText(false)
      setCloseTax(true)
    }
  }, [closeTax])

  const info = getPackageInfo(selected)

  const clearPromotion = useCallback(() => {
    setPromotion({})
  }, [setPromotion])

  const totalPay = useMemo(() => {
    return (selected?.package_price || 0) - (promotion?.value || 0)
  }, [selected, promotion])

  const onNopaySubmit = useCallback(async () => {
    if (timoutId) {
      clearTimeout(timoutId)
    }
    await props.payment.changePackage(
      selected.package_app_id,
      selected.package_duration
    )
    history.push('/account/my-payment')
  }, [timoutId, props.payment, history, selected])

  return (
    <Layout header="ชำระเงิน" subHeader="ขั้นตอนที่ 4 จาก 4">
      <Modal isOpened={isPromotion} setOpen={setModalPromotion}>
        <PromotionModal
          setOpen={setModalPromotion}
          onApplyPromotion={setPromotion}
        />
      </Modal>
      {/* <ModalTax
        user={user}
        taxinvoice={taxinvoice}
        setTaxinvoice={setTaxinvoice}
        isOpened={isOpened} 
        setOpen={setOpen}
        saveText={saveText}
        setSaveText={setSaveText}
      /> */}
      <Caption>เมื่อกด ชำระเงิน ระบบจะแสดงคิวอาร์โค้ด</Caption>
      <Caption>คุณต้องสแกนคิวอาร์โค้ดเพื่อชำระเงินภายใน 10 นาที</Caption>
      <Content>
        <Loading loading={initSelected}>
          <PackagePaymentButton
            info={info}
            credit={user.credit || promotion?.value || 0}
            onClick={() =>
              history.push('/account/package-detail', { from: 'payment' })
            }
          />
        </Loading>
        <Space>
          {/*
          <Coupon
            color={promotion.code}
            onClick={() => {
              if (promotion.code) return
              setModalPromotion(true)
            }}
          >
            <CouponText>ใช้คูปองโค้ด</CouponText>
            <CouponIconContainer>
              <img
                src={promotion.code ? couponIconSuccess : couponIcon}
                alt=""
              />
              {promotion.code && <div onClick={clearPromotion}>X</div>}
            </CouponIconContainer>
          </Coupon> */}
          {/* {saveText ? (
            <ContentDiv>
              <RowDiv>
                <Checkbox
                  size
                  label="ต้องการใบกำกับภาษี"
                  checked={closeTax}
                  onCheck={(val) => setCloseTax(val)}
                />
                <span
                  className="text"
                  onClick={() => {
                    setOpen(true)
                  }}
                >
                  แก้ไข
                </span>
              </RowDiv>
              <Padding>
                {taxinvoice.tax_type === 'บุคคลธรรมดา' ? (
                  <span className="textSpan">ชื่อ {taxinvoice.first_name}</span>
                ) : (
                  <span className="textSpan">
                    ชื่อบริษัท {taxinvoice.company_name}
                  </span>
                )}
                <span className="textSpan">
                  ติดต่อ {helper.toMobile(taxinvoice.phone_number)}
                </span>
                <span className="textSpan">อีเมล {taxinvoice.email}</span>
                <span className="textSpan">
                  หมายเลขบัตรประชาชน/หมายเลขนิติบุคคล {taxinvoice.taxNumber}
                </span>

                <span className="textSpan">
                  {taxinvoice.house_no} ถนน {taxinvoice.road} อำเภอ/เขต
                  {taxinvoice.district}
                </span>
                <span className="textSpan">
                  ตำบล/แขวง {taxinvoice.sub_district}
                  จังหวัด {taxinvoice.province}
                </span>
                <span className="textSpan">{taxinvoice.postcode}</span>
              </Padding>
            </ContentDiv>
          ) : (
            <Checkbox
              size
              label="ต้องการใบกำกับภาษี"
              checked={acceptTax}
              onCheck={(val) => setAcceptTax(val)}
            />
          )} */}
        </Space>

        {isSubmit ? (
          totalPay > 0 ? (
            <Loading loading={init}>
              <ContentView>
                <Payment />
                <QRLogo>
                  <PromptPay />
                </QRLogo>
                <QRCode src={payment.qr_code} />
                <CompanyName>บริษัท ทีคิวดี จำกัด</CompanyName>
              </ContentView>
            </Loading>
          ) : (
            <>
              <NopayContainer>
                <NopayHeader>
                  ไม่มียอดชำระ
                  <br />
                  เริ่มใช้งานแพ็กเกจเริ่มต้นได้ฟรี 30 วัน
                </NopayHeader>
                <NopayBody>จะมีการเรียกเก็บบิลชำระเงินในรอบบิลถัดไป</NopayBody>
              </NopayContainer>
              <Button
                large
                color="primary"
                text="ยืนยัน"
                onClick={onNopaySubmit}
              />
            </>
          )
        ) : (
          <Button
            large
            color="primary"
            text="ชำระเงิน"
            onClick={() => {
              setSubmit(true)
            }}
          />
        )}
      </Content>
    </Layout>
  )
}

const Caption = styled.span`
  font-size: 16px;
  font-weight: 400;
`

const Space = styled.div`
  padding: 24px 0 32px;
  text-align: left;
`
const QRCode = styled.img`
  width: 200px;
  height: 200px;
`

const QRLogo = styled.div`
  padding-top: 25px;
  padding-bottom: 20px;
`

const ContentView = styled.div`
  width: 325px;
  height: 449px;
  border: 1px solid #d9dbe9;
  text-align: center;
  margin-top: 10px;
`

const CompanyName = styled.div`
  color: #0e3e68;
  padding-top: 20px;
  font-weight: 600;
  font-size: 18px;
`
const Padding = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
`
const ContentDiv = styled.div`
  width: 325px;
  height: 264px;
  border: 1px solid #d9dbe9;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 20px;
`
const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .text {
    font-weight: 600;
    font-size: 14px;
    color: #5f2eea;
    font-size: 14px;
    cursor: pointer;
  }
`
const Content = styled.div`
  padding-top: 20px;
  width: 325px;
  margin-bottom: 70px;
  .textSpan {
    font-weight: 500;
    font-size: 14px;
    color: #a0a3bd;
  }
`
const Coupon = styled.div`
  background-color: ${(props) => (props.color ? '#F2FFFB' : 'none')};
  display: flex;
  flex-direction: row;
  padding: 10px;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 16px;
  border: ${(props) =>
    props.color ? '1px solid #00BA88' : '1px solid #d9dbe9'};
  color: ${(props) => (props.color ? '#00BA88' : '#5f2eea')};
  cursor: pointer;
`
const CouponText = styled.div`
  font-size: 16px;
  color: inherit;
  display: inline-block;
  font-weight: 600;
`
const CouponIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export default inject(
  'account',
  'package_app',
  'member',
  'payment'
)(observer(PaymentPromptPay))

const NopayContainer = styled.div`
  margin-bottom: 40px;
  border: 1px solid rgba(217, 219, 233, 1);
  padding: 22px;
  text-align: center;
`
const NopayHeader = styled.div`
  border-bottom: 1px solid rgba(217, 219, 233, 1);
  padding: 1rem;
  color: #0e3e68;
  font-weight: bold;
`
const NopayBody = styled.div`
  padding: 1rem;
  font-size: smaller;
`
