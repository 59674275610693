import React from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'

import MenuSection from './MenuSection'

const ProfileRoute = (props) => {
  const history = useHistory()
  const { children, active } = props
  const onSignOut = () => {
    props.member.logout().then(() => {
      history.push('/login')
    })
  }

  const menus = [
    {
      header: 'บัญชีผู้ใช้งาน',
      list: [
        {
          text: 'โปรไฟล์ของฉัน',
          link: '/account/profile',
          list: ['/account/delete-account'],
        },
        { text: 'ความปลอดภัย', link: '/account/privacy' },
        { text: 'ข้อมูลการชำระเงิน', link: '/account/my-payment' },
      ],
    },
    {
      header: 'การสนับสนุน',
      list: [
        { text: 'ปัญหาที่พบบ่อย', link: '/account/common-problem' },
        { text: 'ศูนย์ช่วยเหลือ', link: '/account/issue-problem' },
        { text: 'เงื่อนไขการใช้บริการ', link: '/account/consent/terms' },
        { text: 'นโยบายความเป็นส่วนตัว', link: '/account/consent/privacy' },
        { text: 'เกี่ยวกับ', link: '/account/about' },
        { text: 'ให้คะแนนผู้ใช้งาน', link: '/account/rating' },
      ],
    },
    { list: [{ text: 'ออกจากระบบ', onClick: onSignOut }] },
  ]

  return (
    <Wrapper>
      <SideBar>
        {menus.map((menu, i) => (
          <MenuSection
            key={menu.header + i}
            active={active}
            header={menu.header}
            list={menu.list}
          />
        ))}
      </SideBar>
      <Content>{children}</Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 244px 1fr;
  position: relative;
  column-gap: 30px;

  padding: 40px 70px;
  width: 100%;
  max-width: 1440px;

  @media screen and (max-width: 1024px) {
    padding: 52px 40px;
  }

  @media screen and (max-width: 768px) {
    padding: 40px;
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 500px) {
    padding: 20px;
    display: block;
  }
`

const SideBar = styled.nav`
  height: 100%;
  min-width: 244px;
  padding: 30px;

  background-color: ${(p) => p.theme.gray.offWhite};

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;

  @media screen and (max-width: 500px) {
    display: block;
  }
`

export default inject('member')(observer(ProfileRoute))
