const project = {
  name: 'พรรคไทยสร้างไทย',
  // eslint-disable-next-line
  address: '132/2 หมู่ที่ 16 ถนนเจนจบทิศ ตําบลหนองสองห้อง อําเภอหนองสองห้อง จังหวัดขอนแก่น รหัสไปรษณีย์ 40190',
  phone: '08-1909-1212',
  logo: 'https://storage.googleapis.com/stp-voting-develop/Logo.png',
  fb_link: 'https://www.facebook.com/thaisangthaiparty',
  ig_link: 'https://www.instagram.com/thaisangthaiparty',
  twitter_link: 'https://twitter.com/thaisangthai',
  youtube_link: 'https://www.youtube.com/channel/UCqkJwRzxzTKUzZ5RXYXsjTw',
  tiktok_link: 'https://www.tiktok.com/@thaisangthaiparty',
  // eslint-disable-next-line
  line_link: 'https://page.line.me/?accountId=thaisangthai&openerPlatform=native&openerKey=chatMessage'
}

const color = {
  active: '#0047FF',
  bg: 'white',
  font: '#4E4B66',
  border: '#000B8C',
  error: '#FF0000',

  disabled_bg: '#EBEBF6',
  disabled_font: '#999DD1',
}

const theme = {
  color: {
    primary: '#5F2EEA',
    primaryDark: '#2A00A2',
    primaryDarkMode: '#BCA4FF',
    primaryBg: '#E4DAFF',
    primaryLight: '#F3EFFF',
    secondaryDisabled: '#5f2eea50',
    secondary: '#FDC851',
    secondaryDark: '#DE9C02',
    secondaryDarkMode: '#FEE6AE',
    secondaryBg: '#FFF3D7',
    secondaryLight: '#FFFCF5',

    success: '#00BA88',
    successDark: '#00966D',
    successDarkMode: '#34EAB9',
    successBg: '#DFFFF6',
    successLight: '#F2FFFB',

    error: '#ED2E7E',
    errorDark: '#C30052',
    errorDarkMode: '#FF84B7',
    errorBg: '#FFDFED',
    errorLight: '#FFF3F8',

    warning: '#F4B740',
    warningDark: '#946200',
    warningDarkMode: '#FFD789',
    warningBg: '#FFF4DF',
    warningLight: '#FFF9EF',
  },
  gray: {
    body: '#4E4B66',
    titleActive: '#14142B',
    inputBackground: '#EFF0F6',
    offWhite: '#FCFCFC',
    placeholder: '#A0A3BD',
    background: '#F7F7FC',
    line: '#D9DBE9',
    label: '#6E7191',
  },
}

export { color, project, theme }
