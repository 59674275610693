import React from 'react'

import { FieldInput } from './index'

export function CCVInput(props) {
  return (
    <FieldInput
      label="CVV"
      name={props.name}
      value={props.value}
      isNumber
      max={3}
      onChange={props.onChange}
      disabled={props.disabled}
      errorText={props.error && 'CVV ไม่ถูกต้อง'}
    />
  )
}
