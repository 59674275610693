import React, { useState } from 'react'
import styled from 'styled-components'

import { Text } from 'components/typo'
import { Button } from 'components/button'
import { DisabledInput } from 'components/input'
import { helper } from 'utils'

import CancelDialog from '../connection/CancelDialog'

const Connected = (props) => {
  const [visible, setVisible] = useState(false)
  const { loading, mobile, onDisconnect } = props

  return (
    <Title>
      <Text size="large" bold>
        ผูกหมายเลขโทรศัพท์
      </Text>

      <Space />
      <DisabledInput
        label="หมายเลขโทรศัพท์"
        value={helper.formatPattern(mobile, 'xxx-xxx-xxxx')}
      />

      <Detail>
        <Button
          color="subtle"
          text="ยกเลิกการผูกหมายเลขโทรศัพท์"
          maxWidth="325px"
          loading={loading}
          onClick={() => setVisible(true)}
        />
      </Detail>
      <CancelDialog
        visible={visible}
        onConfirm={onDisconnect}
        onClose={() => setVisible(false)}
      />
    </Title>
  )
}

const Title = styled.div`
  margin: 0 0 36px;
  text-align: center;
`

const Space = styled.div`
  margin-top: 24px;
`

const Detail = styled.div`
  margin-top: 40px;
  margin-bottom: 29px;
`

export default Connected
