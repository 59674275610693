import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import qs from 'query-string'
import { inject, observer } from 'mobx-react'
import Joi from 'joi'

import { PasswordInput } from 'components/input'
import { Button } from 'components/button'
import Layout from 'pages/account/components/Layout'
import Loading from 'components/loading'
import { MetaData, HelperText } from 'components/display'
import useGTM from 'hooks/useGTM'

const passwordComplexity = [
  'รหัสผ่านต้องไม่มีช่องว่าง',
  'รหัสผ่านต้องมีตัวเลข (0-9) อย่างน้อย 1 ตัว',
  'รหัสผ่านต้องมีความยาวอยู่ระหว่าง 8-15 ตัวอักษร',
  'รหัสผ่านต้องประกอบด้วยตัวอักษรภาษาอังกฤษพิมพ์เล็ก (a-z) อย่างน้อย 1 ตัว',
  'รหัสผ่านต้องประกอบด้วยตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ (A-Z) อย่างน้อย 1 ตัว',
]

const passwordSchema = Joi.string()
  .trim()
  .messages({
    'string.empty': 'รหัสผ่านต้องมีความยาวอยู่ระหว่าง 8-15 ตัวอักษร',
  })
  .ruleset.min(8)
  .max(15)
  .rule({ message: 'รหัสผ่านต้องมีความยาวอยู่ระหว่าง 8-15 ตัวอักษร' })
  .ruleset.pattern(/^(\w+\S+)$/)
  .rule({
    message: 'รหัสผ่านต้องไม่มีช่องว่าง',
  })
  .ruleset.pattern(/[0-9]/)
  .rule({
    message: 'รหัสผ่านต้องมีตัวเลข (0-9) อย่างน้อย 1 ตัว',
  })
  .ruleset.pattern(/[a-z]/)
  .rule({
    message:
      'รหัสผ่านต้องประกอบด้วยตัวอักษรภาษาอังกฤษพิมพ์เล็ก (a-z) อย่างน้อย 1 ตัว',
  })
  .ruleset.pattern(/[A-Z]/)
  .rule({
    message:
      'รหัสผ่านต้องประกอบด้วยตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ (A-Z) อย่างน้อย 1 ตัว',
  })

const ResetPassword = (props) => {
  const params = useParams()
  const location = useLocation()
  const history = useHistory()
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [loading, setLoading] = useState({})
  const [error, setError] = useState({ validate: [] })
  const [isSend, setSend] = useState(false)
  const { onPageLoad, onEvent } = useGTM()

  const setUserPassword = async (e) => {
    e.preventDefault()
    try {
      setLoading({ password: true })
      const query = qs.parse(location.search)
      const { ref_code } = params
      const { code } = query

      await props.member.setNewPassword({
        code,
        ref_code,
        password,
      })
      setSend(true)
    } catch (e) {
      let message = e.message
      setError({ password: message })
    }
    setLoading({ password: false })
  }

  const onPasswordChange = (val) => {
    const { password } = validate({ password: val, repeatPassword })
    setPassword(password)
  }

  const onRepeatPasswordChange = (val) => {
    const { repeatPassword } = validate({
      password,
      repeatPassword: val,
    })
    setRepeatPassword(repeatPassword)
  }

  const validate = ({ password, repeatPassword }) => {
    const result = passwordSchema.validate(password, { abortEarly: false })

    let validate = []

    if (result.error) {
      validate = result.error.details.map((i) => i.message)
    }

    const trimRepeatPassword = repeatPassword.trim()
    if (password !== trimRepeatPassword) {
      validate.push('รหัสผ่านไม่ตรงกัน')
    }

    setError({ validate })

    return { password: result.value, repeatPassword: trimRepeatPassword }
  }

  const verifyEmail = useCallback(async () => {
    try {
      setLoading({ verify: true })
      const query = qs.parse(location.search)
      const { ref_code } = params
      const { code } = query

      await props.member.verifyRegisterCode({
        code,
        ref_code,
      })
    } catch (e) {
      let message = e.message
      if (message === 'ref_code not found') {
        message = 'ไม่พบเลขหมายอ้างอิงอยู่ในระบบ กรุณาสมัครบัญชีใหม่อีกครั้ง'
      }
      setError({ verify: message })
    }
    setLoading({ verify: false })
  }, [location.search, params, props.member])

  const goLogin = () => history.push('/login')

  const onSubmit = () => {
    onEvent({
      pageName: 'reset-password',
      eventName: 'reset-password-button-click',
    })
  }

  const onLogin = () => {
    onEvent({
      pageName: 'reset-password',
      eventName: 'reset-password-login-button-click',
    })
  }

  useEffect(() => {
    verifyEmail()
  }, [verifyEmail])

  useEffect(() => {
    onPageLoad({ pageName: 'reset-password' })
  }, [onPageLoad])

  if (loading.verify) {
    return (
      <Layout header="" subHeader="กำลังตรวจสอบ...">
        <Loading loading />
      </Layout>
    )
  }

  if (error.verify) {
    return (
      <Layout
        header=""
        subHeader="ตรวจสอบโค้ดผิดพลาด กรุณาสร้างบัญชีผู้ใช้ใหม่"
      >
        <HelperText errorText={error.verify} />
      </Layout>
    )
  }

  if (isSend) {
    return (
      <Layout header="รีเซ็ตรหัสผ่านสำเร็จ">
        <Form onSubmit={goLogin}>
          <Button
            type="submit"
            color="primary"
            text="เข้าสู่ระบบ"
            onClick={onLogin}
          />
        </Form>
      </Layout>
    )
  }

  const hasError = error.validate?.length > 0
  const isDisabledButton = hasError || password.length === 0
  return (
    <Layout header="รีเซ็ตรหัสผ่าน">
      <MetaData title={'รีเซ็ตรหัสผ่าน'} description={'รีเซ็ตรหัสผ่าน'} />
      <Form onSubmit={setUserPassword}>
        <PasswordInput
          value={password}
          onChange={onPasswordChange}
          toolTip={<PasswordHint list={passwordComplexity} />}
          errorText={hasError && ' '}
        />
        <PasswordInput
          label={'ยืนยันรหัสผ่าน'}
          value={repeatPassword}
          onChange={onRepeatPasswordChange}
          loading={loading.password}
          errorText={hasError && ' '}
        />
        <ButtonWrapper>
          <Button
            type="submit"
            loading={loading.password}
            disabled={isDisabledButton}
            color="primary"
            text="ถัดไป"
            onClick={onSubmit}
          />
        </ButtonWrapper>
        <HelperText errorText={PasswordHint({ list: error.validate })} />
        <HelperText errorText={error.password} />
      </Form>
    </Layout>
  )
}

const PasswordHint = (props) => {
  if (!props.list) return null

  return (
    <HintWrapper>
      {props.list.map((i) => (
        <li key={i}>{i}</li>
      ))}
    </HintWrapper>
  )
}

const HintWrapper = styled.ul`
  margin: 0;
  padding-left: 16px;

  & li {
    margin: 0;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 325px;
  padding: 32px 0;
  row-gap: 24px;

  @media screen and (max-width: 400px) {
    padding-right: 30px;
  }
`

const ButtonWrapper = styled.div`
  margin-top: 12px;
`

export default inject('member')(observer(ResetPassword))
