import { inject, observer } from 'mobx-react'
import moment from 'moment'
import 'moment/locale/th'
import { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Button } from 'components/button'
import { MetaData, UserImg } from 'components/display'
import { Text } from 'components/typo'
import { USER_AGENT_TYPE, USER_GENDER_TH } from 'definition/user'
import { format, helper } from 'utils'

import useMediaQuery from '../../../hooks/useMediaQuery'

const Profile = (props) => {
  const { init, onEdit } = props
  const history = useHistory()
  const isIpad = useMediaQuery('(max-width:890px)')

  const { user = {} } = props.member.toJS()
  const { agent } = props.account.toJS()

  const onDelete = () => history.push('/account/delete-account')

  const company = agent.company_name || ''
  const license_list = agent.license_list || []
  const infos = [
    { label: 'ชื่อ', value: user.name },
    { label: 'นามสกุล', value: user.surname },
    { label: 'เพศ', value: USER_GENDER_TH[user.gender] || '' },
    { label: 'วันเกิด', value: format.toShortDate(user.birthday_at) },
    {
      label: 'หมายเลขโทรศัพท์',
      value: helper.toMobile(user.mobile, 'xxx-xxx-xxxx'),
    },
    { label: 'อีเมล', value: user.email },
    { label: 'บริษัทที่สังกัด', value: company },
  ]

  license_list.forEach((it, i) => {
    const label = i === 0 ? 'หมายเลขใบอนุญาต' : ''
    const detail = (type) => {
      const foundAgentType = USER_AGENT_TYPE[type]
      if (foundAgentType) {
        return (
          <Fragment>
            {foundAgentType.EN}
            <br />
            {foundAgentType.TH}
          </Fragment>
        )
      }
      return '-'
    }

    const hint = () => {
      return (
        <div className="expired">
          <span className="iconExpired" />
          หมดอายุ {moment(it.expired_at).locale('TH-th').format('DD MMM YYYY')}
        </div>
      )
    }
    infos.push({
      label,
      value: `${it.license_type} ${it.license_no}`,
      detail: detail(it.license_type),
      hint: hint(),
    })
  })

  if (isIpad)
    return (
      <Wrapper>
        <MetaData title="โปรไฟล์ของฉัน" description="โปรไฟล์ของฉัน" />
        <ProfileWrapper>
          <Header>
            <Text size="large" bold>
              โปรไฟล์ของฉัน
            </Text>
          </Header>
          <UserImg size={125} src={user.img_url} gender={user.gender} />
          <Button
            loading={init}
            maxWidth="114px"
            color="subtle"
            small
            text="แก้ไข"
            onClick={onEdit}
          />
          <InfoBox>
            <InformationBox infos={infos} />
            <Btn onClick={onDelete} color="text">
              ลบบัญชีผู้ใช้งาน
            </Btn>
          </InfoBox>
        </ProfileWrapper>
      </Wrapper>
    )

  return (
    <Wrapper>
      <MetaData title="โปรไฟล์ของฉัน" description="โปรไฟล์ของฉัน" />
      <ProfileWrapper>
        <UserImg size={125} src={user.img_url} gender={user.gender} />
        <InfoBox>
          <Header>
            <Text size="large" bold>
              โปรไฟล์ของฉัน
            </Text>
          </Header>
          <InformationBox infos={infos} />
          <Btn onClick={onDelete} color="text">
            ลบบัญชีผู้ใช้งาน
          </Btn>
        </InfoBox>
        <Button
          loading={init}
          maxWidth="114px"
          color="subtle"
          small
          text="แก้ไข"
          onClick={onEdit}
        />
      </ProfileWrapper>
    </Wrapper>
  )
}

const InformationBox = (props) => (
  <Info>
    {props.infos.map((it, i) => {
      return (
        <Fragment key={i}>
          <Text placeholder>{it.label}</Text>
          <InfoValue>
            <Text>{it.value}</Text>
            <Text size="xsmall">{it.detail}</Text>
            <Text size="small" success>
              {it.hint}
            </Text>
          </InfoValue>
        </Fragment>
      )
    })}
  </Info>
)

const ProfileWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 550px 114px;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 1024px) {
    grid-template-columns: auto auto 114px;
  }

  @media screen and (max-width: 890px) {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    row-gap: 24px;
  }

  @media screen and (max-width: 850px) {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    row-gap: 24px;
  }
`

const Wrapper = styled.div`
  display: flex;
  max-width: 800px;
  width: 100%;
  justify-content: center;
  margin-top: 40px;

  @media screen and (max-width: 1024px) {
    margin: 0;
  }
`

const Info = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 8px;
  column-gap: 16px;
  .expired {
    display: flex;
    align-items: center;
  }
  .iconExpired {
    width: 16px;
    height: 16px;
    background-color: white;
    align-items: center;
    border: 4px solid #00ba88;
    border-radius: 50%;
    margin-right: 8px;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`

const InfoValue = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.div``

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  margin-right: 40px;
  row-gap: 28px;
  width: 100%;
  max-width: 400px;

  @media screen and (max-width: 1024px) {
    margin-top: 16px;
  }

  @media screen and (max-width: 768px) {
    margin: 0;
    max-width: 250px;
  }
`

const Btn = styled.div`
  text-align: left;
  font-size: 14px;
  color: #a0a3bd;
  cursor: pointer;
  margin-top: 40px;
`

export default inject('member', 'account')(observer(Profile))
