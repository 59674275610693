import React, { useState } from 'react'
import styled from 'styled-components'

import { Plus, Minus } from 'icon'
import { Text } from 'components/typo'

const ProblemItem = (props) => {
  const [open, setOpen] = useState(false)
  const { item } = props

  const onToggle = () => setOpen(!open)
  const icon = !open ? <Plus /> : <Minus />
  return (
    <Wrapper onClick={onToggle}>
      <Header>
        <Title>
          <Text title bold>
            {item.title}
          </Text>
          <Icon>{icon}</Icon>
        </Title>
      </Header>
      <ContentBox isOpen={open}>
        <Text gray>{item.detail}</Text>
      </ContentBox>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 20px 25px;
  width: 100%;
  border-radius: 16px;
  background-color: ${(p) => p.theme.gray.inputBackground};
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 30px;
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const ContentBox = styled.div`
  overflow: hidden;
  transition: all 0.2s;
  max-height: ${(p) => (p.isOpen ? '1000px' : 0)};
`

export default ProblemItem
