import { Col, Row } from 'antd'
import styled from 'styled-components'

import { Content } from 'components/display'

import Facebook from './Facebook'
import Line from './Line'

const Footer = () => {
  return (
    <>
      <DBDContainer id="app_dbd">
        <DBDContent>
          <DBDInnerContainer>
            <div id="Certificate-banners"></div>
            <span>ใบอนุญาตจากกรมพัฒนาธุรกิจการค้า เลขที่ 0105559148619</span>
          </DBDInnerContainer>
        </DBDContent>
      </DBDContainer>
      <PageView id="app_footer">
        <Content>
          <Row align="middle">
            <Col lg={16} md={18} xs={24}>
              <Title>สงวนลิขสิทธิ์ © 2564 บริษัท ทีคิวดี จำกัด</Title>
            </Col>
            <Col lg={8} md={6} xs={24}>
              <LogoLine>
                <a
                  href="https://bit.ly/3E5yacx"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Line />
                </a>
                <a
                  href="https://bit.ly/3p03WDN"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Facebook />
                </a>
              </LogoLine>
            </Col>
          </Row>
        </Content>
      </PageView>
    </>
  )
}

const PageView = styled.div`
  background-color: #5f2eea;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 1;

  @media screen and (max-width: 500px) {
    height: auto;
  }
`
const DBDContainer = styled(PageView)`
  background-color: #f7f7fc;

  #Certificate-banners {
    img {
      width: 100px;
      margin-right: 10px;
    }
  }

  span {
    font-size: 16px;
    color: #a0a3bd;
  }

  @media screen and (max-width: 990px) {
    #Certificate-banners {
      img {
        width: 80px;
      }
    }
    span {
      font-size: 14px;
    }
  }
`

const DBDContent = styled.div`
  width: 100%;
  min-height: 53px;
  padding: 10px 0;
`

const DBDInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const Title = styled.label`
  color: white;
`

const LogoLine = styled.div`
  float: right;

  svg {
    border-radius: 6px;
    margin-left: 16px;
  }

  @media screen and (max-width: 500px) {
    padding-top: 8px;
    float: left;

    svg {
      margin-left: 0px;
      margin-right: 16px;
    }
  }
`

export default Footer
