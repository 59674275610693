import React from 'react'
import { useHistory } from 'react-router-dom'

import Promotion from '../promotion'

const MyPaymentQR = (props) => {
  const history = useHistory()

  const onNext = () => {
    history.push('/account/my-payment/paynow/qrcode/info')
  }

  return <Promotion onNext={onNext} />
}

export default MyPaymentQR
