import React from 'react'

const TitleText = (props) => {
  const { text, marginTop, marginBottom } = props
  const css = {
    color: '#4E4B66',
    fontSize: '18px',
    lineHeight: '34px',
    marginTop,
    marginBottom,
  }
  return <div style={css}>{text}</div>
}

export default TitleText
