import React from 'react'
import styled from 'styled-components'

import Text from 'components/typo/Text'
import Display from 'components/typo/Display'

const Layout = (props) => {
  const { header, subHeader, children } = props

  return (
    <Wrapper>
      <StepInfo>
        <Text size="small">{subHeader}</Text>
      </StepInfo>

      <Header>
        <Display bold mobileSize="small">
          {header}
        </Display>
      </Header>

      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 32px 70px;

  @media screen and (max-width: 1024px) {
    padding: 32px 16px;
  }
`

const StepInfo = styled.div`
  margin-bottom: 40px;
`

const Header = styled.div`
  margin-bottom: 24px;
`

export default Layout
