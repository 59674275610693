import { runInAction } from 'mobx'

import { http, format } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state

export class PackageApp extends BaseStore {
  constructor() {
    super()
    this.observable({
      feature_list: [],
      package_list: [],
      headerConfig: [],
      rows: [],
    })

    state = this
  }

  async getPackageList(params = {}) {
    const doc = this.toJS()
    if (doc.feature_list.length > 0) return

    const url = `${config.api}/v1/public/package`
    const resp = await http.get(url)

    const { feature_list = [], package_list = [] } = resp.body

    const column = getColumn(package_list, params)
    const { data, list } = getList(feature_list)

    runInAction(() => {
      state.headerConfig = {
        column,
        list,
      }
      state.rows = data
    })
  }

  async setPackage(params = {}) {
    const { planPeriod = 'per_month', packageId } = params
    const url = `${config.api}/v1/user/package/select/${packageId}/${planPeriod}`
    await http.put(url)
  }

  async getSelectPackage() {
    const url = `${config.api}/v1/user/package`
    const res = await http.get(url)
    const body = res.body
    return body
  }
}

function getColumn(package_list, params) {
  const column = [{ key: 'label', align: 'left' }]
  const { planPeriod } = params
  for (let i = 0; i < package_list.length; i++) {
    const item = package_list[i]
    const cost = item[`cost_${planPeriod}`]

    let subText = `ตลอดชีพ`
    const per = planPeriod === 'per_year' ? 'ปี' : 'เดือน'
    const unit = (item.unit || '').replace('ปี', per)

    if (cost > 0) {
      subText = `฿ ${format.toDigi(cost)}`
    }

    column.push({
      id: item.package_app_id,
      key: item.package_type,
      text: item.name,
      subText,
      unit,
      cost,
      topButton: params.topButton,
      downButton: params.downButton,
    })
  }

  return column
}

function getList(feature_list) {
  const list = []
  const data = {}
  for (let i = 0; i < feature_list.length; i++) {
    const item = feature_list[i]
    const subList = []
    if (item.feature_list)
      for (let j = 0; j < item.feature_list.length; j++) {
        const nestItem = item.feature_list[j]

        if (!data[item.name]) data[item.name] = {}
        data[item.name][nestItem.name] = getObject(nestItem)

        subList.push({
          label: nestItem.name,
          key: nestItem.name,
        })
      }

    const newItem = { label: item.name, key: item.name }
    if (subList.length > 0) newItem.list = subList

    list.push(newItem)
  }

  return { list, data }
}

function getObject(nestItem) {
  if (nestItem.package) return { ...nestItem.package, label: nestItem.name }

  return nestItem.sub_list.map((item) => ({
    ...item.package,
    label: item.name,
  }))
}

export default new PackageApp()
