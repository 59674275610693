import React, { useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import Loading from 'components/loading'
import { message } from 'utils'
import AccountLayout from 'pages/account/components/AccountLayout'
import Promptpay from 'pages/account/components/Promptpay'

const MyPaymentPromptpay = (props) => {
  const { packageId, period } = useParams()
  const [init, setInit] = useState(false)
  const [payment, setPayment] = useState({})

  const onChecking = useCallback(
    async (invoice_no, time) => {
      let status
      try {
        const { payment } = await props.account.getPaymentResult(invoice_no)
        status = payment.status
        if (status !== 'pending') {
          props.history.push(
            `/account/my-payment/change-package/result/${invoice_no}`
          )
          return
        }
      } catch (e) {
        console.log('qr:', e.message)
      }
      setTimeout(() => onChecking(invoice_no), time || 8000)
    },
    [props.account, props.history]
  )

  const onInit = useCallback(async () => {
    try {
      setInit(true)

      const res = await props.payment.changePackage(packageId, period, {
        payment_type: 'qr_code',
      })

      setPayment(res.payment)

      onChecking(res.payment.invoice_no, 25000)
    } catch (e) {
      message.error({ message: e.message })
    }
    setInit(false)
  }, [props.payment])

  useEffect(() => {
    onInit()
  }, [])

  return (
    <AccountLayout
      backTo={`/account/my-payment/change-package/payment/${packageId}/${period}/info`}
      backText="ยอดชำระ"
    >
      <Loading loading={init}>
        <Text>สแกนคิวอาร์โค้ดเพื่อชำระเงิน</Text>

        <Promptpay code={payment.qr_code} />
      </Loading>
    </AccountLayout>
  )
}

const Text = styled.div`
  font-size: 18px;
  text-align: center;
`

export default inject('payment', 'account')(observer(MyPaymentPromptpay))
