import React from 'react'
import styled from 'styled-components'
import { Row } from 'antd'

const TableRow = (props) => {
  const { justify = 'center', align = 'middle', isLast, children } = props
  return (
    <PageView className={isLast ? 'last-row' : ''}>
      <Row justify={justify} align={align}>
        {children}
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  background-color: white;
  border: 1px solid #d9dbe9;
  border-top-width: 0;
  width: 100%;

  &.last-row {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

export default TableRow
