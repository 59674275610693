import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { UserImg } from 'components/display'
import { Link } from 'components/link'
import Text from 'components/typo/Text'
import useGTM from 'hooks/useGTM'

const login_menu = [
  { name: 'แพ็กเกจของเรา', link: '/package', eventName: 'package' },
  { name: 'ฟีเจอร์', link: '/features', eventName: 'features' },
  { name: 'LifeBridge', link: '/lifebridge', eventName: 'lifebridge' },
  { name: 'ติดต่อเรา', link: '/contact-us', eventName: 'contact-us' },
]

const menu_list = [
  { name: 'แพ็กเกจของเรา', link: '/package', eventName: 'package' },
  { name: 'ฟีเจอร์', link: '/features', eventName: 'features' },
  { name: 'LifeBridge', link: '/lifebridge', eventName: 'lifebridge' },
  { name: 'ติดต่อเรา', link: '/contact-us', eventName: 'contact-us' },
  { name: 'เข้าสู่ระบบ', link: '/login', eventName: 'login' },
  { name: 'สร้างบัญชี', link: '/register', eventName: 'register' },
]

const UserMenu = (props) => {
  const user = props.member.getCurrentUser()
  const { onEvent } = useGTM()

  const onMenuClick = (menu) => {
    onEvent({
      eventName: 'menu-click',
      params: {
        menu,
      },
    })
  }

  if (user) {
    return (
      <Wrapper>
        {login_menu.map((menu) => (
          <Link
            key={menu.name}
            to={menu.link}
            onClick={() => onMenuClick(menu.eventName)}
          >
            <Text size="small" gray>
              {menu.name}
            </Text>
          </Link>
        ))}
        <Link to={'/account/profile'}>
          <UserProfile>
            <UserImg src={user.img_url} gender={user.gender} />
          </UserProfile>
        </Link>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {menu_list.map((menu, idx) => (
        <Link
          key={menu.name}
          to={menu.link}
          onClick={() => onMenuClick(menu.eventName)}
        >
          <Text
            size="small"
            {...(idx === menu_list.length - 1
              ? { primary: true }
              : { gray: true })}
          >
            {menu.name}
          </Text>
        </Link>
      ))}
    </Wrapper>
  )
}

const UserProfile = styled.div`
  display: flex;
  align-items: center;

  column-gap: 18px;
`

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  grid-auto-flow: column;
  column-gap: 40px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export default inject('member')(observer(UserMenu))
