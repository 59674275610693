import React from 'react'

import Button from 'components/button/Button'
import { Google } from 'icon'

const GoogleBtn = (props) => {
  const {
    text = 'เข้าสู่ระบบผ่าน Gmail',
    disabled = false,
    loading = false,
    onClick,
  } = props
  return (
    <Button
      startIcon={<Google />}
      disabled={disabled}
      text={text}
      loading={loading}
      onClick={onClick}
    />
  )
}

export default GoogleBtn
