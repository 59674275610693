import React from 'react'
import styled from 'styled-components'

import Dialog from 'dialog'
import { Text } from 'components/typo'
import { Button } from 'components/button'

const CancelDialog = (props) => {
  const { visible, onClose, onConfirm } = props

  return (
    <Dialog width="779px" visible={visible} onClose={onClose}>
      <Content>
        <Title>ยกเลิกผูกบัญชี</Title>
        <div>
          <Text size="small">ข้อมูลของคุณจะถูกตัดการเชื่อมโยงกับบัญชีนี้</Text>
        </div>
        <div>
          <Text size="small">
            คุณจะไม่สามารถล็อกอินเข้าสู่ระบบผ่านบัญชีในแพลตฟอร์มนี้ได้
            ดำเนินการต่อหรือไม่?
          </Text>
        </div>
        <AreaBtn>
          <Button color="primary" text="กลับ" onClick={onClose} />
        </AreaBtn>
        <Confirm onClick={onConfirm}>ยืนยัน</Confirm>
      </Content>
    </Dialog>
  )
}

const Content = styled.div`
  text-align: center;
  background-color: white;
`

const Title = styled.div`
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: bold;
`

const AreaBtn = styled.div`
  margin: 0 auto;
  padding: 24px 0;
  width: 220px;
`

const Confirm = styled.span`
  margin: 0 auto;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`

export default CancelDialog
