import React, { useState } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import Menu from 'pages/account/components/Menu/Menu'
import BackBtn from 'pages/account/components/BackBtn'
import { Text } from 'components/typo'
import { Button } from 'components/button'
import { MetaData, UserImg } from 'components/display'
import { format, message, timer } from 'utils'

const DeletedAccount = (props) => {
  const [loading, setLoading] = useState(false)
  const { user = {} } = props.member.toJS()

  const onSend = async () => {
    try {
      setLoading(true)

      await props.member.restoreAccount()
    } catch (e) {
      message.error({
        message: e.message,
      })
    }
    setLoading(false)
  }

  const deleted_at = timer.get(user.deleted_at)
  const date = format.toThaiDateTime(deleted_at)
  const name = `${user.name} ${user.surname}`
  return (
    <Menu>
      <MetaData title="ลบบัญชีผู้ใช้งาน" description="ลบบัญชีผู้ใช้งาน" />
      <BackBtn to="/account/profile" text="โปรไฟล์ของฉัน" />
      <Content>
        <Title>
          <Text size="large" bold>
            ลบบัญชีผู้ใช้งาน
          </Text>
        </Title>

        <Warning>บัญชีผู้ใช้งานของคุณจะถูกลบภายในวันที่ {date}</Warning>
        <UserWrapper>
          <UserImg size={50} src={user.img_url} gender={user.gender} />
          <Text size="small">{name}</Text>
        </UserWrapper>

        <ContentView>
          <Button
            color="primary"
            large
            loading={loading}
            text="ยกเลิกการลบบัญชีผู้ใช้งาน"
            onClick={onSend}
          />
        </ContentView>
      </Content>
    </Menu>
  )
}

const UserWrapper = styled.div`
  margin-top: 19px;
  margin-bottom: 78px;
  img {
    margin-right: 18px;
  }
`

const Content = styled.div`
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  text-align: center;
`

const Title = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
`

const Warning = styled.div`
  margin: 16px 0;
  color: #ed2e7e;
  font-size: 18px;
`

const ContentView = styled.div`
  width: 100%;
  max-width: 325px;
  margin: 0 auto;
`

export default inject('member')(observer(DeletedAccount))
