import member from './Member'
import account from './Account'
import problem from './Problem'
import messager from './Messager'
import app_config from './AppConfig'
import package_app from './PackageApp'
import register from './Register'
import customer from './Customer'
import appointment from './Appointment'
import payment from './Payment'
import promotion from './Promotion'
import report from './Report'

const stores = {
  member,
  account,
  problem,
  messager,
  app_config,
  package_app,
  register,
  customer,
  appointment,
  payment,
  promotion,
  report,
}

export default stores
