import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.29995 18.1984C6.89995 18.5984 6.79995 19.1984 7.19995 19.5984C7.39995 19.7984 7.69995 19.8984 7.99995 19.8984C8.19995 19.8984 8.49995 19.7984 8.69995 19.6984L15 14.1984C15.7 13.5984 16 12.7984 16 11.8984C16 10.9984 15.6 10.1984 15 9.59839L8.69995 4.09839C8.29995 3.69839 7.69995 3.79839 7.29995 4.19839C6.89995 4.59839 6.99995 5.19839 7.39995 5.59839L13.7 11.0984C13.9 11.2984 14 11.5984 14 11.8984C14 12.1984 13.9 12.4984 13.7 12.6984L7.29995 18.1984Z" fill="#5F2EEA"/>
      </svg>
  )
}
// eslint-enable
export default Logo;
