import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { message } from 'utils'

import SendMail from './SendMail'
import VerifyMail from './VerifyMail'
import AccountLayout from '../components/AccountLayout'

const AddEmail = (props) => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [page, setPage] = useState('email')

  const onSendEmail = async (val) => {
    try {
      setLoading(true)
      await props.account.sendConfirmEmail(val, 'add')
      setEmail(val)
      setPage('verifying')
      setLoading(false)
    } catch (e) {
      message.error({ message: e.message })
      setLoading(false)
    }
  }

  const getPage = () => {
    switch (page) {
      case 'email':
        return <SendMail loading={loading} onSendEmail={onSendEmail} />
      case 'verifying':
        return (
          <VerifyMail
            loading={loading}
            email={email}
            onSendEmail={onSendEmail}
            onChangeEmail={() => setPage('email')}
          />
        )
      default:
    }
  }

  const content = getPage()
  return (
    <AccountLayout
      title="เพิ่มอีเมลเพื่อตั้งรหัสผ่าน"
      description="เพิ่มอีเมลเพื่อตั้งรหัสผ่าน"
      backTo="/account/privacy"
      backText="ความปลอดภัย"
    >
      <Content>{content}</Content>
    </AccountLayout>
  )
}

const Content = styled.div`
  width: 325px;
  margin: 0 auto;
`

export default inject('account')(observer(AddEmail))
