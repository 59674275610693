import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Text } from 'components/typo'
import { helper } from 'utils'
import { Button } from 'components/button'

import OtpInputReegiter from './OtpInputRegister'

const VerifyOtpRegister = (props) => {
  const { loading, mobile, onCheckCode, onResend, onBack } = props
  const [counter, setCounter] = useState(60)

  const onSend = () => {
    onResend(mobile)
    setCounter(60)
  }

  const text = helper.formatPattern(mobile, 'xxx-xxx-xxxx')

  useEffect(() => {
    const time = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => {
      clearInterval(time)
    }
  }, [counter])
  return (
    <Title>
      <Text size="large" bold>
        ผูกหมายเลขโทรศัพท์
      </Text>

      <OtpCode>ระบุรหัส OTP ที่ได้รับ</OtpCode>
      <Mobile>ส่งไปที่หมายเลข {text}</Mobile>

      <OtpInputReegiter onComplete={onCheckCode} />
      <ButtonBox>
        <Button
          size="small"
          fontSize="xsmall"
          loading={loading}
          color="primary"
          width="325px"
          onClick={onSend}
          text={
            counter === 0 ? 'ส่งรหัสอีกครั้ง' : `ส่งรหัสอีกครั้ง ${counter}`
          }
          disabled={counter > 0}
        />

        <Button size="small" fontSize="xsmall" color="text" onClick={onBack}>
          เปลี่ยนหมายเลขโทรศัพท์
        </Button>
      </ButtonBox>
    </Title>
  )
}

const Title = styled.div`
  margin: 0 0 36px;
  text-align: center;
`

const OtpCode = styled.div`
  margin-top: 24px;
  font-size: 24px;
  color: #4e4b66;
`

const Mobile = styled.div`
  margin-top: 8px;
  margin-bottom: 40px;
  font-size: 18px;
  color: #4e4b66;
`

const ButtonBox = styled.div`
  margin-top: 40px;
  align-items: center;
  display: flex;
  flex-direction: column;
`

export default VerifyOtpRegister
