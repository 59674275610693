import React from 'react'
import styled from 'styled-components'

import { Display, Text } from 'components/typo'
import { Button } from 'components/button'
import Modal from 'components/modal/Modal'

const RemoveLicenseModal = (props) => {
  const { licenseNo, licenseType, setOpen, isOpened, onOk } = props

  const onConfirm = async () => {
    await onOk()
    closeModal()
  }

  const closeModal = () => setOpen(false)

  return (
    <Modal isOpened={isOpened} setOpen={setOpen}>
      <ModalContent>
        <Display size="small" bold>
          ลบใบอนุญาต
        </Display>

        <Info>
          <Text gray small>
            กดยืนยันเพื่อลบ
          </Text>
          <Text gray small bold>
            ใบอนุญาต {licenseType} หมายเลข {licenseNo}
          </Text>
          <Text gray small>
            ออกจากหน้าโปรไฟล์ของคุณ
          </Text>
        </Info>

        <Button
          text="กลับ"
          color="primary"
          maxWidth="220px"
          onClick={closeModal}
        />
        <Button
          text="ยืนยัน"
          color="text"
          maxWidth="220px"
          onClick={onConfirm}
        />
      </ModalContent>
    </Modal>
  )
}
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 24px 0;
`

export default RemoveLicenseModal
