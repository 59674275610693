import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'

import { Text } from 'components/typo'
import { message, format } from 'utils'
import AccountLayout from 'pages/account/components/AccountLayout'
import Loading from 'components/loading'
import { Button } from 'components/button'

const MyPaymentCard = (props) => {
  const [loading, setLoading] = useState(false)
  const [isSubmit, setSubmit] = useState(false)
  const { payment_package } = props.payment.toJS()
  const { card } = props
  const history = useHistory()

  const onInit = useCallback(async () => {
    try {
      setLoading(true)

      await props.payment.getPaymentPackage()
    } catch (e) {
      message.error({ message: e.message })
    }
    setLoading(false)
  }, [props.payment])

  useEffect(() => {
    onInit()
  }, [onInit])

  const onPayment = async () => {
    try {
      setSubmit(true)
      card.payment_type = 'credit_card'
      const { payment } = await props.payment.makePaymentPackage(card)
      setSubmit(false)

      const { otp_url } = payment || {}
      if (otp_url) {
        window.location.href = otp_url
      } else {
        message.success({ message: 'ต่ออายุแพ็กเกจสำเร็จ' })
        history.push('/account/my-payment')
      }
    } catch (e) {
      message.error({ message: e.message })
      setSubmit(false)
    }
  }

  const infos = [
    {
      label: 'ยอดชำระตามรอบบิล',
      value: format.toDigi(payment_package.package_price),
    },
    {
      label: 'ส่วนลดจากโปรโมชั่น',
      value: format.toDigi(payment_package.used_credit),
      color: { success: true },
    },
    {
      label: 'ยอดที่ต้องชำระ',
      value: format.toDigi(payment_package.total_payment),
      color: { error: true },
    },
  ]

  return (
    <AccountLayout
      title="ชำระเงินทันที"
      backTo={`/account/my-payment/paynow`}
      backText="ชำระเงินทันที"
    >
      <Content>
        <Text size="large" bold>
          ยอดชำระ
        </Text>
        <Loading loading={loading}>
          <InfoList>
            {infos.map((info) => (
              <InfoListItem key={info.label}>
                <Text color="gray">{info.label}</Text>
                <Text {...info.color}>฿ {info.value}</Text>
              </InfoListItem>
            ))}
          </InfoList>

          <Button
            large
            color="primary"
            maxWidth="325px"
            text="ชำระเงิน"
            loading={isSubmit}
            onClick={onPayment}
          />
        </Loading>
      </Content>
    </AccountLayout>
  )
}

const Content = styled.div`
  padding-top: 20px;
  width: 325px;
  margin-bottom: 70px;
`

const InfoList = styled.div`
  width: 100%;
  max-width: 325px;
  margin: 39px 0px 26px 0px;
`

const InfoListItem = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
`

export default inject('payment')(observer(MyPaymentCard))
