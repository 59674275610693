import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'

import Layout from 'pages/account/components/Layout'
import Loading from 'components/loading'
import { Text } from 'components/typo'
import { Checkbox } from 'components/input'
import { HelperText, MetaData, HtmlContent } from 'components/display'
import { Button } from 'components/button'
import SuccessIcon from 'assets/success.svg'

const NewCustomer = (props) => {
  const { app_config = {}, match = {}, customer = {} } = props
  const { customer_id, code } = match.params
  const { loading, error } = customer.toJS().setting
  const { isAccepted } = customer
  const [isCheck, setIsCheck] = useState(true)
  const history = useHistory()

  const onProcess = useCallback(async () => {
    let message = ''
    try {
      await Promise.all([
        app_config.getPrivacyConsent(),
        app_config.getTermsConsent(),
      ])
    } catch (e) {
      message = e.message
      customer.setError(message)
    }
  }, [customer, app_config])

  const onSubmit = useCallback(
    async (id, c) => {
      let message = ''
      try {
        await customer.updateConsent({
          customer_id: id,
          code: c,
          privacy_id: app_config.privacy.consent_id,
          terms_id: app_config.terms.consent_id,
        })
      } catch (e) {
        message = e.message
        customer.setError(message)
      }
    },
    [customer, app_config]
  )

  const onBack = useCallback(() => {
    history.push('/')
  }, [history])

  useEffect(() => {
    onProcess()
  }, [onProcess])

  if (loading) {
    return (
      <Layout header="" subHeader="กำลังตรวจสอบ...">
        <Loading loading />
      </Layout>
    )
  }

  if (error !== '') {
    return (
      <Layout header="" subHeader="ตรวจสอบโค้ดผิดพลาด">
        <HelperText errorText={error} />
      </Layout>
    )
  }

  if (isAccepted) {
    return (
      <SuccessContainer>
        <Img src={SuccessIcon} alt="white check with green background" />
        <Text size="large" bold>
          สำเร็จ
        </Text>
        <br />
        <Text align="center">
          ยอมรับนโยบายความเป็นส่วนตัว
          <BrMobile />
          และคุ้มครองส่วนบุคคลแล้ว
        </Text>
        <br />
        <Button
          large
          text="กลับไปหน้าหลัก"
          width="300px"
          color="primary"
          onClick={onBack}
        />
      </SuccessContainer>
    )
  }

  return (
    <PageView>
      <MetaData
        title={'ยืนยันทึกข้อมูลลูกค้า'}
        description={'ยืนยันทึกข้อมูลลูกค้า'}
      />

      <Text size="large" bold>
        นโยบายความเป็นส่วนตัว
      </Text>
      <Consent>
        <HtmlContent content={props.app_config.privacy.content} />
      </Consent>
      <Checkbox
        size
        label="ยอมรับนโยบายความเป็นส่วนตัวและการคุ้มครองข้อมูลส่วนบุคคล"
        checked={isCheck}
        onCheck={() => setIsCheck(!isCheck)}
        disabled={loading}
      />
      <Container>
        <Button
          large
          text="ยอมรับนโยบายความเป็นส่วนตัว ฯ"
          width="300px"
          color="primary"
          disabled={!isCheck}
          onClick={() => onSubmit(customer_id, code)}
        />
      </Container>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  text-align: center;
  padding: 100px 0px;
  max-width: 786px;
  @media screen and (max-width: 500px) {
    padding: 30px 30px;
  }
`
const Consent = styled.div`
  padding-top: 24px;
  padding-bottom: 30px;
  max-height: 447px;
  margin-bottom: 24px;
  overflow-y: scroll;
  @media screen and (max-width: 500px) {
    margin-bottom: 30px;
  }
`
const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
`
const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 786px;
  padding: 100px 0px;
  text-align: center;
`
const Img = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 24px;
`
const BrMobile = styled.br`
  display: none;
  @media screen and (max-width: 500px) {
    display: block;
  }
`

export default inject('app_config', 'customer')(observer(NewCustomer))
