import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

export class Appointment extends BaseStore {
  constructor() {
    super()
    this.observable({
      customer_list: [],
    })
  }

  async confirmAppointment(params = {}) {
    const { appointment_id, code, status } = params

    const url = `${config.api}/v1/public/crm/appointment/${appointment_id}/${code}/${status}`
    await http.put(url, {})
  }
}

export default new Appointment()
