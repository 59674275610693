import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import Layout from 'pages/account/components/Layout'
import Loading from 'components/loading'
import { MetaData, HelperText } from 'components/display'

const ConfirmAppointment = (props) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({ validate: [] })

  const onProcess = useCallback(
    async ({ appointment_id, code, status }) => {
      try {
        setLoading(true)

        await props.appointment.confirmAppointment({
          appointment_id,
          code,
          status,
        })
      } catch (e) {
        const message = e.message
        setError({ verify: message })
      }
      setLoading(false)
    },
    [props.appointment]
  )

  useEffect(() => {
    onProcess(props.match.params)
  }, [onProcess, props.match.params])

  if (loading.verify) {
    return (
      <Layout header="" subHeader="กำลังตรวจสอบ...">
        <Loading loading />
      </Layout>
    )
  }

  if (error.verify) {
    return (
      <Layout header="" subHeader="ตรวจสอบโค้ดผิดพลาด">
        <HelperText errorText={error.verify} />
      </Layout>
    )
  }

  return (
    <PageView>
      <MetaData title={'ยืนยันนัดหมาย'} description={'ยืนยันนัดหมาย'} />
      ขอขอบคุณสำหรับความร่วมมือ
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  text-align: center;
  padding: 100px 20px;
`

export default inject('appointment')(observer(ConfirmAppointment))
