import { inject, observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Button, FacebookBtn, GoogleBtn } from 'components/button'
import { HelperText, MetaData } from 'components/display'
import { EmailInput } from 'components/input'
import { Link } from 'components/link'
import { Display, Text } from 'components/typo'
import useGTM from 'hooks/useGTM'

import { ReactComponent as RegisterImage } from './assets/register.svg'

const MainPage = (props) => {
  const [loading, setLoading] = useState({})
  const [error, setError] = useState({})
  const [email, setEmail] = useState('')
  const history = useHistory()
  const { onPageLoad, onEvent } = useGTM()

  useEffect(() => {
    onPageLoad({ pageName: 'register' })
  }, [onPageLoad])

  const getErrorMessage = (msg = '') => {
    if (msg.includes('email already register')) {
      return 'อีเมลนี้ถูกใช้แล้ว'
    } else if (msg.includes('user already register')) {
      return 'บัญชีนี้ถูกใช้แล้ว'
    }

    return msg
  }

  const onGoogle = async () => {
    onEvent({
      pageName: 'register',
      eventName: 'register-with-gmail',
    })
    try {
      setLoading({ google: true })
      await props.member.registerByGoogle()

      history.push('/account/select-package')
    } catch (e) {
      setError({ login: getErrorMessage(e.message) })
    }
    setLoading({})
  }

  const onFacebook = async () => {
    onEvent({
      pageName: 'register',
      eventName: 'register-with-facebook',
    })
    try {
      setLoading({ facebook: true })
      const result = await props.member.registerByFacebook()
      if (result.error) {
        let errorMsg = 'Something went wrong'
        if (result.error === 'auth/account-exists-with-different-credential') {
          const existing = result.data.join(' หรือ ')
          errorMsg = `Email ของคุณถูกใช้งานแล้ว กรุณาเข้าสู่ระบบด้วย ${existing}`
        }

        setError({ login: errorMsg })
      } else {
        history.push('/account/select-package')
      }
    } catch (e) {
      setError({ login: getErrorMessage(e.message) })
    }
    setLoading({})
  }

  const onEmail = async (e) => {
    e.preventDefault()
    try {
      setLoading({ email: true })
      const result = await props.member.registerByEmail({ email })
      const ref_code = result.ref_code
      history.push(`/resend-email/${ref_code}`, { email, ref_code })
    } catch (e) {
      setError({ email: getErrorMessage(e.message) })
    }
    setLoading({})
  }

  const onSubmit = () => {
    onEvent({
      pageName: 'register',
      eventName: 'register-email-next-button-click',
    })
  }

  const onLogin = () => {
    onEvent({
      pageName: 'register',
      eventName: 'register-login-link-click',
    })
  }

  const onTermAndPolicyClick = () => {
    onEvent({
      pageName: 'register',
      eventName: 'register-term-and-policy-click',
    })
  }

  const onEmailChange = (val) => {
    const result = props.member.validateEmail(val)

    let msgError = ''
    if (result.error) {
      msgError = 'รูปแบบอีเมลไม่ถูกต้อง'
    }

    setError({ email: msgError })
    setEmail(result.value)
  }

  const onClear = () => {
    setError({})
  }

  const isLoading = Object.keys(loading).length > 0
  const isEmailError = error.email
  const isDisabled = isLoading || isEmailError || !email
  const isDisabledFacebook = !loading.facebook && isLoading
  const isDisabledGoogle = !loading.google && isLoading

  return (
    <PageView>
      <MetaData title="สร้างบัญชี" description="สร้างบัญชีใหม่" />
      <ImageContainer>
        <RegisterImageWrapper />
      </ImageContainer>
      <Login>
        <LoginForm noValidate onSubmit={onEmail}>
          <HeaderBox>
            <Display bold mobileSize="small">
              สร้างบัญชี
            </Display>
          </HeaderBox>

          <EmailInput
            autoFocus
            id="email"
            value={email}
            onClear={onClear}
            onChange={onEmailChange}
            errorText={isEmailError}
          />

          <Button
            large
            type="submit"
            onClick={onSubmit}
            disabled={isDisabled}
            color="primary"
            loading={loading.email}
            text="ถัดไป"
          />
        </LoginForm>
        <HelperBox>
          <Text size="small" gray>
            มีบัญชีผู้ใช้งานแล้ว?
          </Text>
          <Link to="/login" onClick={onLogin}>
            <Text size="small" bold primary>
              เข้าสู่ระบบ
            </Text>
          </Link>
        </HelperBox>

        <Separator>
          <TextMark>
            <Text>หรือ</Text>
          </TextMark>
        </Separator>

        <LoginOption>
          <GoogleBtn
            disabled={isDisabledGoogle}
            loading={loading.google}
            text={<b>สร้างผ่าน Gmail</b>}
            onClick={onGoogle}
          />
          <FacebookBtn
            disabled={isDisabledFacebook}
            loading={loading.facebook}
            text={<b>สร้างผ่าน Facebook</b>}
            onClick={onFacebook}
          />
          <HelperText errorText={error.login} />
        </LoginOption>

        <InfoText>
          <Text size="small" gray>
            การสร้างบัญชีผู้ใช้หมายความว่าคุณได้อ่านและยอมรับ&nbsp;
            <CustomLink to="/term" onClick={onTermAndPolicyClick}>
              <Text size="small" bold primary>
                เงื่อนไขการใช้บริการ
              </Text>
            </CustomLink>
            &nbsp;และ&nbsp;
            <CustomLink to="/policy" onClick={onTermAndPolicyClick}>
              <Text size="small" bold primary>
                นโยบายความเป็นส่วนตัว
              </Text>
            </CustomLink>
            &nbsp;ของเรา
          </Text>
        </InfoText>
      </Login>
    </PageView>
  )
}

const PageView = styled.div`
  display: grid;
  grid-template-columns: 407px 455px;

  width: 100%;
  max-width: 862px;
  height: 100%;
  margin: 60px auto 0;
  border: 1px solid #d9dbe9;
  border-radius: 24px;
  overflow: hidden;

  @media screen and (max-width: 990px) {
    justify-items: center;
    margin: 40px auto 0;
    grid-template-columns: 1fr;
    border: none;
  }
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 24px;
  background: linear-gradient(170.55deg, #9c7aff -5.34%, #2a00a2 85.23%);

  @media screen and (max-width: 990px) {
    display: none;
  }
`

const RegisterImageWrapper = styled(RegisterImage)`
  display: flex;
  max-width: 100%;
`

const CustomLink = styled(Link)`
  margin: 0 8px;
`

const InfoText = styled.div`
  column-gap: 10px;
`

const Separator = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 0;
    border-top: 1px solid ${(props) => props.theme.gray.line};
    width: 100%;
    transform: translateY(-50%);
  }
`

const TextMark = styled.mark`
  background-color: white;
  padding: 0 20px;
`

const HeaderBox = styled.div`
  margin-bottom: 10px;

  @media screen and (max-width: 990px) {
    margin-bottom: 26px;
  }
`

const Login = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 32px;

  width: 100%;
  max-width: 455px;
  padding: 40px;

  @media screen and (max-width: 990px) {
    padding: 0 16px;
  }
`

const LoginForm = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;

  > div.input-container {
    > div {
      gap: 16px;
    }
  }
`

const HelperBox = styled.div`
  display: flex;
  column-gap: 8px;
`

const LoginOption = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  row-gap: 24px;

  color: ${(props) => props.theme.gray.body} !important;
`

export default inject('member')(observer(MainPage))
