import request from 'request'

let member
const isRespError = (resp, params = {}) => {
  let { message } = params
  if (resp.statusCode === 200) {
    return false
  } else if (resp.statusCode === 401) {
    resp.body = {}
    message = 'ไม่มีอีเมลนี้อยู่ในระบบ'
    console.log('member:', member)
    if (member) member.reset()
  } else if (resp.statusCode === 404) {
    resp.body = {}
    message = 'ระบบไม่พร้อมใช้งาน'
  }

  const msg =
    message ||
    resp?.body?.message ||
    'มีบางอย่างผิดปกติ กรุณาลองใหม่อีกครั้งภายหลัง'

  throw new Error(msg)
}

export class Http {
  constructor() {
    this.httpReq = request
    this.token = undefined
  }

  setToken(auth) {
    this.token = auth
  }

  getToken() {
    return this.token
  }

  setMember(val) {
    member = val
  }

  process(options) {
    return new Promise((resolve) => {
      request(options, async (error, response, body) => {
        if (error) {
          let res = {
            statusCode: 400,
            body: error,
          }
          resolve(res)
        } else {
          let data = { message: body }
          try {
            data = JSON.parse(body)
          } catch (e) {
            console.log('http:', e.message)
          }
          let res = {
            statusCode: response.statusCode,
            body: data,
          }
          resolve(res)
        }
      })
    })
  }

  async setOption(setting, options = {}) {
    const { token = true } = options
    if (token && this.token) {
      setting.headers['authorization'] = `Bearer ${this.token}`
    }

    return setting
  }

  async get(url, options = {}) {
    let setting = {
      method: 'GET',
      url,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    setting = await this.setOption(setting, options)
    const resp = await this.process(setting)

    const { check = true } = options
    if (check) isRespError(resp)

    return resp
  }

  async post(url, options = {}) {
    let setting = {
      method: 'POST',
      url,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options.json),
    }

    setting = await this.setOption(setting, options)
    const resp = await this.process(setting)

    const { check = true } = options
    if (check) isRespError(resp)

    return resp
  }

  async put(url, options = {}) {
    let setting = {
      method: 'PUT',
      url,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options.json),
    }

    setting = await this.setOption(setting, options)
    const resp = await this.process(setting)

    const { check = true } = options
    if (check) isRespError(resp)

    return resp
  }
}

export const http = new Http()
export default http
