import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { size = 1 } = props;

  const width = `${Math.ceil(44 * size)}`;
  const height = `${Math.ceil(44 * size)}`;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="22"
        cy="22"
        r="21"
        fill="#5F2EEA"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M22 19C19.2 19 17 21.2 17 24C17 26.8 19.2 29 22 29C24.8 29 27 26.8 27 24C27 21.2 24.8 19 22 19ZM22 27C20.3 27 19 25.7 19 24C19 22.3 20.3 21 22 21C23.7 21 25 22.3 25 24C25 25.7 23.7 27 22 27Z"
        fill="white"
      />
      <path
        d="M31 16H27.7L27.4 15.1C27 13.8 25.9 13 24.6 13H19.5C18.2 13 17.1 13.8 16.7 15.1L16.3 16H13C11.3 16 10 17.3 10 19V29C10 30.7 11.3 32 13 32H31C32.7 32 34 30.7 34 29V19C34 17.3 32.7 16 31 16ZM18.5 15.7C18.6 15.3 19 15 19.4 15H24.5C24.9 15 25.3 15.3 25.4 15.7L25.5 16H18.3L18.5 15.7ZM32 29C32 29.6 31.6 30 31 30H13C12.4 30 12 29.6 12 29V19C12 18.4 12.4 18 13 18H17H27H31C31.6 18 32 18.4 32 19V29Z"
        fill="white"
      />
    </svg>
  );
};
// eslint-enable
export default Logo;
