import Joi from 'joi'

const schema = Joi.object({
  tax_type: Joi.string()
    .trim()
    .required()
    .messages({
      'any.required': 'กรุณาเลือกประเภท',
      'string.empty': 'กรุณาเลือกประเภท',
    })
    .label('ประเภท'),
  tax_no: Joi.when('tax_type', {
    is: 'juristic',
    then: Joi.string().trim().required().messages({
      'any.required': 'กรุณากรอกหมายเลขนิติบุคคล',
      'string.empty': 'กรุณากรอกหมายเลขนิติบุคคล',
    }),
    otherwise: Joi.string().trim().required().messages({
      'any.required': 'กรุณากรอกหมายเลขบัตรประชาชน',
      'string.empty': 'กรุณากรอกหมายเลขบัตรประชาชน',
    }),
  }),
  phone_number: Joi.string()
    .trim()
    .ruleset.pattern(/^[0-9]*$/)
    .length(10)
    .message('หมายเลขของคุณไม่ถูกต้อง')
    .required()
    .messages({
      'any.required': 'กรุณากรอกหมายเลขโทรศัพท์',
      'string.empty': 'กรุณากรอกหมายเลขโทรศัพท์',
    })
    .label('หมายเลขโทรศัพท์'),
  email: Joi.string()
    .trim()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'any.required': 'กรุณากรอกอีเมล',
      'string.empty': 'กรุณากรอกอีเมล',
      'string.email': 'รูปแบบอีเมลของคุณไม่ถูกต้อง',
    })
    .label('อีเมล'),
  first_name: Joi.when('tax_type', {
    is: 'individuals',
    then: Joi.string().trim().required().messages({
      'any.required': 'กรุณากรอกชื่อ',
      'string.empty': 'กรุณากรอกชื่อ',
    }),
  }),
  last_name: Joi.when('tax_type', {
    is: 'individuals',
    then: Joi.string().trim().required().messages({
      'any.required': 'กรุณากรอกนามสกุล',
      'string.empty': 'กรุณากรอกนามสกุล',
    }),
  }),
  company_name: Joi.when('tax_type', {
    is: 'juristic',
    then: Joi.string().trim().required().messages({
      'any.required': 'กรุณากรอกชื่อบริษัท',
      'string.empty': 'กรุณากรอกชื่อบริษัท',
    }),
  }),
  house_no: Joi.string()
    .trim()
    .required()
    .messages({
      'any.required': 'กรุณากรอกบ้านเลขที่',
      'string.empty': 'กรุณากรอกบ้านเลขที่',
    })
    .label('ประเภท'),
  sub_district: Joi.string()
    .trim()
    .required()
    .messages({
      'any.required': 'กรุณากรอกตำบล/แขวง',
      'string.empty': 'กรุณากรอกตำบล/แขวง',
    })
    .label('ประเภท'),
  district: Joi.string()
    .trim()
    .required()
    .messages({
      'any.required': 'กรุณากรอกอำเภอ/เขต',
      'string.empty': 'กรุณากรอกอำเภอ/เขต',
    })
    .label('ประเภท'),
  province: Joi.string()
    .trim()
    .required()
    .messages({
      'any.required': 'กรุณากรอกจังหวัด',
      'string.empty': 'กรุณากรอกจังหวัด',
    })
    .label('ประเภท'),
  postcode: Joi.string()
    .trim()
    .required()
    .messages({
      'any.required': 'กรุณากรอกรหัสไปรษณีย์',
      'string.empty': 'กรุณากรอกรหัสไปรษณีย์',
    })
    .label('ประเภท'),
}).options({ allowUnknown: true })

export default schema
