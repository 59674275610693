import React, { useState } from 'react'
import styled from 'styled-components'

import Arrow from 'icon/Arrow'

import CollapseList from './CollapseList'

const Select = (props) => {
  const { value, options, onChange } = props
  const [open, setOpen] = useState(false)

  return (
    <CollapseList
      open={open}
      setOpen={setOpen}
      options={options}
      value={value}
      onChange={onChange}
    >
      {(state) => {
        return (
          <ButtonCustom>
            {value?.label} <Arrow isUp={state.isOpen} />
          </ButtonCustom>
        )
      }}
    </CollapseList>
  )
}

const ButtonCustom = styled.div`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;

  column-gap: 8px;
`

export default Select
