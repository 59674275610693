import React from 'react'
import styled from 'styled-components'

import { Express, MasterCard, NextArrow, Visa } from 'icon'
import { Text } from 'components/typo'

import { Button } from './index'

function CreditButton(props) {
  return (
    <Button
      color="subtle"
      maxWidth="387px"
      onClick={props.onClick}
      text={
        <InnerButton>
          <Text size="small" bold primary>
            บัตรเครดิต หรือบัตรเดบิต
          </Text>
          <RightBox>
            <IconBox>
              <Visa width={27} height={16} />
              <MasterCard width={27} height={16} />
              <Express width={27} height={16} />
            </IconBox>
            <NextArrow />
          </RightBox>
        </InnerButton>
      }
    />
  )
}

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  column-gap: 1px;
`

const RightBox = styled.div`
  display: flex;
  column-gap: 12px;
`

const InnerButton = styled.div`
  display: flex;
  justify-content: space-between;
`

export default CreditButton
