import React from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'

import { Text } from 'components/typo'
import AccountLayout from 'pages/account/components/AccountLayout'
import PaymentMethod from 'pages/account/myPayment/changePackage/method'

const PaymentInfo = (props) => {
  const history = useHistory()
  const { user } = props.member.toJS()

  const onCreditCard = () => history.push('/account/my-payment/paynow/card')
  const onQRCode = () => history.push('/account/my-payment/paynow/qrcode')

  if (user.package_type === 'trial') {
    return (
      <AccountLayout
        title="ชำระเงินทันที"
        description="Payment (ชำระเงินทันที)"
        back={`/account/my-payment`}
        backText="ข้อมูลการชำระเงิน"
      >
        <ContentView>
          <Text size="large" bold>
            ไม่สามารถต่ออายุแพ็กเกจการของท่านได้เนื่องจากท่านใช้แพ็กเกจการฟรี
          </Text>
        </ContentView>
      </AccountLayout>
    )
  }

  return (
    <PaymentMethod
      title="Payment (ชำระเงินทันที)"
      description="Payment (ชำระเงินทันที)"
      back={`/account/my-payment`}
      backText="ข้อมูลการชำระเงิน"
      onCreditCard={onCreditCard}
      onQRCode={onQRCode}
    />
  )
}

const ContentView = styled.div`
  width: 100%;
  max-width: 325px;
  margin: 39px 0px 26px 0px;
`

export default inject('member')(observer(PaymentInfo))
