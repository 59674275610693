import styled from 'styled-components'

import { ReactComponent as Jumbotron } from './assets/banner.svg'
import { ReactComponent as Checked } from './assets/checked.svg'
import { ReactComponent as Document } from './assets/document.svg'
import { ReactComponent as logo } from './assets/Lifebridge.svg'
import RegisterSlider from './components/RegisterSlider'

function LifeBridge() {
  return (
    <Container>
      <Header>
        <HeaderLogo />
        <HeaderText>About Lifebridge</HeaderText>
        <HeaderJumbotron />
        <HeaderDescription>
          แพลตฟอร์มเสนอขายประกันออนไลน์ที่ครบวงจร
          และครอบคลุมที่สุดไม่ว่าจะเป็นประกันรถยนต์ ประกันอุบัติเหตุ
          ประกันอัคคีภัย และประกันสุขภาพ OTP/On top
          เรามีพันธมิตรบริษัทชั้นนำกว่า 40 แห่ง
          ช่วยให้ตัวแทนประกันสร้างโอกาสในการขาย
          และความประทับใจให้กับลูกค้าได้อย่างไม่รู้จบ บนแพลตฟอร์ม application
          ที่ใช้งานง่าย เข้าใจทุก need และ painpoint ที่ตัวแทนประกันมี
        </HeaderDescription>
      </Header>
      <GoodContainer>
        <GoodHeader>เป็นชาว LifeBridge ดีอย่างไร</GoodHeader>
        <GoodWrapper>
          <Checked />
          <p>
            สิทธิพิเศษใช้งานแอป Friday <b>ฟรี !!</b> ตลอดอายุ สมาชิก{' '}
            <b>มูลค่าปีละ 4,999 บาท !!</b>{' '}
            บูสต์งานขายประกันให้พุ่งกว่าที่เคยด้วย แอปพลิเคชัน Friday
            เลขาดิจิทัลของตัวแทน ประกันมืออาชีพ{' '}
          </p>
          <Checked />
          <p>ผลตอบแทนคุ้มค่า ไม่มีเพดาน</p>
          <Checked />
          <p>
            มีเจ้าหน้าที่ผู้เชี่ยวชาญคอยให้คำแนะนำ Support ทุกเรื่องที่ตัวแทน
            ประกันต้องการอย่างรวดเร็ว
          </p>
          <Checked />
          <p>มีจัดอบรมสอบใบนายหน้าให้</p>
        </GoodWrapper>
      </GoodContainer>
      <HowContainer>
        <HowHeader>อยากเป็นชาว LifeBridege ต้องทำอย่างไร</HowHeader>
        <HowText>
          <li>ต้องมีใบอนุญาตนายหน้าประกันวินาศภัย หรือประกันชีวิต</li>
          <li>กดสมัครผ่านแอปพลิเคชัน Friday</li>
        </HowText>
      </HowContainer>
      <RegisterContainer>
        <RegisterHeader>วิธีการสมัคร</RegisterHeader>
        <RegisterSlider />
        <RegisterWrapper>
          <p>
            <Document /> <b>เอกสารที่ต้องเตรียม</b>
          </p>
          <RegisterText>
            <li>สัญญา (ดาวน์โหลดได้บนแอปพลิเคชัน)</li>
            <li>สำเนาบัตรประชาชน</li>
            <li>รายงานการตรวจประวัติการอาชญากรรม</li>
          </RegisterText>
        </RegisterWrapper>
      </RegisterContainer>
    </Container>
  )
}

export default LifeBridge

const Container = styled.div`
  width: 100%;
`

const Header = styled.div`
  padding: 40px 16px;
  margin-bottom: 40px;
  background: #e7f0fd;
`

const HeaderLogo = styled(logo)`
  display: block;
  width: 100%;
  max-width: 240px;
  margin: 0 auto 48px;
`

const HeaderText = styled.h1`
  width: 100%;
  max-width: 820px;
  margin: 0 auto 48px;
  font-size: 24px;
  font-weight: bold;
  line-height: 38px;
  text-align: center;
  color: #0f6de7;
`

const HeaderJumbotron = styled(Jumbotron)`
  display: block;
  max-width: 300px;
  margin: 0 auto 48px;
`

const HeaderDescription = styled.p`
  width: 100%;
  max-width: 820px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #4e4b66;
`

const GoodContainer = styled.div`
  padding: 0 16px;
  margin-bottom: 24px;
`

const GoodHeader = styled.h2`
  width: 100%;
  max-width: 820px;
  margin: 0 auto 16px;
  font-size: 24px;
  font-weight: bold;
  line-height: 38px;
  text-align: center;
  color: #0ec0a1;
`

const GoodWrapper = styled.div`
  display: grid;
  grid-template-columns: 24px auto;
  row-gap: 16px;
  column-gap: 8px;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;

  p {
    font-size: 16px;
    line-height: 28px;
    color: #4e4b66;
  }
`

const HowContainer = styled.div`
  width: 100%;
  max-width: 480px;
  padding: 16px;
  margin: 0 auto 56px;
  background: #e5fffa;
  border-radius: 16px;
`

const HowHeader = styled.h2`
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  color: #0ec0a1;
`

const HowText = styled.ol`
  font-size: 16px;
  line-height: 28px;
  color: #4e4b66;
  padding-inline-start: 20px;

  > li:not(:last-of-type) {
    margin-bottom: 8px;
  }
`

const RegisterContainer = styled.div`
  width: 100%;
  max-width: 792px;
  padding: 0 16px;
  margin: 0 auto;
`

const RegisterHeader = styled.h2`
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 600;
  line-height: 38px;
  text-align: center;
  color: #4e4b66;
`

const RegisterWrapper = styled.div`
  width: 100%;
  max-width: 343px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 24px;
  color: #a0a3bd;

  > p {
    margin-bottom: 8px;
  }
`

const RegisterText = styled.ol`
  padding-inline-start: 20px;

  > li:not(:last-of-type) {
    margin-bottom: 8px;
  }
`
