import React, { useState } from 'react'
import styled from 'styled-components'
import Joi from 'joi'

import { Text } from 'components/typo'
import { Button } from 'components/button'
import { FieldInput } from 'components/input'
import { validator } from 'utils'

const SendMail = (props) => {
  const [email, setEmail] = useState('')
  const [validate, setValidate] = useState({})

  const { loading, onSendEmail } = props

  const onChange = (val) => setEmail(val)

  const onSend = () => {
    const res = validator.process(rules, { email })
    if (res.isValid) {
      setValidate({})
      onSendEmail(email)
    } else {
      setValidate(res.errors)
    }
  }

  return (
    <Content>
      <Title>
        <Text size="large" bold>
          เพิ่มอีเมลเพื่อตั้งรหัสผ่าน
        </Text>
      </Title>

      <Space />
      <FieldInput
        small
        label="อีเมล"
        value={email}
        onChange={onChange}
        errorText={validate.email}
        disabled={loading}
      />

      <Detail>
        <Button
          color="primary"
          text="ยืนยันอีเมล"
          maxWidth="325px"
          loading={loading}
          onClick={onSend}
        />
      </Detail>
    </Content>
  )
}

const rules = Joi.object({
  email: Joi.string()
    .trim()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'any.required': 'กรุณากรอกอีเมล',
      'string.empty': 'กรุณากรอกอีเมล',
      'string.email': 'รูปแบบอีเมลของคุณไม่ถูกต้อง',
    })
    .label('อีเมล'),
}).options({ allowUnknown: true })

const Content = styled.div``

const Title = styled.div`
  margin: 0 0 36px;
  text-align: center;
`

const Space = styled.div`
  margin-top: 24px;
`

const Detail = styled.div`
  margin-top: 40px;
`

export default SendMail
