import React from 'react'
import styled from 'styled-components'

import Logo from 'icon/Logo'
import Text from 'components/typo/Text'
import Display from 'components/typo/Display'
import BaseBtn from 'components/button/BaseBtn'

import Badge from './asset/badge.png'
import CRM_ICON from './asset/crm_icon.svg'
import PLANNING_ICON from './asset/planning_icon.svg'
import TEAM_ICON from './asset/team_icon.svg'
import KNOWLEDGE_ICON from './asset/knowledge_icon.svg'
import banner from './asset/banner_promotion.svg'
import subscribe1 from './asset/Subscribe1.png'
import subscribe2 from './asset/Subscribe2.png'

const APP_IMAGE =
  'https://prod-public-friday.s3.ap-southeast-1.amazonaws.com/web/iPad+191021.png'

function Info() {
  return (
    <Container>
      <Banner src={banner} />
      <PlanInfo>
        <ContainerPlan>
          <Plan>
            <img src={subscribe1} alt="standard package detail" />
            <img src={subscribe2} alt="advance package detail" />
          </Plan>
          <Btn
            color={{
              bg: '#ff5d81',
              font: '#f7f7fc',
              fontHover: '#f7f7fc',
            }}
            maxWidth="100%"
            onClick={() => {
              if (typeof window !== 'undefined')
                window.location.href = 'https://bit.ly/3TeNDPH'
            }}
          >
            ลงทะเบียน
          </Btn>
        </ContainerPlan>
        <ConditionContainer>
          <Condition>
            <Text primary bold>
              เงื่อนไขสำหรับโปรโมชั่น
            </Text>
            <ul>
              <li>ดาวน์โหลด และลงทะเบียนใช้งานแอปพลิเคชัน friday</li>
              <li>
                เลือกสมัครและชำระเงินแพ็กเกจ &quot;เริ่มต้น&quot; แบบรายปี
              </li>
              <li>
                ผู้ใช้งานสามารถใช้แพ็กเกจ &quot;ขั้นสูง**&quot;
                ได้โดยที่ไม่มีค่าใช้จ่ายเพิ่มเติม เป็นเวลา 3 ปี
                นับตั้งแต่วันเริ่มใช้งานแพ็กเกจ &quot;เริ่มต้น&quot; แบบรายปี
                จำกัด 200 สิทธิ์/แคมเปญ
              </li>
              <li>ระยะเวลาเข้าร่วมโปรโมชั่น 14 ต.ค. - 31 พ.ค. 66</li>
            </ul>
            <Text size="small" placeholder>
              หมายเหตุ **แพ็กเกจขั้นสูงพร้อมใช้งานต้นปี 2023 ในราคา 7,999 บาท/ปี
            </Text>
          </Condition>
          <Condition>
            <Text primary bold>
              เงื่อนไขทั่วไป
            </Text>
            <ul>
              <li>
                บริษัทฯสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไขหรือยกเลิกสิทธิพิเศษได้
                โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
              </li>
              <li>
                หากมีกรณีพิพาทเกี่ยวกับสิทธิพิเศษ คำตัดสินของบริษัทฯ
                ให้ถือเป็นที่สุด
              </li>
              <li>
                บริษัทไม่มีส่วนเกี่ยวข้องกับสินค้าหรือบริการที่ให้บริการร่วมกับคูปองส่วนลด
                กรณีพบปัญหากรุณาติดต่อผู้ให้บริการนั้นๆ โดยตรง
              </li>
              <li>
                สอบถามข้อมูลเพิ่มเติมที่ศูนย์บริการลูกค้า Friday โทร
                063-065-6919 ได้ทุกวันทำการเวลา 10.00 น. - 17.00 น.
              </li>
            </ul>
          </Condition>
        </ConditionContainer>
      </PlanInfo>
      <TopicContainer>
        <HeadText>
          <Logo size="2" />
          <Display size="small" bold secondary>
            แอปผู้ช่วยมือหนึ่งของ <br />
            ตัวแทนประกันยุคใหม่
          </Display>
        </HeadText>
        <GroupImage>
          <Image src={APP_IMAGE} alt="app image" />
          <BadgeImage src={Badge} alt="app badge image" />
        </GroupImage>
      </TopicContainer>
      <InfoContainer>
        <InfoWrapper>
          <Icon src={CRM_ICON} alt="crm icon" />
          <div>
            <Text align="center" size="large" primary title bold>
              {`Basic & Advance CRM`}
            </Text>
            <br />
            <Text align="center" size="small" gray>
              รวมศูนย์ข้อมูลลูกค้าไว้ในที่เดียวจัดการนัดหมาย และงานที่ต้องทำ
              ติดตามโอกาสในการขายอย่าง ใกล้ชิด สร้างมาตรฐานในการทำงาน
              แบบมืออาชีพ
            </Text>
          </div>
        </InfoWrapper>
        <InfoWrapper>
          <Icon src={PLANNING_ICON} alt="financial planning icon" />
          <div>
            <Text align="center" size="large" primary title bold>
              {`Financial Planning`}
            </Text>
            <br />
            <Text align="center" size="small" gray>
              ช่วยคุณวางแผนการเงินให้ลูกค้าได้ง่ายกว่าที่เคย
              รวบรวมข้อมูลทางการเงิน สรุปกรมธรรม์ของลูกค้าสร้างเอกสารนำเสนอ
              เพื่อใช้ประกอบการให้บริการ
            </Text>
          </div>
        </InfoWrapper>
        <InfoWrapper>
          <Icon src={TEAM_ICON} alt="team icon" />
          <div>
            <Text align="center" size="large" primary title bold>
              {`Team & Report`}
            </Text>
            <br />
            <Text align="center" size="small" gray>
              บริหารทีมครบวงจรจบได้ในที่เดียว
              <br />
              ทำงานร่วมกับทีมของคุณอย่างใกล้ชิด
              <br />
              ติดตามผลการทำงานของทีมได้ตลอดเวลาไม่มีพลาด
            </Text>
          </div>
        </InfoWrapper>
        <InfoWrapper>
          <Icon src={KNOWLEDGE_ICON} alt={`knowledge & product icon`} />
          <div>
            <Text align="center" size="large" primary title bold>
              {`Knowledge & Products`}
            </Text>
            <br />
            <Text align="center" size="small" gray>
              บทความเพื่อตัวแทนประกันและที่ปรึกษาการเงิน
              พร้อมด้วยแหล่งข้อมูลผลิตภัณฑ์ทางการเงินเพื่อ ช่วยในการบริการ
            </Text>
          </div>
        </InfoWrapper>
      </InfoContainer>
    </Container>
  )
}

export default Info

const Container = styled.div`
  background-image: url('${(p) => p.src}');
  background-repeat: no-repeat;
  background-size: 1600px;
  background-position: top -1060px right -467px;

  @media screen and (max-width: 550px) {
    background-position: top -1200px right -600px;
  }
`
const Banner = styled.img`
  width: 100%;
`
const PlanInfo = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #8b63ef 13.02%, #ffffff 41%);
`
const Plan = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 24px;
  @media screen and (max-width: 550px) {
    flex-direction: column;
    align-items: center;
    width: 280%;
  }
`
const ContainerPlan = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  padding-top: 64px;
  @media screen and (max-width: 550px) {
    img {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }
  img {
    width: 50%;
    height: auto;
    :first-child {
      margin-right: 6px;
    }
    :last-child {
      margin-left: 6px;
    }
  }
`
const Btn = styled(BaseBtn)`
  border-radius: 16px;
  height: 64px;
  width: 100%;
  text-align: center;
  border: 0px;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 104px;
`
const ConditionContainer = styled.div`
  display: flex;
  width: 65%;
  justify-content: space-around;
  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`
const Condition = styled.div`
  width: 50%;
  align-items: flex-start;
  @media screen and (max-width: 550px) {
    width: 100%;
  }
`
const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`
const TopicContainer = styled(GridWrapper)`
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'header-text group-image';
  width: 100%;
  max-width: 1194px;
  margin: 0 auto;
  padding: 100px 70px 70px;

  @media screen and (max-width: 550px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'group-image' 'header-text';
    padding: 100px 35px 70px;
    row-gap: 50px;
  }
`
const GroupImage = styled.div`
  grid-area: group-image;
  position: relative;
  width: 100%;
`
const Image = styled.img`
  display: block;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
`
const BadgeImage = styled.img`
  position: absolute;
  top: -50px;
  right: -50px;

  @media screen and (max-width: 550px) {
    width: 120px;
    top: -40px;
    right: -25px;
  }
`
const HeadText = styled.div`
  grid-area: header-text;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 45px;

  h1 {
    font-size: 48px;
    line-height: 1.4;
  }

  @media screen and (max-width: 1024px) {
    align-items: center;

    h1 {
      font-size: 32px;
    }
  }

  @media screen and (max-width: 550px) {
    align-items: flex-start;
  }
`
const InfoContainer = styled(GridWrapper)`
  width: 100%;
  max-width: 874px;
  margin: 0 auto 64px;
  gap: 50px;

  @media screen and (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`
const InfoWrapper = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 8px;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
`
const Icon = styled.img`
  margin-right: 26px;
  width: 94px;
  height: 94px;
  @media screen and (max-width: 768px) {
    margin-right: 0;
  }
`
