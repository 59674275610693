import React from 'react'
import styled from 'styled-components'

import { format } from 'utils'
import { UserImg } from 'components/display'

const Message = (props) => {
  const { item } = props
  const getName = () => {
    const name = `${item.name || ''}`

    const isAdmin = item.admin_id
    return isAdmin ? `ทีมงาน ${name}` : 'คุณ'
  }

  return (
    <PageView>
      <Img>
        <UserImg img_url={item.img_url} />
      </Img>
      <div>
        <Actor>
          {getName()}
          <span>{format.toThaiDateTime(item.created_at)}</span>
        </Actor>
        <Text>{item.text}</Text>
      </div>
    </PageView>
  )
}

const PageView = styled.div`
  display: grid;
  grid-template-columns: min-content 7fr;
  column-gap: 18px;
  padding: 10px 0;
`

const Img = styled.div`
  text-align: left;
`

const Actor = styled.div`
  color: #a0a3bd;
  display: block;
  span {
    float: right;
  }
`

const Text = styled.div`
  padding: 2px 0 10px 0;

  border: 0 solid #d9dbe9;
  border-bottom-width: 1px;
`

export default Message
