import React from 'react'
import styled from 'styled-components'

import { BackLink } from 'components/link'

import Menu from './Menu/Menu'
import { MetaData } from '../../../components/display'

const AccountLayout = (props) => {
  const { onClick, title, description, backTo, backText } = props
  return (
    <Menu>
      <MetaData title={title} description={description} />
      <Wrapper>
        <BackLinkBox>
          <BackLink onClick={onClick} to={backTo} text={backText} />
        </BackLinkBox>
        {props.children}
      </Wrapper>
    </Menu>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding-top: 42px;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

const BackLinkBox = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export default AccountLayout
