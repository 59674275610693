import React, { useState } from 'react'

import { helper } from 'utils'

import { FieldInput } from './index'

const CreditCardInput = (props) => {
  const [mock, setMock] = useState('')
  const onChangeCard = (val, name) => {
    const originalValue = String(val).replaceAll('-', '')
    if (Number.isNaN(Number(originalValue))) return

    const newValue = helper.formatPattern(originalValue, 'xxxx-xxxx-xxxx-xxxx')
    setMock(newValue)
    props.onChange({
      [name]: originalValue,
    })
  }

  return (
    <FieldInput
      label="หมายเลขหน้าบัตร 16 หลัก"
      name={props.name}
      value={mock}
      max={19}
      onChange={onChangeCard}
      disabled={props.disabled}
      errorText={props.error && 'หมายเลขไม่ถูกต้อง'}
    />
  )
}

export default CreditCardInput
