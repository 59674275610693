import React from 'react'
import styled from 'styled-components'
import { Col } from 'antd'

const ColItem = (props) => {
  const { text, children, line, isMobile = false, isTablet = false } = props

  let content
  if (isTablet) {
    content = <PageTabletView {...props}>{text || children}</PageTabletView>
  } else if (isMobile) {
    content = <PageMobileView {...props}>{text || children}</PageMobileView>
  } else {
    content = <PageView {...props}>{text || children}</PageView>
  }

  const css = line ? { borderRight: '1px solid #d9dbe9' } : undefined
  return (
    <Col {...props} style={css}>
      {content}
    </Col>
  )
}

const PageView = styled.div`
  padding-right: 8px;
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent};
`

const PageTabletView = styled.div`
  padding-right: 8px;
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent};

  @media screen and (max-width: 800px) {
    padding: 8px 4px 0 0;
  }
`

const PageMobileView = styled.div`
  padding-right: 4px;
  min-height: 52px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 465px) {
    padding: 8px 4px 0 0;
  }
`

export default ColItem
