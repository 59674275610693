import React from 'react'

import Button from 'components/button/Button'
import { Phone } from 'icon'

const PhoneBtn = (props) => {
  const {
    text = 'เข้าสู่ระบบผ่าน Phone',
    disabled = false,
    loading = false,
    onClick,
  } = props
  return (
    <Button
      startIcon={<Phone />}
      disabled={disabled}
      text={text}
      loading={loading}
      onClick={onClick}
    />
  )
}

export default PhoneBtn
