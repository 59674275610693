import React from 'react'
import styled from 'styled-components'

import { Close } from 'icon'
import IconButton from 'components/button/IconButton'

const Modal = (props) => {
  const { children, isOpened, setOpen } = props

  const closeModal = () => setOpen(false)
  const onClickInside = (e) => {
    e.stopPropagation()
  }

  if (isOpened) {
    return (
      <Wrapper onClick={closeModal}>
        <InnerWrapper onClick={onClickInside}>
          <CloseIcon>
            <IconButton onClick={closeModal}>
              <Close />
            </IconButton>
          </CloseIcon>
          {children}
        </InnerWrapper>
      </Wrapper>
    )
  }

  return null
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;

  padding: 0 24px;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(20, 20, 43, 0.5);
`

const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  position: relative;

  width: 100%;
  height: 100%;
  max-width: 641px;
  max-height: 466px;
  border-radius: 16px;
  padding: 0 24px;

  //max-width: 560px;
`

const CloseIcon = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`

export default Modal
