import React from 'react'

const Text = (props) => {
  const { text, marginTop, marginBottom } = props
  const css = {
    color: '#4E4B66',
    fontSize: '16px',
    lineHeight: '28px',
    marginTop,
    marginBottom,
  }
  return <div style={css}>{text}</div>
}

export default Text
