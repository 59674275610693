import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import Menu from 'pages/account/components/Menu/Menu'
import { Text } from 'components/typo'
import { Button } from 'components/button'
import { BackLink } from 'components/link'
import { MetaData } from 'components/display'
import { TextArea, Dropdown } from 'components/input'
import { message } from 'utils'

const IssueProblemDetail = (props) => {
  const [loading, setLoading] = useState(false)
  const [note, setNote] = useState('')
  const [categoryId, setCategoryId] = useState(null)

  const { history } = props

  const onGetList = useCallback(async () => {
    try {
      setLoading(true)

      await props.problem.getIssueCategoryList()
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    }
    setLoading(false)
  }, [props.problem])

  useEffect(() => {
    onGetList()
  }, [onGetList])

  const onCreateIssue = async () => {
    try {
      setLoading(true)
      await props.problem.createIssueProblem({
        category_id: categoryId,
        note,
      })
      setLoading(false)
      history.push('/account/issue-problem')
    } catch (e) {
      message.error({
        title: `สร้างรายการ`,
        message: e.message,
      })
    }
    setLoading(false)
  }

  const { issue_category_list = [] } = props.problem.toJS()
  const disabled = categoryId === null || note === ''
  return (
    <Menu active="ศูนย์ช่วยเหลือ">
      <MetaData
        title={`สร้างศูนย์ช่วยเหลือ`}
        description="สร้างศูนย์ช่วยเหลือ"
      />
      <Wrapper>
        <BackArea>
          <BackLink to="/account/issue-problem" text="ศูนย์ช่วยเหลือ" />
        </BackArea>
        <Title>
          <Text size="large" bold>
            New Issue
          </Text>
        </Title>
        <InputBox>
          <Dropdown
            label="หัวข้อ"
            value={categoryId}
            options={issue_category_list}
            onChange={(obj) => setCategoryId(obj)}
            disabled={loading}
          />
          <TextArea
            label="ข้อความ"
            value={note}
            onChange={(val) => setNote(val)}
            disabled={loading}
          />
        </InputBox>
        <Button
          large
          disabled={disabled}
          loading={loading}
          text="โพสต์"
          color="primary"
          onClick={onCreateIssue}
        />
      </Wrapper>
    </Menu>
  )
}

const BackArea = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 25px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 325px;

  @media screen and (max-width: 768px) {
    margin: 0 auto;
  }
`

const Title = styled.div`
  margin: 16px 0;
  text-align: center;
`

const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 40px;
`

export default inject('problem')(observer(IssueProblemDetail))
