import React from 'react'

const Row = (props) => {
  const { children } = props

  const css = {
    display: 'flex',
    alignItems: 'stretch',
    width: '100%',
  }
  return <div style={css}>{children}</div>
}

export default Row
