import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import Menu from 'pages/account/components/Menu/Menu'
import { Text } from 'components/typo'
import { MetaData } from 'components/display'
import Loading from 'components/loading'
import { Star } from 'icon'
import { message } from 'utils'

const MyRating = (props) => {
  const [loading, setLoading] = useState(false)
  const { rating = {} } = props.account.toJS()
  const { level } = rating

  const onInit = useCallback(async () => {
    try {
      setLoading(true)

      await props.account.getRating()
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    }
    setLoading(false)
  }, [props.account])

  useEffect(() => {
    onInit()
  }, [onInit])

  const onUpdate = async (val) => {
    try {
      setLoading(true)

      await props.account.updateRating({ level: val })
    } catch (e) {
      message.error({
        title: `บันทึก`,
        message: e.message,
      })
    }
    setLoading(false)
  }

  const getSelected = () => {
    const selected = { text: '' }
    switch (level) {
      case 'perfect':
        selected.perfect = true
        selected.high = true
        selected.normal = true
        selected.low = true
        selected.bad = true
        selected.text = 'ดีมาก'
        break
      case 'high':
        selected.high = true
        selected.normal = true
        selected.low = true
        selected.bad = true
        selected.text = 'ดี'
        break
      case 'normal':
        selected.normal = true
        selected.low = true
        selected.bad = true
        selected.text = 'พอใช้'
        break
      case 'low':
        selected.low = true
        selected.bad = true
        selected.text = 'ไม่พอใจ'
        break
      case 'bad':
        selected.bad = true
        selected.text = 'ต้องปรับปรุง'
        break
      default:
        console.log('not found')
    }

    return selected
  }

  const selected = getSelected()
  const content = levelList.map((val, i) => {
    return (
      <StarItem key={i} onClick={() => onUpdate(val)}>
        <Star isSelect={selected[val]} />
      </StarItem>
    )
  })

  return (
    <Menu>
      <MetaData title="ให้คะแนนผู้ใช้งาน" description="ให้คะแนนผู้ใช้งาน" />
      <Content>
        <Title>
          <Text size="large" title bold>
            ให้คะแนนผู้ใช้งาน
          </Text>
        </Title>

        <Text size="small" gray>
          ความพึงพอใจของคุณ
        </Text>
        <Loading loading={loading}>
          <StarList>{content}</StarList>
          <Text size="small" gray>
            {selected.text}
          </Text>
        </Loading>
      </Content>
    </Menu>
  )
}

const levelList = ['bad', 'low', 'normal', 'high', 'perfect']

const Content = styled.div`
  width: 100%;
  max-width: 730px;
  margin: 0 auto;
  text-align: center;
`

const Title = styled.div`
  margin: 36px 0 16px 0;
`

const StarList = styled.div`
  padding-top: 16px;
`

const StarItem = styled.span`
  margin: 0 4px;
  cursor: pointer;
`

export default inject('account')(observer(MyRating))
