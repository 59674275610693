import React from 'react'

import Row from './Row'
import Col from './Col'

const Title = (props) => {
  const { title, detail = '' } = props

  const css = {
    padding: '4px 0px',
    borderBottom: '1px solid #D9DBE9',
  }

  const cssTitle = {
    color: '#14142B',
    fontSize: '32px',
    fontWeight: 'bold',
  }

  const cssDetail = {
    color: '#4E4B66',
    fontSize: '24px',
  }

  const textAlign = 'right'
  return (
    <div style={css}>
      <Row>
        <Col
          size={detail === '' ? '100%' : '40%'}
          paddingTop="0px"
          paddingBottom="0px"
        >
          <span style={cssTitle}>{title}</span>
        </Col>

        <Col
          size={detail === '' ? '0%' : '60%'}
          paddingTop="0px"
          paddingBottom="0px"
          textAlign={textAlign}
        >
          <span style={cssDetail}>{detail}</span>
        </Col>
      </Row>
    </div>
  )
}

export default Title
