import React from 'react'

const Finance = (props) => {
  const cssCircle = {
    marginTop: '24px',
    padding: '6px 24px',
    borderRadius: '16px',
    border: '1px solid #D9DBE9',
    width: '325px',
  }

  const cssLabel = {
    color: '#6E7191',
    fontSize: '14px',
  }

  const cssValue = {
    color: '#14142B',
    fontSize: '16px',
  }

  const cssOr = {
    color: '#4E4B66',
    fontSize: '18px',
    marginTop: '16px',
  }

  const cssLink = {
    color: '#5F2EEA',
    fontSize: '18px',
    marginLeft: '4px',
  }
  return (
    <>
      <div style={cssCircle}>
        <div style={cssLabel}>ระดับความเสี่ยง</div>
        <div style={cssValue}>ความเสี่ยงกลาง</div>
      </div>

      <div style={cssOr}>
        หรือ
        <span style={cssLink}>ลองทำแบบทดสอบระดับความเสี่ยงที่ยอมรับได้</span>
      </div>
    </>
  )
}

export default Finance
