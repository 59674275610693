import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { size = 1 } = props
  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`

  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d="M12.0008 3C7.50078 3 3.20078 5.7 0.500781 10.2C-0.0992188 11.3 -0.0992188 12.6 0.500781 13.7C3.20078 18.2 7.50078 20.9 12.0008 20.9C16.6008 20.9 20.8008 18.3 23.5008 13.7C24.1008 12.6 24.1008 11.3 23.5008 10.2C20.8008 5.6 16.6008 3 12.0008 3ZM21.8008 12.7C19.5008 16.7 15.9008 19 12.0008 19C8.10078 19 4.60078 16.7 2.20078 12.7C1.90078 12.2 1.90078 11.7 2.20078 11.2C4.60078 7.2 8.10078 4.9 12.0008 4.9C15.9008 4.9 19.4008 7.2 21.8008 11.2C22.1008 11.7 22.1008 12.3 21.8008 12.7Z" fill="#14142B"/>
        <path d="M12 8C9.8 8 8 9.8 8 12C8 14.2 9.8 16 12 16C14.2 16 16 14.2 16 12C16 9.8 14.2 8 12 8ZM12 14C10.9 14 10 13.1 10 12C10 10.9 10.9 10 12 10C13.1 10 14 10.9 14 12C14 13.1 13.1 14 12 14Z" fill="#14142B"/>
    </svg>
  )
}

// eslint-enable
export default Logo
